import { useState } from 'react';

import { gql } from '@apollo/client';
import { Stack } from '@mui/material';

import { FETCH_POLICIES } from 'constants/globalConstants';
import {
  getAutodeskGlueFolders as GET_AUTODESK_GLUE_FOLDERS,
  getAutodeskGlueProjects as GET_AUTODESK_GLUE_PROJECTS
} from 'graphql/queries';
import useLazyPaginatedQuery from 'hooks/useLazyPaginatedQuery';
import { DROPDOWN_DEFAULT_PG_SIZE, MAX_DROPDOWN_ITEMS_IN_VIEW } from 'modules/Sync/Constants/constants';
import { useCopyFilesFormContext } from 'modules/Sync/WorkflowEditor/Contexts/CopyFilesContext';
import GlueFoldersDropdown from 'modules/Sync/WorkflowEditor/Forms/CopyFiles/Autodesk/Glue/GlueFoldersDropdown';
import { useGlueMountHook } from 'modules/Sync/WorkflowEditor/Forms/CopyFiles/Autodesk/Glue/useGlueMountHook';
import FiltersAutocompleteDropdown from 'modules/Sync/WorkflowEditor/Forms/Shared/CopyFilesComponents/FiltersAutocompleteDropdown';
import { multiSelectFolders } from 'modules/Sync/WorkflowEditor/Forms/Shared/CopyFilesComponents/FoldersSelectDropdown/folderTreeItemHelper';
import DropdownFormControl from 'modules/Sync/WorkflowEditor/Utils/DropdownFormControl';

const { fetchPolicy: FETCH_POLICY } = FETCH_POLICIES.CACHE_AND_NETWORK;
const emptyFolderState = { folders: [], folder: { id: '', name: '' }, isSubmitted: false };

const AutodeskGlue = ({
  connectionId = '',
  connectionType = '',
  nodeId = '',
  fileTypeFilters = [],
  showFilters = false,
  isSource = false,
  allowPrivateFiles = false
}) => {
  const { getConnectorNodeValues, updateConnectorNodeValues } = useCopyFilesFormContext();
  const formValues = getConnectorNodeValues({ isSource, nodeId });
  const [parentFolderId, setParentFolderId] = useState('');
  const { project, folders = [], uiFolders = [] } = formValues;

  const [
    { paginatedLazyLoad: lazyLoadProjects, paginationHandler: projectsPaginationHandler },
    { loading: isLoadingProjects, data: { getAutodeskGlueProjects: AutodeskGlueProjects = [] } = {} }
  ] = useLazyPaginatedQuery(gql(GET_AUTODESK_GLUE_PROJECTS), FETCH_POLICY, MAX_DROPDOWN_ITEMS_IN_VIEW);

  const onScrollProjects = () => projectsPaginationHandler(AutodeskGlueProjects?.length, { connectionId });

  const fetchProjectsOnLoad = () => {
    if (project?.id) lazyLoadProjects({ skip: null, take: null, connectionId });
    if (!project?.id) lazyLoadProjects({ skip: AutodeskGlueProjects?.length, connectionId });
  };

  const [
    { lazyLoad: loadFolders, paginationHandler: fetchMoreRootFolders },
    { loading: isLoadingFolders, data: { getAutodeskGlueFolders: AutodeskGlueFolders = [] } = {} }
  ] = useLazyPaginatedQuery(gql(GET_AUTODESK_GLUE_FOLDERS), FETCH_POLICY, DROPDOWN_DEFAULT_PG_SIZE);

  const fetchOnMountGlueFolders = project?.id && !isLoadingProjects && AutodeskGlueProjects?.length;

  const updateFormValues = (props) => updateConnectorNodeValues({ nodeId, isSource, props });

  const selectProjectHandler = (event, childElement) => {
    event.preventDefault();
    const projectId = event.target.value;
    updateFormValues({
      project: { id: projectId, name: childElement?.props?.dataText },
      projects: [{ id: projectId, name: childElement?.props?.dataText }],
      ...emptyFolderState
    });
    loadFolders({ connectionId, projectId, skip: 0, take: DROPDOWN_DEFAULT_PG_SIZE });
  };

  const selectFolderHandler = (event, id, children) =>
    multiSelectFolders({
      event,
      id,
      folders,
      uiFolders,
      children,
      parentFolderId,
      setParentFolderId,
      updateFormValues
    });

  const setParentFolderIdOnStackLoad = (parentFolderId) => setParentFolderId(parentFolderId);

  useGlueMountHook({
    connectionId,
    connectionType,
    nodeId,
    project,
    formValues,
    updateFormValues,
    loadFolders,
    fetchOnMountGlueFolders,
    fetchProjectsOnLoad
  });

  return (
    <Stack spacing={2}>
      <DropdownFormControl
        title="Project"
        id="project"
        labelId="project"
        label="project"
        value={project?.id}
        onChange={selectProjectHandler}
        loading={isLoadingProjects}
        items={AutodeskGlueProjects}
        fetchPaginatedRecords={onScrollProjects}
        required
      />

      <GlueFoldersDropdown
        connectionId={connectionId}
        folders={AutodeskGlueFolders}
        disabled={isLoadingProjects || !project?.id || !AutodeskGlueProjects?.length}
        onSelect={selectFolderHandler}
        isSource={isSource}
        isLoadingFolders={isLoadingFolders}
        nodeId={nodeId}
        required
        onFolderStackLoad={setParentFolderIdOnStackLoad}
        fetchMoreRootFolders={fetchMoreRootFolders}
        multiple={isSource}
      />

      <FiltersAutocompleteDropdown
        allowPrivateFiles={allowPrivateFiles}
        showFilters={showFilters}
        fileTypeFilters={fileTypeFilters}
        isSource={isSource}
      />
    </Stack>
  );
};

export default AutodeskGlue;
