import React from 'react';

import { gql, useLazyQuery } from '@apollo/client';

import {
  getProcoreProjects as GET_PROCORE_PROJECTS,
  getNewformaProjects as GET_NEWFORMA_PROJECTS,
  getPlangridProjects as GET_PLANGRID_PROJECTS,
  getAutodeskFieldProjects as GET_AUTODESKFIELD_PROJECTS,
  getAutodeskGlueProjects as GET_AUTODESKGLUE_PROJECTS,
  getAutodeskForgeProjects as GET_AUTODESKFORGE_PROJECTS,
  getSharepointSites as GET_SHAREPOINT_SITES,
  getProcoreCompanies as GET_PROCORE_COMPANIES,
  getAutodeskForgeHubs as GET_AUTODESKFORGE_HUBS,
  getProcoreFolderName as GET_PROCORE_FOLDER_NAME,
  getNewformaFolderName as GET_NEWFORMA_FOLDER_NAME,
  getPlangridFolderName as GET_PLANGRID_FOLDER_NAME,
  getSharepointFolderName as GET_SHAREPOINT_FOLDER_NAME,
  getAutodeskFieldFolderName as GET_AUTODESKFIELD_FOLDER_NAME,
  getAutodeskGlueFolderName as GET_AUTODESKGLUE_FOLDER_NAME,
  getAutodeskForgeItemName as GET_AUTODESKFORGE_ITEM_NAME
} from 'graphql/queries';
import { CONNECTION_TYPES } from 'modules/Sync/Constants/constants';

const FetchWorkflowDataContext = React.createContext();

// eslint-disable-next-line max-lines-per-function
const FetchWorkflowDataProvider = ({ children }) => {
  const [loadProcoreProjects] = useLazyQuery(gql(GET_PROCORE_PROJECTS));
  const [loadNewformaProjects] = useLazyQuery(gql(GET_NEWFORMA_PROJECTS));
  const [loadPlangridProjects] = useLazyQuery(gql(GET_PLANGRID_PROJECTS));
  const [loadAutodeskFieldProjects] = useLazyQuery(gql(GET_AUTODESKFIELD_PROJECTS));
  const [loadAutodeskGlueProjects] = useLazyQuery(gql(GET_AUTODESKGLUE_PROJECTS));
  const [loadAutodeskforgeProjects] = useLazyQuery(gql(GET_AUTODESKFORGE_PROJECTS));
  const [loadSharepointSites] = useLazyQuery(gql(GET_SHAREPOINT_SITES));

  const [loadProcoreCompanies] = useLazyQuery(gql(GET_PROCORE_COMPANIES));
  const [loadAutodeskforgeHubs] = useLazyQuery(gql(GET_AUTODESKFORGE_HUBS));

  const [loadProcoreFolderName] = useLazyQuery(gql(GET_PROCORE_FOLDER_NAME));
  const [loadNewformaFolderName] = useLazyQuery(gql(GET_NEWFORMA_FOLDER_NAME));
  const [loadPlangridFolderName] = useLazyQuery(gql(GET_PLANGRID_FOLDER_NAME));
  const [loadSharepointFolderName] = useLazyQuery(gql(GET_SHAREPOINT_FOLDER_NAME));
  const [loadAutodeskFieldFolderName] = useLazyQuery(gql(GET_AUTODESKFIELD_FOLDER_NAME));
  const [loadAutodeskGlueFolderName] = useLazyQuery(gql(GET_AUTODESKGLUE_FOLDER_NAME));
  const [loadAutodeskforgeItem] = useLazyQuery(gql(GET_AUTODESKFORGE_ITEM_NAME));

  const getProjectName = async (data, connectionType) => {
    switch (connectionType) {
      case CONNECTION_TYPES.PROCORE:
        return getProcoreProjectName(data);
      case CONNECTION_TYPES.NEWFORMA:
        return getNewformaProjectName(data);
      case CONNECTION_TYPES.PLANGRID:
        return getPlangridProjectName(data);
      case CONNECTION_TYPES.AUTODESKFIELD:
        return getAutodeskFieldProjectName(data);
      case CONNECTION_TYPES.AUTODESKGLUE:
        return getAutodeskGlueProjectName(data);
      case CONNECTION_TYPES.AUTODESKHQ:
      case CONNECTION_TYPES.AUTODESKTEAMDOCS:
        return getAutodeskForgeProjectName(data);
      case CONNECTION_TYPES.SHAREPOINT:
        return getSharepointProjectName(data);
      default:
        return false;
    }
  };

  const getProcoreProjectName = async (data) => {
    const { connectionId, companyId } = data;
    const { data: { getProcoreProjects: ProcoreProjects = [] } = {} } = await loadProcoreProjects({
      variables: { query: { connectionId, companyId } }
    });

    return ProcoreProjects;
  };

  const getNewformaProjectName = async (data) => {
    const { connectionId } = data;
    const { data: { getNewformaProjects: NewformaProjects = [] } = {} } = await loadNewformaProjects({
      variables: { query: { connectionId } }
    });
    return NewformaProjects;
  };

  const getSharepointProjectName = async (data) => {
    const { connectionId } = data;
    const { data: { getSharepointSites: SharepointSites = [] } = {} } = await loadSharepointSites({
      variables: { query: { connectionId } }
    });
    return SharepointSites;
  };

  const getPlangridProjectName = async (data) => {
    const { connectionId } = data;
    const { data: { getPlangridProjects: PlangridProjects = [] } = {} } = await loadPlangridProjects({
      variables: { query: { connectionId } }
    });
    return PlangridProjects;
  };
  const getAutodeskFieldProjectName = async (data) => {
    const { connectionId } = data;
    const { data: { getAutodeskFieldProjects: AutodeskFieldProjects = [] } = {} } = await loadAutodeskFieldProjects({
      variables: { query: { connectionId } }
    });
    return AutodeskFieldProjects;
  };
  const getAutodeskGlueProjectName = async (data) => {
    const { connectionId } = data;
    const { data: { getAutodeskGlueProjects: AutodeskGlueProjects = [] } = {} } = await loadAutodeskGlueProjects({
      variables: { query: { connectionId } }
    });
    return AutodeskGlueProjects;
  };
  const getAutodeskForgeProjectName = async (data) => {
    const { connectionId, companyId: hubId } = data;
    const { data: { getAutodeskForgeProjects: AutodeskForgeProjects = [] } = {} } = await loadAutodeskforgeProjects({
      variables: { query: { connectionId, hubId } }
    });
    return AutodeskForgeProjects;
  };

  const getCompanyName = async (data, connectionType) => {
    if (!data.companyId) return null;
    switch (connectionType) {
      case CONNECTION_TYPES.PROCORE:
        return getProcoreCompanyName(data);
      case CONNECTION_TYPES.AUTODESKTEAMDOCS:
      case CONNECTION_TYPES.AUTODESKHQ:
        return getAutodeskForgeCompanyName(data);
      default:
        return null;
    }
  };

  const getProcoreCompanyName = async (data) => {
    const { connectionId } = data;
    const { data: { getProcoreCompanies: ProcoreCompanies = [] } = {} } = await loadProcoreCompanies({
      variables: { query: { connectionId } }
    });
    return ProcoreCompanies;
  };

  const getAutodeskForgeCompanyName = async (data) => {
    const { connectionId } = data;
    const { data: { getAutodeskForgeHubs: AutodeskForgeCompanies = [] } = {} } = await loadAutodeskforgeHubs({
      variables: { query: { connectionId } }
    });
    return AutodeskForgeCompanies;
  };

  const getAutodeskFolderName = (response) => response?.name?.split('/')?.pop() || '';

  const getFolderName = async (data, connectionType) => {
    switch (connectionType) {
      case CONNECTION_TYPES.PROCORE:
        return getProcoreFolderName(data);
      case CONNECTION_TYPES.NEWFORMA:
        return getNewformaFolderName(data);
      case CONNECTION_TYPES.PLANGRID:
        return getPlangridFolderName(data);
      case CONNECTION_TYPES.SHAREPOINT:
        return getSharepointFolderName(data);
      case CONNECTION_TYPES.AUTODESKFIELD:
        return getAutodeskFieldFolderName(data);
      case CONNECTION_TYPES.AUTODESKGLUE:
        return getAutodeskGlueFolderName(data);
      case CONNECTION_TYPES.WINDOWS:
        return getWindowsFoldername(data);
      case CONNECTION_TYPES.AUTODESKTEAMDOCS:
      case CONNECTION_TYPES.AUTODESKHQ:
        return getAutodeskForgeFolderName(data);
      default:
        return null;
    }
  };

  const getProcoreFolderName = async (data) => {
    const { connectionId, companyId, projectId, folderId, connectionTypeId } = data;
    const { data: { getProcoreFolderName: ProcoreFolderName = {} } = {} } = await loadProcoreFolderName({
      variables: { query: { connectionId, companyId, projectId, folderId, connectionTypeId } }
    });
    return ProcoreFolderName?.name || '';
  };
  const getNewformaFolderName = async (data) => {
    const { connectionId, folderId } = data;
    const { data: { getNewformaFolderName: NewformaFolderName = {} } = {} } = await loadNewformaFolderName({
      variables: { query: { connectionId, folderId } }
    });
    return NewformaFolderName?.name || '';
  };

  const getPlangridFolderName = async (data) => {
    const { connectionId, folderId, projectId } = data;
    const { data: { getPlangridFolderName: PlangridFolderName = {} } = {} } = await loadPlangridFolderName({
      variables: { query: { connectionId, projectId, folderId } }
    });
    return PlangridFolderName?.name || '';
  };

  const getSharepointFolderName = async (data) => {
    const { connectionId, projectId: SiteId, folderId } = data;
    const { data: { getSharepointFolderName: SharepointFolderName = {} } = {} } = await loadSharepointFolderName({
      variables: { query: { connectionId, SiteId, folderId } }
    });
    return SharepointFolderName?.name || '';
  };

  const getAutodeskFieldFolderName = async (data) => {
    const { connectionId, folderId, projectId } = data;
    const { data: { getAutodeskFieldFolderName: AutodeskFieldFolderName = {} } = {} } =
      await loadAutodeskFieldFolderName({
        variables: { query: { connectionId, projectId, folderId } }
      });
    return getAutodeskFolderName(AutodeskFieldFolderName);
  };

  const getAutodeskGlueFolderName = async (data) => {
    const { connectionId, folderId, projectId } = data;
    const { data: { getAutodeskGlueFolderName: AutodeskGlueFolderName = {} } = {} } = await loadAutodeskGlueFolderName({
      variables: { query: { connectionId, projectId, folderId } }
    });
    return getAutodeskFolderName(AutodeskGlueFolderName);
  };

  const getAutodeskForgeFolderName = async (data) => {
    const { connectionId, folderId, projectId, companyId: hubId } = data;
    const { data: { getAutodeskForgeItemName: AutodeskForgeFolderName = {} } = {} } = await loadAutodeskforgeItem({
      variables: { query: { connectionId, projectId, folderId, hubId } }
    });
    return getAutodeskFolderName(AutodeskForgeFolderName);
  };

  const getWindowsFoldername = (data) => {
    const { folderId } = data;
    return folderId.split('/').at(-1) || '';
  };

  const selectedStateObj = React.useMemo(
    () => ({
      getProjectName,
      getCompanyName,
      getFolderName
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return <FetchWorkflowDataContext.Provider value={selectedStateObj}>{children}</FetchWorkflowDataContext.Provider>;
};

const useFetchWorkflowDataContext = () => {
  const context = React.useContext(FetchWorkflowDataContext);
  if (context === undefined) {
    throw new Error('useFetchWorkflowDataContext must be used within a FetchWorkflowDataContext');
  }
  return context;
};

export { FetchWorkflowDataContext, FetchWorkflowDataProvider, useFetchWorkflowDataContext };
