import { useEffect, useMemo } from 'react';

import { gridPaginatedVisibleSortedGridRowEntriesSelector, useGridApiRef } from '@mui/x-data-grid-pro';

import { MAX_DATAGRID_ROWS_IN_VIEW } from 'modules/Sync/Constants/constants';
import { useSyncWebhooksContext } from 'modules/Sync/Webhooks/Contexts/WebhooksContext';
import WebhookDataGridComponent from 'modules/Sync/Webhooks/WebhooksDataGridTable/WebhookDataGridComponent';

import WebhookMenuItems from './WebhookMenuItems';
import WebhooksDataGridCell from './WebhooksDataGridCell';
import { getColumns, webhooksMapper } from './WebhooksDataGridTableHelper';

const WebhooksDataGridTable = ({ activeRows }) => {
  const { webhooks, webhooksPaginationHandler, loadingWebhooks, webhooksSortHandler, searchPhrase } =
    useSyncWebhooksContext();
  const { selectedWebhookIds, setSelectedWebhookIds, setSelectedWebhookDetails, setWebhookIdWithStatus } =
    useSyncWebhooksContext();

  const apiRef = useGridApiRef();

  const handleSort = (sortModel) =>
    sortModel.length ? webhooksSortHandler({ columnName: sortModel?.[0]?.field }) : null;

  const onScroll = () => webhooksPaginationHandler(webhooks?.syncWebhooks?.length, { searchPhrase });

  const handleCellComponent = ({ id, field, rowNode, row }) => (
    <WebhooksDataGridCell id={id} field={field} rowNode={rowNode} row={row} options={WebhookMenuItems(row)} />
  );

  const columns = getColumns({ renderCell: handleCellComponent, apiRef });

  const rows = useMemo(() => webhooksMapper(webhooks?.syncWebhooks), [webhooks]);

  useEffect(() => {
    if (!loadingWebhooks && apiRef?.current?.windowRef?.current && activeRows.length && rows.length) {
      const allRows = gridPaginatedVisibleSortedGridRowEntriesSelector(apiRef);
      const rowIndex = allRows?.findIndex(({ id }) => id === activeRows?.[0]);
      const indexToScrollAt =
        rowIndex + MAX_DATAGRID_ROWS_IN_VIEW >= allRows.length
          ? allRows.length - 1
          : rowIndex + MAX_DATAGRID_ROWS_IN_VIEW;
      apiRef?.current?.scrollToIndexes({ rowIndex: Number(indexToScrollAt) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiRef, activeRows.length, rows.length, loadingWebhooks]);

  return (
    <WebhookDataGridComponent
      rows={rows}
      columns={columns}
      apiRef={apiRef}
      onScroll={onScroll}
      handleSort={handleSort}
      activeRows={activeRows}
      loadingWebhooks={loadingWebhooks}
      selectedWebhookIds={selectedWebhookIds}
      setSelectedWebhookIds={setSelectedWebhookIds}
      setSelectedWebhookDetails={setSelectedWebhookDetails}
      setWebhookIdWithStatus={setWebhookIdWithStatus}
    />
  );
};

export default WebhooksDataGridTable;
