import { useSnackbar } from 'notistack';
import { v4 as uuidv4 } from 'uuid';

import { VARIANT_ERROR } from 'constants/snackbarConstants';
import {
  ACTION_CANVAS_NODE,
  CANVAS_NODE_TYPES,
  CANVAS_VALIDATIONS,
  CONNECTOR_CANVAS_NODE,
  filterTypes
} from 'modules/Sync/Constants/constants';
import { useSyncWorkflowEditorContext } from 'modules/Sync/WorkflowEditor/Contexts/WorkflowEditorContext';
import { isNodeValid } from 'modules/Sync/WorkflowEditor/helpers/canvasHelper';

const AUTO_HIDE_DURATION = 2500;

const useDropAction = ({
  reactFlowWrapper,
  editAction,
  deleteNodeAction,
  connectAction,
  unmapAction,
  syncNowAction
}) => {
  const {
    workflowElements,
    setWorkflowElements,
    reactFlowInstance,
    setActionId,
    setConnectorAlias,
    showAliasModal,
    setActionAlias,
    showActionAliasModal,
    setCurrentNodeStatus,
    setFilterType
  } = useSyncWorkflowEditorContext();
  const { enqueueSnackbar } = useSnackbar() || {};
  const dropAction = (event) => {
    event?.preventDefault();
    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
    const whichNodeType = event?.dataTransfer.getData('workflowEditor/nodeType');
    const nodeData = event?.dataTransfer.getData('workflowEditor/nodeData');

    if (!isNodeValid(whichNodeType, workflowElements)) {
      enqueueSnackbar(
        `Max of ${CANVAS_VALIDATIONS.MAX_ACTION_NODES_ALLOWED} Action Nodes are allowed at a time on the canvas`,
        {
          autoHideDuration: AUTO_HIDE_DURATION,
          ...VARIANT_ERROR
        }
      );
      return;
    }

    const position = reactFlowInstance.project({
      x: event?.clientX - reactFlowBounds.left,
      y: event?.clientY - reactFlowBounds.top
    });
    const actionId = `${whichNodeType}-tasks-${uuidv4()}`;
    let dropNodeData = {};
    if (nodeData) {
      dropNodeData = { ...JSON.parse(nodeData) };
    }
    const actionNodes = workflowElements.filter(({ type }) => type === ACTION_CANVAS_NODE);
    const connectorNodes = workflowElements.filter(
      ({ type, data: { connectionType = '' } = {} }) =>
        type === CONNECTOR_CANVAS_NODE && connectionType === dropNodeData?.connectionType
    );

    const nodeCount = whichNodeType === CANVAS_NODE_TYPES.ACTION ? actionNodes?.length : connectorNodes?.length || 0;
    const name = `${dropNodeData.name || dropNodeData?.taskDetail}`;
    dropNodeData = {
      ...dropNodeData,
      name: `${name} - ${nodeCount + 1}`,
      isAvailable: true,
      onEdit: editAction,
      onDelete: deleteNodeAction,
      onCustomConnect: connectAction,
      onUnMap: unmapAction,
      onSyncNow: syncNowAction
    };

    const newNode = {
      id: actionId,
      sourcePosition: 'right',
      type: whichNodeType === CANVAS_NODE_TYPES.ACTION ? ACTION_CANVAS_NODE : CONNECTOR_CANVAS_NODE,
      actionType: whichNodeType === CANVAS_NODE_TYPES.ACTION ? name : '',
      targetPosition: 'left',
      data: dropNodeData,
      position: {
        x: position.x - 60,
        y: position.y - 50
      }
    };
    setWorkflowElements((els) => els.concat(newNode));
    setActionId(actionId);
    setCurrentNodeStatus(true);
    setConnectorAlias(dropNodeData.name || dropNodeData?.taskDetail);
    setActionAlias(dropNodeData.name || dropNodeData?.taskDetail);
    setFilterType(filterTypes.ACTIVE);

    if (whichNodeType === CANVAS_NODE_TYPES.ACTION) showActionAliasModal();

    if (whichNodeType === CANVAS_NODE_TYPES.CONNECTOR) showAliasModal();
  };

  return { dropAction };
};

export default useDropAction;
