import client from 'apollo/client';
import { convertToCamelCase } from 'helpers/stringFunctions';
import { CONNECTION_TYPES, DRAWER_NAV_MENU_ITEMS } from 'modules/Sync/Constants/constants';
import AutodeskForge from 'modules/Sync/WorkflowEditor/Forms/PublishAction/Autodesk/Forge/Forge';

export const formatMappings = (connectorDropDownOptions) => {
  if (connectorDropDownOptions.length > 0) {
    connectorDropDownOptions = connectorDropDownOptions.filter(
      (options) =>
        options.data.connectionType === CONNECTION_TYPES.AUTODESKHQ ||
        options.data.connectionType === CONNECTION_TYPES.AUTODESKTEAMDOCS
    );
  }
  if (!connectorDropDownOptions) return [];

  return connectorDropDownOptions.map((options) => ({
    value: options.id,
    name: options.data.connectorAlias || options.data?.taskDetail,
    componentToRender: (props) => {
      const type = options.data.connectionType;
      const isForgeType = type === CONNECTION_TYPES.AUTODESKHQ || type === CONNECTION_TYPES.AUTODESKTEAMDOCS;
      return (
        isForgeType && (
          <AutodeskForge
            {...props}
            nodeId={options.id}
            connectionId={options.data?.nodeId || options.data?.taskId}
            connectionType={type}
          />
        )
      );
    }
  }));
};

export const getAvailableConnections = (nodes, isSource = false, defaultSelectedNodeId = '') => {
  if (isSource) {
    return nodes;
  }
  return nodes?.filter(({ id, data: { isAvailable } }) => isAvailable || defaultSelectedNodeId === id) || [];
};

export const getConnectorNodesWithEdges = (edges) =>
  edges.reduce((nodes, { source, target }) => {
    if (source.includes(DRAWER_NAV_MENU_ITEMS.CONNECTOR)) {
      nodes.push(source);
    }
    if (target.includes(DRAWER_NAV_MENU_ITEMS.CONNECTOR)) {
      nodes.push(target);
    }
    return nodes;
  }, []);

export const getActionNodesWithEdges = (edges) =>
  edges.reduce((nodes, { source, target }) => {
    if (source.includes(DRAWER_NAV_MENU_ITEMS.ACTION)) {
      nodes.push(source);
    }
    if (target.includes(DRAWER_NAV_MENU_ITEMS.ACTION)) {
      nodes.push(target);
    }
    return nodes;
  }, []);

const hasForgeConnection = (connections) =>
  connections?.includes(CONNECTION_TYPES.AUTODESKHQ) || connections?.includes(CONNECTION_TYPES.AUTODESKTEAMDOCS);

export const getSyncQueriesForRefetch = (connections) => {
  if (hasForgeConnection(connections))
    return ['GetAutodeskForgeHubs', 'GetAutodeskForgeProjects', 'GetAutodeskForgeSubItems'];
  return [];
};

export const getSyncFolderQueriesForConnection = (connections) => {
  if (hasForgeConnection(connections)) return ['GetAutodeskForgeSubItems'];
  return [];
};

export const refreshConnections = async ({ workflowMappings }) => {
  if (workflowMappings?.length) {
    const connectionsToRefresh = [
      ...new Set(
        ...workflowMappings?.map(({ source, destinations }) => [
          source?.connection?.type,
          ...destinations?.map(({ connection: { type } = {} }) => type)
        ])
      )
    ];

    getSyncQueriesForRefetch(connectionsToRefresh).forEach(async (query) => {
      await client.cache.evict({
        id: 'ROOT_QUERY',
        fieldName: convertToCamelCase(query)
      });
    });
  }
};
