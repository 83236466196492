const LogsDataGridTableColumns = (handleCellComponent) => [
  {
    field: 'name',
    headerName: 'Workflow / Mapping / Action',
    minWidth: 200,
    flex: 1.5,
    sortable: true,
    editable: false,
    hideable: false,
    renderCell: handleCellComponent
  },
  {
    field: 'numFiles',
    headerName: 'Files Synced / Total',
    flex: 1,
    minWidth: 50,
    sortable: false,
    editable: false,
    hideable: false,
    renderCell: handleCellComponent
  },
  {
    field: 'totalSize',
    headerName: 'File Size',
    flex: 1,
    minWidth: 50,
    sortable: false,
    editable: false,
    hideable: false,
    renderCell: handleCellComponent
  },
  {
    field: 'syncType',
    headerName: 'Sync Type',
    flex: 1,
    minWidth: 50,
    sortable: true,
    editable: false,
    hideable: false,
    renderCell: handleCellComponent
  },
  {
    field: 'source',
    headerName: 'Source',
    flex: 1,
    minWidth: 50,
    sortable: true,
    editable: false,
    hideable: false,
    renderCell: handleCellComponent
  },
  {
    field: 'destinations',
    headerName: 'Destination(s)',
    flex: 1,
    minWidth: 50,
    sortable: false,
    editable: false,
    hideable: false,
    renderCell: handleCellComponent
  },
  {
    field: 'lastSyncTime',
    headerName: 'Last Sync Time',
    flex: 1,
    minWidth: 50,
    sortable: true,
    renderCell: handleCellComponent
  },
  {
    field: '*download',
    headerName: '',
    flex: 0.35,
    minWidth: 35,
    hideable: false,
    sortable: false,
    resizable: false,
    renderCell: handleCellComponent
  }
];

export default LogsDataGridTableColumns;
