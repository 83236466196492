import { useEffect } from 'react';

import { FormControl, Typography } from '@mui/material';
import { useStoreState } from 'react-flow-renderer';
import { useForm } from 'react-hook-form';

import CustomModal from 'modules/Sync/components/CustomModal';
import { ControlTextField } from 'modules/Sync/Connections/ConnectionProperties/FormFields';
import useRenameAction from 'modules/Sync/WorkflowEditor/apiActions/renameAction';
import { useSyncWorkflowEditorContext } from 'modules/Sync/WorkflowEditor/Contexts/WorkflowEditorContext';
import useEditAction from 'modules/Sync/WorkflowEditor/editorHooks/useEditAction';
import useSaveMappingAction from 'modules/Sync/WorkflowEditor/editorHooks/useSaveMappingAction';
import ModalFooter from 'modules/Sync/Workflows/components/ModalFooter';

// eslint-disable-next-line max-lines-per-function
const ActionAliasModal = ({ actionAlias = '', actionId = '', open = false, elements = [], onClose, onSubmit }) => {
  const { saveMappingAction } = useSaveMappingAction();
  const { editAction } = useEditAction();
  const { setWorkflowMappings, workflowMappings, currentNodeStatus, mappingId } = useSyncWorkflowEditorContext();
  const { renameActionAlias } = useRenameAction();
  const methods = useForm({
    mode: 'all',
    defaultValues: { alias: actionAlias }
  });
  const {
    control,
    watch,
    handleSubmit,
    setError,
    reset,
    formState: { errors }
  } = methods;
  const { alias } = watch();
  const { solitaryFlowId = '' } = workflowMappings?.find(({ actionNodeId }) => actionNodeId === actionId) || {};
  const edges = useStoreState((store) => store.edges);
  const connectedAction = edges.some(({ source }) => source === actionId);

  const validateAlias = () => {
    if (!alias) {
      setError('alias', { type: 'required', message: 'Alias is Required' });
      return false;
    }

    const elementWithSameAlias = elements.find(
      ({ id: actionElementId, data: { actionAlias } = {} }) => actionAlias === alias && actionElementId !== actionId
    );

    if (elementWithSameAlias) {
      setError('alias', { type: 'unique', message: 'Alias must be unique' });
      return false;
    }
    return true;
  };

  const onModalClose = () => {
    if (currentNodeStatus) {
      return onClose();
    }
    if (validateAlias() || connectedAction) {
      return onClose?.(true);
    }
    return onClose();
  };

  const handleUpdateMappingAlias = ({ solitaryFlowId, actionAlias }) => {
    const updateMappings = workflowMappings.map((item) =>
      item?.solitaryFlowId === solitaryFlowId ? { ...item, actionAlias } : item
    );
    setWorkflowMappings(updateMappings);
  };

  const handleSaveMappingAction = ({ successMessage = 'Action alias updated', solitaryFlowId, actionAlias }) => {
    saveMappingAction({ successMessage });
    handleUpdateMappingAlias({ solitaryFlowId, actionAlias });
  };

  const onModalSubmit = handleSubmit((formValues) => {
    if (validateAlias()) {
      const actionAlias = formValues?.alias;
      onSubmit?.(actionId, { actionAlias }, () =>
        solitaryFlowId && actionAlias
          ? renameActionAlias({
              mappingId,
              solitaryFlowId,
              actionAlias,
              onCompleted: () => handleSaveMappingAction({ solitaryFlowId, actionAlias }),
              successMessage: ''
            })
          : handleSaveMappingAction({ successMessage: '' })
      );
      onClose(true);
      if (!solitaryFlowId) {
        editAction(actionId, actionAlias); /* Open Edit Copy Files Action Modal */
      }
    }
    return null;
  });

  const onKeyDown = (event) => {
    event.stopPropagation();
    if (event.key === 'Escape') onModalClose();
  };

  useEffect(() => {
    reset({ alias: actionAlias });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <CustomModal
      disableModalOutsideClick
      open={open}
      title={
        <Typography fontWeight={600} variant="body4">
          Action alias
        </Typography>
      }
      onClose={onModalClose}
      sx={{ '& .MuiPaper-root': { width: '475px' } }}
      footer={<ModalFooter onModalClose={onModalClose} createBtnText="Done" onSubmit={onModalSubmit} />}
    >
      <FormControl size="small" fullWidth>
        <ControlTextField
          autoFocus
          control={control}
          name="alias"
          label="Alias *"
          maxLength={32}
          errors={errors}
          errorOnFocusOnly
          onKeyDown={onKeyDown}
          sx={{ margin: 0 }}
        />
      </FormControl>
    </CustomModal>
  );
};

export default ActionAliasModal;
