import { CANVAS_VALIDATIONS, FLOATING_CANVAS_EDGE, SYNC_LOGS } from 'modules/Sync/Constants/constants';
import FloatingEdge from 'modules/Sync/WorkflowEditor/FlowchartEdges/FloatingEdge';
import ActionNode from 'modules/Sync/WorkflowEditor/FlowchartNodes/ActionNode';
import ConnectorNode from 'modules/Sync/WorkflowEditor/FlowchartNodes/ConnectorNode';
import { getAvailableConnections } from 'modules/Sync/WorkflowEditor/helpers/CopyFilesHelpers/connectionsHelper';

export const isNodeValid = (nodeType, canvasElements) => {
  if (CANVAS_VALIDATIONS.ENFORCE_CANVAS_VALIDATIONS) {
    if (nodeType === 'connector') return true;

    const actionNodes = canvasElements.filter(({ type }) => type === 'actionType');

    if (actionNodes.length >= CANVAS_VALIDATIONS.MAX_ACTION_NODES_ALLOWED) {
      return false;
    }
  }

  return true;
};

export const nodeTypes = {
  connectorType: ConnectorNode,
  actionType: ActionNode
};

export const edgeTypes = {
  floating: FloatingEdge
};

export const edgeData = ({ source, target }) => ({
  id: `${source}->${target}`,
  source,
  target,
  arrowHeadType: 'arrow',
  type: 'floating',
  animated: false
});

export const allowAddMoreDestination = (destinations, connectorNodes) => {
  if (destinations?.length >= CANVAS_VALIDATIONS.MAX_DESTINATION_ALLOWED_PER_SOURCE) {
    return false;
  }
  if (!getAvailableConnections(connectorNodes, false).length) {
    return false;
  }

  if (destinations.some((destination) => Object.keys(destination).length === 0)) {
    return false;
  }

  return true;
};

export const loggingHelper = (content) => {
  if (process.env.NODE_ENV === 'develop') {
    // eslint-disable-next-line no-console
    console.log(content);
  }
};

export const getSyncStatusUpdatedCanvasElements = (workflowElements, solitaryFlowsSyncStatuses = []) =>
  workflowElements?.map((element) => {
    const { type, id: elementId } = element || {};
    const solitaryFlow = solitaryFlowsSyncStatuses.find(({ actionNodeId }) => elementId.includes(actionNodeId));

    if (type === FLOATING_CANVAS_EDGE && solitaryFlow) {
      const { syncStatus } = solitaryFlow || {};
      return {
        ...element,
        animated: syncStatus === SYNC_LOGS.LOGS_SYNC_STATUS.IN_PROGRESS
      };
    }

    return element;
  });
