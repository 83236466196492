import { useCallback, useEffect, useState } from 'react';

import { gql } from '@apollo/client';
import { Stack } from '@mui/material';

import { FETCH_POLICIES } from 'constants/globalConstants';
import { getAutodeskFieldProjects as GET_AUTODESK_FIELD_PROJECTS } from 'graphql/queries';
import useLazyPaginatedQuery from 'hooks/useLazyPaginatedQuery';
import { DEFAULT_ROOT_FOLDER, MAX_DROPDOWN_ITEMS_IN_VIEW } from 'modules/Sync/Constants/constants';
import { useCopyFilesFormContext } from 'modules/Sync/WorkflowEditor/Contexts/CopyFilesContext';
import AutodeskFieldFoldersDropdown from 'modules/Sync/WorkflowEditor/Forms/CopyFiles/Autodesk/Field/AutodeskFieldFoldersDropdown';
import FiltersAutocompleteDropdown from 'modules/Sync/WorkflowEditor/Forms/Shared/CopyFilesComponents/FiltersAutocompleteDropdown';
import { multiSelectFolders } from 'modules/Sync/WorkflowEditor/Forms/Shared/CopyFilesComponents/FoldersSelectDropdown/folderTreeItemHelper';
import { getSubmittedStatus } from 'modules/Sync/WorkflowEditor/helpers/validationsHelper';
import DropdownFormControl from 'modules/Sync/WorkflowEditor/Utils/DropdownFormControl';

const { fetchPolicy: FETCH_POLICY } = FETCH_POLICIES.CACHE_AND_NETWORK;
const AutodeskField = ({
  connectionId = '',
  connectionType = '',
  nodeId = '',
  fileTypeFilters = [],
  showFilters = false,
  isSource = false,
  allowPrivateFiles = false
}) => {
  const { getConnectorNodeValues, updateConnectorNodeValues } = useCopyFilesFormContext();
  const [parentFolderId, setParentFolderId] = useState('');
  const formValues = getConnectorNodeValues({ isSource, nodeId });

  const { projects, project = projects?.[0], folders = [], uiFolders = [] } = formValues;

  const [
    { paginatedLazyLoad: lazyLoadProjects, paginationHandler: projectsPaginationHandler },
    { loading: isLoadingProjects, data: { getAutodeskFieldProjects: AutodeskFieldProjects = [] } = {} }
  ] = useLazyPaginatedQuery(gql(GET_AUTODESK_FIELD_PROJECTS), FETCH_POLICY, MAX_DROPDOWN_ITEMS_IN_VIEW);
  const onScrollProjects = () => projectsPaginationHandler(AutodeskFieldProjects?.length, { connectionId });

  const fetchProjectsOnLoad = () => {
    if (project?.id) {
      lazyLoadProjects({ skip: null, take: null, connectionId });
    } else {
      lazyLoadProjects({ skip: AutodeskFieldProjects?.length, connectionId });
    }
  };
  const AutodeskFieldFolders = [{ id: DEFAULT_ROOT_FOLDER.ID, text: DEFAULT_ROOT_FOLDER.TEXT }];

  const updateFormValues = useCallback(
    (props) => updateConnectorNodeValues({ nodeId, isSource, props }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formValues, isSource, nodeId]
  );

  const selectProjectHandler = (event, childElement) => {
    event.preventDefault();
    updateFormValues({
      projects: [{ id: event.target.value, name: childElement?.props?.dataText }],
      project: { id: event.target.value, name: childElement?.props?.dataText },
      folders: [],
      folder: { id: '', name: '' },
      isSubmitted: false
    });
  };

  const selectFolderHandler = (event, id, children) =>
    multiSelectFolders({
      event,
      id,
      folders,
      uiFolders,
      children,
      parentFolderId,
      setParentFolderId,
      updateFormValues
    });

  const setParentFolderIdOnStackLoad = (parentFolderId) => setParentFolderId(parentFolderId);

  useEffect(() => {
    const isSubmitted = getSubmittedStatus(formValues);
    updateFormValues({ connectionId, connectionType, nodeId, isSubmitted });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchProjectsOnLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack spacing={2}>
      <DropdownFormControl
        title="Project"
        id="project"
        labelId="project"
        label="project"
        value={project?.id}
        onChange={selectProjectHandler}
        loading={isLoadingProjects}
        items={AutodeskFieldProjects}
        fetchPaginatedRecords={onScrollProjects}
        required
      />

      <AutodeskFieldFoldersDropdown
        connectionId={connectionId}
        folders={AutodeskFieldFolders}
        disabled={isLoadingProjects || !project?.id || !AutodeskFieldProjects?.length}
        onSelect={selectFolderHandler}
        isSource={isSource}
        nodeId={nodeId}
        multiple={isSource}
        onFolderStackLoad={setParentFolderIdOnStackLoad}
        rootParentFolderId={DEFAULT_ROOT_FOLDER.ID}
        setParentFolderId={setParentFolderId}
        required
      />

      <FiltersAutocompleteDropdown
        allowPrivateFiles={allowPrivateFiles}
        showFilters={showFilters}
        fileTypeFilters={fileTypeFilters}
        isSource={isSource}
      />
    </Stack>
  );
};

export default AutodeskField;
