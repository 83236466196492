import { ACTION_STATUS, REALTIME_SYNC, SCHEDULE_SYNC } from '../Constants/constants';

export const getStatus = (statusList) => {
  if (!statusList.length) return ACTION_STATUS.DISABLED;

  const isAllFailed = statusList.every(({ syncStatus }) => syncStatus === ACTION_STATUS.FAILED);
  if (isAllFailed) return ACTION_STATUS.FAILED;

  const isAllCompleted = statusList.every(({ syncStatus }) => syncStatus === ACTION_STATUS.COMPLETED);
  if (isAllCompleted) return ACTION_STATUS.COMPLETED;

  const isAllUpToDate = statusList.every(({ syncStatus }) => syncStatus === ACTION_STATUS.UP_TO_DATE);
  if (isAllUpToDate) return ACTION_STATUS.UP_TO_DATE;

  const isAllInProgress = statusList.every(({ syncStatus }) => syncStatus === ACTION_STATUS.IN_PROGRESS);
  if (isAllInProgress) return ACTION_STATUS.IN_PROGRESS;

  const isAllDisabled = statusList.every(({ syncStatus }) => syncStatus === ACTION_STATUS.DISABLED);
  if (isAllDisabled) return ACTION_STATUS.DISABLED;

  const isAllDeactivated = statusList.every(({ isDeactivated }) => isDeactivated);
  if (isAllDeactivated) return ACTION_STATUS.DEACTIVATED;

  const isAllCancelled = statusList.every(({ syncStatus }) => syncStatus === ACTION_STATUS.CANCELLED);
  if (isAllCancelled) return ACTION_STATUS.CANCELLED;

  const isAllInQueue = statusList.every(({ syncStatus }) => syncStatus === ACTION_STATUS.IN_THE_QUEUE);
  if (isAllInQueue) return ACTION_STATUS.IN_THE_QUEUE;

  const isAllPending = statusList.every(({ syncStatus }) => syncStatus === ACTION_STATUS.PENDING);
  if (isAllPending) return ACTION_STATUS.PENDING;

  const isFailed = statusList.find(
    ({ syncStatus }) => syncStatus === ACTION_STATUS.FAILED || syncStatus === ACTION_STATUS.NEED_REVIEW
  );
  if (isFailed) return ACTION_STATUS.NEED_REVIEW;

  const isInProgress = statusList.find(({ syncStatus }) => syncStatus === ACTION_STATUS.IN_PROGRESS);
  if (isInProgress) return ACTION_STATUS.IN_PROGRESS;

  const isUpToDate = statusList.find(
    ({ syncStatus, syncType = '' }) =>
      (syncType === SCHEDULE_SYNC || syncType === REALTIME_SYNC) && syncStatus === ACTION_STATUS.UP_TO_DATE
  );
  if (isUpToDate) return ACTION_STATUS.UP_TO_DATE;

  const isInQueue = statusList.find(({ syncStatus }) => syncStatus === ACTION_STATUS.IN_THE_QUEUE);
  if (isInQueue) return ACTION_STATUS.IN_THE_QUEUE;

  const isPending = statusList.find(({ syncStatus }) => syncStatus === ACTION_STATUS.PENDING);
  if (isPending) return ACTION_STATUS.PENDING;

  const isCompleted = statusList.find(({ syncStatus }) => syncStatus === ACTION_STATUS.COMPLETED);
  if (isCompleted) return ACTION_STATUS.COMPLETED;

  const isCancelled = statusList.find(({ syncStatus }) => syncStatus === ACTION_STATUS.CANCELLED);
  if (isCancelled) return ACTION_STATUS.CANCELLED;

  return ACTION_STATUS.DISABLED;
};

export const getMappingStatus = (actions) => {
  if (!actions?.length) return ACTION_STATUS.PENDING;
  const statuses = actions?.map(({ syncStatus, source, isDeactivated }) => ({
    syncStatus,
    syncType: source?.settings?.syncType,
    isDeactivated
  }));
  const status = getStatus(statuses);
  return status;
};

export const getWorkflowStatus = (mappings) => {
  const statuses = mappings.map(({ solitaryFlows = [], publishModelSolitaryFlows = [] }) => ({
    syncStatus: getMappingStatus([...solitaryFlows, ...publishModelSolitaryFlows])
  }));
  const status = getStatus(statuses);
  return status;
};
