import { gql } from '@apollo/client';

import client from 'apollo/client';
import { FETCH_POLICIES } from 'constants/globalConstants';
import { getAutodeskForgeSubItems as GET_AUTODESK_FORGE_SUB_ITEMS } from 'graphql/queries';
import { DROPDOWN_DEFAULT_PG_SIZE } from 'modules/Sync/Constants/constants';
import { multiSelectFolders } from 'modules/Sync/WorkflowEditor/Forms/Shared/PublishActionComponents/FoldersSelectDropdown/folderTreeItemHelper';

const {
  NO_CACHE: { fetchPolicy: NO_CACHE_FETCH_POLICY }
} = FETCH_POLICIES;

export const getForgeSelectEventHandlers = ({
  updateFormValues,
  loadProjects,
  loadSubItems,
  connectionId,
  hub,
  parentFolderId,
  setParentFolderId,
  folders,
  uiFolders,
  project = {}
}) => {
  const selectHubHandler = ({ target: { value: hubId } = {} }, childElement) => {
    updateFormValues({
      hub: { id: hubId, name: childElement?.props?.dataText },
      project: { id: '', name: '' },
      folder: { id: '', name: '' },
      isSubmitted: false
    });
    loadProjects({ connectionId, hubId });
  };

  const selectProjectHandler = ({ target: { value: projectId } = {} }, childElement) => {
    updateFormValues({
      project: { id: projectId, name: childElement?.props?.dataText },
      projects: [{ id: projectId, name: childElement?.props?.dataText }],
      folder: { id: '', name: '' },
      folders: [{ id: '', name: '' }],
      isSubmitted: false
    });
    loadSubItems({
      connectionId,
      hubId: hub?.id,
      projectId,
      isPublishModel: true,
      skip: 0,
      take: DROPDOWN_DEFAULT_PG_SIZE
    });
  };

  const selectSubItemsHandler = (event, id) =>
    multiSelectFolders({
      event,
      id,
      folders,
      parentFolderId,
      setParentFolderId,
      updateFormValues,
      uiFolders
    });

  const fetchForgeFolders = async ({
    folderId,
    fetchPolicy = NO_CACHE_FETCH_POLICY,
    skip = 0,
    take = DROPDOWN_DEFAULT_PG_SIZE,
    onCompleted = () => {}
  }) => {
    const { data } = await client.query({
      query: gql(GET_AUTODESK_FORGE_SUB_ITEMS),
      variables: {
        query: {
          connectionId,
          hubId: hub?.id,
          projectId: project?.id,
          isPublishModel: true,
          parentFolderId: folderId,
          skip,
          take
        }
      },
      fetchPolicy
    });
    const subItems = data?.getAutodeskForgeSubItems || [];
    onCompleted(subItems);
    return subItems;
  };

  return {
    selectHubHandler,
    selectProjectHandler,
    selectSubItemsHandler,
    fetchForgeFolders
  };
};
