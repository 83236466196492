import { v4 as uuidv4 } from 'uuid';

import { formatToBackendDateString, getFormattedDateTime } from 'helpers/dateFunctions';
import EmptyDataGridCell from 'modules/Sync/components/EmptyDataGridCell';
import { convertBytes } from 'modules/Sync/helpers/common';
import { getMappingStatus, getWorkflowStatus } from 'modules/Sync/helpers/getStatus';
import { getLogFileName } from 'modules/Sync/Logs/components/DownloadLogFile';

const DEFAULT_FIELDS = {
  syncType: '',
  source: '',
  destinations: '',
  '*download': ''
};

const EmptyCell = () => <EmptyDataGridCell placeholder="" />;

const getFileCount = (succeded = 0, total = 0) => `${succeded ?? 0}/${total ?? 0}`;

const parseActionsForLogsTable = (
  { workflowName, workflowId, mappingName, mappingId, index, uid },
  {
    actionAlias,
    source,
    destinations,
    fileSyncLogId,
    syncStatus,
    lastSyncTime,
    totalFiles = 0,
    successfulFilesCount = 0,
    totalSize = 0,
    ...rest
  }
) => {
  const flowUid = uuidv4();
  const lastSyncFileDate = formatToBackendDateString(new Date(lastSyncTime));
  const fileName = getLogFileName({
    workflowName,
    mappingName,
    fileSyncLogId,
    lastSyncFileDate
  });

  return {
    ...DEFAULT_FIELDS,
    id: `${fileSyncLogId}-${flowUid}`,
    uid: `${workflowId}-${mappingId}-${fileSyncLogId}`,
    path: [`${workflowId}-${index}`, `${mappingId}-${uid}`, `${fileSyncLogId}-${flowUid}`],
    parentIds: [`${workflowId}-${index}`, `${mappingId}-${uid}`],
    source: source?.name,
    destinations: destinations?.map(({ name }) => name).join(', '),
    fileSyncLogId,
    syncStatus,
    name: actionAlias || '',
    lastSyncTime: getFormattedDateTime(lastSyncTime, true) || <EmptyCell />,
    fileName,
    numFiles: getFileCount(successfulFilesCount, totalFiles),
    totalSize: convertBytes(totalSize),
    ...rest
  };
};

const LogsDataGridTableRows = (logs = []) => {
  if (!logs) return [];

  return logs.flatMap(
    ({ workflowName, workflowId, mappings, totalFiles = 0, successfulFilesCount = 0, lastSyncTime }, index) => {
      const syncStatus = getWorkflowStatus(mappings);
      return [
        {
          id: `${workflowId}-${index}`,
          path: [`${workflowId}-${index}`],
          uid: workflowId,
          parentIds: [],
          name: workflowName,
          workflowName,
          workflowId,
          syncStatus,
          numFiles: getFileCount(successfulFilesCount, totalFiles),
          lastSyncTime: lastSyncTime ? getFormattedDateTime(lastSyncTime, true) : <EmptyCell />,
          ...DEFAULT_FIELDS
        },
        ...mappings?.flatMap(
          ({
            mappingName,
            mappingId,
            solitaryFlows,
            publishModelSolitaryFlows,
            totalFiles = 0,
            successfulFilesCount = 0,
            lastSyncTime
          }) => {
            const uid = uuidv4();
            const mappingStatus = getMappingStatus([...solitaryFlows, ...publishModelSolitaryFlows]);
            const parentData = { workflowName, workflowId, mappingName, mappingId, index, uid };
            return [
              {
                id: `${mappingId}-${uid}`,
                path: [`${workflowId}-${index}`, `${mappingId}-${uid}`],
                uid: `${workflowId}-${mappingId}`,
                parentIds: [`${workflowId}-${index}`],
                name: mappingName,
                mappingName,
                mappingId,
                syncStatus: mappingStatus,
                numFiles: getFileCount(successfulFilesCount, totalFiles),
                lastSyncTime: lastSyncTime ? getFormattedDateTime(lastSyncTime, true) : <EmptyCell />,
                ...DEFAULT_FIELDS
              },
              ...solitaryFlows?.map((action) => parseActionsForLogsTable(parentData, action)),
              ...publishModelSolitaryFlows?.map((action) => parseActionsForLogsTable(parentData, action))
            ];
          }
        )
      ];
    }
  );
};

export default LogsDataGridTableRows;
