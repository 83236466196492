import React, { useEffect, useState } from 'react';

import { Typography } from '@mui/material';
import { useForm } from 'react-hook-form';

import { ControlledInputCheckbox } from 'components';
import { useCopyFilesFormContext } from 'modules/Sync/WorkflowEditor/Contexts/CopyFilesContext';

import AutoComplete from './AutoComplete';
import AutoCompleteTooltip from './AutoCompleteTooltip';
import { getFiltersHelpers } from './filtersHelpers';

const ROOT_FILTER_GROUP = 'Select all';

const DEFAULT_TOOLTIP_TEXT =
  'Please confirm that the current user has Admin access for this Procore project. \n Non-admin can move new files but cannot update the status of existing files in Procore.';

const getSelectedFilterGroups = ({ defaultFilterGroups, parsedFileTypeFilters, filters }) =>
  defaultFilterGroups.filter((group) => {
    if (group === ROOT_FILTER_GROUP) return true;

    const currentGroupFilters = parsedFileTypeFilters.filter(({ type }) => type === group);
    return currentGroupFilters.some(({ name }) => filters.includes(name));
  });

const getFilters = (fileTypeFilters, filters) => {
  const parsedFileTypeFilters = fileTypeFilters.reduce((filters, { type, extensions }) => {
    filters.push(...extensions.map((extension) => ({ name: extension, type })));
    return filters;
  }, []);
  const defaultFilters = parsedFileTypeFilters.map(({ name }) => name);
  const defaultFilterGroups = [...new Set(parsedFileTypeFilters.map(({ type }) => type)), ROOT_FILTER_GROUP];

  const selectedFilterGroups = getSelectedFilterGroups({
    defaultFilterGroups,
    parsedFileTypeFilters,
    filters
  });

  return { parsedFileTypeFilters, defaultFilters, defaultFilterGroups, selectedFilterGroups };
};

const FiltersAutocompleteDropdown = ({
  allowPrivateFiles = false,
  showFilters = false,
  fileTypeFilters = [],
  isSource = false,
  tooltipText = DEFAULT_TOOLTIP_TEXT,
  nodeId = ''
}) => {
  const {
    includedExtensions: filters,
    setIncludedExtensions: setFilters,
    source,
    setSource,
    destinations,
    setDestinations
  } = useCopyFilesFormContext();

  const [enableFilters, setFiltersAvailablity] = useState(filters.length);
  const { parsedFileTypeFilters, defaultFilters, defaultFilterGroups, selectedFilterGroups } = getFilters(
    fileTypeFilters,
    filters
  );
  const [filterGroups, setFilterGroups] = useState(selectedFilterGroups);
  const [expandedFilterGroups, setExpandedFilterGroups] = useState([ROOT_FILTER_GROUP]);
  const isChecked = isSource
    ? source?.downloadBehavior
    : destinations.find(({ nodeId: id }) => id === nodeId)?.uploadBehavior;

  const { control } = useForm({
    mode: 'all',
    defaultValues: { behavior: isChecked }
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setFiltersAvailablity(!!filters.length), []);

  const {
    deselectAllFilters,
    toggleFilters,
    onFilterGroupTransition,
    selectAllFiltersHandler,
    selectFilterGroupHandler,
    selectFilterHandler,
    selectUploadBehaviour,
    selectDownloadBehaviour
  } = getFiltersHelpers({
    expandedFilterGroups,
    setExpandedFilterGroups,
    filters,
    setFilters,
    setSource,
    setDestinations,
    filterGroups,
    parsedFileTypeFilters,
    setFilterGroups,
    defaultFilters,
    defaultFilterGroups,
    setFiltersAvailablity
  });

  const onChange = (event) => (isSource ? selectDownloadBehaviour(event) : selectUploadBehaviour(event, nodeId));
  return (
    <div>
      {allowPrivateFiles && (
        <div>
          <ControlledInputCheckbox
            customCallBack={onChange}
            name="behavior"
            control={control}
            label={<Typography variant="body2">{isSource ? 'Include private files' : 'Upload as private'}</Typography>}
            color="secondary"
            defaultValue={isChecked}
            checkboxProps={{ size: 'small' }}
          />
        </div>
      )}
      {showFilters && (
        <>
          <AutoCompleteTooltip title={tooltipText} onChange={toggleFilters} checked={enableFilters} />
          {!!enableFilters && !!fileTypeFilters.length && (
            <AutoComplete
              expandedFilterGroups={expandedFilterGroups}
              filters={filters}
              defaultFilters={defaultFilters}
              selectFilterHandler={selectFilterHandler}
              selectAllFiltersHandler={selectAllFiltersHandler}
              onFilterGroupTransition={onFilterGroupTransition}
              filterGroups={filterGroups}
              selectFilterGroupHandler={selectFilterGroupHandler}
              options={parsedFileTypeFilters}
              onClear={deselectAllFilters}
            />
          )}
        </>
      )}
    </div>
  );
};

export default FiltersAutocompleteDropdown;
