import Amplify from 'aws-amplify';

import awsmobile from './aws-exports';

const {
  NODE_ENV,
  REACT_APP_AMPLIFY_AUTH_IDENTITY_POOL_ID,
  REACT_APP_AMPLIFY_AUTH_USER_POOL_ID,
  REACT_APP_AMPLIFY_AUTH_USER_POOL_WEB_CLIENT_ID
} = process.env;

const configureAmplify = () => {
  Amplify.configure({
    ...awsmobile,
    ...(NODE_ENV === 'production'
      ? {}
      : {
          Auth: {
            identityPoolId: REACT_APP_AMPLIFY_AUTH_IDENTITY_POOL_ID,
            userPoolId: REACT_APP_AMPLIFY_AUTH_USER_POOL_ID,
            userPoolWebClientId: REACT_APP_AMPLIFY_AUTH_USER_POOL_WEB_CLIENT_ID
          }
        })
  });
};
export { configureAmplify };
