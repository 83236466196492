import React, { useState } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import { ControlledRadioGroup } from 'components';
import ControlledSwitchInput from 'components/FormComponents/ControlledSwitchInput';
import ControlledTooltip from 'modules/Sync/components/ControlledTooltip';
import {
  MAPPING_ACTIONS,
  PUBLISH_SYNC_TYPES,
  REALTIME_SYNC,
  SYNC_OPTION_LABELS,
  SYNC_TYPES
} from 'modules/Sync/Constants/constants';
import { useSyncWorkflowEditorContext } from 'modules/Sync/WorkflowEditor/Contexts/WorkflowEditorContext';
import ScheduleSyncFields from 'modules/Sync/WorkflowEditor/Forms/Shared/SyncFilesForm/ScheduleSyncFields';
import { getSyncFilesFormEventHandlers } from 'modules/Sync/WorkflowEditor/Forms/Shared/SyncFilesForm/syncFilesFormHelpers';
import { isTemplateCanvas } from 'modules/Sync/WorkflowEditor/helpers/mappingConfigHelper';
import {
  getScheduleSyncConfigPayload,
  parseRequestScheduleSyncJson
} from 'modules/Sync/WorkflowEditor/helpers/syncHelpers';
import { surfaceColors } from 'styles/theme/colors';

import useSyncFilesHooks from './useSyncFilesHooks';

const { publish: PUBLISH_ACTION } = MAPPING_ACTIONS;
const primaryTextColor = surfaceColors.lightSurface.primary;
const disabledTextColor = surfaceColors.lightSurface.disabled;

const getDefaultFormValues = ({
  currentSourceScheduleSyncConfig,
  selectedSourceId,
  currentSourceSyncType,
  currentFlow,
  selectedSourceSyncEnabled
}) => ({
  isSyncEnabled: !!(selectedSourceSyncEnabled || currentFlow?.isSyncEnabled),
  syncType: currentFlow?.syncType || currentSourceSyncType,
  Sunday: selectedSourceId ? currentSourceScheduleSyncConfig?.Sunday : currentFlow?.scheduleSyncConfig?.Sunday,
  Monday: selectedSourceId ? currentSourceScheduleSyncConfig?.Monday : currentFlow?.scheduleSyncConfig?.Monday,
  Tuesday: selectedSourceId ? currentSourceScheduleSyncConfig?.Tuesday : currentFlow?.scheduleSyncConfig?.Tuesday,
  Wednesday: selectedSourceId ? currentSourceScheduleSyncConfig?.Wednesday : currentFlow?.scheduleSyncConfig?.Wednesday,
  Thursday: selectedSourceId ? currentSourceScheduleSyncConfig?.Thursday : currentFlow?.scheduleSyncConfig?.Thursday,
  Friday: selectedSourceId ? currentSourceScheduleSyncConfig?.Friday : currentFlow?.scheduleSyncConfig?.Friday,
  Saturday: selectedSourceId ? currentSourceScheduleSyncConfig?.Saturday : currentFlow?.scheduleSyncConfig?.Saturday,
  hours: currentFlow?.scheduleSyncConfig?.hours || currentSourceScheduleSyncConfig.hours,
  minutes: currentFlow?.scheduleSyncConfig?.minutes || currentSourceScheduleSyncConfig.minutes,
  format: currentFlow?.scheduleSyncConfig?.format || currentSourceScheduleSyncConfig.format
});

// eslint-disable-next-line max-lines-per-function
const SyncOptions = ({
  onSyncSettingsChange = () => {},
  setMappingFlowsSyncSettings = () => {},
  mappingFlowsSyncSettings = [],
  selectedSource = {},
  currentAction = '',
  syncSettings = {},
  importMethods,
  recommendTime,
  disableRecommendTime,
  loadingRecommendTime = false,
  action,
  disabled = false
}) => {
  const { pathname } = useLocation();
  const isPublishAction = action === PUBLISH_ACTION;
  const filteredSyncType = isPublishAction ? PUBLISH_SYNC_TYPES : SYNC_TYPES;
  const { connectionType = '', connection: { type = '' } = {}, nodeId: selectedSourceId = '' } = selectedSource;
  const {
    isSyncEnabled: selectedSourceSyncEnabled = false,
    syncType: currentSourceSyncType = isPublishAction ? PUBLISH_SYNC_TYPES[0].value : SYNC_TYPES[0].value,
    scheduleSyncConfig = {}
  } = syncSettings;
  const selectedSourceConnectionType = connectionType || type;
  const { workflowMappings, allConnections } = useSyncWorkflowEditorContext();
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);

  const isTemplate = React.useMemo(() => isTemplateCanvas(pathname), [pathname]);
  const {
    solitaryFlowId: currentFlowId = '',
    source: { connection: { type: currentFlowConnectionType = '' } = {} } = {}
  } = workflowMappings?.find(({ actionNodeId }) => actionNodeId === currentAction) || {};
  const currentFlow = mappingFlowsSyncSettings?.find(({ solitaryFlowId }) => solitaryFlowId === currentFlowId) || {};
  const methods = useForm({
    mode: 'all',
    defaultValues: getDefaultFormValues({
      currentFlow,
      currentSourceScheduleSyncConfig: scheduleSyncConfig,
      selectedSourceId,
      currentSourceSyncType,
      selectedSourceSyncEnabled
    })
  });
  const { control, watch, setValue, register } = methods;
  const { isSyncEnabled, syncType, hours, minutes, format } = watch();
  const { description, isDetailed } = isPublishAction
    ? PUBLISH_SYNC_TYPES.find(({ value }) => value === syncType) || {}
    : SYNC_TYPES.find(({ value }) => value === syncType) || {};

  const { optionLabel, disabledDescription, ...syncOptionLabels } = isPublishAction
    ? SYNC_OPTION_LABELS[MAPPING_ACTIONS.publish]
    : SYNC_OPTION_LABELS[MAPPING_ACTIONS.copyFiles];

  const isSyncTypeDisabled = (syncType) => {
    if (!isSyncEnabled) {
      return true;
    }
    return syncType === REALTIME_SYNC
      ? !allConnections?.syncConnectorsList?.find(
          ({ name }) => name === selectedSourceConnectionType || name === currentFlowConnectionType
        )?.realTimeSync
      : false;
  };

  useSyncFilesHooks({
    currentFlowId,
    setValue,
    currentFlow /* For global sync config */,
    sourceScheduleSyncConfig: scheduleSyncConfig /* For single flow */
  });

  const syncTypes = filteredSyncType.map(({ syncType, label, ...rest }) => {
    const syncTypeDisabled = isSyncTypeDisabled(syncType);
    const syncDisabled = syncTypeDisabled || disabled;
    return {
      syncType,
      ...rest,
      disabled: syncDisabled,
      label:
        syncDisabled && syncType === REALTIME_SYNC ? (
          <ControlledTooltip
            open={tooltipIsOpen}
            onOpen={() => setTooltipIsOpen(true)}
            onClose={() => setTooltipIsOpen(false)}
            title="This connector doesn't support real-time sync"
          >
            <span>{label}</span>
          </ControlledTooltip>
        ) : (
          <span>{label}</span>
        )
    };
  });

  const getSyncTypesStackStyles = () =>
    isPublishAction ? { justifyContent: 'start', gap: 6 } : { justifyContent: 'space-between' };

  const { onToggleHandler, onSyncTypeChangeHandler, onScheduleSyncOptionsChange } = getSyncFilesFormEventHandlers({
    selectedSourceId,
    currentFlowId,
    currentFlow,
    onSyncSettingsChange,
    setMappingFlowsSyncSettings
  });

  const onToggle = (event, checked) => {
    if (!importMethods) {
      onToggleHandler(event, checked);
    } else {
      importMethods?.updateSettings({ isSyncEnabled: checked }, importMethods?.workflowId, importMethods?.mappingId);
    }
  };

  const onTypeChange = (event) => {
    if (!importMethods) {
      onSyncTypeChangeHandler(event);
    } else {
      importMethods?.updateSettings(
        { syncType: event.target.value },
        importMethods?.workflowId,
        importMethods?.mappingId
      );
    }
  };

  const onScheduleChange = (data) => {
    if (!importMethods) {
      onScheduleSyncOptionsChange(data);
    } else {
      const { event, checked, name } = data;
      const { value } = event.target;
      const config =
        checked !== undefined
          ? {
              [name]: checked
            }
          : {
              [name]: value
            };

      const schedule = getScheduleSyncConfigPayload(config, syncSettings?.scheduleSyncConfig);

      importMethods?.updateSettings(
        { scheduleSyncConfig: parseRequestScheduleSyncJson(schedule) },
        importMethods?.workflowId,
        importMethods?.mappingId
      );
    }
  };

  return (
    <FormProvider {...methods}>
      <Stack sx={{ width: 450, pr: disabled ? 1 : 0 }}>
        <ControlledSwitchInput
          name="isSyncEnabled"
          control={control}
          label={
            <Typography
              variant="subtitle2"
              display="flex"
              textAlign="center"
              data-behavior="DO-NOT-EXPAND"
              sx={{ fontSize: '16px' }}
              color={disabled ? disabledTextColor : primaryTextColor}
            >
              {optionLabel} {isSyncEnabled ? 'enabled' : 'disabled'}
            </Typography>
          }
          color={disabled ? 'primary' : 'secondary'}
          sx={{ marginRight: 0.5 }}
          switchProps={{
            inputProps: { 'data-behavior': 'DO-NOT-EXPAND' } // To prevent checkbox from affecting accordion behavior
          }}
          onChangeHandler={onToggle}
          disabled={isTemplate || disabled}
        />
        <ControlledRadioGroup
          name="syncType"
          radioOptions={syncTypes}
          direction="row"
          control={control}
          activeColor="secondary"
          onChange={onTypeChange}
          defaultValue={syncType}
          sx={{
            '.MuiFormControlLabel-label': { p: 0 },
            '.MuiStack-root': { ...getSyncTypesStackStyles() },
            '.MuiFormControlLabel-root': { mr: 0 }
          }}
        />
        {syncType && (
          <Stack gap={0.5} color={disabled ? disabledTextColor : primaryTextColor}>
            <Typography variant="subtitle2">Description</Typography>
            <Typography variant="body2">
              {isSyncEnabled && isSyncTypeDisabled ? description : disabledDescription}
            </Typography>
          </Stack>
        )}
        {isSyncEnabled && isDetailed && (
          <Box sx={{ width: 450 }}>
            <ScheduleSyncFields
              disabled={disabled}
              control={control}
              register={register}
              onChangeHandler={onScheduleChange}
              defaultValues={{ hours, minutes, format }}
              recommendTime={recommendTime}
              disableRecommendTime={disableRecommendTime}
              loadingRecommendTime={loadingRecommendTime}
              syncOptionLabels={syncOptionLabels}
            />
          </Box>
        )}
      </Stack>
    </FormProvider>
  );
};

export default SyncOptions;
