import { useSyncWorkflowEditorContext } from 'modules/Sync/WorkflowEditor/Contexts/WorkflowEditorContext';

const useEditAction = () => {
  const { setActionId, showActionsModal, showPublishModal } = useSyncWorkflowEditorContext();

  const editAction = (nodeId, nodeType) => {
    setActionId(nodeId);
    const action = nodeType?.split(' -')?.[0];
    if (action === 'Copy Files') return showActionsModal();
    if (action === 'Publish') return showPublishModal();

    return showActionsModal();
  };

  return { editAction };
};

export default useEditAction;
