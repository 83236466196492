import React from 'react';

import client from 'apollo/client';
import { convertToCamelCase } from 'helpers/stringFunctions';
import { CONNECTION_TYPES, DRAWER_NAV_MENU_ITEMS } from 'modules/Sync/Constants/constants';
import AutodeskField from 'modules/Sync/WorkflowEditor/Forms/CopyFiles/Autodesk/Field/Field';
import AutodeskForge from 'modules/Sync/WorkflowEditor/Forms/CopyFiles/Autodesk/Forge/Forge';
import AutodeskGlue from 'modules/Sync/WorkflowEditor/Forms/CopyFiles/Autodesk/Glue/Glue';
import Foresite from 'modules/Sync/WorkflowEditor/Forms/CopyFiles/Foresite/Foresite';
import Newforma from 'modules/Sync/WorkflowEditor/Forms/CopyFiles/Newforma/Newforma';
import Plangrid from 'modules/Sync/WorkflowEditor/Forms/CopyFiles/Plangrid/Plangrid';
import Procore from 'modules/Sync/WorkflowEditor/Forms/CopyFiles/Procore/Procore';
import Sharepoint from 'modules/Sync/WorkflowEditor/Forms/CopyFiles/Sharepoint/Sharepoint';
import Windows from 'modules/Sync/WorkflowEditor/Forms/CopyFiles/Windows/Windows';

export const formatMappings = (connectorDropDownOptions) => {
  if (!connectorDropDownOptions) return [];
  return connectorDropDownOptions.map((options) => ({
    value: options.id,
    name: options.data.connectorAlias || options.data?.taskDetail,
    componentToRender: (props) => {
      const type = options.data.connectionType;
      return (
        <>
          {type === CONNECTION_TYPES.PROCORE && (
            <Procore
              {...props}
              nodeId={options.id}
              connectionId={options.data?.nodeId || options.data?.taskId}
              connectionType={CONNECTION_TYPES.PROCORE}
              allowPrivateFiles
            />
          )}
          {type === CONNECTION_TYPES.AUTODESKFIELD && (
            <AutodeskField
              {...props}
              nodeId={options.id}
              connectionId={options.data?.nodeId || options.data?.taskId}
              connectionType={CONNECTION_TYPES.AUTODESKFIELD}
            />
          )}
          {type === CONNECTION_TYPES.AUTODESKHQ && (
            <AutodeskForge
              {...props}
              nodeId={options.id}
              connectionId={options.data?.nodeId || options.data?.taskId}
              connectionType={CONNECTION_TYPES.AUTODESKHQ}
            />
          )}
          {type === CONNECTION_TYPES.AUTODESKTEAMDOCS && (
            <AutodeskForge
              {...props}
              nodeId={options.id}
              connectionId={options.data?.nodeId || options.data?.taskId}
              connectionType={CONNECTION_TYPES.AUTODESKTEAMDOCS}
            />
          )}
          {type === CONNECTION_TYPES.AUTODESKGLUE && (
            <AutodeskGlue
              {...props}
              nodeId={options.id}
              connectionId={options.data?.nodeId || options.data?.taskId}
              connectionType={CONNECTION_TYPES.AUTODESKGLUE}
            />
          )}
          {type === CONNECTION_TYPES.SHAREPOINT && (
            <Sharepoint
              {...props}
              nodeId={options.id}
              connectionId={options.data?.nodeId || options.data?.taskId}
              connectionType={CONNECTION_TYPES.SHAREPOINT}
            />
          )}
          {type === CONNECTION_TYPES.NEWFORMA && (
            <Newforma
              {...props}
              nodeId={options.id}
              connectionId={options.data?.nodeId || options.data?.taskId}
              connectionType={CONNECTION_TYPES.NEWFORMA}
            />
          )}
          {type === CONNECTION_TYPES.PLANGRID && (
            <Plangrid
              {...props}
              nodeId={options.id}
              connectionId={options.data?.nodeId || options.data?.taskId}
              connectionType={CONNECTION_TYPES.PLANGRID}
            />
          )}
          {type === CONNECTION_TYPES.FORESITE && (
            <Foresite
              {...props}
              nodeId={options.id}
              connectionId={options.data?.nodeId || options.data?.taskId}
              connectionType={CONNECTION_TYPES.FORESITE}
            />
          )}
          {type === CONNECTION_TYPES.WINDOWS && (
            <Windows
              {...props}
              nodeId={options.id}
              connectionId={options.data?.nodeId || options.data?.taskId}
              connectionType={CONNECTION_TYPES.WINDOWS}
            />
          )}
        </>
      );
    }
  }));
};

export const getAvailableConnections = (nodes, isSource = false, defaultSelectedNodeId = '') => {
  if (isSource) {
    return nodes;
  }
  return nodes?.filter(({ id, data: { isAvailable } }) => isAvailable || defaultSelectedNodeId === id) || [];
};

export const getConnectorNodesWithEdges = (edges) =>
  edges.reduce((nodes, { source, target }) => {
    if (source.includes(DRAWER_NAV_MENU_ITEMS.CONNECTOR)) {
      nodes.push(source);
    }
    if (target.includes(DRAWER_NAV_MENU_ITEMS.CONNECTOR)) {
      nodes.push(target);
    }
    return nodes;
  }, []);

export const getActionNodesWithEdges = (edges) =>
  edges.reduce((nodes, { source, target }) => {
    if (source.includes(DRAWER_NAV_MENU_ITEMS.ACTION)) {
      nodes.push(source);
    }
    if (target.includes(DRAWER_NAV_MENU_ITEMS.ACTION)) {
      nodes.push(target);
    }
    return nodes;
  }, []);

export const getSyncQueriesForRefetch = (connections) => {
  const queries = [];

  if (connections.includes(CONNECTION_TYPES.PROCORE)) {
    queries.push(
      'GetSupportedExtensions',
      'GetProcoreCompanies',
      'GetProcoreProjects',
      'GetProcoreFolders',
      'GetProcoreSelectedFolderIdStack'
    );
  }

  if (connections.includes(CONNECTION_TYPES.AUTODESKFIELD)) {
    queries.push('GetAutodeskFieldProjects', 'GetAutodeskFieldFolders');
  }

  if (connections.includes(CONNECTION_TYPES.AUTODESKHQ) || connections.includes(CONNECTION_TYPES.AUTODESKTEAMDOCS)) {
    queries.push('GetAutodeskForgeHubs', 'GetAutodeskForgeProjects', 'GetAutodeskForgeSubItems');
  }

  if (connections.includes(CONNECTION_TYPES.AUTODESKGLUE)) {
    queries.push('GetAutodeskGlueProjects', 'GetAutodeskGlueFolders', 'GetAutodeskGlueSelectedFolderIdStack');
  }

  if (connections.includes(CONNECTION_TYPES.SHAREPOINT)) {
    queries.push('GetSharepointSites', 'GetSharepointFolders', 'GetSharepointSelectedFolderIdStack');
  }

  if (connections.includes(CONNECTION_TYPES.FORESITE)) {
    queries.push(
      'SyncFetchForesiteCompanies',
      'SyncFetchForesiteProjects',
      'SyncFetchForesiteFolders',
      'SyncFetchForesiteSelectedFolderIdStack'
    );
  }

  if (connections.includes(CONNECTION_TYPES.NEWFORMA)) {
    queries.push('GetNewformaProjects', 'GetNewformaFolders', 'GetNewformaSelectedFolderIdStack');
  }

  if (connections.includes(CONNECTION_TYPES.PLANGRID)) {
    queries.push('GetPlangridProjects', 'GetPlangridFolders', 'GetPlangridSelectedFolderIdStack');
  }

  if (connections.includes(CONNECTION_TYPES.WINDOWS)) {
    queries.push('SyncGetWindowsFolders');
  }
  return queries;
};

export const getSyncFolderQueriesForConnection = (connections) => {
  const queries = [];

  if (connections.includes(CONNECTION_TYPES.PROCORE)) queries.push('GetProcoreFolders');

  if (connections.includes(CONNECTION_TYPES.AUTODESKFIELD)) queries.push('GetAutodeskFieldFolders');

  if (connections.includes(CONNECTION_TYPES.AUTODESKHQ) || connections.includes(CONNECTION_TYPES.AUTODESKTEAMDOCS)) {
    queries.push('GetAutodeskForgeSubItems');
  }

  if (connections.includes(CONNECTION_TYPES.AUTODESKGLUE)) queries.push('GetAutodeskGlueFolders');

  if (connections.includes(CONNECTION_TYPES.SHAREPOINT)) queries.push('GetSharepointFolders');

  if (connections.includes(CONNECTION_TYPES.FORESITE)) queries.push('SyncFetchForesiteFolders');

  if (connections.includes(CONNECTION_TYPES.NEWFORMA)) queries.push('GetNewformaFolders');

  if (connections.includes(CONNECTION_TYPES.PLANGRID)) queries.push('GetPlangridFolders');

  if (connections.includes(CONNECTION_TYPES.WINDOWS)) queries.push('SyncGetWindowsFolders');
  return queries;
};

export const refreshConnections = async ({ workflowMappings }) => {
  if (workflowMappings?.length) {
    const connectionsToRefresh = [
      ...new Set(
        ...workflowMappings?.map(({ source, destinations }) => [
          source?.connection?.type,
          ...destinations?.map(({ connection: { type } = {} }) => type)
        ])
      )
    ];

    getSyncQueriesForRefetch(connectionsToRefresh).forEach(async (query) => {
      await client.cache.evict({
        id: 'ROOT_QUERY',
        fieldName: convertToCamelCase(query)
      });
    });
  }
};
