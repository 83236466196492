import { addEdge, getConnectedEdges } from 'react-flow-renderer';

import { MAPPING_ACTIONS } from 'modules/Sync/Constants/constants';
import { useSyncWorkflowEditorContext } from 'modules/Sync/WorkflowEditor/Contexts/WorkflowEditorContext';
import { edgeData } from 'modules/Sync/WorkflowEditor/helpers/canvasHelper';

const useCustomConnectAction = () => {
  const { workflowElements, setWorkflowElements } = useSyncWorkflowEditorContext();
  const connectAction = (actionId, sourceId, destIds, syncType, isSyncEnabled) => {
    const actionNode = workflowElements.find(({ id }) => id === actionId);
    const actionType = actionNode.data.name.split(' -');
    const connectedEdgeIds = getConnectedEdges([actionNode], workflowElements).map(({ id }) => id);
    setWorkflowElements((els) => els.filter((element) => !connectedEdgeIds.includes(element.id)));
    if (actionType[0] === MAPPING_ACTIONS.copyFiles)
      destIds?.forEach((destId) =>
        setWorkflowElements((els) =>
          addEdge(edgeData({ source: actionId, target: destId, syncType, isSyncEnabled }), els)
        )
      );
    setWorkflowElements((els) =>
      addEdge(edgeData({ source: sourceId, target: actionId, syncType, isSyncEnabled }), els)
    );
  };
  return { connectAction };
};

export default useCustomConnectAction;
