import { useEffect, useMemo, useState } from 'react';

import { gridPaginatedVisibleSortedGridRowEntriesSelector, useGridApiRef } from '@mui/x-data-grid-pro';

import ItemsDataGridPro from 'components/ItemsDatagridPro';
import { getMicroFeContainer } from 'helpers/domHelpers';
import { MAX_DATAGRID_ROWS_IN_VIEW } from 'modules/Sync/Constants/constants';
import { useSyncLogsContext } from 'modules/Sync/Logs/Contexts/LogsContext';
import useLogsLazyPaginationHook from 'modules/Sync/Logs/Contexts/useLogsLazyPaginationHook';
import logsDataGridStyles from 'modules/Sync/Logs/LogsDataGridTable/logsDataGridStyles';
import LogsDataGridTableCell from 'modules/Sync/Logs/LogsDataGridTable/LogsDataGridTableCell';
import LogsDataGridTableColumns from 'modules/Sync/Logs/LogsDataGridTable/LogsDataGridTableColumns';
import LogsDataGridTableGroupingCell from 'modules/Sync/Logs/LogsDataGridTable/LogsDataGridTableGroupingCell';
import LogsDataGridTableRows from 'modules/Sync/Logs/LogsDataGridTable/LogsDataGridTableRows';
import LogsDataHeader from 'modules/Sync/Logs/LogsDataGridTable/LogsDataHeader';
import NoRowsOverlay from 'modules/Sync/Logs/NoRowsOverlay';

const NoRowsOverlayText = () => <span>Start by syncing a mapping or workflow.</span>;

const LogsDataGridTable = ({ activeRows }) => {
  const { setLogsSearchPhrase } = useSyncLogsContext();
  const { loadingLogs, logsData, lazyLoadLogs, logsPaginationHandler, logsSearchHandler, logsSortHandler } =
    useLogsLazyPaginationHook();
  const apiRef = useGridApiRef();
  const [expandedRows, setExpandedRows] = useState([]);

  const handleCellComponent = ({ field, row, ...rest }) => <LogsDataGridTableCell field={field} row={row} {...rest} />;
  const onScroll = () => logsPaginationHandler(logsData?.syncLogs?.length);

  const columns = LogsDataGridTableColumns(handleCellComponent);
  const rows = useMemo(() => LogsDataGridTableRows(logsData?.syncLogs), [logsData]);
  const lastParentRowId = rows?.filter(({ workflowId }) => !!workflowId)?.at(-1)?.id;
  const groupingColDef = {
    headerName: '',
    sortable: false,
    maxWidth: 50,
    renderCell: (params) => (
      <LogsDataGridTableGroupingCell {...params} onWaypointEnter={onScroll} lastParentRowId={lastParentRowId} />
    )
  };

  const handleSort = (sortModel) => (sortModel.length ? logsSortHandler({ columnName: sortModel?.[0]?.field }) : null);
  const onSearch = (phrase) => {
    setLogsSearchPhrase(phrase);
    return logsSearchHandler(phrase);
  };

  useEffect(() => {
    if (!loadingLogs && apiRef?.current?.windowRef?.current && activeRows.length && rows.length) {
      const allRows = gridPaginatedVisibleSortedGridRowEntriesSelector(apiRef);

      const rowIndex = allRows?.findIndex(({ id }) => id.includes(activeRows?.[0]));

      const indexToScrollAt =
        rowIndex + MAX_DATAGRID_ROWS_IN_VIEW >= allRows.length
          ? allRows.length - 1
          : rowIndex + MAX_DATAGRID_ROWS_IN_VIEW;

      apiRef?.current?.scrollToIndexes({ rowIndex: Number(indexToScrollAt) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiRef, activeRows.length, rows.length, loadingLogs]);

  useEffect(() => {
    lazyLoadLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const newExpandedRows = rows?.find((row) => activeRows.includes(row?.uid))?.parentIds || [];
    setExpandedRows(newExpandedRows);
  }, [activeRows, rows]);

  return (
    <>
      <LogsDataHeader searchHandler={onSearch} />
      <ItemsDataGridPro
        key={expandedRows.length}
        apiRef={apiRef}
        treeData
        getTreeDataPath={(row) => row.path}
        isGroupExpandedByDefault={(node) => {
          if (expandedRows.length) return expandedRows.includes(node.id);
          return rows?.[0]?.id === node?.id || rows?.[1]?.id === node?.id;
        }}
        initialState={{ pinnedColumns: { left: [], right: ['*download'] } }}
        groupingColDef={groupingColDef}
        components={{
          NoRowsOverlay: () => <NoRowsOverlay text={<NoRowsOverlayText />} />
        }}
        slotProps={{
          PopperProps: { container: getMicroFeContainer() }
        }}
        density="standard"
        checkboxSelection={false}
        disableColumnMenu
        disableSelectionOnClick
        columns={columns}
        rows={rows}
        loading={loadingLogs}
        experimentalFeatures={{ rowPinning: true, newEditingApi: !true }}
        asyncApi={{ onScroll }}
        onRowsScrollEnd={onScroll}
        onSortModelChange={handleSort}
        sortingOrder={['asc', 'desc']}
        getRowClassName={(row) => (activeRows.includes(row.id) ? 'active-log-row' : '')}
        sx={logsDataGridStyles}
      />
    </>
  );
};

export default LogsDataGridTable;
