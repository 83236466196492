import { useEffect, useMemo, useState } from 'react';

import { useGridApiRef } from '@mui/x-data-grid-pro';
import { useLocation, useParams } from 'react-router-dom';

import ComponentPaneLayout from 'app/Layout/ReactGridLayout/ComponentPaneLayout';
import { ItemsDataGridProProvider } from 'components/ItemsDatagridPro';
import { getTimeZone } from 'helpers/dateFunctions';
import { IsSyncFinished } from 'modules/Sync/helpers/logSyncStatusHelpers';
import useEvictApolloCache from 'modules/Sync/hooks/useEvictApolloCache';
import { LOGS_DETAILS_APIS, useSyncLogsContext } from 'modules/Sync/Logs/Contexts/LogsContext';
import useLazyLoadLogsActionDetailsHook from 'modules/Sync/Logs/Contexts/useLazyLoadLogsActionDetailsHook';
import useLogsDetailsLazyPaginationHook from 'modules/Sync/Logs/Contexts/useLogsDetailsLazyPaginationHook';
import FileDetailsDataGridTable from 'modules/Sync/Logs/LogsDetails/FileDetailsDataGridTable';
import LogFoldersDetailsDataGridTable from 'modules/Sync/Logs/LogsDetails/LogFoldersDetailsDataGridTable';
import LogDetailsHeader from 'modules/Sync/Logs/LogsDetails/LogsDetailsHeader';

const LogDetails = () => {
  const { logsDetailsSearchPhrase, setLogsDetailsSearchPhrase } = useSyncLogsContext();
  const {
    startLogActionDetailsPolling,
    stopLogActionDetailsPolling,
    loadLogActionDetails,
    logActionDetailsData,
    loadingLogActionDetails
  } = useLazyLoadLogsActionDetailsHook();
  const {
    lazyLoadLogsDetails,
    logsDetailsPaginationHandler,
    logsDetailsSearchHandler,
    refetchExistingLogsDetailsPages,
    loadingLogDetails,
    logDetails
  } = useLogsDetailsLazyPaginationHook();

  const actionDetailsDestinations = logActionDetailsData?.syncLogActionDetails?.data?.destinations || [];
  const actionDetailsSyncStatus = logActionDetailsData?.syncLogActionDetails?.syncStatus;
  const syncInProgress = actionDetailsDestinations.length ? !IsSyncFinished(actionDetailsSyncStatus) : false;
  const apiRef = useGridApiRef();
  const timeZone = useMemo(() => getTimeZone(), []);

  const { id: fileSyncLogId = '' } = useParams();
  const [activeTab, setActiveTab] = useState(0);
  const [debugging, setDebugging] = useState(false);
  const [autoRefreshFolderDetails, setAutoRefreshFolderDetails] = useState(false);
  const [manuallyLoadingLogs, setManuallyLoadingLogs] = useState(false);
  const { search, pathname } = useLocation();
  const [expandedRows, setExpandedRows] = useState([]);

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const currentTab = Number(searchParams.get('activeTab'));
    if (currentTab) setActiveTab(currentTab);
    searchParams.delete('activeTab');
    window.history.replaceState({}, '', pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEvictApolloCache(LOGS_DETAILS_APIS, []);

  const searchHandler = (searchPhrase) => {
    setLogsDetailsSearchPhrase(searchPhrase);
    return activeTab ? logsDetailsSearchHandler(searchPhrase, { fileSyncLogId, IsDebug: debugging, timeZone }) : null;
  };

  const debugHandler = async (debug) => {
    setDebugging(!!debug);
    const variables = { fileSyncLogId, IsDebug: !!debug, searchPhrase: logsDetailsSearchPhrase, timeZone };
    await lazyLoadLogsDetails(variables);
  };

  const toggleAutoRefreshFolderDetails = (autoRefresh) => setAutoRefreshFolderDetails(!!autoRefresh);

  return (
    <ComponentPaneLayout>
      <ItemsDataGridProProvider contextId={activeTab ? 'LogsFileDetailsTable' : 'LogsFoldersDetailsTable'}>
        <LogDetailsHeader
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          searchHandler={searchHandler}
          debugHandler={debugHandler}
          autoRefreshFolderDetails={autoRefreshFolderDetails}
          autoRefreshFolderDetailsHandler={toggleAutoRefreshFolderDetails}
          loadingLogs={(activeTab ? loadingLogDetails : loadingLogActionDetails) || manuallyLoadingLogs}
          syncInProgress={syncInProgress}
          debugging={debugging}
          refetchExistingLogsDetailsPages={refetchExistingLogsDetailsPages}
          logDetails={logDetails}
          loadLogActionDetails={loadLogActionDetails}
          logActionDetailsData={logActionDetailsData}
          apiRef={apiRef}
          expandedRows={expandedRows}
        />
        {activeTab === 0 && (
          <LogFoldersDetailsDataGridTable
            autoRefreshFolderDetails={autoRefreshFolderDetails}
            startLogActionDetailsPolling={startLogActionDetailsPolling}
            stopLogActionDetailsPolling={stopLogActionDetailsPolling}
            loadLogActionDetails={loadLogActionDetails}
            logActionDetailsData={logActionDetailsData}
            setManuallyLoadingLogs={setManuallyLoadingLogs}
            apiRef={apiRef}
            expandedRows={expandedRows}
            setExpandedRows={setExpandedRows}
          />
        )}
        {activeTab === 1 && (
          <FileDetailsDataGridTable
            debugging={debugging}
            logDetails={logDetails}
            logsDetailsPaginationHandler={logsDetailsPaginationHandler}
            loadingLogDetails={loadingLogDetails}
            lazyLoadLogsDetails={lazyLoadLogsDetails}
          />
        )}
      </ItemsDataGridProProvider>
    </ComponentPaneLayout>
  );
};

export default LogDetails;
