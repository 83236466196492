/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';

import { CircularProgress } from '@mui/material';
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';

import PageNotFound from 'app/PageNotFound';
import { ROUTES } from 'modules/Sync/Constants/constants';
import {
  ConnectionsCallbackContent,
  ConnectionsNavContent,
  WorkflowsNavContent,
  LogsNavContent,
  WorkflowContent,
  WebhooksNavContent,
  LogsDetailsContent
} from 'modules/Sync/SyncNavInterfaces';

const {
  SYNC: SYNC_ROUTE,
  CONNECTIONS: CONNECTIONS_ROUTE,
  CONNECTIONS_CALLBACK: CONNECTIONS_CALLBACK_ROUTE,
  WORKFLOWS: WORKFLOWS_ROUTE,
  MAPPING_EDITOR: MAPPING_EDITOR_ROUTE,
  TEMPLATE_EDITOR: TEMPLATE_EDITOR_ROUTE,
  ARCHIVED_EDITOR: ARCHIVED_EDITOR_ROUTE,
  LOGS: LOGS_ROUTE,
  DASHBOARD: WEBHOOK_ROUTE,
  LOGS_DETAILS: LOGS_DETAILS_ROUTE
} = ROUTES;

const SyncRoutes = () => {
  const navigate = useNavigate();
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    document.addEventListener('ContainerUI:location', (e) => {
      const path = e.detail.location.pathname;
      const shortenedPath = path.substring(path.lastIndexOf('/') + 1);
      if (path.startsWith(SYNC_ROUTE)) {
        console.log('shortenedPath :>> ', shortenedPath);
        console.log('path :>> ', path);
        navigate(path);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    navigate(window.location.pathname);
    setIsMounted(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  return (
    <Routes>
      {!isMounted && <Route path="*" element={<CircularProgress />} />}
      {isMounted && (
        <>
          <Route path={`${SYNC_ROUTE}${CONNECTIONS_ROUTE}`} element={<ConnectionsNavContent />} />
          <Route path={`${SYNC_ROUTE}${CONNECTIONS_CALLBACK_ROUTE}`} element={<ConnectionsCallbackContent />} />
          <Route path={`${SYNC_ROUTE}${MAPPING_EDITOR_ROUTE}`} element={<WorkflowContent />} />
          <Route path={`${SYNC_ROUTE}${TEMPLATE_EDITOR_ROUTE}`} element={<WorkflowContent />} />
          <Route path={`${SYNC_ROUTE}${ARCHIVED_EDITOR_ROUTE}`} element={<WorkflowContent />} />
          <Route path={`${SYNC_ROUTE}${WORKFLOWS_ROUTE}`} element={<WorkflowsNavContent />} />
          <Route path={`${SYNC_ROUTE}${LOGS_ROUTE}`} element={<LogsNavContent />} />
          <Route path={`${SYNC_ROUTE}${LOGS_DETAILS_ROUTE}`} element={<LogsDetailsContent />} />
          <Route path={`${SYNC_ROUTE}${WEBHOOK_ROUTE}`} element={<WebhooksNavContent />} />
          <Route path={SYNC_ROUTE} element={<Navigate to={`${SYNC_ROUTE}${WORKFLOWS_ROUTE}`} />} />
          <Route path="*" element={<PageNotFound />} />
        </>
      )}
    </Routes>
  );
};

export default SyncRoutes;
