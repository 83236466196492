import React, { useEffect, useState } from 'react';

import { Box, ListItemButton, ListItemText, Stack } from '@mui/material';

import DraggableBox from 'components/DraggableComponents/DraggableBox';
import { Custom, IconStyled } from 'components/Icons';
import ListFlyoutPaper from 'components/ListFlyoutPaper';
import NavItemTooltip from 'components/Tooltip/NavItemTooltip';
import { debounce } from 'helpers/common';
import { CANVAS_NODE_TYPES, SYNC_ENABLED_CONNECTIONS } from 'modules/Sync/Constants/constants';
import { useSyncWorkflowEditorContext } from 'modules/Sync/WorkflowEditor/Contexts/WorkflowEditorContext';
import { onDrag, onDragStart, onDragEnd } from 'modules/Sync/WorkflowEditor/Drawers/dragDropHelpers';
import {
  connectorDrawerStyles,
  StyledConnectorDnDNode,
  StyledConnectorNodeThumbnailContainer
} from 'modules/Sync/WorkflowEditor/Drawers/drawer-styles';
import { connectionsMapper } from 'modules/Sync/WorkflowEditor/Drawers/node-mappers';
import StyledPopup from 'modules/Sync/WorkflowEditor/Drawers/StyledPopup';
import { getConnectorIcon } from 'modules/Sync/WorkflowEditor/helpers/SyncIcons';
import { syncColors } from 'styles/theme/colors';

const ConnectorsDrawer = ({ onClose, draggable }) => {
  const [connections, setConnections] = useState([]);
  const onCloseHandler = () => onClose('', false);
  const { connectionsList } = useSyncWorkflowEditorContext();
  const queryParameters = new URLSearchParams(window.location.search);
  const pathname = queryParameters.get('isArchived');
  const isArchived = pathname !== null;

  const onChangeHandler = debounce((event) => {
    const query = event?.target?.value?.toLowerCase();
    if (connectionsList?.syncConnections) {
      const filteredTasks = connectionsMapper(connectionsList?.syncConnections).filter(
        ({ taskDetail, name, connectionType }) =>
          `${name || taskDetail} ${connectionType}`.toLowerCase().includes(query)
      );
      setConnections(filteredTasks);
    }
  });

  useEffect(() => {
    if (connectionsList?.syncConnections) {
      setConnections(connectionsMapper(connectionsList?.syncConnections));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectionsList]);

  return (
    <DraggableBox draggable={draggable} handle=".handle" sx={{ zIndex: 'auto' }}>
      <StyledPopup onClose={onCloseHandler} sx={{ width: 280, height: '88%', bottom: 4 }}>
        <Box className="handle" sx={{ cursor: 'move' }}>
          <ListFlyoutPaper.Title textHelp="Drag items to add to workflow">Connectors</ListFlyoutPaper.Title>
        </Box>
        <ListFlyoutPaper.Search
          disabled={!!isArchived}
          placeholder="Search connectors..."
          onSearch={onChangeHandler}
          sx={{
            width: '100%',
            alignItems: 'center',
            '& > .MuiBox-root': { width: '96%' }
          }}
          InputSx={{
            backgroundColor: 'surface.darkSurface.primaryOpacity10',
            '&:hover': {
              backgroundColor: 'surface.darkSurface.primaryOpacity10'
            },
            color: 'surfaceColors.darkSurface.primaryOpacity60'
          }}
        />
        <ListFlyoutPaper.Items sx={{ overflowY: 'auto', marginBottom: 2, height: '100%' }}>
          {!!connections?.length && (
            <Stack
              sx={{
                width: '98%',
                height: '100%',
                overflowY: 'overlay',
                scrollbarColor: 'red yellow',
                marginBottom: 0,
                'span:last-child .dndnode': { mb: 0 }
              }}
            >
              {connections.map((connection, index) => (
                <DndConnectionType
                  key={connection?.nodeId || connection?.taskId}
                  data={connection}
                  index={index}
                  isArchived={isArchived}
                />
              ))}
            </Stack>
          )}
        </ListFlyoutPaper.Items>
      </StyledPopup>
    </DraggableBox>
  );
};

export default ConnectorsDrawer;

const DndConnectionType = ({ data, isArchived }) => {
  const { isCanvasUnlocked, setIsCanvasUnlocked, isInQueue } = useSyncWorkflowEditorContext();
  const [isHovered, setIsHovered] = React.useState(false);
  const { connectionType, nodeId, taskId } = data;
  const disabled = !SYNC_ENABLED_CONNECTIONS.includes(connectionType) || isInQueue;
  const disabledMessage = isInQueue
    ? 'Dragging is disabled while in queue'
    : 'Only Procore and Autodesk Forge connections are supported currently';
  const dragDisabled = disabled || !isCanvasUnlocked || isArchived;
  const onDragStartHandler = (event) => onDragStart(event, 'default', data, CANVAS_NODE_TYPES.CONNECTOR);

  useEffect(() => {
    setIsCanvasUnlocked(true);
  }, [setIsCanvasUnlocked]);

  return (
    <NavItemTooltip
      title={
        (isArchived && 'Workflow/mapping/action(s) are archived') ||
        (disabled ? disabledMessage : `${isCanvasUnlocked ? '' : 'Dragging nodes is disabled while canvas is locked'}`)
      }
    >
      <span className={`${nodeId} ${taskId}`}>
        <ListItemButton
          disableGutters
          disabled={dragDisabled}
          sx={{
            margin: 0,
            padding: 0,
            pl: '2%',
            width: '100%',
            justifyContent: 'space-between'
          }}
        >
          <IconStyled
            icon={<Custom.DragIndicator />}
            sx={{ position: 'absolute', left: 6, top: 12, opacity: isHovered ? 1 : 0 }}
          />
          <StyledConnectorDnDNode
            className="dndnode"
            draggable={!dragDisabled}
            onDragStart={onDragStartHandler}
            onDrag={onDrag}
            onDragEnd={onDragEnd}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            sx={{
              cursor: dragDisabled ? '' : 'pointer',
              marginX: 2,
              width: '100%'
            }}
          >
            <div
              style={{
                ...connectorDrawerStyles.flexrow
              }}
            >
              <StyledConnectorNodeThumbnailContainer data={data}>
                <div
                  style={{
                    ...connectorDrawerStyles.connectorThumbIcon
                  }}
                >
                  <IconStyled
                    key={data.connectionType}
                    icon={getConnectorIcon(data.connectionType)}
                    sx={{
                      svg: { width: 28, height: 28 },
                      position: 'relative',
                      right: 5,
                      bottom: 3
                    }}
                  />
                </div>
              </StyledConnectorNodeThumbnailContainer>

              <ListItemText
                sx={connectorDrawerStyles.connectorContainer}
                primary={
                  <div
                    style={{
                      ...connectorDrawerStyles.connectorTitle,
                      color: syncColors.canvasNodeColors.default
                    }}
                  >
                    {CANVAS_NODE_TYPES.CONNECTOR}
                  </div>
                }
                secondary={
                  <div style={connectorDrawerStyles.connectorDetail}>
                    {data?.connectorAlias || data?.name || data?.taskDetail}
                  </div>
                }
              />
            </div>
          </StyledConnectorDnDNode>
        </ListItemButton>
      </span>
    </NavItemTooltip>
  );
};
