import { ApolloClient, from, HttpLink } from '@apollo/client';
import { createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';

import cache from './cache/cache';
import awsmobile from '../aws-exports';

const url = awsmobile.aws_appsync_graphqlEndpoint;

const region = awsmobile.aws_appsync_region;

const auth = {
  type: 'AMAZON_COGNITO_USER_POOLS',
  jwtToken: async () => {
    try {
      return getTokenFromLocalStorage();
    } catch (error) {
      return '';
    }
  }
};

const httpLink = new HttpLink({
  uri: url,
  fetchOptions: {
    timeout: 900000 // TODO: TBD -> Timeout issue in production
  }
});

const link = from([
  createAuthLink({ url, region, auth }),
  createSubscriptionHandshakeLink({ url, region, auth }, httpLink)
]);

const defaultOptions = {
  watchQuery: { errorPolicy: 'ignore' },
  query: { errorPolicy: 'all' },
  mutate: { errorPolicy: 'all' }
};

const client = new ApolloClient({
  cache,
  link,
  defaultOptions,
  connectToDevTools: process.env.NODE_ENV !== 'production'
});

export default client;

const getTokenFromLocalStorage = () => {
  const query = /CognitoIdentityServiceProvider.+?.idToken/;
  // eslint-disable-next-line no-restricted-syntax
  for (const i in localStorage) {
    // eslint-disable-next-line no-prototype-builtins
    if (localStorage.hasOwnProperty(i) && (i.match(query) || (!query && typeof i === 'string'))) {
      return localStorage.getItem(i);
    }
  }
  return '';
};
