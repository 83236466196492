import { LicenseInfo } from '@mui/x-license-pro';
import ReactDOM from 'react-dom';

import App from 'App';
import { renderingInMicroFE } from 'helpers/domHelpers';
import { SyncModuleContentWebComponent } from 'modules/Sync/SyncModuleContent';

import { configureAmplify } from './amplifyconfig';

LicenseInfo.setLicenseKey(
  '89457e55e8ae2dcc565a4475d2f349d0Tz03MTQzNyxFPTE3MjE5MzIyMDIwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

configureAmplify();

const DevApp = () => <App serveLocal />;

if (renderingInMicroFE) ReactDOM.render(<DevApp />, document.getElementById('root'));
else if (!customElements.get('sync-module-content-web-component')) {
  customElements.define('sync-module-content-web-component', SyncModuleContentWebComponent);
}
