import { useEffect } from 'react';

import { gql } from '@apollo/client';

import client from 'apollo/client';
import { FETCH_POLICIES } from 'constants/globalConstants';
import { syncLogActionDetails as SYNC_LOG_ACTION_DETAILS } from 'graphql/queries';
import { IsSyncFinished } from 'modules/Sync/helpers/logSyncStatusHelpers';

import { handleRowExpansionChange, refreshStatus, updateRows } from './logFolderDetailsDataGridHelpers';
import LogFoldersDetailDataGridTableRows from './LogFoldersDetailDataGridTableRows';

const { fetchPolicy: FETCH_POLICY } = FETCH_POLICIES.CACHE_FIRST;

const useLogFolderDetailsDataGridTableHooks = ({
  fileSyncLogId,
  loadLogActionDetails,
  stopLogActionDetailsPolling,
  logFoldersInitiating,
  setLogFoldersInitiating,
  autoRefreshFolderDetails,
  pollingItems,
  loadLogActionFolderDetails,
  setPollingItems,
  stopLogActionFolderDetailsPolling,
  startLogActionDetailsPolling,
  apiRef,
  logActionDetailsData,
  rows,
  setManuallyLoadingLogs
}) => {
  const syncStatus = logActionDetailsData?.syncLogActionDetails?.syncStatus || 0;
  const onLoadLogActionDetailsCompleted = (logActionDetailsData) => {
    const actionDetails = logActionDetailsData?.syncLogActionDetails;
    const latestSyncStatus = actionDetails?.syncStatus || 0;
    const newRows = LogFoldersDetailDataGridTableRows(actionDetails?.data?.destinations);
    if (IsSyncFinished(latestSyncStatus)) stopLogActionDetailsPolling();
    updateRows(apiRef, newRows);
  };

  useEffect(() => {
    if (fileSyncLogId) {
      const fetchFoldersData = async () => {
        const { data } = await client.query({
          query: gql(SYNC_LOG_ACTION_DETAILS),
          variables: { query: { fileSyncLogId } },
          notifyOnNetworkStatusChange: true,
          fetchPolicy: FETCH_POLICY
        });

        onLoadLogActionDetailsCompleted(data);
        if (logFoldersInitiating) setLogFoldersInitiating(false);
      };
      fetchFoldersData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileSyncLogId]);

  useEffect(() => {
    refreshStatus({
      autoRefreshFolderDetails,
      syncStatus,
      pollingItems,
      loadLogActionDetails,
      loadLogActionFolderDetails,
      fileSyncLogId,
      setPollingItems,
      stopLogActionFolderDetailsPolling,
      startLogActionDetailsPolling,
      stopLogActionDetailsPolling,
      apiRef,
      setManuallyLoadingLogs
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoRefreshFolderDetails, pollingItems]);

  useEffect(() => {
    const fetchedRows = LogFoldersDetailDataGridTableRows(
      logActionDetailsData?.syncLogActionDetails?.data?.destinations
    );
    if (rows?.length) apiRef.current.setRowChildrenExpansion(rows?.[0]?.id, false);
    updateRows(apiRef, fetchedRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logActionDetailsData]);

  useEffect(
    () => apiRef.current.subscribeEvent('rowExpansionChange', handleRowExpansionChange({ apiRef, fileSyncLogId })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [apiRef]
  );
};

export default useLogFolderDetailsDataGridTableHooks;
