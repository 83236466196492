export const getFiltersHelpers = ({
  expandedFilterGroups,
  setExpandedFilterGroups,
  filters,
  setFilters,
  filterGroups,
  parsedFileTypeFilters,
  setFilterGroups,
  defaultFilters,
  defaultFilterGroups,
  setFiltersAvailablity,
  setSource,
  setDestinations
}) => {
  const selectAllFilters = () => {
    setFilters(defaultFilters);
    setFilterGroups(defaultFilterGroups);
  };

  const deselectAllFilters = () => {
    setFilters([]);
    setFilterGroups([]);
  };

  const toggleFilters = (event) => {
    if (event.target.checked) selectAllFilters();
    else deselectAllFilters();

    setFiltersAvailablity(event.target.checked);
  };

  const onFilterGroupTransition = (event, group) => {
    event.preventDefault();
    event.stopPropagation();

    return expandedFilterGroups.includes(group)
      ? setExpandedFilterGroups((groups) => [...groups.filter((filterGroup) => filterGroup !== group)])
      : setExpandedFilterGroups((groups) => [...groups, group]);
  };

  const selectAllFiltersHandler = (event) => {
    onFilterGroupTransition(event);
    if (filters.length) return deselectAllFilters();
    return selectAllFilters();
  };

  const selectFilterGroupHandler = (_, group) => {
    setFilters((currFilters) => {
      let newFilters = [];
      if (filterGroups.includes(group)) {
        const groupFilters = parsedFileTypeFilters.filter(({ type }) => type === group).map(({ name }) => name);
        const nonGroupFilters = currFilters.filter((filter) => !groupFilters.includes(filter));
        newFilters = nonGroupFilters;
      } else {
        const selectedGroupFilters = parsedFileTypeFilters.filter(({ type }) => type === group).map(({ name }) => name);
        newFilters = [...new Set([...currFilters, ...selectedGroupFilters]), group];
      }
      return newFilters;
    });

    setFilterGroups((currentGroups) =>
      currentGroups.includes(group)
        ? currentGroups.filter((currentGroup) => currentGroup !== group)
        : [...currentGroups, group]
    );
  };

  const selectFilterHandler = (event, id) => {
    event.stopPropagation();
    setFilters((filters) => (filters.includes(id) ? filters.filter((filterId) => filterId !== id) : [...filters, id]));
  };

  const selectUploadBehaviour = (event, nodeId) =>
    setDestinations((destinations) =>
      destinations.reduce(
        (newDestinations, currentDestination) => [
          ...newDestinations,
          {
            ...currentDestination,
            ...(currentDestination.nodeId === nodeId ? { uploadBehavior: event?.target?.checked ? 1 : 0 } : {})
          }
        ],
        []
      )
    );

  const selectDownloadBehaviour = (event) =>
    setSource((source) => ({ ...source, downloadBehavior: event?.target?.checked ? 1 : 0 }));

  return {
    selectAllFilters,
    deselectAllFilters,
    toggleFilters,
    onFilterGroupTransition,
    selectAllFiltersHandler,
    selectFilterGroupHandler,
    selectFilterHandler,
    selectUploadBehaviour,
    selectDownloadBehaviour
  };
};
