import { SYNC_LOGS } from 'modules/Sync/Constants/constants';
import { syncColors } from 'styles/theme/colors';

const { LOG_FILE_TERMINAL_STATUS, LOG_FOLDER_TERMINAL_STATUS, LOG_ACTION_TERMINAL_STATUS, LOGS_SYNC_STATUS } =
  SYNC_LOGS;

const { IN_PROGRESS, IN_THE_QUEUE, FAILED, NEED_REVIEW } = LOGS_SYNC_STATUS;

export const IsSyncFinished = (syncStatus) => syncStatus !== IN_PROGRESS;

export const isMigrated = (syncStatus) => syncStatus !== IN_THE_QUEUE;

export const IsSyncFailedOrNeedsReview = (syncStatus) => syncStatus === FAILED || syncStatus === NEED_REVIEW;

export const getMappingTerminalStatus = (status) => {
  switch (status) {
    case LOG_ACTION_TERMINAL_STATUS.SUCCESS:
      return [syncColors.statusColors.success, 'Success'];
    case LOG_ACTION_TERMINAL_STATUS.FAILED:
      return [syncColors.statusColors.failed, 'Failed'];
    case LOG_ACTION_TERMINAL_STATUS.SKIPPED:
      return [syncColors.statusColors.skipped, 'Skipped'];
    case LOG_ACTION_TERMINAL_STATUS.IN_PROGRESS:
      return [syncColors.statusColors.inProgress, 'In progress'];
    case LOG_ACTION_TERMINAL_STATUS.NEEDS_REVIEW:
    default:
      return [syncColors.statusColors.needsReview, 'Needs review'];
  }
};

export const getFileTerminalStatus = (status) => {
  switch (status) {
    case LOG_FILE_TERMINAL_STATUS.SUCCESS:
      return [syncColors.statusColors.success, 'Success'];
    case LOG_FILE_TERMINAL_STATUS.SKIPPED:
      return [syncColors.statusColors.skipped, 'Skipped'];
    case LOG_FILE_TERMINAL_STATUS.IN_PROGRESS:
      return [syncColors.statusColors.inProgress, 'In progress'];
    default:
      return [syncColors.statusColors.failed, 'Failed'];
  }
};

export const getFolderTerminalStatus = (status) => {
  switch (status) {
    case LOG_FOLDER_TERMINAL_STATUS.SUCCESS:
      return [syncColors.statusColors.success, 'Success'];
    case LOG_FOLDER_TERMINAL_STATUS.FAILED:
      return [syncColors.statusColors.failed, 'Failed'];
    case LOG_FOLDER_TERMINAL_STATUS.SKIPPED:
      return [syncColors.statusColors.skipped, 'Skipped'];
    case LOG_FOLDER_TERMINAL_STATUS.IN_PROGRESS:
      return [syncColors.statusColors.inProgress, 'In progress'];
    case LOG_FOLDER_TERMINAL_STATUS.NEEDS_REVIEW:
    default:
      return [syncColors.statusColors.needsReview, 'Needs review'];
  }
};
