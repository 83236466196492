import {
  DROPDOWN_DEFAULT_PG_SIZE,
  MAPPING_ACTIONS,
  REALTIME_SYNC,
  SCHEDULE_SYNC,
  SYNC_NOW,
  SYNC_STATUS,
  SYNC_TYPES
} from 'modules/Sync/Constants/constants';
import { syncColors } from 'styles/theme/colors';

export const getSyncStatus = (status) =>
  Object.values(SYNC_STATUS).find((value) => value === status) || SYNC_STATUS.START;

export const getSyncUserReadableState = (status) => (status ? 'Stop sync' : 'Run sync');

export const getSyncType = (type) => SYNC_TYPES.find(({ value }) => value === type);

export const isTemplateCanvas = (pathname) => RegExp('template').test(pathname);

export const getSyncTypeConfig = (syncType, isSyncEnabled, action) => ({
  ...getSyncType(syncType),
  ...getSyncActionColor(syncType, isSyncEnabled, action)
});

export const getSyncActionColor = (syncType, isSyncEnabled, action) => {
  if (syncType === SYNC_NOW && action === MAPPING_ACTIONS.publish && isSyncEnabled === true) {
    return {
      color: syncColors.publishActionNodeColors.syncNow,
      backgroundColor: syncColors.publishActionNodeColors.syncNowBackground
    };
  }
  if (isSyncEnabled === false)
    return {
      color: syncColors.canvasNodeColors.disabled,
      backgroundColor: syncColors.canvasNodeColors.disabledBackground
    };
  if (syncType === REALTIME_SYNC)
    return {
      color: syncColors.canvasNodeColors.realTimeSync,
      backgroundColor: syncColors.canvasNodeColors.realTimeSyncBackground
    };
  if (syncType === SCHEDULE_SYNC)
    return {
      color: syncColors.canvasNodeColors.scheduleSync,
      backgroundColor: syncColors.canvasNodeColors.scheduleSyncBackground
    };
  if (syncType === SYNC_NOW)
    return {
      color: syncColors.canvasNodeColors.syncNow,
      backgroundColor: syncColors.canvasNodeColors.syncNowBackground
    };

  return {
    color: syncColors.canvasNodeColors.default,
    backgroundColor: syncColors.canvasNodeColors.defaultBackground
  };
};

export const getSyncEdgeColor = (syncType, isSyncEnabled, action) => {
  if (syncType === SYNC_NOW && action === 'Publish' && isSyncEnabled === true)
    return {
      color: syncColors.publishActionEdgeColors.syncNow
    };
  if (isSyncEnabled !== true)
    return {
      color: syncColors.canvasEdgeColors.disabled
    };
  if (syncType === REALTIME_SYNC)
    return {
      color: syncColors.canvasEdgeColors.realTimeSync
    };
  if (syncType === SCHEDULE_SYNC)
    return {
      color: syncColors.canvasEdgeColors.scheduleSync
    };
  if (syncType === SYNC_NOW)
    return {
      color: syncColors.canvasEdgeColors.syncNow
    };
  return {
    color: syncColors.canvasEdgeColors.disabled
  };
};

export const getLatestFolders = (folders) => {
  const totalFolders = folders?.length;
  const leftoverFolders = totalFolders % DROPDOWN_DEFAULT_PG_SIZE;
  const lastPageFolderCount = leftoverFolders ? -leftoverFolders : totalFolders;
  return folders?.slice(lastPageFolderCount);
};
