import { useEffect, useMemo, useState } from 'react';

import { useGridApiRef } from '@mui/x-data-grid-pro';
import { useParams } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import { v4 as uuidv4 } from 'uuid';

import ItemsDatagridPro from 'components/ItemsDatagridPro';
import { getTimeZone } from 'helpers/dateFunctions';
import { hashCode } from 'helpers/stringFunctions';
import { useSyncLogsContext } from 'modules/Sync/Logs/Contexts/LogsContext';
import fileDetailsDataGridStyles from 'modules/Sync/Logs/LogsDetails/FileDetailsDataGridTable/fileDetailsDataGridStyles';
import NoRowsOverlay from 'modules/Sync/Logs/LogsDetails/NoRowsOverlay';

const NoRowsOverlayText = () => (
  <span>
    No syncs have been run. <br />
    There are no logs to display.
  </span>
);

const LogsDetails = ({
  debugging,
  logDetails,
  logsDetailsPaginationHandler,
  loadingLogDetails,
  lazyLoadLogsDetails
}) => {
  const apiRef = useGridApiRef();
  const { logsDetailsSearchPhrase } = useSyncLogsContext();
  const { id: fileSyncLogId = '' } = useParams();
  const [rows, setRows] = useState([]);
  const handleCellComponent = ({ field, row }) => {
    if (row.isLast)
      return (
        <>
          {row[field]}
          <Waypoint key={rows.length} bottomOffset="-20%" onEnter={onScroll} />
        </>
      );
    return row[field] || null;
  };
  const timeZone = useMemo(() => getTimeZone(), []);

  const columns = [
    {
      field: 'logEntry',
      headerName: 'Log File',
      maxWidth: '100%',
      flex: 1,
      sortable: false,
      renderCell: handleCellComponent
    }
  ];

  const onScroll = () => {
    const skip = rows?.length || 0;
    logsDetailsPaginationHandler(skip, {
      fileSyncLogId,
      searchPhrase: logsDetailsSearchPhrase,
      IsDebug: !!debugging,
      timeZone
    });
  };

  useEffect(() => {
    const getRows = () =>
      logDetails?.syncLogFileDetails?.reduce((logs, currLog, currIndex, syncLogFileDetailsData) => {
        if (currLog)
          logs.push({
            id: `${hashCode(currLog + uuidv4())}`,
            logEntry: currLog,
            isLast: currIndex === syncLogFileDetailsData?.length - 1
          });
        return logs;
      }, []) || [];
    setRows(getRows());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logDetails?.syncLogFileDetails?.length]);

  useEffect(() => {
    lazyLoadLogsDetails({ fileSyncLogId, IsDebug: !!debugging, searchPhrase: logsDetailsSearchPhrase, timeZone });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileSyncLogId]);

  return (
    <ItemsDatagridPro
      apiRef={apiRef}
      components={{ NoRowsOverlay: () => <NoRowsOverlay text={<NoRowsOverlayText />} /> }}
      asyncApi={{ onScroll }}
      onRowsScrollEnd={onScroll}
      density="standard"
      checkboxSelection={false}
      disableColumnMenu
      disableSelectionOnClick
      columns={columns}
      rows={rows}
      experimentalFeatures={{ rowPinning: true, newEditingApi: true }}
      loading={loadingLogDetails}
      sx={fileDetailsDataGridStyles}
      getRowHeight={() => 'auto'}
    />
  );
};

export default LogsDetails;
