import { createSvgIcon } from '@mui/material';

import { ReactComponent as ActionAlt } from './action-alt.svg';
import { ReactComponent as Action } from './action.svg';
import { ReactComponent as AddBoxSvg } from './add-box.svg';
import { ReactComponent as AddBoxAlt } from './add-box1.svg';
import { ReactComponent as Add } from './add.svg';
import { ReactComponent as ArchivedActive } from './archived-active.svg';
import { ReactComponent as ArchivedInactive } from './archived-inactive.svg';
import { ReactComponent as Archived } from './archived.svg';
import { ReactComponent as ArrowDropDownSvg } from './arrow-down-alt.svg';
import { ReactComponent as ArrowDown } from './arrow-down.svg';
import { ReactComponent as ArrowDropDown } from './arrow-drop-down.svg';
import { ReactComponent as ArrowRightSvg } from './arrow-right.svg';
import { ReactComponent as AttachFile } from './attach-file.svg';
import { ReactComponent as AutodeskIcon } from './autodesk-icon.svg';
import { ReactComponent as Calendar } from './calendar.svg';
import { ReactComponent as CanvasFitView } from './canvas-fit-view.svg';
import { ReactComponent as CanvasHorizontalLayout } from './canvas-horizontal-layout.svg';
import { ReactComponent as CanvasLock } from './canvas-lock.svg';
import { ReactComponent as CanvasUnLock } from './canvas-unlock.svg';
import { ReactComponent as CanvasVerticalLayout } from './canvas-vertical-layout.svg';
import { ReactComponent as CanvasZoomIn } from './canvas-zoom-in.svg';
import { ReactComponent as CanvasZoomOut } from './canvas-zoom-out.svg';
import { ReactComponent as CheckCircleOutlinePrimary } from './check-circle-outline-primary.svg';
import { ReactComponent as Clear } from './clear.svg';
import { ReactComponent as CloseSvg } from './close-icon.svg';
import { ReactComponent as Close } from './close.svg';
import { ReactComponent as Complete } from './complete.svg';
import { ReactComponent as Connection } from './connection.svg';
import { ReactComponent as CONNECTIONS } from './connections.svg';
import { ReactComponent as CopyFiles } from './copy-files.svg';
import { ReactComponent as CopyLink } from './copy-link.svg';
import { ReactComponent as DASHBOARD } from './dashboard.svg';
import { ReactComponent as DataUsage } from './data-usage.svg';
import { ReactComponent as DeleteOutlineActive } from './delete-outline-active.svg';
import { ReactComponent as DeleteOutline } from './delete-outline.svg';
import { ReactComponent as DisableByDefaultSvg } from './disable-by-default.svg';
import { ReactComponent as DockSvg } from './dock-icon.svg';
import { ReactComponent as Dock } from './dock.svg';
import { ReactComponent as Download2 } from './download-2.svg';
import { ReactComponent as DragIndicatorAlt } from './drag-indicator-alt.svg';
import { ReactComponent as DragIndicatorSvg } from './drag-indicator-icon.svg';
import { ReactComponent as DragIndicator } from './drag-indicator.svg';
import { ReactComponent as Edit } from './edit.svg';
import { ReactComponent as Error } from './error.svg';
import { ReactComponent as EvolveForesiteIcon } from './evolve-foresite-icon.svg';
import { ReactComponent as ExpandMore } from './expand-more.svg';
import { ReactComponent as Export } from './export.svg';
import { ReactComponent as FilterListSvg } from './filter-list.svg';
import { ReactComponent as FolderOpen } from './folder-open.svg';
import { ReactComponent as Folder } from './folder.svg';
import { ReactComponent as Import } from './import.svg';
import { ReactComponent as InfoRounded } from './info-rounded.svg';
import { ReactComponent as InfoOutline } from './info_outline.svg';
import { ReactComponent as KeyboardArrowDown } from './keyboard-arrow-down.svg';
import { ReactComponent as KeyboardArrowRight } from './keyboard-arrow-right.svg';
import { ReactComponent as LOGS } from './logs.svg';
import { ReactComponent as Mail } from './mail.svg';
import { ReactComponent as ManageIssues } from './manage-issues.svg';
import { ReactComponent as Mapping } from './mapping.svg';
import { ReactComponent as Menu } from './menu.svg';
import { ReactComponent as MobileRestricted } from './mobile-restricted.svg';
import { ReactComponent as MoreVertSvg } from './more-vert-alt.svg';
import { ReactComponent as NavItem1 } from './nav-item-1.svg';
import { ReactComponent as NavItem10 } from './nav-item-10.svg';
import { ReactComponent as NavItem11 } from './nav-item-11.svg';
import { ReactComponent as NavItem13 } from './nav-item-13.svg';
import { ReactComponent as NavItem14 } from './nav-item-14.svg';
import { ReactComponent as NavItem2 } from './nav-item-2.svg';
import { ReactComponent as NavItem3 } from './nav-item-3.svg';
import { ReactComponent as NavItem4 } from './nav-item-4.svg';
import { ReactComponent as NavItem5 } from './nav-item-5.svg';
import { ReactComponent as NavItem6 } from './nav-item-6.svg';
import { ReactComponent as NavItem7 } from './nav-item-7.svg';
import { ReactComponent as NavItem8 } from './nav-item-8.svg';
import { ReactComponent as NavItem9 } from './nav-item-9.svg';
import { ReactComponent as NavigateNextSvg } from './navigate-next.svg';
import { ReactComponent as NewformaIcon } from './newforma-icon.svg';
import { ReactComponent as PlangridIcon } from './plangrid-icon.svg';
import { ReactComponent as ProcoreIcon } from './procore-icon.svg';
import { ReactComponent as Publish } from './publish.svg';
import { ReactComponent as Refresh } from './refresh.svg';
import { ReactComponent as Save } from './save.svg';
import { ReactComponent as SearchSvg } from './search-alt.svg';
import { ReactComponent as Search } from './search.svg';
import { ReactComponent as Settings } from './setting.svg';
import { ReactComponent as SharepointIcon } from './sharepoint-icon.svg';
import { ReactComponent as SmartDisplay } from './smart-display.svg';
import { ReactComponent as StopFilled } from './stop-filled.svg';
import { ReactComponent as Stop } from './stop.svg';
import { ReactComponent as SwapHorizontalActive } from './swap-horiz-active.svg';
import { ReactComponent as SwapHorizontalInactive } from './swap-horiz-inactive.svg';
import { ReactComponent as Sync } from './sync.svg';
import { ReactComponent as Template } from './template.svg';
import { ReactComponent as UpdateBg } from './update-bg.svg';
import { ReactComponent as ViewColumnSvg } from './view-column-alt.svg';
import { ReactComponent as WarningIcon } from './warning.svg';
import { ReactComponent as Webhook } from './webhook.svg';
import { ReactComponent as WindowsIcon } from './windows-icon.svg';
import { ReactComponent as WorkflowActive } from './workflow-active.svg';
import { ReactComponent as WorkflowEditorBg } from './workflow-editor-bg.svg';
import { ReactComponent as WorkflowInactive } from './workflow-inactive.svg';
import { ReactComponent as Workflow } from './workflow.svg';
import { ReactComponent as WORKFLOWS } from './workflows.svg';

const AddBoxIcon = createSvgIcon(<AddBoxSvg />, 'AddBox');
const ArrowRightIcon = createSvgIcon(<ArrowRightSvg />, 'ArrowRight');
const DataUsageSvg = createSvgIcon(<DataUsage />, 'DataUsageSvg');
const DragIndicatorIcon = createSvgIcon(<DragIndicatorSvg />, 'DragIndicator');
const NavigateNextIcon = createSvgIcon(<NavigateNextSvg />, 'NavigateNext');
const ViewColumnIcon = createSvgIcon(<ViewColumnSvg />, 'ViewColumn');
const FilterList = createSvgIcon(<FilterListSvg />, 'FilterList');
const MoreVerAlt = createSvgIcon(<MoreVertSvg />, 'MoreVert');
const CloseIcon = createSvgIcon(<CloseSvg />, 'Close');
const UpdateIcon = createSvgIcon(<UpdateBg />, 'UpdateBg');
const SyncIcon = createSvgIcon(<Sync />, 'Sync');
const SearchIcon = createSvgIcon(<SearchSvg />, 'Search');
const ArrowDropDownIcon = createSvgIcon(<ArrowDropDownSvg />, 'ArrowDropDown');
const InfoOutlineIcon = createSvgIcon(<InfoOutline />, 'InfoOutline');
const DockIcon = createSvgIcon(<DockSvg />, 'Dock');
const DisableByDefault = createSvgIcon(<DisableByDefaultSvg />, 'DisabledByDefault');

export {
  Action,
  ActionAlt,
  AddBoxIcon,
  ArrowDown,
  ArrowDropDown,
  ArrowDropDownIcon,
  ArrowRightIcon,
  Connection,
  Dock,
  DockIcon,
  DisableByDefault,
  Export,
  InfoRounded,
  NavItem1,
  NavItem2,
  NavItem3,
  NavItem4,
  NavItem5,
  NavItem6,
  NavItem7,
  NavItem8,
  NavItem9,
  NavItem10,
  NavItem11,
  NavItem13,
  NavItem14,
  WorkflowActive,
  WorkflowInactive,
  WORKFLOWS,
  Workflow,
  WorkflowEditorBg,
  Template,
  CONNECTIONS,
  LOGS,
  DASHBOARD,
  Mapping,
  MobileRestricted,
  Webhook,
  Menu,
  DataUsage,
  DataUsageSvg,
  AddBoxAlt,
  Add,
  AttachFile,
  AutodeskIcon,
  ArchivedActive,
  ArchivedInactive,
  Archived,
  Calendar,
  CanvasLock,
  CanvasUnLock,
  CanvasFitView,
  CanvasZoomOut,
  CanvasZoomIn,
  CanvasVerticalLayout,
  CanvasHorizontalLayout,
  CheckCircleOutlinePrimary,
  Clear,
  Complete,
  CopyFiles,
  CopyLink,
  Close,
  CloseIcon,
  DeleteOutlineActive,
  DeleteOutline,
  Download2,
  DragIndicator,
  DragIndicatorAlt,
  DragIndicatorIcon,
  Edit,
  Error,
  EvolveForesiteIcon,
  ExpandMore,
  FilterList,
  Folder,
  FolderOpen,
  Import,
  InfoOutlineIcon,
  KeyboardArrowDown,
  KeyboardArrowRight,
  Mail,
  ManageIssues,
  NewformaIcon,
  NavigateNextIcon,
  PlangridIcon,
  ProcoreIcon,
  Publish,
  Refresh,
  Save,
  Search,
  SearchIcon,
  SharepointIcon,
  SmartDisplay,
  StopFilled,
  Stop,
  ViewColumnIcon,
  WarningIcon,
  WindowsIcon,
  MoreVerAlt,
  UpdateIcon,
  SwapHorizontalActive,
  SwapHorizontalInactive,
  SyncIcon,
  Settings
};
