import { syncColors } from 'styles/theme/colors';

export const actionNodeStyles = {
  nodeContainer: {
    minHeight: 50,
    height: 50,
    width: 200,
    borderStyle: 'solid',
    borderWidth: '1px',
    borderRadius: '4px',
    padding: 0,
    boxShadow: ' 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)'
  },
  flexBox: { display: 'flex', flexDirection: 'row', height: '100%' },
  iconContainer: {
    width: 48,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 12,
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4
  },
  whiteSquare: { width: 20, height: 20, backgroundColor: syncColors.canvasNodeColors.background },
  robotoText: { marginLeft: 16, fontFamily: 'Roboto' },
  title: { fontSize: 12, width: 176, height: 16, textTransform: 'capitalize' },
  taskDetails: {
    parent: {
      fontSize: 14,
      width: 176,
      height: 25,
      marginBottom: 6,
      color: 'rgba(0, 0, 0, 0.87)',
      overflow: 'hidden'
    },
    child: {
      width: 130,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  },
  optionsMenuOnTop: { position: 'absolute', top: 0, right: -4, backgroundColor: 'transparent' },
  optionsMenuOnBottom: { position: 'absolute', top: 20, right: -4, backgroundColor: 'transparent' },
  handle: {
    left: '50%',
    borderRadius: 2,
    backgroundColor: syncColors.canvasNodeColors.background
  }
};

export const connectorNodeStyles = {
  nodeContainer: {
    height: 50,
    width: 200,
    borderStyle: 'solid',
    borderWidth: '1px',
    borderRadius: '4px',
    padding: 0,
    boxShadow: ' 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)'
  },
  flexBox: { display: 'flex', flexDirection: 'row', height: '100%' },
  iconContainer: {
    width: 48.5,
    paddingLeft: 14,
    paddingRight: 10,
    paddingTop: 12,
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4
  },
  square: {
    width: 18,
    height: 19
  },
  robotoText: { marginLeft: 16, fontFamily: 'Roboto' },
  title: {
    fontSize: 12,
    width: 176,
    height: 16,
    textTransform: 'capitalize'
  },
  taskDetails: {
    parent: {
      fontSize: 14,
      width: 176,
      height: 25,
      marginBottom: 6,
      color: 'rgba(0, 0, 0, 0.87)',
      overflow: 'hidden'
    },
    child: {
      width: 130,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  },
  optionsMenu: { position: 'absolute', top: 0, right: 0 },
  handle: {
    top: {
      top: '50%',
      borderRadius: 2,
      backgroundColor: syncColors.canvasNodeColors.background
    },
    left: {
      left: '50%',
      borderRadius: 2,
      backgroundColor: syncColors.canvasNodeColors.background
    }
  }
};
