import React, { useEffect } from 'react';

import { Stack } from '@mui/material';
import MUIAccordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';

import { Custom } from 'components/Icons';

export const BORDER_COLOR = "1px solid #0000001A";

const ControlledAccordion = ({
  items = [],
  hideFirstBorder,
  hideLastBorder,
  sx,
  titleSx,
  infoSx,
  defaultExpandedItemId
}) => {
  const [expanded, setExpanded] = React.useState(
    typeof defaultExpandedItemId === 'undefined'
      ? items[items.length - 1].id
      : defaultExpandedItemId
  );
  const prevItems = React.useRef(items);

  const handleChange = (panel) => (event, isExpanded) => {
    /* Input Elements can be added to the accordion with 'data-behavior': 'DO-NOT-EXPAND',
    this way the events on those elements won't cause changes in accordion behavior  */
    if (event?.target?.getAttribute('data-behavior') !== 'DO-NOT-EXPAND') {
      setExpanded(isExpanded ? panel : false);
    }
  };

  useEffect(() => {
    /* When a new item is added to the end of item's list, expand it */
    if (typeof defaultExpandedItemId === 'undefined') {
      const newItem = items[items.length - 1];
      setExpanded(newItem.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items.length, defaultExpandedItemId]);

  useEffect(() => {
    /* when any of the items changes its id to anything other than undefined, 
    it is added at the end of items array, so set it as expanded again  */
    const idChangedItem = items.filter(
      ({ id }, index) => id && id !== prevItems.current[index]?.id
    );

    if (idChangedItem.length) {
      setExpanded(items[items.length - 1].id);
    }
    prevItems.current = items;
  }, [items]);

  if (!items.length) return null;
  return (
    <Stack
      sx={{
        '& .MuiAccordion-root': {
          position: 'unset',
          borderRadius: 0,
          boxShadow: 'none',
          borderBottom: BORDER_COLOR
        },
        '& .MuiAccordion-root:first-of-type': {
          borderTop: hideFirstBorder ? 'none' : BORDER_COLOR
        },
        '& .MuiAccordion-root:last-of-type': {
          borderBottom: hideLastBorder ? 'none' : BORDER_COLOR
        },
        ...sx
      }}
    >
      {items.map((item) => (
        <MUIAccordion
          key={item.id}
          expanded={item.id === expanded}
          disableGutters
          onChange={handleChange(item.id)}
        >
          <AccordionSummary expandIcon={<Custom.ExpandMore />}>
            <Typography sx={titleSx}>{item.title}</Typography>
            <Typography sx={infoSx}>{item.info}</Typography>
          </AccordionSummary>
          <AccordionDetails>{item.content}</AccordionDetails>
        </MUIAccordion>
      ))}
    </Stack>
  );
};

export default ControlledAccordion;
