const RealTimeLogsDataGridTableColumns = (handleCellComponent) => [
  {
    field: 'name',
    headerName: 'Date / File / Workflow / Mapping ',
    flex: 1.5,
    colSpan: (params) => (params?.row?.filePath ? 5 : 1),
    editable: false,
    hideable: false,
    sortable: false,
    resizable: true,
    renderCell: handleCellComponent
  },
  {
    field: 'actionAlias',
    headerName: 'Action',
    flex: 1,
    sortable: false,
    resizable: true,
    renderCell: handleCellComponent
  },
  {
    field: 'source',
    headerName: 'Source',
    flex: 1,
    sortable: false,
    resizable: true,
    renderCell: handleCellComponent
  },
  {
    field: 'destinations',
    headerName: 'Destination(s)',
    flex: 1,
    sortable: false,
    resizable: true,
    renderCell: handleCellComponent
  }
];

export default RealTimeLogsDataGridTableColumns;
