import { useSyncConnectionsContext } from 'modules/Sync/Connections/ConnectionsContext';
import { CONNECTION_TYPES } from 'modules/Sync/Constants/constants';

import { triggerAuthentication } from '../authenticationUtils';

const useAutodeskMutation = () => {
  const { connectorsList } = useSyncConnectionsContext();
  const getUrl = (connectionType) => connectorsList?.syncConnectorsList?.find((x) => x.name === connectionType)?.url;

  const authenticateCredentials = async ({ formData, onError }) => {
    let url = '';
    if (formData?.configuration?.isTeamDocs) url = getUrl(CONNECTION_TYPES.AUTODESKTEAMDOCS);
    if (formData?.configuration?.isField) url = getUrl(CONNECTION_TYPES.AUTODESKFIELD);
    if (!url) {
      onError('Callback URL is not configured on this Source');
      return 'error';
    }
    const code = await triggerAuthentication({
      url
    });
    if (!code) {
      onError('Failed to fetch Authorization Code. Please try again');
      return 'error';
    }
    return code;
  };

  const generateAuthCode = async ({ formData, onError }) => {
    let code = null;
    if (formData?.configuration?.isTeamDocs || formData?.configuration?.isField) {
      code = await authenticateCredentials({ formData, onError });
      if (code) {
        return code;
      }
    }
    return '';
  };
  return { generateAuthCode };
};

export default useAutodeskMutation;
