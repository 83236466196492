import skipTakePagination from './skipTakePagination';

const QueryPolicies = {
  fields: {
    syncConnections: skipTakePagination(),
    syncWorkflows: skipTakePagination(),
    syncArchivedWorkflows: skipTakePagination(),
    syncWebhooks: skipTakePagination(),
    getAutodeskForgeHubs: skipTakePagination(['query', ['connectionId']]),
    getAutodeskForgeProjects: skipTakePagination(['query', ['connectionId', 'hubId']]),
    getAutodeskForgeSubItems: skipTakePagination(['query', ['connectionId', 'hubId', 'projectId', 'parentFolderId']]),
    getAutodeskFieldProjects: skipTakePagination(['query', ['connectionId']]),
    getAutodeskFieldFolders: skipTakePagination(['query', ['connectionId', 'projectId', 'parentFolderId']]),
    getAutodeskGlueProjects: skipTakePagination(['query', ['connectionId']]),
    getAutodeskGlueFolders: skipTakePagination(['query', ['connectionId', 'projectId', 'parentFolderId']]),
    getNewformaProjects: skipTakePagination(['query', ['connectionId']]),
    getNewformaFolders: skipTakePagination(['query', ['connectionId', 'projectId', 'parentFolderId']]),
    getProcoreCompanies: skipTakePagination(['query', ['connectionId']]),
    getProcoreProjects: skipTakePagination(['query', ['connectionId', 'companyId']]),
    getProcoreFolders: skipTakePagination(['query', ['connectionId', 'companyId', 'projectId', 'parentFolderId']]),
    getPlangridProjects: skipTakePagination(['query', ['connectionId']]),
    getPlangridFolders: skipTakePagination(['query', ['connectionId', 'projectId', 'parentFolderId']]),
    getSharepointSites: skipTakePagination(['query', ['connectionId']]),
    getSharepointFolders: skipTakePagination(['query', ['connectionId', 'siteId', 'parentFolderId']]),
    syncFetchForesiteCompanies: skipTakePagination(['query', ['connectionId']]),
    syncFetchForesiteProjects: skipTakePagination(['query', ['connectionId', 'companyId']]),
    syncFetchForesiteFolders: skipTakePagination([
      'query',
      ['connectionId', 'companyId', 'projectId', 'parentFolderId']
    ]),
    syncGetWindowsFolders: skipTakePagination(['query', ['connectionId', 'parentFolderId']]),
    syncGetWorkflowMappingTemplatesList: skipTakePagination(),
    syncLogs: skipTakePagination(),
    syncRealTimeLogs: skipTakePagination(),
    syncLogFileDetails: skipTakePagination(['query', ['fileSyncLogId', 'IsDebug', 'searchPhrase']])
  }
};

export default QueryPolicies;
