import React from 'react';

import ConfirmLeavingModal from 'components/ConfirmLeavingModal/ConfirmLeavingModal';
import { useSyncWorkflowEditorContext } from 'modules/Sync/WorkflowEditor/Contexts/WorkflowEditorContext';
import ActionAliasModal from 'modules/Sync/WorkflowEditor/Modals/ActionAliasModal';
import ConnectorAliasModal from 'modules/Sync/WorkflowEditor/Modals/ConnectorAliasModal';
import CopyFilesModal from 'modules/Sync/WorkflowEditor/Modals/CopyFilesModal';
import CreateTemplateModal from 'modules/Sync/WorkflowEditor/Modals/CreateTemplateModal';
import CreateWorkflowModal from 'modules/Sync/WorkflowEditor/Modals/CreateWorkflowModal';
import DeleteCanvasModal from 'modules/Sync/WorkflowEditor/Modals/DeleteCanvasModal';
import GlobalSyncModal from 'modules/Sync/WorkflowEditor/Modals/GlobalSyncModal';
import PublishModal from 'modules/Sync/WorkflowEditor/Modals/PublishModal';
import SyncConfigModal from 'modules/Sync/WorkflowEditor/Modals/SyncConfigModal';

const ModalsWrapper = ({
  onCloseCopyFilesModal,
  onClosePublishModal,
  onSubmitCopyFilesModal,
  onSubmitPublishActionModal,
  onCloseConnectorAliasModal,
  onSubmitConnectorAliasModal,
  onCloseActionAliasModal,
  onSubmitActionAliasModal,
  onCloseCreateTemplateModal,
  onSubmitCreateTemplateModal
}) => {
  const {
    workflowElements,
    actionId,
    connectorAlias,
    actionAlias,
    isOpenActionsModal,
    isOpenPublishModal,
    isOpenAliasModal,
    isOpenActionAliasModal,
    isOpenTemplateModal,
    isOpenCreateWorkflowModal,
    mappingId,
    hideDeleteCanvasModal,
    hideCreateWorkflowModal,
    hideSyncConfigModal,
    hideGlobalSyncModal,
    isMappingDataSaved
  } = useSyncWorkflowEditorContext();

  return (
    <>
      <CopyFilesModal
        key={actionId}
        open={isOpenActionsModal}
        onClose={onCloseCopyFilesModal}
        onCustomConnect={onSubmitCopyFilesModal}
        actionId={actionId}
      />
      <PublishModal
        key={actionId}
        open={isOpenPublishModal}
        onClose={onClosePublishModal}
        onCustomConnect={onSubmitPublishActionModal}
        actionId={actionId}
      />
      <ConnectorAliasModal
        key={actionId}
        connectorAlias={connectorAlias}
        connectorId={actionId}
        elements={workflowElements}
        open={isOpenAliasModal}
        onClose={onCloseConnectorAliasModal}
        onSubmit={onSubmitConnectorAliasModal}
      />
      <ActionAliasModal
        key={actionId}
        actionAlias={actionAlias}
        actionId={actionId}
        elements={workflowElements}
        open={isOpenActionAliasModal}
        onClose={onCloseActionAliasModal}
        onSubmit={onSubmitActionAliasModal}
      />
      <CreateTemplateModal
        key={mappingId}
        templateName=""
        open={isOpenTemplateModal}
        onClose={onCloseCreateTemplateModal}
        onSubmit={onSubmitCreateTemplateModal}
      />
      <CreateWorkflowModal
        key={mappingId}
        open={isOpenCreateWorkflowModal}
        onClose={hideCreateWorkflowModal}
        onSubmit={hideCreateWorkflowModal}
      />
      <DeleteCanvasModal onClose={hideDeleteCanvasModal} onSubmit={hideDeleteCanvasModal} />
      <SyncConfigModal onClose={hideSyncConfigModal} onSubmit={hideSyncConfigModal} />
      <GlobalSyncModal onClose={hideGlobalSyncModal} onSubmit={hideGlobalSyncModal} />
      <ConfirmLeavingModal showDialog={!isMappingDataSaved} title="Changes not saved">
        The changes have not been saved. Navigating away will lose all changes. Are you sure you want to leave this
        page?
      </ConfirmLeavingModal>
    </>
  );
};

export default ModalsWrapper;
