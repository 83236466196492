import React, { useMemo, useRef, useState } from 'react';

import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { ReactFlowProvider } from 'react-flow-renderer';
import { useLocation, useNavigate } from 'react-router-dom';

import ComponentPaneLayout from 'app/Layout/ReactGridLayout/ComponentPaneLayout';
import FlowCanvas from 'components/FlowCanvas/FlowCanvas';
import { VARIANT_ERROR } from 'constants/snackbarConstants';
import {
  syncGetWorkflowMappingSyncStatus as SYNC_GET_WORKFLOW_MAPPING_SYNC_STATUS,
  syncGetWorkflowMappingConfiguration as SYNC_GET_WORKFLOW_MAPPING_CONFIGURATION
} from 'graphql/queries';
import { isEqual } from 'helpers/common';
import { ROUTES, SYNC_LOGS } from 'modules/Sync/Constants/constants';
import { IsSyncFinished } from 'modules/Sync/helpers/logSyncStatusHelpers';
import useWorkflowInitAction from 'modules/Sync/WorkflowEditor//editorHooks/useWorkflowInitAction';
import useSaveCanvasDataApiAction from 'modules/Sync/WorkflowEditor/apiActions/saveCanvasData';
import {
  SyncWorkflowEditorProvider,
  useSyncWorkflowEditorContext
} from 'modules/Sync/WorkflowEditor/Contexts/WorkflowEditorContext';
import DrawerNavMenu from 'modules/Sync/WorkflowEditor/DrawerNavMenu';
import { connectionsMapper } from 'modules/Sync/WorkflowEditor/Drawers/node-mappers';
import EditorFooter from 'modules/Sync/WorkflowEditor/EditorFooter';
import useCustomConnectAction from 'modules/Sync/WorkflowEditor/editorHooks/useCustomConnectAction';
import useDeleteNodeAction from 'modules/Sync/WorkflowEditor/editorHooks/useDeleteNodeAction';
import useDragAction from 'modules/Sync/WorkflowEditor/editorHooks/useDragAction';
import useDropAction from 'modules/Sync/WorkflowEditor/editorHooks/useDropAction';
import useEditAction from 'modules/Sync/WorkflowEditor/editorHooks/useEditAction';
import useLoadAction from 'modules/Sync/WorkflowEditor/editorHooks/useLoadAction';
import useSaveMappingAction from 'modules/Sync/WorkflowEditor/editorHooks/useSaveMappingAction';
import useSaveTemplateAction from 'modules/Sync/WorkflowEditor/editorHooks/useSaveTemplateAction';
import useSyncNowAction from 'modules/Sync/WorkflowEditor/editorHooks/useSyncNowAction';
import useUnmapAction from 'modules/Sync/WorkflowEditor/editorHooks/useUnmapAction';
import useUpdateNodeAction from 'modules/Sync/WorkflowEditor/editorHooks/useUpdateNodeAction';
import {
  edgeTypes,
  getSyncStatusUpdatedCanvasElements,
  nodeTypes
} from 'modules/Sync/WorkflowEditor/helpers/canvasHelper';
import { isTemplateCanvas } from 'modules/Sync/WorkflowEditor/helpers/mappingConfigHelper';
import ModalsWrapper from 'modules/Sync/WorkflowEditor/Modals';
import TemplateEditorToolbar from 'modules/Sync/WorkflowEditor/TemplateEditorToolbar';
import WorkflowEditorFilter from 'modules/Sync/WorkflowEditor/WorkflowEditorFilter';
import WorkflowEditorToolbar from 'modules/Sync/WorkflowEditor/WorkflowEditorToolbar';

const { SYNC, WORKFLOWS } = ROUTES;

const CANVAS_DEFAULT_PROPS = {
  elementsSelectable: true,
  showControls: true,
  nodeTypes,
  edgeTypes
};

const AUTO_HIDE_DURATION = 2000;
const MAPPING_404_MESSAGE = 'Invalid mapping';

// eslint-disable-next-line max-lines-per-function
const WorkflowCanvas = () => {
  const {
    workflowElements,
    setWorkflowElements,
    canvasData,
    actionId,
    hideActionsModal,
    hidePublishModal,
    hideAliasModal,
    hideActionAliasModal,
    showTemplateModal,
    hideTemplateModal,
    mappingId,
    showDeleteCanvasModal,
    showCreateWorkflowModal,
    showSyncConfigModal,
    showGlobalSyncModal,
    setCurrentNodeStatus,
    setIsMappingDataSaved,
    connectionsList,
    isCanvasUnlocked,
    setIsCanvasUnlocked,
    setIsSyncRunning,
    setLastSyncAt,
    workflowMappings,
    setActionNodesInSync,
    setIsInQueue
  } = useSyncWorkflowEditorContext();
  const { saveCanvasData } = useSaveCanvasDataApiAction();
  const [isMappingLoaded, setIsMappingLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const reactFlowWrapper = useRef(null);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar() || {};
  const redirectToWorkflowDashboard = () => navigate(`${SYNC}${WORKFLOWS}`);
  const mappedConnectorsList = useMemo(
    () => connectionsMapper(connectionsList?.syncConnections),
    [connectionsList?.syncConnections]
  );
  const onSyncStatusRefetch = (response) => {
    const {
      lastSyncAt = '',
      isSyncRunning = false,
      solitaryFlows = []
    } = response?.syncGetWorkflowMappingSyncStatus || {};
    const isInQueue = !!solitaryFlows.find((item) => item.syncStatus === SYNC_LOGS.LOGS_SYNC_STATUS.IN_THE_QUEUE);
    setIsSyncRunning(isSyncRunning);
    setLastSyncAt(lastSyncAt);
    setIsInQueue(isInQueue);

    const newWorkflowElements =
      workflowElements?.length && getSyncStatusUpdatedCanvasElements(workflowElements, solitaryFlows);

    const actionNodesInProgress = solitaryFlows.reduce((actionNodesInProgress, { actionNodeId, syncStatus }) => {
      if (!IsSyncFinished(syncStatus)) actionNodesInProgress.push(actionNodeId);
      return actionNodesInProgress;
    }, []);

    setActionNodesInSync(actionNodesInProgress);

    if (newWorkflowElements?.length && !isEqual(newWorkflowElements, workflowElements)) {
      setWorkflowElements(newWorkflowElements);
      saveCanvasData({
        mappingId,
        canvasData: JSON.stringify({ ...canvasData, elements: newWorkflowElements }),
        successMessage: ''
      });
    }
  };
  const {
    data: { syncGetWorkflowMappingConfiguration: workflowData = {} } = {},
    loading: loadingMappingConfigurations = false
  } = useQuery(gql(SYNC_GET_WORKFLOW_MAPPING_CONFIGURATION), {
    variables: { params: { mappingId } },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setIsMappingDataSaved(true);
      if (data?.syncGetWorkflowMappingConfiguration === null) {
        enqueueSnackbar(MAPPING_404_MESSAGE, {
          autoHideDuration: AUTO_HIDE_DURATION,
          ...VARIANT_ERROR
        });
        redirectToWorkflowDashboard();
      }
    }
  });

  const [fetchSyncStatus] = useLazyQuery(gql(SYNC_GET_WORKFLOW_MAPPING_SYNC_STATUS), {
    onCompleted: onSyncStatusRefetch
  });
  const queryParameters = new URLSearchParams(window.location.search);
  const { pathname } = useLocation();
  const isArchived = queryParameters.has('isArchived');

  const isArchivedMappingAvailable = !!workflowMappings?.some((item) => item.isArchived);
  const { deleteNodeAction } = useDeleteNodeAction();
  const { editAction } = useEditAction();
  const { connectAction } = useCustomConnectAction();
  const { unmapAction } = useUnmapAction();
  const { syncNowAction } = useSyncNowAction();
  const { loadAction } = useLoadAction();
  const { dragAction } = useDragAction();
  const { dropAction } = useDropAction({
    reactFlowWrapper,
    editAction,
    deleteNodeAction,
    connectAction,
    unmapAction,
    syncNowAction,
    mappedConnectorsList
  });
  const { updateNodeAction } = useUpdateNodeAction();
  const { saveMappingAction } = useSaveMappingAction();
  const { saveTemplateAction } = useSaveTemplateAction({ workflowData }, () =>
    saveMappingAction({ successMessage: '' })
  );

  const isTemplate = React.useMemo(() => isTemplateCanvas(pathname), [pathname]);
  const closeConnectorAliasModal = (validated = false) => {
    if (!validated) setWorkflowElements((elements) => elements.filter(({ id }) => id !== actionId));
    setCurrentNodeStatus(false);
    return hideAliasModal();
  };
  const closeActionAliasModal = (validated = false) => {
    if (!validated) setWorkflowElements((elements) => elements.filter(({ id }) => id !== actionId));
    setCurrentNodeStatus(false);
    return hideActionAliasModal();
  };

  useWorkflowInitAction({
    workflowData,
    loadingMappingConfigurations,
    editAction,
    deleteNodeAction,
    connectAction,
    unmapAction,
    syncNowAction,
    fetchSyncStatus,
    isMappingLoaded,
    setIsMappingLoaded,
    setIsLoading,
    isArchived,
    isTemplate
  });

  return (
    <div style={{ height: '100%' }} className="reactflow-wrapper" ref={reactFlowWrapper}>
      {!isArchived && (
        <ModalsWrapper
          onCloseCopyFilesModal={hideActionsModal}
          onClosePublishModal={hidePublishModal}
          onSubmitCopyFilesModal={connectAction}
          onSubmitPublishActionModal={connectAction}
          onCloseConnectorAliasModal={closeConnectorAliasModal}
          onSubmitConnectorAliasModal={updateNodeAction}
          onCloseActionAliasModal={closeActionAliasModal}
          onSubmitActionAliasModal={updateNodeAction}
          onCloseCreateTemplateModal={hideTemplateModal}
          onSubmitCreateTemplateModal={saveTemplateAction}
        />
      )}
      {isTemplate ? (
        <TemplateEditorToolbar
          workflowData={workflowData}
          onSaveAction={() => {
            saveMappingAction({ successMessage: 'Template successfully saved' });
          }}
          onDeleteAction={showDeleteCanvasModal}
          onCreateWorkflowAction={showCreateWorkflowModal}
        />
      ) : (
        <WorkflowEditorToolbar
          workflowData={workflowData}
          onSaveAction={() => {
            saveMappingAction({ successMessage: 'Workflow successfully saved' });
          }}
          onDeleteAction={showDeleteCanvasModal}
          onSaveTemplateAction={showTemplateModal}
          onSyncAction={showSyncConfigModal}
          onRunAction={showGlobalSyncModal}
          isArchived={isArchived}
        />
      )}

      {isArchivedMappingAvailable && !isArchived && <WorkflowEditorFilter />}
      <FlowCanvas
        elements={workflowElements}
        setElements={setWorkflowElements}
        onLoad={loadAction}
        loading={loadingMappingConfigurations || isLoading}
        onDragOver={dragAction}
        onDrop={dropAction}
        onCanvasChange={() => setIsMappingDataSaved(false)}
        defaultLockState={isCanvasUnlocked}
        onCanvasLockStateUpdate={setIsCanvasUnlocked}
        {...CANVAS_DEFAULT_PROPS}
        {...canvasData}
      />

      <EditorFooter />
    </div>
  );
};

const WorkflowLayout = () => {
  const { editAction } = useEditAction();
  return (
    <ComponentPaneLayout flyoutMenu={<DrawerNavMenu editMappingAction={editAction} />}>
      <WorkflowCanvas />
    </ComponentPaneLayout>
  );
};

const WorkflowEditor = () => (
  <ReactFlowProvider>
    <SyncWorkflowEditorProvider>
      <WorkflowLayout />
    </SyncWorkflowEditorProvider>
  </ReactFlowProvider>
);

export default WorkflowEditor;
