/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import {
  ACTION_CANVAS_NODE,
  FLOATING_CANVAS_EDGE,
  CONNECTOR_CANVAS_NODE,
  WORKFLOW_CANVAS_SYNC_STATUS_POLLING_INTERVAL
} from 'modules/Sync/Constants/constants';
import useSaveCanvasDataApiAction from 'modules/Sync/WorkflowEditor/apiActions/saveCanvasData';
import { useSyncWorkflowEditorContext } from 'modules/Sync/WorkflowEditor/Contexts/WorkflowEditorContext';
import { loggingHelper } from 'modules/Sync/WorkflowEditor/helpers/canvasHelper';

const formatWorkflowData = (isTemplate, workflowData) => {
  if (!isTemplate) return workflowData || {};

  const { mapping } = workflowData;
  const canvasData = JSON.parse(mapping?.canvasNode || '{}');
  const elements =
    canvasData?.elements?.map(({ type, style, ...rest }) => {
      const animated = type === FLOATING_CANVAS_EDGE ? { animated: false } : {};
      return { ...rest, ...animated, type, style: { ...style, opacity: 1 } };
    }) || [];

  const canvasNode = JSON.stringify({ ...canvasData, elements });

  const solitaryFlowConfiguration = mapping?.solitaryFlowConfiguration?.map(({ source, ...rest }) => ({
    ...rest,
    source: { ...source, settings: { ...source.settings, isSyncEnabled: false } }
  }));

  return { ...workflowData, mapping: { ...mapping, canvasNode, solitaryFlowConfiguration, isSyncRunning: false } };
};

const getValidatedCanvasNodes = ({
  elements,
  connectionsList,
  mappingId,
  canvasData,
  saveCanvasData,
  workflowData
}) => {
  const connectorNodes = elements?.filter(({ type }) => type === CONNECTOR_CANVAS_NODE);
  const actionNodes = elements?.filter(({ type }) => type === ACTION_CANVAS_NODE);
  /* Nodes that are connected via lines */
  const edgeTerminals = Array.from(
    elements?.reduce((edgeTerminals, { type, source, target }) => {
      if (type === FLOATING_CANVAS_EDGE) {
        edgeTerminals.add(source).add(target);
      }
      return edgeTerminals;
    }, new Set())
  );

  /* Connector Nodes present on the canvas that are not part of any action flow */
  const unconnectedConnectorNodes = connectorNodes?.filter(({ id }) => !edgeTerminals?.includes(id));

  const connectionIdsList = connectionsList?.syncConnections?.map(({ id }) => id);

  /* Connector Nodes present on the canvas that are not visible in the drawer i.e. deleted connectors  */
  const deletedUnconnectedNodes = unconnectedConnectorNodes?.filter(
    ({ data: { nodeId, taskId } = {} }) => !connectionIdsList?.includes(nodeId || taskId)
  );

  /* Check If there is an unconnectedConnectorNodes which is not present in the connectionsList 
  & if there is then delete it from the canvas */
  if (connectionsList?.syncConnections?.length && deletedUnconnectedNodes?.length) {
    elements = elements.filter(({ id: elementId }) => !deletedUnconnectedNodes.find(({ id }) => id === elementId));
  }

  /* Check if any of solitary flow(s) are deleted */
  const connectedActionNodes = actionNodes?.filter(({ id }) => edgeTerminals?.includes(id));

  if (connectedActionNodes?.length) {
    /* Action Nodes whose configurations have been deleted i.e. Actions that need to be cleaned from canvas */
    const ghostActionNodeIds = connectedActionNodes
      .filter(
        ({ id: nodeId }) =>
          !workflowData?.mapping?.solitaryFlowConfiguration.find(({ actionNodeId }) => actionNodeId === nodeId) &&
          !workflowData?.mapping?.solitaryFlowPublishModelConfiguration.find(
            ({ actionNodeId }) => actionNodeId === nodeId
          )
      )
      .map(({ id }) => id);

    if (!ghostActionNodeIds?.length) return elements;

    elements = ghostActionNodeIds?.length
      ? elements?.filter(({ id: nodeId }) => !ghostActionNodeIds?.some((id) => nodeId.includes(id)))
      : elements;
  }

  if (deletedUnconnectedNodes?.length || connectedActionNodes?.length) {
    saveCanvasData({
      mappingId,
      canvasData: JSON.stringify({ ...canvasData, elements }),
      successMessage: ''
    });
  }

  return elements;
};

// eslint-disable-next-line max-lines-per-function
const useWorkflowInitAction = ({
  workflowData,
  loadingMappingConfigurations,
  editAction,
  deleteNodeAction,
  connectAction,
  unmapAction,
  syncNowAction,
  fetchSyncStatus,
  isMappingLoaded,
  setIsMappingLoaded,
  setIsLoading,
  isArchived,
  isTemplate
}) => {
  const {
    setWorkflowElements,
    setCurrentMapping,
    setWorkflowMappings,
    setCanvasData,
    setIsSyncRunning,
    setLastSyncAt,
    setLastSavedAt,
    workflowMappings,
    mappingId,
    connectionsList
  } = useSyncWorkflowEditorContext();
  const { saveCanvasData } = useSaveCanvasDataApiAction();
  const { pathname } = useLocation();

  useEffect(() => {
    window.history.replaceState({}, '', pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (connectionsList?.syncConnections?.length && !loadingMappingConfigurations) {
      const workflowConfigData = formatWorkflowData(isTemplate, workflowData);

      const { mapping } = workflowConfigData || {};

      const { isSyncRunning = false } = mapping || {};
      const canvasData = JSON.parse(mapping?.canvasNode || '{}');

      const elements =
        canvasData?.elements?.map(({ id, data, type, style, ...rest }) => ({
          ...rest,
          type,
          id,
          data: {
            ...data,
            ...(type === ACTION_CANVAS_NODE ? { actionAlias: data.actionAlias || data?.actionId || id } : {}),
            onEdit: editAction,
            onDelete: deleteNodeAction,
            onCustomConnect: connectAction,
            onUnMap: unmapAction,
            onSyncNow: syncNowAction
          }
        })) || [];
      setCurrentMapping(workflowConfigData);
      const validatedElements = getValidatedCanvasNodes({
        elements,
        connectionsList,
        mappingId,
        canvasData,
        saveCanvasData,
        workflowData: workflowConfigData,
        isTemplate
      });

      const workflowCopyFileConfiguration =
        workflowConfigData?.mapping?.solitaryFlowConfiguration?.map((mappingFlow) => ({
          ...mappingFlow,
          actionAlias: validatedElements?.find(({ id }) => id === mappingFlow?.actionNodeId)?.data?.actionAlias || ''
        })) || [];
      const workflowPublishFileConfiguration =
        workflowData?.mapping?.solitaryFlowPublishModelConfiguration?.map((mappingFlow) => ({
          ...mappingFlow,
          actionAlias: validatedElements?.find(({ id }) => id === mappingFlow?.actionNodeId)?.data?.actionAlias || ''
        })) || [];

      setWorkflowElements(validatedElements);
      setWorkflowMappings([...workflowCopyFileConfiguration, ...workflowPublishFileConfiguration]);
      setCanvasData({ position: canvasData?.position, zoom: canvasData?.zoom });
      setIsSyncRunning(isSyncRunning);
      setIsLoading(false);
      loggingHelper('INVOKED LOADING-MAPPING-CONFIGURATIONS HOOK');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectionsList?.syncConnections?.length, loadingMappingConfigurations]);

  useEffect(() => {
    const workflowConfigData = formatWorkflowData(isTemplate, workflowData);
    const { mapping } = workflowConfigData;
    const { isSyncRunning = false, lastSavedAt = '', lastSyncAt = '' } = mapping || {};
    const canvasData = JSON.parse(mapping?.canvasNode || '{}');

    const workflowCopyFileConfiguration =
      mapping?.solitaryFlowConfiguration?.map((mappingFlow) => ({
        ...mappingFlow,
        actionAlias: canvasData?.elements?.find(({ id }) => id === mappingFlow?.actionNodeId)?.data?.actionAlias || ''
      })) || [];
    const workflowPublishFileConfiguration =
      workflowData?.mapping?.solitaryFlowPublishModelConfiguration?.map((mappingFlow) => ({
        ...mappingFlow,
        actionAlias: canvasData?.elements?.find(({ id }) => id === mappingFlow?.actionNodeId)?.data?.actionAlias || ''
      })) || [];

    setCurrentMapping(workflowConfigData);
    setWorkflowMappings([...workflowCopyFileConfiguration, ...workflowPublishFileConfiguration]);
    setIsSyncRunning(isSyncRunning);
    setLastSyncAt(lastSyncAt);
    setLastSavedAt(lastSavedAt);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflowData]);

  useEffect(() => {
    if (isTemplate) return true;

    return fetchSyncStatus({
      variables: { query: { mappingId } },
      pollInterval: isArchived ? 0 : WORKFLOW_CANVAS_SYNC_STATUS_POLLING_INTERVAL,
      notifyOnNetworkStatusChange: true
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mappingId, isTemplate]);

  /* COMMENTED BELOW CODE TO VERIFY MISSING ARROWS ISSUE'S ROOT CAUSE 
      THIS DISABLES THE "CACHE REFRESH ON RETURN TO CANVAS" FEATURE 
  useEffect(
    () => {
      if (workflowMappings.length && !isMappingLoaded) {
        loggingHelper('INVOKED REFRESH-CONNECTIONS HOOK');
        refreshConnections({ workflowMappings });
        setIsMappingLoaded(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [workflowMappings.length, isMappingLoaded]
  ); */
};

export default useWorkflowInitAction;
