import { Box, Link, Stack, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { Custom, IconStyled } from 'components/Icons';
import NavItemTooltip from 'components/Tooltip/NavItemTooltip';
import CustomSyncTooltip from 'modules/Sync/components/CustomSyncTooltip';
import { ROUTES, SYNC_LOGS } from 'modules/Sync/Constants/constants';
import { IsSyncFailedOrNeedsReview, IsSyncFinished } from 'modules/Sync/helpers/logSyncStatusHelpers';
import DownloadLogFile from 'modules/Sync/Logs/components/DownloadLogFile';
import { actionColors, surfaceColors, syncColors } from 'styles/theme/colors';

const { SCHEDULED, REAL_TIME_SYNC, SYNCED } = SYNC_LOGS.LOGS_SYNC_TYPE;

const syncTypeIndicator = (type) => {
  let title = '';
  let Icon = null;
  switch (type) {
    case SCHEDULED:
      Icon = () => <Custom.UpdateIcon sx={{ width: 20, height: 20 }} />;
      title = 'Scheduled';
      break;
    case REAL_TIME_SYNC:
      Icon = () => <Custom.SyncIcon sx={{ width: 20, height: 20 }} />;
      title = 'Real time';
      break;
    case SYNCED:
    default:
      Icon = () => <Custom.Complete sx={{ width: 20, height: 20 }} />;
      title = 'Synced';
      break;
  }

  return (
    <Typography variant="body2" sx={{ display: 'flex' }}>
      <IconStyled
        sx={{ marginRight: 0.5, alignItems: 'center', display: 'flex' }}
        color={actionColors.lightSurface.active}
        icon={<Icon />}
      />
      {title}
    </Typography>
  );
};

const LogsDataGridTableCell = ({ field, row, rowNode }) => {
  const { fileSyncLogId = '', workflowName = '', mappingName = '', name = '', fileName, source } = row || {};
  const downloadDisabled = !fileSyncLogId;
  const syncInProgress = !IsSyncFinished(row.syncStatus);
  const isRowExpanded = rowNode?.childrenExpanded;

  if (field === 'name')
    return (
      <Stack gap={1} direction="horizontal" sx={{ '& .MuiTypography-body2': { display: 'flex' } }} width="100%">
        {workflowName && (
          <Link
            underline="none"
            key={workflowName}
            component={RouterLink}
            to={`${ROUTES.SYNC}${ROUTES.WORKFLOWS}?id=${row?.workflowId}`}
          >
            <Typography variant="body2" className="workflow-name">
              <IconStyled
                sx={{ mr: 1.5, alignItems: 'center', display: 'flex' }}
                color={isRowExpanded ? surfaceColors.lightSurface.primary : actionColors.lightSurface.active}
                icon={<Custom.Workflow />}
              />
              <CustomSyncTooltip title={workflowName} sx={{ maxWidth: 150 }}>
                {workflowName}
              </CustomSyncTooltip>
            </Typography>
          </Link>
        )}
        {mappingName && (
          <Link
            underline="none"
            key={mappingName}
            component={RouterLink}
            to={`${ROUTES.SYNC}${ROUTES.MAPPING_EDITOR.replace(':id', row?.mappingId)}`}
          >
            <Typography variant="body2" color={syncColors.text} className="mapping-name" ml={0}>
              <IconStyled
                sx={{ ml: 1.5, mr: 1.5, alignItems: 'center', display: 'flex' }}
                color={isRowExpanded ? surfaceColors.lightSurface.primary : actionColors.lightSurface.active}
                icon={<Custom.Mapping />}
              />
              <CustomSyncTooltip title={mappingName} sx={{ maxWidth: 150 }}>
                {mappingName}
              </CustomSyncTooltip>
            </Typography>
          </Link>
        )}
        {source && (
          <Link
            underline="none"
            key={mappingName}
            component={RouterLink}
            to={`${ROUTES.SYNC}${ROUTES.LOGS_DETAILS.replace(':id', row?.fileSyncLogId.replaceAll('/', '_'))}`}
          >
            <Typography variant="body2" color={syncColors.text} className="action-name" ml={1}>
              <IconStyled sx={{ ml: 0.5, mr: 1, alignItems: 'center', display: 'flex' }} icon={<Custom.ActionAlt />} />
              <CustomSyncTooltip title={name} sx={{ maxWidth: 150 }}>
                {name}
              </CustomSyncTooltip>
            </Typography>
          </Link>
        )}
      </Stack>
    );

  if (field === 'syncType') return row[field] !== '' ? syncTypeIndicator(row[field]) : '';

  if (field === '*download')
    return (
      source && (
        <DownloadLogFile
          fileSyncLogId={fileSyncLogId}
          fileName={fileName}
          placement="left"
          disabled={downloadDisabled}
          syncInProgress={syncInProgress}
        />
      )
    );

  if (field === 'numFiles') {
    const alertCount = IsSyncFailedOrNeedsReview(row?.syncStatus);
    const text = row[field];
    const title = alertCount ? 'Sync error' : text;
    return alertCount ? (
      <Typography variant="body2" color={syncColors.logsColors.failedFileCount}>
        <NavItemTooltip title={title}>
          <Box>{text}</Box>
        </NavItemTooltip>
      </Typography>
    ) : (
      <>{text}</>
    );
  }

  return (
    (
      <CustomSyncTooltip title={row[field]} sx={{ maxWidth: 200 }}>
        {row[field]}
      </CustomSyncTooltip>
    ) || null
  );
};

export default LogsDataGridTableCell;
