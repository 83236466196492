import React, { useEffect, useState } from 'react';

import { FormHelperText } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { Caption, ControlTextField } from 'modules/Sync/Connections/ConnectionProperties/FormFields';
import useUpsertConnectionMutation from 'modules/Sync/Connections/ConnectionProperties/useUpsertConnectionMutation';
import { useSyncConnectionsContext } from 'modules/Sync/Connections/ConnectionsContext';
import { CONNECTION_TYPES } from 'modules/Sync/Constants/constants';

export const windowsFormDefaultValues = ({ configuration: selectedConnectionConfig }) => ({
  agentId: selectedConnectionConfig?.agentId || ''
});

const Windows = ({ actionRenderer }) => {
  const { currentConnection, setHeaderActionsDisabled } = useSyncConnectionsContext();
  const [validationError, setValidationError] = useState('');
  const { upsertConnection, loading } = useUpsertConnectionMutation();

  const {
    control,
    formState: { isValid }
  } = useFormContext();

  const setError = (errors) => {
    if (typeof errors === 'string') {
      setValidationError(errors);
    } else {
      const { errorInfo } = errors?.[0] || {};
      setValidationError(Object.values(errorInfo)?.[0]);
    }
  };

  useEffect(() => {
    setValidationError('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentConnection]);

  const updateConnection = async (formData) => {
    setValidationError('');
    delete formData.dataSource;
    setHeaderActionsDisabled(false);
    upsertConnection({
      formData,
      connectionType: CONNECTION_TYPES.WINDOWS,
      onError: setError
    });
  };

  const submitDisabled = !isValid;
  return (
    <>
      <Caption>Credentials</Caption>
      <ControlTextField
        key={currentConnection?.connectionId}
        control={control}
        requiredNoSpace
        name="configuration.agentId"
        label="Agent ID*"
      />
      <FormHelperText error>{validationError}</FormHelperText>
      {actionRenderer({ onSubmit: updateConnection, submitDisabled, loading })}
    </>
  );
};

export default Windows;
