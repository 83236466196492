import { React, useMemo } from 'react';

import { grey } from '@mui/material/colors';
import { useStoreState, getSmoothStepPath } from 'react-flow-renderer';
import { useLocation } from 'react-router-dom';

import { useSyncWorkflowEditorContext } from 'modules/Sync/WorkflowEditor/Contexts/WorkflowEditorContext';
import { getSyncEdgeColor, isTemplateCanvas } from 'modules/Sync/WorkflowEditor/helpers/mappingConfigHelper';

import { getEdgeParams } from '../../../../components/FlowCanvas/utils';

//  refer to this link for more context - https://v9.reactflow.dev/examples/floating-edges
const FloatingEdge = ({ id, source, target, style = {} }) => {
  const { workflowMappings, isSetOpacity } = useSyncWorkflowEditorContext();
  const templatePathname = useLocation();

  const { isSyncEnabled, syncType } =
    workflowMappings?.find(({ actionNodeId }) => actionNodeId === source || actionNodeId === target)?.source
      ?.settings || {};
  const { action } =
    workflowMappings?.find(({ actionNodeId }) => actionNodeId === source || actionNodeId === target) || {};
  const { color: syncColor } = getSyncEdgeColor(syncType, isSyncEnabled, action) || {};

  const nodes = useStoreState((state) => state.nodes);
  const sourceNode = useMemo(() => nodes.find((n) => n.id === source), [source, nodes]);
  const targetNode = useMemo(() => nodes.find((n) => n.id === target), [target, nodes]);
  const queryParameters = new URLSearchParams(window.location.search);
  const pathname = queryParameters.get('isArchived');
  const isArchivedTab = pathname !== null;
  const isArchivedNode = workflowMappings.filter((node) => node.isArchived);
  const isTemplate = useMemo(() => isTemplateCanvas(templatePathname.pathname), [templatePathname]);

  const newId = id.split('->');
  const edgeToArchiveAction = isArchivedNode.filter((node) => newId.includes(node.actionNodeId));
  if (!sourceNode || !targetNode) {
    return null;
  }

  const { sx, sy, tx, ty, sourcePos, targetPos } = getEdgeParams(sourceNode, targetNode);

  const d = getSmoothStepPath({
    sourceX: sx,
    sourceY: sy,
    sourcePosition: sourcePos,
    targetPosition: targetPos,
    targetX: tx,
    targetY: ty
  });

  const getStroke = () => {
    if (isTemplate) return grey[600];

    const isInActive = !isSetOpacity && !isArchivedTab && edgeToArchiveAction.length !== 0;
    return isInActive ? grey[200] : syncColor || grey[600];
  };

  const edgeStroke = getStroke();

  return (
    <>
      <defs>
        <marker
          id={`react-flow-custom__arrowclosed-${id}`}
          markerWidth="12.5"
          markerHeight="12.5"
          viewBox="-10 -10 20 20"
          orient="auto"
          refX="0"
          refY="0"
        >
          <polyline
            stroke={edgeStroke}
            strokeLinecap="square"
            strokeLinejoin="miter"
            strokeWidth="1"
            fill={edgeStroke}
            points="-7,-4 0,0 -7,4 -7,-4"
          />
        </marker>
      </defs>
      <path
        id={id}
        className="react-flow__edge-path"
        d={d}
        markerEnd={`url(#react-flow-custom__arrowclosed-${id})`}
        style={{
          stroke: edgeStroke,
          strokeWidth: 2,
          ...style
        }}
      />
    </>
  );
};

export default FloatingEdge;
