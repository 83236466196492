import { gql } from '@apollo/client';

import client from 'apollo/client';
import { FETCH_POLICIES } from 'constants/globalConstants';
import {
  syncLogActionFolderDetails as SYNC_LOG_ACTION_FOLDER_DETAILS,
  syncLogActionFileDetails as SYNC_LOG_ACTION_FILE_DETAILS
} from 'graphql/queries';
import { DATAGRID_DEFAULT_PG_SIZE, LOG_POLLING_INTERVAL, SYNC_LOGS } from 'modules/Sync/Constants/constants';
import { IsSyncFinished } from 'modules/Sync/helpers/logSyncStatusHelpers';
import { generateRandomIdForLoadMoreRow } from 'modules/Sync/Logs/components/LoadMoreBtn';
import LogFoldersDetailDataGridTableRows, {
  formatFilesToRows,
  formatFoldersToRows
} from 'modules/Sync/Logs/LogsDetails/LogFoldersDetailsDataGridTable/LogFoldersDetailDataGridTableRows';

const { LOG_FOLDER_TERMINAL_STATUS, LOG_ACTION_TERMINAL_STATUS } = SYNC_LOGS;
const { fetchPolicy: FETCH_POLICY } = FETCH_POLICIES.NETWORK_ONLY;

export const updateRows = (apiRef, rows) => {
  if (!apiRef.current) return;
  apiRef.current.updateRows(rows);
};

const getExpandedParentRowForFolderAndDest = async ({ queryParams, parentRow, rowId }) => {
  const { data: parentItemLogData } = await client.query({
    query: gql(SYNC_LOG_ACTION_FOLDER_DETAILS),
    variables: { query: queryParams },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: FETCH_POLICY
  });

  const fetchedParentRows = formatFoldersToRows(
    parentItemLogData?.syncLogActionFolderDetails,
    parentRow?.path,
    parentRow?.level
  );

  const expandedParentRow = fetchedParentRows.find((row) => row?.id === rowId);
  const parentRowInTerminalState = !!(
    expandedParentRow && expandedParentRow?.terminalStatus !== LOG_FOLDER_TERMINAL_STATUS.IN_PROGRESS
  );
  return { expandedParentRow, parentRowInTerminalState };
};

const getExpandedParentRowForAction = async ({ loadLogActionDetails, rowId }) => {
  const { data: parentItemLogData } = await loadLogActionDetails();

  const fetchedParentRows = LogFoldersDetailDataGridTableRows(
    parentItemLogData?.syncLogActionDetails?.data?.destinations
  );

  const expandedParentRow = fetchedParentRows.find((row) => row?.id === rowId);
  const parentRowInTerminalState = !!(
    expandedParentRow && expandedParentRow?.terminalStatus !== LOG_ACTION_TERMINAL_STATUS.IN_PROGRESS
  );
  return { parentRowInTerminalState, expandedParentRow };
};

const getAutoRefreshPollingHelper =
  ({
    fileSyncLogId,
    parentRow,
    setManuallyLoadingLogs,
    rowId,
    loadLogActionDetails,
    itemToPollIsFolder,
    setPollingItems,
    stopLogActionFolderDetailsPolling,
    getSiblingRows,
    parentPath,
    level,
    apiRef,
    row,
    descendantCount
  }) =>
  async (response) => {
    const logFolders = response?.syncLogActionFolderDetails;

    const queryParams = { fileSyncLogId };
    const syncCompletedFolderIds = logFolders
      ?.filter(({ terminalStatus }) => terminalStatus !== LOG_FOLDER_TERMINAL_STATUS.IN_PROGRESS)
      ?.map(({ syncFolderMetricId }) => syncFolderMetricId);

    setManuallyLoadingLogs(true);

    if (parentRow?.syncFolderMetricId) queryParams.syncFolderMetricId = parentRow?.syncFolderMetricId;
    if (parentRow?.syncFlowMetricId) queryParams.syncFlowMetricId = parentRow?.syncFlowMetricId;

    const { parentRowInTerminalState, expandedParentRow } = await getExpandedParentRowAndSyncStatus({
      parentRow,
      queryParams,
      rowId,
      loadLogActionDetails
    });

    const allChildrenInTerminalState = syncCompletedFolderIds?.length === logFolders?.length;

    /* 
      For folder : Remove the current folder from pollingItems if all its subfolders & itself are in terminal states 
      For destination: Remove the current folder from pollingItems if all its subfolders are in terminal states 
    */
    const shouldStopPolling = itemToPollIsFolder
      ? parentRowInTerminalState && allChildrenInTerminalState
      : allChildrenInTerminalState;

    if (shouldStopPolling) {
      setPollingItems((pollingItems) => pollingItems.filter(({ id }) => id !== rowId));
      stopLogActionFolderDetailsPolling();
    }

    const folders = formatFoldersToRows(response?.syncLogActionFolderDetails, parentPath, level);
    const siblingFileRows = getSiblingRows();
    const loadMoreRow = siblingFileRows.find(({ isLoadMore }) => isLoadMore);
    const loadMoreRowId = generateRandomIdForLoadMoreRow();
    const files = formatFilesToRows(siblingFileRows, parentPath, level);
    const newLoadMoreBtnRow = loadMoreRow
      ? [{ ...loadMoreRow, id: loadMoreRowId, path: [...loadMoreRow?.path?.slice(0, -1), loadMoreRowId] }]
      : [];
    setManuallyLoadingLogs(false);

    return updateRows(apiRef, [
      { ...row, ...expandedParentRow, childrenFetched: true, descendantCount: descendantCount + folders?.length || 0 },
      ...folders,
      ...siblingFileRows.map((file) => ({ ...file, _action: 'delete' })) /* Remove old "File" Rows for same folder */,
      ...files /* Add new files after folder */,
      ...newLoadMoreBtnRow
    ]);
  };

export const refreshStatus = async ({
  autoRefreshFolderDetails,
  syncStatus,
  pollingItems,
  loadLogActionDetails,
  loadLogActionFolderDetails,
  fileSyncLogId,
  setPollingItems,
  stopLogActionFolderDetailsPolling,
  startLogActionDetailsPolling,
  stopLogActionDetailsPolling,
  apiRef,
  setManuallyLoadingLogs
}) => {
  const shouldUpdateStatus = autoRefreshFolderDetails && !IsSyncFinished(syncStatus);
  const row = pollingItems?.slice(-1)?.[0] || {};
  const parentRow = pollingItems.slice(-2)?.[0];
  const { id: rowId, syncFolderMetricId, path: parentPath, level, descendantCount = 0 } = row;
  const itemToPollIsFolder = !!syncFolderMetricId;
  const getSiblingRows = () => {
    const allRows = apiRef.current.getRowModels();
    const siblingFileRows = [...allRows].reduce((rows, [, value]) => {
      const { parentId, isFile, parentFolderId, isLoadMore } = value;
      if ((parentId === row.id && isFile) || (parentFolderId === row.id && isLoadMore)) rows.push(value);
      return rows;
    }, []);
    return siblingFileRows;
  };

  const loadLogActionFolderDetailsCompletedCb = getAutoRefreshPollingHelper({
    fileSyncLogId,
    parentRow,
    setManuallyLoadingLogs,
    rowId,
    loadLogActionDetails,
    itemToPollIsFolder,
    setPollingItems,
    stopLogActionFolderDetailsPolling,
    getSiblingRows,
    parentPath,
    level,
    apiRef,
    row,
    descendantCount
  });

  const pollFolder = async () => {
    const { data: actionFolderDetailsData } = await loadLogActionFolderDetails({
      variables: { query: { fileSyncLogId, syncFolderMetricId } },
      pollInterval: LOG_POLLING_INTERVAL,
      notifyOnNetworkStatusChange: true,
      onCompleted: loadLogActionFolderDetailsCompletedCb
    });
    const folders = formatFoldersToRows(actionFolderDetailsData?.syncLogActionFolderDetails, parentPath, level);
    const siblingFileRows = getSiblingRows();
    const loadMoreRow = siblingFileRows.find(({ isLoadMore }) => isLoadMore);
    const loadMoreRowId = generateRandomIdForLoadMoreRow();
    const files = formatFilesToRows(siblingFileRows, parentPath, level);
    const newLoadMoreBtnRow = loadMoreRow
      ? [{ ...loadMoreRow, id: loadMoreRowId, path: [...loadMoreRow?.path?.slice(0, -1), loadMoreRowId] }]
      : [];

    return updateRows(apiRef, [
      { ...row, childrenFetched: true, descendantCount: descendantCount + folders?.length || 0 },
      ...folders,
      ...siblingFileRows.map((file) => ({ ...file, _action: 'delete' })) /* Remove old "File" Rows for same folder */,
      ...files /* Add new files after folder */,
      ...newLoadMoreBtnRow
    ]);
  };

  const pollDestination = async () => {
    const { data: actionFolderDetailsData } = await loadLogActionFolderDetails({
      variables: { query: { fileSyncLogId, syncFlowMetricId: row.syncFlowMetricId } },
      pollInterval: LOG_POLLING_INTERVAL,
      notifyOnNetworkStatusChange: true,
      onCompleted: loadLogActionFolderDetailsCompletedCb
    });
    const folders = formatFoldersToRows(actionFolderDetailsData?.syncLogActionFolderDetails, parentPath, level);

    return updateRows(apiRef, [
      { ...row, childrenFetched: true, descendantCount: descendantCount + folders?.length || 0 },
      ...folders /* Destination has no files */
    ]);
  };

  /* Everytime this hook is invoked, first stop all existing polling APIs */
  stopLogActionDetailsPolling();
  stopLogActionFolderDetailsPolling();

  const shouldPollFolder = shouldUpdateStatus && itemToPollIsFolder;
  const shouldPollDestination = shouldUpdateStatus && !itemToPollIsFolder && pollingItems?.length;
  const shouldPollAction = shouldUpdateStatus;

  setManuallyLoadingLogs(true);
  if (shouldPollFolder) return pollFolder();
  if (shouldPollDestination) return pollDestination();
  setManuallyLoadingLogs(false);
  if (shouldPollAction) return startLogActionDetailsPolling(LOG_POLLING_INTERVAL);
  return stopLogActionDetailsPolling();
};

const loadMoreRowsAndUpdateCb =
  ({ apiRef, node, queryParams, parentPath, level }) =>
  async () => {
    const row = apiRef.current.getRow(node.id); /* Fetch row with latest values */
    const allRows = [...apiRef.current.getRowModels()].map(([, value]) => value);
    const rowData = allRows.find(({ isLoadMore, parentFolderId }) => isLoadMore && parentFolderId === node.id);
    const siblingFileRows = allRows.filter(
      ({ id, parentId, isFile }) => parentId === node.id && id !== row.id && isFile
    );
    const { data: actionFileDetailsData } = await client.query({
      query: gql(SYNC_LOG_ACTION_FILE_DETAILS),
      variables: {
        query: {
          ...queryParams,
          skip: siblingFileRows.length || DATAGRID_DEFAULT_PG_SIZE,
          take: DATAGRID_DEFAULT_PG_SIZE
        }
      },
      fetchPolicy: FETCH_POLICY
    });

    const moreFilesRows = formatFilesToRows(actionFileDetailsData?.syncLogActionFileDetails, parentPath, level);
    const loadEvenMore =
      moreFilesRows?.length >= DATAGRID_DEFAULT_PG_SIZE ||
      row?.terminalStatus === LOG_FOLDER_TERMINAL_STATUS.IN_PROGRESS;

    /* Remove old "Load More" Row */
    updateRows(apiRef, [{ ...rowData, isLoadMore: false, _action: 'delete' }]);

    /* Add new if needed */
    updateRows(apiRef, [
      ...moreFilesRows,
      { ...row, childrenFetched: true },
      ...(loadEvenMore ? [{ ...rowData, isLoadMore: loadEvenMore }] : [])
    ]);
  };

const getExpandedParentRowAndSyncStatus = async ({ parentRow, queryParams, rowId, loadLogActionDetails }) => {
  let isParentRowSynced = false;
  let expandedRow = {};
  const parentToFetchIsFolder = !!parentRow?.syncFolderMetricId;
  const parentToFetchIsDestination = !!parentRow?.syncFlowMetricId;
  const parentToFetchIsAction = !parentToFetchIsDestination && !parentToFetchIsFolder;
  if (parentToFetchIsFolder || parentToFetchIsDestination) {
    const { expandedParentRow, parentRowInTerminalState } = await getExpandedParentRowForFolderAndDest({
      queryParams,
      parentRow,
      rowId
    });
    isParentRowSynced = parentRowInTerminalState;
    expandedRow = expandedParentRow;
  }

  if (parentToFetchIsAction) {
    const { expandedParentRow, parentRowInTerminalState } = await getExpandedParentRowForAction({
      loadLogActionDetails,
      rowId
    });
    isParentRowSynced = parentRowInTerminalState;
    expandedRow = expandedParentRow;
  }
  return { parentRowInTerminalState: isParentRowSynced, expandedParentRow: expandedRow };
};

export const handleRowExpansionChange =
  ({ apiRef, fileSyncLogId }) =>
  async (node) => {
    const row = apiRef.current.getRow(node.id);
    const { syncFlowMetricId, syncFolderMetricId, path: parentPath, level } = row;
    const queryParams = { fileSyncLogId };
    const childrenRows = [];
    const onLoadMoreClick = loadMoreRowsAndUpdateCb({ apiRef, node, queryParams, parentPath, level });

    if (!node.childrenExpanded || !row || row.childrenFetched) return;

    if (syncFlowMetricId) {
      queryParams.syncFlowMetricId = syncFlowMetricId;
      const { data: actionFolderDetailsData } = await client.query({
        query: gql(SYNC_LOG_ACTION_FOLDER_DETAILS),
        variables: { query: queryParams },
        fetchPolicy: FETCH_POLICY
      });
      const folders = actionFolderDetailsData?.syncLogActionFolderDetails || [];
      childrenRows.push(...formatFoldersToRows(folders, parentPath, level));
    }

    if (syncFolderMetricId) {
      queryParams.syncFolderMetricId = syncFolderMetricId;
      const { data: actionFolderDetailsData } = await client.query({
        query: gql(SYNC_LOG_ACTION_FOLDER_DETAILS),
        variables: { query: queryParams },
        fetchPolicy: FETCH_POLICY
      });
      /* if its a folder row, fetch files too */
      const { data: actionFileDetailsData } = await client.query({
        query: gql(SYNC_LOG_ACTION_FILE_DETAILS),
        variables: { query: { ...queryParams, skip: 0, take: DATAGRID_DEFAULT_PG_SIZE } },
        fetchPolicy: FETCH_POLICY
      });
      const folderRows = formatFoldersToRows(actionFolderDetailsData?.syncLogActionFolderDetails, parentPath, level);
      const fileRows = formatFilesToRows(actionFileDetailsData?.syncLogActionFileDetails, parentPath, level);

      const id = `placeholder-children-${node.id}`;
      const loadMoreRow =
        fileRows?.length >= DATAGRID_DEFAULT_PG_SIZE || row?.terminalStatus === LOG_FOLDER_TERMINAL_STATUS.IN_PROGRESS
          ? [
              {
                id,
                path: [...row.path, id],
                parentFolderId: node?.id,
                rowClass: 'loader',
                isLoadMore: true,
                onClick: onLoadMoreClick
              }
            ]
          : [];

      childrenRows.push(...folderRows, ...fileRows, ...loadMoreRow);
    }
    updateRows(apiRef, [...childrenRows, { ...row, childrenFetched: true, descendantCount: childrenRows.length }]);
  };
