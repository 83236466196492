import { useEffect, useMemo, useRef, useState } from 'react';

import { gql } from '@apollo/client';
import { IconButton } from '@mui/material';
import fileDownload from 'js-file-download';

import client from 'apollo/client';
import ConfirmLeavingModal from 'components/ConfirmLeavingModal/ConfirmLeavingModal';
import { Custom, IconStyled } from 'components/Icons';
import CircularProgressWithLabel from 'components/ProgressBars/CircularProgressWithLabel';
import CustomTooltip from 'components/Tooltip';
import { FETCH_POLICIES } from 'constants/globalConstants';
import { syncLogFileDetailsWithCount as SYNC_LOG_FILE_DETAILS_WITH_COUNT } from 'graphql/queries';
import { getTimeZone } from 'helpers/dateFunctions';
import { LOG_FILE_DOWNLOAD_BATCH_SIZE, WAIT_TIME_FOR_DOWNLOAD_COMPLETED } from 'modules/Sync/Constants/constants';
import { actionColors, greyColors } from 'styles/theme/colors';

export const getLogFileName = ({ workflowName, mappingName, fileSyncLogId, lastSyncFileDate = '' }) =>
  `${workflowName}_${mappingName}_${fileSyncLogId}_${lastSyncFileDate}`
    .replaceAll(' ', '_')
    .replace(/(?=.*)_?$/, '') /* Trim underscore '_' from the name */
    .concat('.txt');

const DownloadLogFile = ({
  fileSyncLogId,
  fileName,
  sx = {},
  IsDebug = true,
  placement = 'top',
  disabled = false,
  syncInProgress = false,
  waitTimeForCompleted = WAIT_TIME_FOR_DOWNLOAD_COMPLETED
}) => {
  let logsFetched = [];
  const [progress, setProgress] = useState(0);
  const isComponentMounted = useRef(true);
  const timeZone = useMemo(() => getTimeZone(), []);
  const downloadDisabled = progress !== 0 || disabled;

  useEffect(
    () => () => {
      isComponentMounted.current = false;
    },
    []
  );

  const saveLogFileToPC = (logs) => {
    setTimeout(() => {
      setProgress(0);
    }, waitTimeForCompleted);
    const data = logs.join('\n');
    fileDownload(data, fileName);
  };

  const downloadFile = async (event) => {
    setProgress(1);
    event.stopPropagation();
    await getLogsForDownload(logsFetched.length);
  };

  const getLogsForDownload = async (skip) => {
    if (!isComponentMounted.current) return;
    const response = await client.query({
      query: gql(SYNC_LOG_FILE_DETAILS_WITH_COUNT),
      variables: {
        query: {
          fileSyncLogId,
          IsDebug,
          timeZone,
          skip,
          take: LOG_FILE_DOWNLOAD_BATCH_SIZE
        }
      },
      fetchPolicy: syncInProgress ? FETCH_POLICIES.NO_CACHE.fetchPolicy : FETCH_POLICIES.CACHE_FIRST.fetchPolicy,
      notifyOnNetworkStatusChange: true
    });
    const { entireCount, data } = response.data.syncLogFileDetailsWithCount;
    logsFetched = [...logsFetched, ...data];
    const fetchedLogsLength = logsFetched?.length;
    if (entireCount / fetchedLogsLength > 1) {
      setProgress((100 * fetchedLogsLength) / entireCount);
      await getLogsForDownload(fetchedLogsLength);
    } else {
      setProgress(100);
      saveLogFileToPC(logsFetched);
    }
  };

  return (
    <>
      <CustomTooltip title="Download File" arrow placement={placement}>
        <IconButton onClick={downloadFile} sx={{ width: 35, height: 35, ...sx }} disabled={downloadDisabled}>
          <IconStyled
            sx={{ mx: 1.5, alignItems: 'center', display: 'flex' }}
            className={!progress && 'download-log-file'}
            icon={
              progress ? (
                <CircularProgressWithLabel value={progress} />
              ) : (
                <Custom.Download2 fill={downloadDisabled ? greyColors.grey500 : actionColors.lightSurface.active} />
              )
            }
          />
        </IconButton>
      </CustomTooltip>
      <ConfirmLeavingModal showDialog={progress} title="Download in Progress">
        Log file download in progress. Are you sure you want to cancel download and proceed?
      </ConfirmLeavingModal>
    </>
  );
};

export default DownloadLogFile;
