import { actionColors } from 'styles/theme/colors';

const logsDataGridStyles = {
  marginBottom: 1,
  '.MuiDataGrid-row': {
    '&:hover': {
      cursor: 'pointer'
    },
    '.expansion-icon': { display: 'flex', alignItems: 'center' },
    '.workflow-name, .mapping-name, .action-name': {
      display: 'flex',
      '&:hover': {
        textDecoration: 'underline'
      }
    }
  },

  '.MuiDataGrid-row .download-log-file': { opacity: 0 },
  '.MuiDataGrid-row.Mui-hovered .download-log-file': { opacity: 1 },
  '& .MuiDataGrid-pinnedRows .MuiDataGrid-row': {
    background: actionColors.lightSurface.selected
  },
  '.MuiDataGrid-pinnedColumnHeaders, .MuiDataGrid-pinnedColumns': {
    background: 'transparent',
    boxShadow: 'none'
  },
  '.selected-connector': {
    background: actionColors.lightSurface.selected
  },
  '.MuiDataGrid-pinnedRows': {
    zIndex: 0
  }
};

export default logsDataGridStyles;
