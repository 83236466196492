import React from 'react';

import { Stepper, Step, StepLabel, Stack, StepButton, Box, Typography } from '@mui/material';

import { CONNECTION_NAME } from 'modules/Sync/Constants/constants';
import { useCopyFilesFormContext } from 'modules/Sync/WorkflowEditor/Contexts/CopyFilesContext';
import SyncFilesForm from 'modules/Sync/WorkflowEditor/Forms/Shared/SyncFilesForm/index';
import MappingForm from 'modules/Sync/WorkflowEditor/Modals/CopyFilesModal/MappingForm';
import { greyColors, surfaceColors, syncColors } from 'styles/theme/colors';

import { getSubmittedStatus } from '../../helpers/validationsHelper';

const STEPS = ['Source', 'Destination', 'Sync'];

const stepProps = {
  '& .MuiStepIcon-root': {
    '&.Mui-active': { color: 'warning.main' },
    '&.Mui-completed': { color: 'warning.main' }
  }
};

const stepperSx = {
  width: 402,
  '.MuiStep-root.active-step .MuiStepLabel-iconContainer *': { color: syncColors.main },
  '.MuiStep-root.inactive-step .MuiStepLabel-iconContainer *': {
    color: syncColors.mainDarkBackground
  },
  '.MuiStepLabel-iconContainer.Mui-disabled *': {
    color: surfaceColors.lightSurface.disabled
  }
};

const SourceForm = (props) => <MappingForm {...props} showFilters isSource />;
const DestinationForm = (props) => <MappingForm {...props} />;

const LabelText = ({ isActive, isCompleted, text }) => (
  <Typography
    color={isActive || isCompleted ? surfaceColors.lightSurface.primary : surfaceColors.lightSurface.secondary}
    variant={isActive || isCompleted ? 'subtitle2' : 'body2'}
  >
    {text}
  </Typography>
);

// eslint-disable-next-line max-lines-per-function
const CopyFilesForm = ({
  actionId,
  filters,
  sourceSubmitDisabled,
  destinationSubmitDisabled,
  finalSubmitDisabled,
  onNext
}) => {
  const { activeStep, setActiveStep, syncSettings, setSyncSettings, source, destinations, activeFlow, action } =
    useCopyFilesFormContext();

  const isNewFlow = !activeFlow?.solitaryFlowId;
  const isStepDisabled = (step = activeStep) => {
    if (step === 0) {
      return isNewFlow || sourceSubmitDisabled;
    }
    if (step === 1) {
      return isNewFlow || destinationSubmitDisabled;
    }
    return isNewFlow || finalSubmitDisabled;
  };

  const disabled = isStepDisabled();
  const allowFreeStepNavigation = !isNewFlow && !disabled;

  const onClickHandler = (step) => () => {
    if (step === 0) {
      return setActiveStep(step);
    }

    if (step === 1) {
      if (activeStep === 0) {
        return sourceSubmitDisabled ? null : onNext();
      }
      return setActiveStep(step);
    }

    if (step === 2) {
      return sourceSubmitDisabled && destinationSubmitDisabled ? null : setActiveStep(step);
    }

    return null;
  };

  const onChangeHandler = ({ scheduleSyncConfig, ...props }) => {
    setSyncSettings((syncSettings) => ({
      ...syncSettings,
      scheduleSyncConfig: { ...syncSettings.scheduleSyncConfig, ...scheduleSyncConfig },
      ...props
    }));
  };

  const isCompleted = (step, index) => {
    if (isNewFlow) return activeStep > index;

    const isSourceSubmitted = getSubmittedStatus(source);

    /* Source */
    if (step === STEPS[0]) return isSourceSubmitted;

    /* Destination */
    if (step === STEPS[1])
      return isSourceSubmitted && destinations.every((destination) => getSubmittedStatus(destination));

    return true; /* Sync step will always be considerd completed in edit mode */
  };

  return (
    <Stack spacing={2} sx={{ justifyContent: 'space-between' }}>
      <Stack direction="row" sx={{ justifyContent: 'space-between', marginBottom: activeStep ? 0 : 2 }}>
        <Stepper sx={stepperSx} activeStep={activeStep} nonLinear={allowFreeStepNavigation}>
          {STEPS.map((step, index) => (
            <Step
              key={step}
              active={!isStepDisabled(index)}
              sx={{ ...stepProps, pl: index === 0 ? 0 : 1 }}
              completed={isCompleted(step, index)}
              className={index === activeStep ? 'active-step' : !isNewFlow && 'inactive-step'}
            >
              <StepButton disableRipple onClick={onClickHandler(index)} sx={{ zIndex: 9, pt: 0, pb: 0 }}>
                <StepLabel>
                  <LabelText isActive={activeStep === index} isCompleted={isCompleted(step, index)} text={step} />
                </StepLabel>
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </Stack>
      {activeStep !== 0 && (
        <Box width="20%" align="center" mt={0} pt={0} sx={{ letterSpacing: '0.15px' }}>
          <Typography fontWeight={700} component="span" noWrap color={greyColors.grey550}>
            Source:{' '}
          </Typography>
          <Typography fontWeight={700} component="span" noWrap>
            {CONNECTION_NAME[source?.connectionType]}
          </Typography>
        </Box>
      )}
      {activeStep === 0 && <SourceForm key={activeStep} nodeId={actionId} filters={filters} />}
      {activeStep === 1 && <DestinationForm key={activeStep} nodeId={actionId} filters={filters} />}
      {activeStep === 2 && (
        <SyncFilesForm
          key={activeStep}
          onSyncSettingsChange={onChangeHandler}
          selectedSource={source}
          syncSettings={syncSettings}
          selectedAction={action}
        />
      )}
    </Stack>
  );
};
export default CopyFilesForm;
