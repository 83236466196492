const LogFoldersDetailsDataGridTableColumns = (handleCellComponent) => [
  {
    field: 'name',
    headerName: 'Folder / File',
    flex: 3,
    sortable: false,
    editable: false,
    hideable: false,
    colSpan: ({ row }) => (row?.isLoadMore ? 5 : 1),
    renderCell: handleCellComponent
  },
  {
    field: 'numFiles',
    headerName: 'Files Processed',
    flex: 1,
    sortable: false,
    renderCell: handleCellComponent
  },
  {
    field: 'totalSize',
    headerName: 'File Size',
    flex: 1,
    minWidth: 50,
    sortable: false,
    editable: false,
    hideable: false,
    renderCell: handleCellComponent
  },
  {
    field: 'terminalStatus',
    headerName: 'Sync Status',
    flex: 1,
    sortable: false,
    renderCell: handleCellComponent
  },
  {
    field: 'fileSyncDate',
    headerName: 'File Sync Date',
    flex: 1,
    sortable: false,
    renderCell: handleCellComponent
  }
];

export default LogFoldersDetailsDataGridTableColumns;
