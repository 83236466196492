import { useEffect, useState } from 'react';

import { useLocation, useParams } from 'react-router-dom';

import ComponentPaneLayout from 'app/Layout/ReactGridLayout/ComponentPaneLayout';
import { ItemsDataGridProProvider } from 'components/ItemsDatagridPro';
import useEvictApolloCache from 'modules/Sync/hooks/useEvictApolloCache';
import { LOGS_APIS, useSyncLogsContext } from 'modules/Sync/Logs/Contexts/LogsContext';
import useLogsLazyPaginationHook from 'modules/Sync/Logs/Contexts/useLogsLazyPaginationHook';
import LogsDataGridTable from 'modules/Sync/Logs/LogsDataGridTable';
import LogsDataUsageInfo from 'modules/Sync/Logs/LogsDataUsageInfo';
import LogsHeader from 'modules/Sync/Logs/LogsHeader/LogsHeader';
import RealTimeLogsDataGridTable from 'modules/Sync/Logs/RealTimeLogsDataGridTable';

const Logs = () => {
  const { activeLogTab, setActiveLogTab, setCurrentLogFile } = useSyncLogsContext();
  const { logsData } = useLogsLazyPaginationHook();
  const [activeRows, setActiveRows] = useState([]);
  const { search, pathname } = useLocation();
  const { id: logId } = useParams();

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const activeRowId = searchParams.get('id');
    const currentTab = Number(searchParams.get('activeTab'));

    if (activeRowId) setActiveRows([activeRowId]);
    if (currentTab) setActiveLogTab(currentTab);

    searchParams.delete('activeTab');
    window.history.replaceState({}, '', pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    if (logId && logsData?.syncLogs?.length) {
      setCurrentLogFile(
        logsData?.syncLogs
          ?.flatMap(({ mappings, ...workflowProps }) =>
            mappings?.flatMap(({ solitaryFlows, publishModelSolitaryFlows, ...mappingProps }) => [
              ...solitaryFlows?.map((props) => ({ ...props, ...mappingProps, ...workflowProps })),
              ...publishModelSolitaryFlows?.map((props) => ({ ...props, ...mappingProps, ...workflowProps }))
            ])
          )
          ?.find(({ fileSyncLogId }) => fileSyncLogId === logId)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logsData]);

  useEvictApolloCache(LOGS_APIS, []);

  return (
    <ComponentPaneLayout>
      <ItemsDataGridProProvider>
        <LogsHeader activeTab={activeLogTab} setActiveTab={setActiveLogTab} />
        {activeLogTab === 0 && <LogsDataGridTable activeRows={activeRows} />}
        {activeLogTab === 1 && <RealTimeLogsDataGridTable />}
        <LogsDataUsageInfo />
      </ItemsDataGridProProvider>
    </ComponentPaneLayout>
  );
};
export default Logs;
