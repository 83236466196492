import React from 'react';

import { Typography, IconButton, Box, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { MoreMenu } from 'components';
import { Custom, IconStyled } from 'components/Icons';
import NavItemTooltip from 'components/Tooltip/NavItemTooltip';
import CustomSyncTooltip from 'modules/Sync/components/CustomSyncTooltip';
import SyncStatusHig from 'modules/Sync/components/SyncStatusHig';
import { WORKFLOW_MODAL_KEYS, ROUTES, ACTION_STATUS } from 'modules/Sync/Constants/constants';
import { useSyncWorkflowsContext } from 'modules/Sync/Workflows/Contexts/WorkflowsContext';
import { actionColors, surfaceColors, syncColors } from 'styles/theme/colors';

const { CREATE_MAPPING } = WORKFLOW_MODAL_KEYS;
const preStyleArray = ['lastSavedAt', 'lastSyncAt'];
const IconStyledSx = { marginRight: 1.5, alignItems: 'center', display: 'flex' };

const WorkflowsDataGridCell = ({ id, field, rowNode, row, options }) => {
  const preStyle = preStyleArray.includes(field) ? { whiteSpace: 'pre' } : {};
  const { setActiveModal, setCurrentWorkflow } = useSyncWorkflowsContext();
  const isRowExpanded = rowNode?.childrenExpanded;

  const showCreateMappingModal = () => {
    setCurrentWorkflow({
      workflowId: id,
      workflowName: row?.name,
      workflowMapping: row?.workflowMapping,
      ...row?.rest
    });
    setActiveModal(CREATE_MAPPING);
  };
  const { activeTab } = useSyncWorkflowsContext();
  if (id !== 0 && field.toLowerCase().includes('name')) {
    return (
      <Box width="100%">
        {row.isWorkflow && (
          <Typography variant="body2" sx={{ display: 'flex' }} className="workflow-name">
            <WorkflowIcon isRowExpanded={isRowExpanded} />
            <CustomTooltipForCell row={row} />
          </Typography>
        )}
        {row.isMapping && (
          <Link
            underline="none"
            key={row.name}
            component={RouterLink}
            to={
              (activeTab === 0 && `${ROUTES.SYNC}${ROUTES.MAPPING_EDITOR.replace(':id', id)}`) ||
              (activeTab === 2 && `${ROUTES.SYNC}${ROUTES.MAPPING_EDITOR.replace(':id', id)}?isArchived=1`)
            }
          >
            <Typography
              variant="body2"
              color={syncColors.text}
              sx={{ display: 'flex', marginLeft: 2, marginRight: 0 }}
              className="mapping-name"
            >
              <MappingIcon isRowExpanded={isRowExpanded} />
              <CustomTooltipForCell row={row} />
            </Typography>
          </Link>
        )}
        {row.isAction && (
          <Link
            underline="none"
            key={row.name}
            component={RouterLink}
            to={`${ROUTES.SYNC}${ROUTES.MAPPING_EDITOR.replace(':id', row.workflowMappingId)}?activeAction=${
              row.solitaryFlowId
            }${activeTab === 2 ? '&isArchived=1' : ''}`}
          >
            <Typography
              variant="body2"
              color={syncColors.text}
              sx={{ display: 'flex', marginLeft: 2, marginRight: 0 }}
              className="action-name"
            >
              <IconStyled sx={IconStyledSx} icon={<Custom.ActionAlt />} />
              <CustomTooltipForCell row={row} />
            </Typography>
          </Link>
        )}
      </Box>
    );
  }
  if (id !== 0 && field === '*options' && (activeTab === 0 || row.isArchived)) {
    return (
      !!options?.length && (
        <MoreMenu
          options={options}
          sx={{ fontSize: 21, opacity: 0, ':hover': { opacity: 1 }, width: 24, height: 24 }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        />
      )
    );
  }
  if (id !== 0 && field === 'syncStatus') {
    return SyncStatusHig(row.isDeactivated ? ACTION_STATUS.DEACTIVATED : row[field], { ml: row.isAction ? -1.8 : '' });
  }
  if (activeTab === 0 && id !== 0 && field === '*addNew' && !rowNode?.parent) {
    return (
      <IconButton>
        <NavItemTooltip title="New mapping" placement="top">
          <Custom.AddBoxAlt onClick={() => showCreateMappingModal()} data-id="create-mapping-icon" />
        </NavItemTooltip>
      </IconButton>
    );
  }
  return (
    <Typography variant="body2" width="100%" marginLeft={row.isAction ? -1.8 : 'inherit'} sx={preStyle}>
      {row[field]}
    </Typography>
  );
};
const CustomTooltipForCell = ({ row }) => (
  <CustomSyncTooltip title={row.name} sx={{ maxWidth: '100%' }}>
    {row.name}
  </CustomSyncTooltip>
);

const MappingIcon = ({ isRowExpanded }) => (
  <IconStyled
    sx={IconStyledSx}
    color={isRowExpanded ? surfaceColors.lightSurface.primary : actionColors.lightSurface.active}
    icon={<Custom.Mapping />}
  />
);

const WorkflowIcon = ({ isRowExpanded }) => (
  <IconStyled
    sx={{ marginRight: 1.5, alignItems: 'center', display: 'flex' }}
    color={isRowExpanded ? surfaceColors.lightSurface.primary : actionColors.lightSurface.active}
    icon={<Custom.Workflow />}
  />
);

export default WorkflowsDataGridCell;
