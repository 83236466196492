import React, { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

import ComponentPaneLayout from 'app/Layout/ReactGridLayout/ComponentPaneLayout';
import { ItemsDataGridProProvider } from 'components/ItemsDatagridPro';
import useEvictApolloCache from 'modules/Sync/hooks/useEvictApolloCache';
import ActivateWorkflowModal from 'modules/Sync/Workflows/components/ActivateWorkflowModal';
import ArchiveWorkflowModal from 'modules/Sync/Workflows/components/ArchiveWorkflowModal';
import CreateMappingModal from 'modules/Sync/Workflows/components/CreateMappingModal';
import CreateTemplateModal from 'modules/Sync/Workflows/components/CreateTemplateModal';
import CreateWorkflowModal from 'modules/Sync/Workflows/components/CreateWorkflowModal';
import DeactivateWorkflowModal from 'modules/Sync/Workflows/components/DeactivateWorkflowModal';
import DeleteTemplateModal from 'modules/Sync/Workflows/components/DeleteTemplateModal';
import DeleteWorkflowModal from 'modules/Sync/Workflows/components/DeleteWorkflowModal';
import DuplicateMappingModal from 'modules/Sync/Workflows/components/DuplicateMappingModal';
import DuplicateTemplateModal from 'modules/Sync/Workflows/components/DuplicateTemplateModal';
import DuplicateWorkflowModal from 'modules/Sync/Workflows/components/DuplicateWorkflowModal';
import RenameMappingModal from 'modules/Sync/Workflows/components/RenameMappingModal';
import RenameTemplateModal from 'modules/Sync/Workflows/components/RenameTemplateModal';
import RenameWorkflowModal from 'modules/Sync/Workflows/components/RenameWorkflowModal';
import RestoreWorkflowModal from 'modules/Sync/Workflows/components/RestoreWorkflowModal';
import RunSyncModal from 'modules/Sync/Workflows/components/RunSyncModal';
import { WORKFLOWS_APIS, useSyncWorkflowsContext } from 'modules/Sync/Workflows/Contexts/WorkflowsContext';
import TemplatesDataGridTable from 'modules/Sync/Workflows/TemplatesDataGridTable/index';
import WorkflowsDataGridTable from 'modules/Sync/Workflows/WorkflowsDataGridTable/WorkflowsDataGridTable';
import WorkflowsHeader from 'modules/Sync/Workflows/WorkflowsHeader/WorkflowsHeader';

export const WorkflowsTableContent = () => {
  const { activeTab, setActiveTab } = useSyncWorkflowsContext();
  const [activeRows, setActiveRows] = useState([]);

  const { search, pathname } = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const activeRowId = searchParams.get('id');
    const currentTab = Number(searchParams.get('activeTab'));
    if (currentTab) setActiveTab(currentTab);
    if (activeRowId) setActiveRows([activeRowId]);
    searchParams.delete('activeTab');
    window.history.replaceState({}, '', pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEvictApolloCache(WORKFLOWS_APIS, []);

  return (
    <ItemsDataGridProProvider contextId="WorkflowsTable">
      <WorkflowsHeader activeTab={activeTab} setActiveTab={setActiveTab} />
      {activeTab === 0 && <WorkflowsDataGridTable activeRows={activeRows} />}
      {activeTab === 1 && <TemplatesDataGridTable activeRows={activeRows} />}
      {activeTab === 2 && <WorkflowsDataGridTable activeRows={activeRows} />}
    </ItemsDataGridProProvider>
  );
};

const Workflows = () => {
  const {
    setActiveModal,
    setSelectedWorkflowIds,
    setSelectedMappingIds,
    setCurrentWorkflow,
    setCurrentMapping,
    setWorkflowSelectionModel,
    setSelectedActionIds
  } = useSyncWorkflowsContext();

  const hideAllModals = () => {
    setActiveModal();

    /* Cleanup the selected rows after performing any action i.e. rename/delete/sync etc */
    setSelectedWorkflowIds([]);
    setSelectedMappingIds([]);
    setSelectedActionIds([]);
    setWorkflowSelectionModel([]);

    setCurrentWorkflow();
    setCurrentMapping();
  };

  return (
    <>
      <ComponentPaneLayout>
        <WorkflowsTableContent />
      </ComponentPaneLayout>
      <CreateWorkflowModal onClose={hideAllModals} />
      <CreateMappingModal onClose={hideAllModals} />
      <CreateTemplateModal onClose={hideAllModals} />
      <DeleteWorkflowModal onClose={hideAllModals} />
      <DeleteTemplateModal onClose={hideAllModals} />
      <DuplicateWorkflowModal onClose={hideAllModals} />
      <DuplicateMappingModal onClose={hideAllModals} />
      <DuplicateTemplateModal onClose={hideAllModals} />
      <RenameWorkflowModal onClose={hideAllModals} />
      <RenameMappingModal onClose={hideAllModals} />
      <RenameTemplateModal onClose={hideAllModals} />
      <RunSyncModal onClose={hideAllModals} />
      <DeactivateWorkflowModal onClose={hideAllModals} />
      <ActivateWorkflowModal onClose={hideAllModals} />
      <ArchiveWorkflowModal onClose={hideAllModals} />
      <RestoreWorkflowModal onClose={hideAllModals} />
    </>
  );
};
export default Workflows;
