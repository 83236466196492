import { useState } from 'react';

import { gql } from '@apollo/client';
import { Stack } from '@mui/material';

import { FETCH_POLICIES } from 'constants/globalConstants';
import {
  getAutodeskForgeHubs as GET_AUTODESK_FORGE_HUBS,
  getAutodeskForgeProjects as GET_AUTODESK_FORGE_PROJECTS,
  getAutodeskForgeSubItems as GET_AUTODESK_FORGE_SUB_ITEMS
} from 'graphql/queries';
import useLazyPaginatedQuery from 'hooks/useLazyPaginatedQuery';
import { DROPDOWN_DEFAULT_PG_SIZE, MAX_DROPDOWN_ITEMS_IN_VIEW } from 'modules/Sync/Constants/constants';
import { useCopyFilesFormContext } from 'modules/Sync/WorkflowEditor/Contexts/CopyFilesContext';
import FiltersAutocompleteDropdown from 'modules/Sync/WorkflowEditor/Forms/Shared/CopyFilesComponents/FiltersAutocompleteDropdown';

import ForgeFoldersDropdown from './ForgeFoldersDropdown';
import { getForgeSelectEventHandlers } from './forgeHelpers';
import ForgeHubDropdown from './ForgeHubDropdown';
import ForgeProjectDropdown from './ForgeProjectDropdown';
import { useForgeMountHook } from './useForgeMountHook';

const { fetchPolicy: FETCH_POLICY } = FETCH_POLICIES.CACHE_AND_NETWORK;

const AutodeskForge = (props) => {
  const { fileTypeFilters = [], connectionId = '', connectionType = '', nodeId = '' } = props;
  const { showFilters = false, isSource = false } = props;
  const { getConnectorNodeValues, updateConnectorNodeValues } = useCopyFilesFormContext();
  const formValues = getConnectorNodeValues({ isSource, nodeId });
  const { hub, project, folders = [], uiFolders = [] } = formValues;
  const [parentFolderId, setParentFolderId] = useState('');

  const [
    { paginatedLazyLoad: lazyLoadHubs, paginationHandler: hubsPaginationHandler },
    { loading: isLoadingHubs, data: { getAutodeskForgeHubs: ForgeHubs = [] } = {} }
  ] = useLazyPaginatedQuery(gql(GET_AUTODESK_FORGE_HUBS), FETCH_POLICY, MAX_DROPDOWN_ITEMS_IN_VIEW);

  const [
    { paginatedLazyLoad: lazyLoadProjects, paginationHandler: projectsPaginationHandler },
    { loading: isLoadingProjects, data: { getAutodeskForgeProjects: ForgeProjects = [] } = {} }
  ] = useLazyPaginatedQuery(gql(GET_AUTODESK_FORGE_PROJECTS), FETCH_POLICY, MAX_DROPDOWN_ITEMS_IN_VIEW);

  const [
    { lazyLoad: loadSubItems, paginationHandler: fetchMoreRootFolders },
    { loading: isLoadingFolders, data: { getAutodeskForgeSubItems: ForgeSubItems = [] } = {} }
  ] = useLazyPaginatedQuery(gql(GET_AUTODESK_FORGE_SUB_ITEMS), FETCH_POLICY, DROPDOWN_DEFAULT_PG_SIZE);

  const fetchOnMountForgeProjects = hub?.id && !isLoadingHubs && ForgeHubs?.length && !ForgeProjects?.length;
  const fetchOnMountForgeSubItems =
    project?.id && !isLoadingProjects && ForgeProjects?.length && !ForgeSubItems?.length;

  const updateFormValues = (props) => updateConnectorNodeValues({ nodeId, isSource, props });
  const setParentFolderIdOnStackLoad = (parentFolderId) => setParentFolderId(parentFolderId);

  const onScrollHubs = () => hubsPaginationHandler(ForgeHubs?.length, { connectionId });
  const onScrollProjects = () => projectsPaginationHandler(ForgeProjects?.length, { connectionId, hubId: hub?.id });

  const fetchHubsOnLoad = () => {
    if (hub?.id) lazyLoadHubs({ skip: null, take: null, connectionId });
    if (!hub?.id) lazyLoadHubs({ skip: ForgeHubs?.length, connectionId });
  };

  const fetchProjectsOnLoad = () => {
    if (project?.id) lazyLoadProjects({ skip: null, take: null, connectionId, hubId: hub?.id });
    if (!project?.id) lazyLoadProjects({ skip: ForgeProjects?.length, connectionId, hubId: hub?.id });
  };

  const { selectHubHandler, selectProjectHandler, selectSubItemsHandler } = getForgeSelectEventHandlers({
    updateFormValues,
    loadProjects: lazyLoadProjects,
    loadSubItems,
    connectionId,
    hub,
    parentFolderId,
    setParentFolderId,
    folders,
    uiFolders
  });

  useForgeMountHook({
    connectionId,
    connectionType,
    nodeId,
    hub,
    project,
    formValues,
    updateFormValues,
    loadSubItems,
    loadProjects: fetchProjectsOnLoad,
    fetchOnMountForgeProjects,
    fetchOnMountForgeSubItems,
    fetchHubsOnLoad
  });

  return (
    <Stack spacing={2}>
      <ForgeHubDropdown
        value={hub?.id}
        onChange={selectHubHandler}
        loading={isLoadingHubs}
        items={ForgeHubs}
        fetchPaginatedRecords={onScrollHubs}
      />

      <ForgeProjectDropdown
        value={project?.id}
        onChange={selectProjectHandler}
        loading={isLoadingProjects}
        disabled={!hub?.id || !ForgeHubs?.length}
        items={ForgeProjects}
        fetchPaginatedRecords={onScrollProjects}
      />

      <ForgeFoldersDropdown
        folders={ForgeSubItems}
        connectionId={connectionId}
        disabled={isLoadingProjects || !project?.id || !ForgeProjects?.length}
        onSelect={selectSubItemsHandler}
        isSource={isSource}
        isLoadingFolders={isLoadingFolders}
        required
        nodeId={nodeId}
        onFolderStackLoad={setParentFolderIdOnStackLoad}
        fetchMoreRootFolders={fetchMoreRootFolders}
        multiple={isSource}
        vertical={-170}
        horizontal={675}
      />
      <FiltersAutocompleteDropdown showFilters={showFilters} fileTypeFilters={fileTypeFilters} isSource={isSource} />
    </Stack>
  );
};

export default AutodeskForge;
