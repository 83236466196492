import React from 'react';

import { TreeItem } from '@mui/lab';

import { Custom } from 'components/Icons';
import {
  FolderDropdownProvider,
  useFolderDropdownContext
} from 'modules/Sync/WorkflowEditor/Contexts/FolderDropdownContext';
import { usePublishActionFormContext } from 'modules/Sync/WorkflowEditor/Contexts/PublishActionContext';
import FolderRowMenuLabel from 'modules/Sync/WorkflowEditor/Forms/Shared/PublishActionComponents/FoldersSelectDropdown/FolderRowMenuLabel';
import LoadMoreFoldersBtn from 'modules/Sync/WorkflowEditor/Forms/Shared/PublishActionComponents/FoldersSelectDropdown/LoadMoreFoldersBtn';
import {
  useFolderRowMountHook,
  useFolderRowToggleHook,
  useFolderRowTreeItemEventHandlers
} from 'modules/Sync/WorkflowEditor/Forms/Shared/PublishActionComponents/FoldersSelectDropdown/useFolderRowHooks';

const defaultFormatter = (text) => text;

const RowToggleIcon = ({ isExpandable, isExpanded }) =>
  isExpandable &&
  (isExpanded ? (
    <Custom.KeyboardArrowRight width={30} height={30} style={{ transform: 'rotate(90deg)' }} />
  ) : (
    <Custom.KeyboardArrowRight width={30} height={30} />
  ));

const FolderRowTreeItem = (props) => {
  const { folderId, isFolder, parentFolderId, parentFolderName } = props;
  const { text: folderText, connectionId, connectionTypeId, onSelect, nodeId, depth } = props;
  const { isSource = false, disabled = false, expandableFolders = true, isInitialStackFetched = true } = props;
  const { formatText = defaultFormatter, multiple = false, permitFolderCreationForIds = [] } = props;
  const { onFolderRowItemExpansion, onFolderRowItemCollapse, onFolderSelectHandler, onFolderExpandHandler } = props;
  const { onCreateFolderHandler, fetchMoreFoldersHandler } = props;

  const { getConnectorNodeValues } = usePublishActionFormContext();
  const { isExpanded, setIsExpanded, isExpandable, folders } = useFolderDropdownContext();

  const formValues = getConnectorNodeValues({ isSource, nodeId });
  const toggleIsExpanded = () => setIsExpanded((status) => !status);
  const { expandedFolderIds = [] } = formValues;
  const { onFolderExpand, onFolderSelect, onFoldersFetchMore } = useFolderRowTreeItemEventHandlers({
    folderId,
    isFolder,
    parentFolderId,
    isExpanded,
    toggleIsExpanded,
    setIsExpanded,
    onSelect,
    onFolderExpandHandler,
    onFolderSelectHandler,
    onCreateFolderHandler,
    fetchMoreFoldersHandler,
    permitFolderCreationForIds
  });

  useFolderRowMountHook({
    folderId,
    isFolder,
    expandedFolderIds,
    onFolderExpandHandler,
    toggleIsExpanded,
    isInitialStackFetched
  });

  useFolderRowToggleHook({ folderId, onFolderRowItemExpansion, onFolderRowItemCollapse });

  return (
    <StyledTreeItemRoot
      nodeId={folderId}
      label={
        <FolderRowMenuLabel
          nodeId={nodeId}
          folderId={folderId}
          isFolder={isFolder}
          parentFolderId={parentFolderId}
          parentFolderName={parentFolderName}
          text={folderText}
          textFormatter={formatText}
          onFolderSelect={onFolderSelect}
          disabled={disabled}
          multiple={multiple}
          isChecked={formValues?.folders?.find(({ id }) => id === folderId)}
          isSource={isSource}
        />
      }
      onClick={onFolderExpand}
      icon={isFolder && <RowToggleIcon isExpandable={isExpandable} isExpanded={isExpanded} />}
      disabled={disabled}
      data-tree-node={`folder-tree-node-${folderId}`}
    >
      {folders?.map(({ id, text, isFolder }) => (
        <FolderDropdownProvider expandableFolders={permitFolderCreationForIds?.includes(id) || expandableFolders}>
          <FolderRowTreeItem
            key={id}
            depth={depth + 1}
            folderId={id}
            parentFolderId={folderId}
            parentFolderName={folderText}
            text={text}
            connectionId={connectionId}
            connectionTypeId={connectionTypeId}
            onSelect={onSelect}
            isSource={isSource}
            isFolder={isFolder}
            onFolderRowItemExpansion={onFolderRowItemExpansion}
            onFolderRowItemCollapse={onFolderRowItemCollapse}
            onFolderExpandHandler={onFolderExpandHandler}
            onFolderSelectHandler={onFolderSelectHandler}
            onCreateFolderHandler={onCreateFolderHandler}
            fetchMoreFoldersHandler={fetchMoreFoldersHandler}
            isInitialStackFetched={isInitialStackFetched}
            formatText={formatText}
            nodeId={nodeId}
            multiple={multiple}
          />
        </FolderDropdownProvider>
      )) || ''}
      <LoadMoreFoldersBtn
        showMoreBtn={!!folders?.length}
        fetchMoreFolders={onFoldersFetchMore}
        folderId={parentFolderId}
      />
    </StyledTreeItemRoot>
  );
};

const StyledTreeItemRoot = (props) => (
  <TreeItem
    sx={{
      '& .folder-row': {
        '&:hover': {
          '& .create-folder-icon': {
            opacity: 1
          }
        }
      },
      '& .Mui-focused': {
        background: 'transparent !important'
      },
      '& .MuiTreeItem-content:hover': {
        background: 'transparent'
      }
    }}
    {...props}
  />
);

export default FolderRowTreeItem;
