import { useEffect } from 'react';

import { gql } from '@apollo/client';

import { FETCH_POLICIES } from 'constants/globalConstants';
import { syncLogs as SYNC_LOGS } from 'graphql/queries';
import useLazyPaginatedQuery from 'hooks/useLazyPaginatedQuery';
import { DATAGRID_DEFAULT_PG_SIZE } from 'modules/Sync/Constants/constants';

const { fetchPolicy: FETCH_POLICY } = FETCH_POLICIES.CACHE_AND_NETWORK;

const useLogsLazyPaginationHook = () => {
  const [
    {
      lazyLoad: lazyLoadLogs,
      paginationHandler: logsPaginationHandler,
      searchHandler: logsSearchHandler,
      onOrderby: logsSortHandler
    },
    { loading: loadingLogs, data: logsData }
  ] = useLazyPaginatedQuery(gql(SYNC_LOGS), FETCH_POLICY, DATAGRID_DEFAULT_PG_SIZE);

  useEffect(() => {
    lazyLoadLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    lazyLoadLogs,
    logsPaginationHandler,
    logsSearchHandler,
    logsSortHandler,
    loadingLogs,
    logsData
  };
};

export default useLogsLazyPaginationHook;
