import { gql, useMutation } from '@apollo/client';
import { Typography } from '@mui/material';

import { syncRestoreWorkflow as RESTORE_WORKFLOW } from 'graphql/mutations';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';
import CustomModal from 'modules/Sync/components/CustomModal';
import { WORKFLOW_MODAL_KEYS } from 'modules/Sync/Constants/constants';
import ModalFooter from 'modules/Sync/Workflows/components/ModalFooter';
import useArchivedWorkflowsLazyPaginationHook from 'modules/Sync/Workflows/Contexts/useArchivedWorkflowsLazyPaginationHook';
import { useSyncWorkflowsContext } from 'modules/Sync/Workflows/Contexts/WorkflowsContext';
import { cleanedSolitaryFlowId } from 'modules/Sync/Workflows/WorkflowsDataGridTable/workflowsDataGridTableHelper';

const RestoreWorkflowModal = ({ onClose }) => {
  let newSelectedMappingIds = [];
  let newSelectedActionIds = [];
  const mappingIdsNottoDelete = [];
  const actionIdsNottoDelete = [];
  const { activeModal, selectedWorkflowIds, selectedMappingIds, selectedActionIds } = useSyncWorkflowsContext();
  const { archivedWorkflows } = useArchivedWorkflowsLazyPaginationHook();

  const { handleResponse } = useGraphqlResponseHandler();

  const cleanedSolitaryFlowIds = selectedActionIds?.map(
    (id) => cleanedSolitaryFlowId(id) /* TODO: SolitaryFlowId should be unique */
  );
  archivedWorkflows?.syncArchivedWorkflows.forEach((index) => {
    const workflowMappings = index.workflowMapping;
    let solitaryFlows = [];

    if (selectedWorkflowIds.includes(index.workflowId)) {
      workflowMappings.forEach((mapping) => {
        if (selectedMappingIds.includes(mapping.workflowMappingId)) {
          mappingIdsNottoDelete.push(mapping.workflowMappingId);
        }
      });
    }
    workflowMappings.forEach((mapping) => {
      if (selectedMappingIds.includes(mapping.workflowMappingId)) {
        solitaryFlows = mapping.solitaryFlows;
        solitaryFlows.forEach((solitaryFlow) => {
          if (cleanedSolitaryFlowIds.includes(solitaryFlow.solitaryFlowId)) {
            actionIdsNottoDelete.push(solitaryFlow.solitaryFlowId);
          }
        });
      }
    });

    newSelectedMappingIds = selectedMappingIds.filter((itemId) => !mappingIdsNottoDelete.includes(itemId));

    newSelectedActionIds = cleanedSolitaryFlowIds.filter((itemId) => !actionIdsNottoDelete.includes(itemId));
  });

  const [restoreWorkflow, { loading }] = useMutation(gql(RESTORE_WORKFLOW), {
    refetchQueries: ['SyncArchivedWorkflows', 'SyncWorkflows']
  });

  const submit = async () => {
    await handleResponse(
      restoreWorkflow,
      {
        variables: {
          body: {
            WorkflowIds: selectedWorkflowIds,
            MappingIds: newSelectedMappingIds,
            SolitaryFlowIds: newSelectedActionIds
          }
        }
      },
      { successMessage: 'Workflow/Mapping/Action(s) successfully restored' },
      onClose,
      onClose
    );
  };

  return (
    <CustomModal
      disableModalOutsideClick
      disableCloseButton={loading}
      open={activeModal === WORKFLOW_MODAL_KEYS.RESTORE_WORKFLOW}
      onClose={onClose}
      title="Confirm restore"
      reducePadding
      titleStyles={{ fontWeight: 'bold' }}
      maxWidth="false"
      paperSx={{ width: 600 }}
      footer={
        <ModalFooter
          cancelBtnText="NO"
          createBtnText="CONFIRM"
          loading={loading}
          onModalClose={onClose}
          onSubmit={submit}
        />
      }
    >
      <Typography variant="body1" mb="12px">
        Are you sure you want to restore selected workflow/mapping/action(s)?
      </Typography>
    </CustomModal>
  );
};

export default RestoreWorkflowModal;
