import React, { useState, useEffect } from 'react';

import { FormControlLabel, FormHelperText, Grid, Stack } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import { useFormContext } from 'react-hook-form';

import { RadioOption } from 'components/FormComponents';
import { Caption, ControlTextField } from 'modules/Sync/Connections/ConnectionProperties/FormFields';
import useUpsertConnectionMutation from 'modules/Sync/Connections/ConnectionProperties/useUpsertConnectionMutation';
import { useSyncConnectionsContext } from 'modules/Sync/Connections/ConnectionsContext';
import {
  CONNECTION_TYPES,
  fieldServerOptions,
  glueServerOptions,
  hQServerOptions,
  teamsAndDocsServerOptions
} from 'modules/Sync/Constants/constants';

import FieldInputs from './AutodeskField';
import GlueInputs from './AutodeskGlue';
import HQInputs from './AutodeskHQ';
import TeamsAndDocsInputs, { AdminLogInputs } from './AutodeskTeams';
import useAutodeskMutation from '../hooks/useAutodeskMutation';

const autodeskOptions = [
  {
    label: 'Team/Docs',
    value: CONNECTION_TYPES.AUTODESKTEAMDOCS,
    typeValue: CONNECTION_TYPES.AUTODESK
  },
  { label: 'HQ', value: CONNECTION_TYPES.AUTODESKHQ },
  { label: 'Glue', value: CONNECTION_TYPES.AUTODESKGLUE },
  { label: 'Field', value: CONNECTION_TYPES.AUTODESKFIELD }
];

export const autodeskFormDefaultValues = ({ configuration: selectedConnectionConfig }) => ({
  userName: selectedConnectionConfig?.userName || '',
  password: selectedConnectionConfig?.password || '',
  forgeHQ: {
    forgeHQServer: selectedConnectionConfig?.forgeHQ?.forgeHQServer || hQServerOptions[0],
    forgeHQClientId: selectedConnectionConfig?.forgeHQ?.forgeHQClientId || '',
    forgeHQClientSecret: selectedConnectionConfig?.forgeHQ?.forgeHQClientSecret || ''
  },
  forgeTeamDocs: {
    forgeServer: selectedConnectionConfig?.forgeTeamDocs?.forgeServer || teamsAndDocsServerOptions[0],
    shouldUploadFileInfoLog: selectedConnectionConfig?.forgeTeamDocs?.shouldUploadFileInfoLog === true
  },
  glue: {
    glueServer: selectedConnectionConfig?.glue?.glueServer || glueServerOptions[0],
    glueCompanyId: selectedConnectionConfig?.glue?.glueCompanyId || ''
  },
  field: {
    fieldServer: selectedConnectionConfig?.field?.fieldServer || fieldServerOptions[0],
    timeout: selectedConnectionConfig?.field?.timeout || 3600
  },
  isField: selectedConnectionConfig?.isField === true,
  isGlue: selectedConnectionConfig?.isGlue === true,
  isTeamDocs: selectedConnectionConfig?.isTeamDocs === true,
  isHQ: selectedConnectionConfig?.isHQ === true
});

// eslint-disable-next-line max-lines-per-function
const Autodesk = ({
  actionRenderer,
  dataSource,
  connectionSource,
  isAutodeskRadioDisabled,
  disableAllFields,
  onSuccessCallback
}) => {
  const [validationError, setValidationError] = useState();
  const { currentConnection, setHeaderActionsDisabled } = useSyncConnectionsContext();
  const isNewForm = !currentConnection?.connectionId;
  const defaultConnection = React.useMemo(() => {
    if (isNewForm && dataSource === CONNECTION_TYPES.AUTODESK) {
      return CONNECTION_TYPES.AUTODESKTEAMDOCS;
    }
    return currentConnection?.connectionType || dataSource || connectionSource || CONNECTION_TYPES.AUTODESK;
  }, [currentConnection?.connectionType, dataSource, isNewForm, connectionSource]);
  const [selectedType, setSelectedType] = useState(defaultConnection);
  const { upsertConnection, loading } = useUpsertConnectionMutation();
  const { generateAuthCode } = useAutodeskMutation();

  const {
    control,
    formState: { isValid }
  } = useFormContext();

  const setError = (errors) => {
    if (typeof errors === 'string') {
      setValidationError(errors);
    } else {
      const { errorInfo } = errors?.[0] || {};
      setValidationError(errorInfo?.[0]);
    }
  };

  useEffect(() => {
    setSelectedType(defaultConnection);
    setValidationError('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentConnection]);

  const updateConnection = async (formData) => {
    setValidationError('');
    delete formData.dataSource;
    formData.configuration.isField = selectedType === CONNECTION_TYPES.AUTODESKFIELD;
    formData.configuration.isGlue = selectedType === CONNECTION_TYPES.AUTODESKGLUE;
    formData.configuration.isTeamDocs = selectedType === CONNECTION_TYPES.AUTODESKTEAMDOCS;
    formData.configuration.isHQ = selectedType === CONNECTION_TYPES.AUTODESKHQ;
    if (formData?.configuration?.isHQ) {
      const [usOption] = hQServerOptions;
      formData.configuration.forgeHQ.forgeHQServer = usOption;
    }

    const authenticationCode = await generateAuthCode({ formData, onError: setError });
    if (authenticationCode !== 'error') {
      setHeaderActionsDisabled(false);
      upsertConnection({
        formData,
        authenticationCode,
        connectionType: selectedType,
        onError: setError,
        onSuccess: onSuccessCallback
      });
    }
  };

  const isAutodeskTypeSelected = React.useMemo(
    () =>
      selectedType === CONNECTION_TYPES.AUTODESKFIELD ||
      selectedType === CONNECTION_TYPES.AUTODESKGLUE ||
      selectedType === CONNECTION_TYPES.AUTODESKTEAMDOCS ||
      selectedType === CONNECTION_TYPES.AUTODESKHQ,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedType]
  );

  const submitDisabled = !isValid || !isAutodeskTypeSelected;

  return (
    <>
      <Caption>Credentials</Caption>
      <AutodeskTypeRadioButtons
        selectedType={selectedType}
        setSelectedType={setSelectedType}
        disabled={(!isNewForm && currentConnection.isConnectionUsed) || isAutodeskRadioDisabled}
      />
      <Grid item container justifyContent="space-between" spacing={2}>
        <Grid item xs={12}>
          <ControlTextField
            key={currentConnection?.connectionId}
            control={control}
            requiredNoSpace
            name="configuration.userName"
            label="Username*"
            disabled={disableAllFields}
          />
        </Grid>
        <Grid item xs={12}>
          {selectedType === CONNECTION_TYPES.AUTODESKGLUE && (
            <ControlTextField
              key={currentConnection?.connectionId}
              control={control}
              requiredNoSpace
              type="password"
              name="configuration.password"
              label="Password*"
              disabled={disableAllFields}
            />
          )}
        </Grid>
      </Grid>
      <Stack>
        {selectedType === CONNECTION_TYPES.AUTODESKFIELD && (
          <FieldInputs control={control} disableAllFields={disableAllFields} />
        )}
        {selectedType === CONNECTION_TYPES.AUTODESKGLUE && (
          <GlueInputs control={control} disableAllFields={disableAllFields} />
        )}
        {selectedType === CONNECTION_TYPES.AUTODESKTEAMDOCS && (
          <TeamsAndDocsInputs control={control} disableAllFields={disableAllFields} />
        )}
        {selectedType === CONNECTION_TYPES.AUTODESKHQ && (
          <HQInputs control={control} disableAllFields={disableAllFields} />
        )}
        {selectedType === CONNECTION_TYPES.AUTODESKTEAMDOCS && (
          <AdminLogInputs control={control} disableAllFields={disableAllFields} />
        )}
      </Stack>
      <FormHelperText error>{validationError}</FormHelperText>
      {actionRenderer({ onSubmit: updateConnection, submitDisabled, loading })}
    </>
  );
};

export default Autodesk;

const AutodeskTypeRadioButtons = ({ selectedType, setSelectedType, disabled }) => (
  <FormControl disabled={disabled}>
    <RadioGroup
      onChange={(event) => {
        setSelectedType(event.target.value);
      }}
      aria-labelledby="demo-radio-buttons-group-label"
      value={selectedType}
    >
      <Grid container>
        {autodeskOptions.map((option) => (
          <Grid item xs={5} key={option.label}>
            <FormControlLabel
              value={option.value}
              checked={selectedType === option.typeValue || selectedType === option.value}
              control={<RadioOption />}
              label={option.label}
            />
          </Grid>
        ))}
      </Grid>
    </RadioGroup>
  </FormControl>
);
