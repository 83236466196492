import { Stack } from '@mui/material';

import Accordion from 'components/Accordion/ControlledAccordion';
import SyncActionTitle from 'modules/Sync/WorkflowEditor/Forms/Shared/SyncFilesForm/SyncActionTitle';
import SyncOptions from 'modules/Sync/WorkflowEditor/Forms/Shared/SyncFilesForm/SyncOptions';
import { greyColors } from 'styles/theme/colors';

const accordionProps = {
  hideFirstBorder: true,
  hideLastBorder: true,
  titleSx: { fontWeight: 700, fontSize: 14, margin: 0 },
  sx: {
    marginTop: 1,
    '& .MuiAccordionSummary-root,.MuiAccordionDetails-root': {
      paddingTop: 0,
      paddingX: 0,
      width: '100%'
    },
    '& .Mui-disabled.MuiPaper-root': {
      background: greyColors.grey300,
      px: 1
    }
  }
};

const getAccordionItems = ({
  availableActionNodes = [],
  onSyncSettingsChange,
  setMappingFlowsSyncSettings,
  mappingFlowsSyncSettings,
  importMethods,
  recommendTime,
  disableRecommendTime
}) =>
  availableActionNodes?.reduce(
    (sourceOptions, { id, isDeactivated, isArchived, data: { actionAlias } = {}, actionType = '' }, index) => {
      let status = '';
      if (isDeactivated) status = '(Deactivated)';
      if (isArchived) status = '(Archived)';

      const disabled = isDeactivated || isArchived;
      sourceOptions.push({
        id: index,
        title: <SyncActionTitle actionAlias={`${actionAlias} ${status}`} />,
        disabled,
        content: (
          <SyncOptions
            action={actionType}
            onSyncSettingsChange={onSyncSettingsChange}
            setMappingFlowsSyncSettings={setMappingFlowsSyncSettings}
            mappingFlowsSyncSettings={mappingFlowsSyncSettings}
            currentAction={id}
            importMethods={importMethods}
            recommendTime={recommendTime}
            disableRecommendTime={disableRecommendTime}
            disabled={disabled}
          />
        )
      });
      return sourceOptions;
    },
    []
  );

const SyncFilesForm = ({
  onSyncSettingsChange,
  setMappingFlowsSyncSettings = () => {},
  mappingFlowsSyncSettings = [],
  selectedSource = {},
  selectedAction = {},
  syncSettings = {},
  availableActionNodes,
  importMethods,
  recommendTime,
  disableRecommendTime,
  loadingRecommendTime
}) => {
  const { id: selectedActionId } = selectedAction;
  const { name } = selectedAction?.data || {};
  const actionName = name?.split(' -');

  const options = selectedActionId
    ? []
    : getAccordionItems({
        availableActionNodes,
        onSyncSettingsChange,
        setMappingFlowsSyncSettings,
        mappingFlowsSyncSettings,
        importMethods,
        recommendTime,
        disableRecommendTime
      });

  const defaultExpandedItemId = options?.find(({ disabled }) => !disabled)?.id || options.length - 1;

  return (
    <Stack gap={0} sx={{ marginTop: '0px !important' }}>
      {selectedActionId && (
        <SyncOptions
          action={actionName?.[0]}
          onSyncSettingsChange={onSyncSettingsChange}
          setMappingFlowsSyncSettings={setMappingFlowsSyncSettings}
          mappingFlowsSyncSettings={mappingFlowsSyncSettings}
          selectedSource={selectedSource}
          selectedAction={selectedAction}
          syncSettings={syncSettings}
          importMethods={importMethods}
          recommendTime={recommendTime}
          disableRecommendTime={disableRecommendTime}
          loadingRecommendTime={loadingRecommendTime}
        />
      )}
      <Accordion {...accordionProps} items={options} defaultExpandedItemId={defaultExpandedItemId} />
    </Stack>
  );
};

export default SyncFilesForm;
