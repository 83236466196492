import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GridHeaderCheckbox
} from '@mui/x-data-grid-pro';

import { getFormattedDateTime } from 'helpers/dateFunctions';
import EmptyDataGridCell from 'modules/Sync/components/EmptyDataGridCell';
import { WORKFLOW_TABLE_ENTITIES } from 'modules/Sync/Constants/constants';
import { getMappingStatus, getWorkflowStatus } from 'modules/Sync/helpers/getStatus';
import { isMigrated, IsSyncFinished } from 'modules/Sync/helpers/logSyncStatusHelpers';
import { getSyncType } from 'modules/Sync/WorkflowEditor/helpers/mappingConfigHelper';
import WorkflowsDataGridGroupingCell from 'modules/Sync/Workflows/WorkflowsDataGridTable/WorkflowsDataGridGroupingCell';
import WorkflowsDataGridTableColumns from 'modules/Sync/Workflows/WorkflowsDataGridTable/WorkflowsDataGridTableColumns';

const formatSolitaryFlows = ({ actions, workflowId, workflowMappingId, parentStatus }) => {
  if (!actions?.length) return [];
  return actions?.map(({ solitaryFlowId, actionAlias, source, lastSyncAt, isArchived, isDeactivated, syncStatus }) => ({
    path: [workflowId, workflowMappingId, solitaryFlowId],
    id: `${WORKFLOW_TABLE_ENTITIES.SOLITARY_FLOW}-${solitaryFlowId}`,
    workflowMappingId,
    lastSyncAt: lastSyncAt ? getFormattedDateTime(lastSyncAt, true) : <EmptyDataGridCell />,
    name: actionAlias || <EmptyDataGridCell />,
    syncType: getSyncType(source?.settings?.syncType)?.label,
    parentStatus,
    solitaryFlowId,
    isArchived,
    isDeactivated,
    syncStatus,
    isAction: true
  }));
};

export const workflowsMapper = (workflows = []) => {
  if (!workflows.length) return [];

  return workflows.flatMap(({ workflowId, workflowName, workflowMapping, isArchived, isDeactivated }) => {
    const workflowSyncStatus = getWorkflowStatus(workflowMapping);
    return [
      {
        path: [workflowId],
        id: workflowId,
        workflowId,
        name: workflowName || <EmptyDataGridCell />,
        workflowMapping,
        syncStatus: workflowSyncStatus,
        isArchived,
        isDeactivated,
        isWorkflow: true
      },
      ...workflowMapping?.flatMap(
        ({
          workflowMappingId,
          workflowMappingName,
          lastSavedAt,
          isArchived,
          isDeactivated,
          solitaryFlows = [],
          publishModelSolitaryFlows = []
        }) => {
          const mappingStatus = getMappingStatus([...solitaryFlows, ...publishModelSolitaryFlows]);
          return [
            {
              path: [workflowId, workflowMappingId],
              id: workflowMappingId,
              lastSavedAt: lastSavedAt ? getFormattedDateTime(lastSavedAt, true) : <EmptyDataGridCell />,
              workflowId,
              syncStatus: mappingStatus,
              parentStatus: workflowSyncStatus,
              workflowMappingId,
              name: workflowMappingName || <EmptyDataGridCell />,
              solitaryFlows,
              publishModelSolitaryFlows,
              isArchived,
              isDeactivated,
              isMapping: true
            },
            ...formatSolitaryFlows({
              actions: solitaryFlows,
              workflowId,
              workflowMappingId,
              parentStatus: mappingStatus
            }),
            ...formatSolitaryFlows({
              actions: publishModelSolitaryFlows,
              workflowId,
              workflowMappingId,
              parentStatus: mappingStatus
            })
          ];
        }
      )
    ];
  });
};

export const getGroupingColDef = (props) => ({
  headerName: '',
  hideable: false,
  sortable: false,
  maxWidth: 40,
  resizable: false,
  renderCell: (params) => <WorkflowsDataGridGroupingCell {...params} {...props} />
});

export const actionMenusApiPayload = ({ selectedWorkflowIds, selectedMappingIds, selectedActionIds, workflows }) => {
  const mappingIdsNotToSend = [];
  const actionIdsNotToSend = [];
  const workflowIdsNotToSend = [];

  const cleanedSolitaryFlowIds = selectedActionIds?.map((id) => cleanedSolitaryFlowId(id));
  workflows?.syncWorkflows.forEach((workflow) => {
    const mappingWithStatusInProgress = workflow.workflowMapping.filter(
      (workflowMapping) => workflowMapping.isSyncRunning
    );
    if (mappingWithStatusInProgress.length > 0) workflowIdsNotToSend.push(workflow.workflowId);

    if (selectedWorkflowIds.includes(workflow.workflowId) && !workflowIdsNotToSend.includes(workflow.workflowId)) {
      workflow.workflowMapping.forEach((mapping) => {
        if (selectedMappingIds.includes(mapping.workflowMappingId)) mappingIdsNotToSend.push(mapping.workflowMappingId);
      });
    }
    workflow.workflowMapping.forEach((mapping) => {
      if (selectedMappingIds.includes(mapping.workflowMappingId)) {
        mapping.solitaryFlows.forEach((solitaryFlow) => {
          if (cleanedSolitaryFlowIds.includes(solitaryFlow.solitaryFlowId))
            actionIdsNotToSend.push(solitaryFlow.solitaryFlowId);
        });
      }
    });
  });
  const selectedWorkflowIdsToSend = selectedWorkflowIds.filter((itemId) => !workflowIdsNotToSend.includes(itemId));
  const selectedMappingIdsToSend = selectedMappingIds.filter((itemId) => !mappingIdsNotToSend.includes(itemId));
  const selectedActionIdsToSend = cleanedSolitaryFlowIds.filter((itemId) => !actionIdsNotToSend.includes(itemId));
  return { selectedWorkflowIdsToSend, selectedMappingIdsToSend, selectedActionIdsToSend };
};

export const disableActionMenus = ({ workflows, selectedWorkflowIds, selectedMappingIds, selectedActionIds }) => {
  const areAllEntitiesSelected =
    selectedWorkflowIds.length < 1 && selectedMappingIds.length < 1 && selectedActionIds.length < 1;

  const cleanedSolitaryFlowIds = selectedActionIds?.map(
    (id) => cleanedSolitaryFlowId(id) /* TODO: SolitaryFlowId should be unique */
  );

  const getWorkflows = (activatedOnly = false) =>
    selectedWorkflowIds?.some((id) =>
      workflows?.syncWorkflows?.find(
        ({ workflowId, isDeactivated }) => workflowId === id && (activatedOnly ? !isDeactivated : isDeactivated)
      )
    ) || false;

  const getWorkflowMappings = (activatedOnly = false) =>
    selectedMappingIds?.some((id) =>
      workflows?.syncWorkflows?.some(({ workflowMapping }) =>
        workflowMapping.find(
          ({ workflowMappingId, isDeactivated }) =>
            workflowMappingId === id && (activatedOnly ? !isDeactivated : isDeactivated)
        )
      )
    ) || false;

  const getMappingActions = (activatedOnly = false) =>
    cleanedSolitaryFlowIds?.some((id) =>
      workflows?.syncWorkflows?.some(({ workflowMapping }) =>
        workflowMapping.some(
          ({ solitaryFlows = [], publishModelSolitaryFlows = [], issueManagementSolitaryFlows = [] }) =>
            [...solitaryFlows, ...publishModelSolitaryFlows, ...issueManagementSolitaryFlows].find(
              ({ solitaryFlowId, isDeactivated }) =>
                solitaryFlowId === id && (activatedOnly ? !isDeactivated : isDeactivated)
            )
        )
      )
    ) || false;

  const activatedWorkflowSelected = getWorkflows(true);
  const deactivatedWorkflowSelected = getWorkflows();
  const activatedMappingsSelected = getWorkflowMappings(true);
  const deactivatedMappingsSelected = getWorkflowMappings();
  const activatedActionsSelected = getMappingActions(true);
  const deactivatedActionsSelected = getMappingActions();

  const runSyncMappingSelected = selectedMappingIds?.some((id) =>
    workflows?.syncWorkflows?.some(({ workflowMapping }) =>
      workflowMapping.find(
        ({ workflowMappingId, solitaryFlows }) => workflowMappingId === id && solitaryFlows.length < 1
      )
    )
  );
  const runSyncWorkflowSelected = selectedWorkflowIds?.some((id) =>
    workflows?.syncWorkflows?.find(({ workflowId, workflowMapping }) => workflowId === id && workflowMapping.length < 1)
  );

  const isSyncInProgressForSelectedMappings = !!workflows?.syncWorkflows?.some(({ workflowMapping }) =>
    workflowMapping.some(
      ({ workflowMappingId, isSyncRunning }) => selectedMappingIds.includes(workflowMappingId) && isSyncRunning
    )
  );
  const isDeactivateActionDisabled =
    areAllEntitiesSelected ||
    deactivatedWorkflowSelected ||
    deactivatedMappingsSelected ||
    deactivatedActionsSelected ||
    isSyncInProgressForSelectedMappings;

  const isActivateActionDisabled =
    activatedWorkflowSelected ||
    activatedMappingsSelected ||
    activatedActionsSelected ||
    isSyncInProgressForSelectedMappings;

  const isArchiveActionDisabled = areAllEntitiesSelected;

  const isRunSyncDisabled =
    selectedWorkflowIds?.some((id) =>
      workflows?.syncWorkflows?.some(
        ({ workflowId, workflowMapping }) =>
          workflowId === id &&
          workflowMapping.find(
            ({ solitaryFlows = [], publishModelSolitaryFlows = [], issueManagementSolitaryFlows = [] }) =>
              [...solitaryFlows, ...publishModelSolitaryFlows, ...issueManagementSolitaryFlows].length < 1
          )
      )
    ) ||
    deactivatedWorkflowSelected ||
    deactivatedMappingsSelected ||
    deactivatedActionsSelected ||
    isDeactivateActionDisabled ||
    runSyncMappingSelected ||
    runSyncWorkflowSelected;

  return {
    isActivateActionDisabled,
    isDeactivateActionDisabled,
    isArchiveActionDisabled,
    isRunSyncDisabled
  };
};

export const getColumns = ({ renderCell, apiRef }) => [
  {
    ...GRID_CHECKBOX_SELECTION_COL_DEF,
    type: '',
    maxWidth: 40,
    hideable: false,
    resizable: false,
    renderHeader: (params) => {
      let indeterminate = false;
      const totalRowsCount = apiRef.current?.getRowsCount() ?? 0;
      const selectedRows = apiRef.current?.getSelectedRows();
      if (selectedRows?.size) {
        const selectedRowsCount = selectedRows?.size;
        indeterminate = selectedRowsCount > 0 && selectedRowsCount < totalRowsCount;
      }
      return (
        <>
          <GridHeaderCheckbox {...params} indeterminate={indeterminate} checked={selectedRows?.size > 0} />
        </>
      );
    }
  },
  { ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF, maxWidth: 40, hideable: false, resizable: false },
  ...WorkflowsDataGridTableColumns(renderCell),
  {
    field: '*addNew',
    headerName: '',
    flex: 1,
    maxWidth: 40,
    hideable: false,
    sortable: false,
    resizable: false,
    renderCell
  },
  {
    field: '*options',
    headerName: '',
    flex: 1,
    maxWidth: 40,
    hideable: false,
    sortable: false,
    resizable: false,
    renderCell
  }
];

export const defaultDataGridConfig = {
  treeData: true,
  disableChildrenSorting: true,
  disableChildrenFiltering: true,
  density: 'standard',
  disableColumnMenu: true,
  disableSelectionOnClick: true,
  experimentalFeatures: { rowPinning: true, newEditingApi: true },
  initialState: { pinnedColumns: { left: [], right: ['*addNew', '*options'] } }
};

export const cleanedSolitaryFlowId = (id) => id.replaceAll(`${WORKFLOW_TABLE_ENTITIES.SOLITARY_FLOW}-`, '');

export const getSelectionModelChangeCb =
  ({
    workflowSelectionModel,
    workflowMappingIds,
    mappingFlowActionIds,
    rows,
    apiRef,
    workFlowIds,
    setSelectedActionIds,
    setSelectedMappingIds,
    setSelectedWorkflowIds,
    setWorkflowSelectionModel,
    setFirstSelectedItemName
  }) =>
  (selectedItems) => {
    let selectedWorkflowIds = [];
    let selectedMappingIds = [];
    let selectedActionIds = [];

    /* if a row is unselected resulting in empty selection */
    if (selectedItems.length === 0) {
      setSelectedActionIds([]);
      setSelectedMappingIds([]);
      setSelectedWorkflowIds([]);
      return setWorkflowSelectionModel([]);
    }

    /* if a row is unselected */
    if (selectedItems.length < workflowSelectionModel.length) {
      const removedItems = workflowSelectionModel.filter((itm) => !selectedItems.includes(itm));

      removedItems.forEach((id) => {
        /* unselecting parent unselects the children */
        const children = apiRef.current.getRowGroupChildren({ groupId: id });
        if (children.length) selectedItems = selectedItems.filter((itemId) => !children.includes(itemId));

        if (mappingFlowActionIds.includes(id)) selectedActionIds = selectedActionIds.filter((itemId) => itemId !== id);
        if (workflowMappingIds.includes(id)) selectedMappingIds = selectedMappingIds.filter((itemId) => itemId !== id);
        if (workFlowIds.includes(id)) selectedWorkflowIds = selectedWorkflowIds.filter((itemId) => itemId !== id);

        /* unselecting child(mapping) unselects the parent(workflow) */
        if (workflowMappingIds.includes(id)) {
          const workflowIdsToBeRemoved = rows.reduce((workflowsToBeRemoved, row) => {
            const workflowRowContainsCurrentIdMapping = row?.workflowMapping?.some(
              ({ workflowMappingId }) => workflowMappingId === id
            );

            if (workflowRowContainsCurrentIdMapping) workflowsToBeRemoved.push(row.id);
            return workflowsToBeRemoved;
          }, []);
          selectedItems = selectedItems.filter((itemId) => !workflowIdsToBeRemoved.includes(itemId));
        }

        /* unselecting child(action) unselects the parent(mapping) */
        if (mappingFlowActionIds.includes(id)) {
          const mappingIdsToBeRemoved = rows.reduce((mappingIdsToBeRemoved, row) => {
            const mappingRowContainsCurrentIdFlow = row?.solitaryFlows?.some(
              ({ solitaryFlowId }) => cleanedSolitaryFlowId(id) === solitaryFlowId
            );
            if (mappingRowContainsCurrentIdFlow) mappingIdsToBeRemoved.push(row.id);
            return mappingIdsToBeRemoved;
          }, []);

          const workflowIdsToBeRemoved = rows.reduce((workflowIdsToBeRemoved, row) => {
            const workflowRowContainsCurrentIdMapping = row?.workflowMapping?.some(({ workflowMappingId }) =>
              mappingIdsToBeRemoved.includes(workflowMappingId)
            );

            if (workflowRowContainsCurrentIdMapping) workflowIdsToBeRemoved.push(row.id);
            return workflowIdsToBeRemoved;
          }, []);

          selectedItems = selectedItems.filter(
            (itemId) => !mappingIdsToBeRemoved.includes(itemId) && !workflowIdsToBeRemoved.includes(itemId)
          );
        }

        setSelectedActionIds([...new Set(selectedActionIds)]);
        setSelectedMappingIds([...new Set(selectedMappingIds)]);
        setSelectedWorkflowIds([...new Set(selectedWorkflowIds)]);
        return setWorkflowSelectionModel([...new Set(selectedItems)]);
      });
    }

    /* if a row is selected */
    const selectedDependentItems = selectedItems.reduce((selectedItems, itemId) => {
      let children = apiRef.current.getRowGroupChildren({ groupId: itemId });
      rows?.forEach((row) => {
        if ((!IsSyncFinished(row.syncStatus) || !isMigrated(row.syncStatus)) && children.includes(row.id))
          children = children.filter((itemId) => itemId !== row.id);
      });
      selectedItems = [...selectedItems, ...children, itemId];
      return selectedItems;
    }, []);

    selectedDependentItems.reduce((selectedItems, itemId) => {
      if (mappingFlowActionIds.includes(itemId)) selectedActionIds.push(itemId); /* action row */
      if (workflowMappingIds.includes(itemId)) selectedMappingIds.push(itemId); /* mapping row */
      if (workFlowIds.includes(itemId)) selectedWorkflowIds.push(itemId); /* workflow row */
      return selectedItems;
    }, []);

    setSelectedActionIds([...new Set(selectedActionIds)]);
    setSelectedMappingIds([...new Set(selectedMappingIds)]);
    setSelectedWorkflowIds([...new Set(selectedWorkflowIds)]);
    rows.forEach((row) => {
      if (row?.id === selectedItems?.[0]) setFirstSelectedItemName(row?.name);
    });
    return setWorkflowSelectionModel([...new Set(selectedDependentItems)]);
  };
