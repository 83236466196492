import { v4 as uuidv4 } from 'uuid';

import { formatTo } from 'helpers/dateFunctions';
import { CONNECTION_NAME } from 'modules/Sync/Constants/constants';

const RealTimeLogsDataGridTableRows = (logs = []) => {
  if (!logs) return [];

  return logs.flatMap(({ date, files }, index) => {
    const name = formatTo(new Date(date));
    const id = `${name}-${index}`;
    return [
      { id, path: [id], name, date, isParent: true },
      ...files?.flatMap(({ fileName = '', fileSyncTime, solitaryFlows }) => {
        const slicedFileName = fileName?.replaceAll(' ', '_')?.slice(0, 10) || '';
        const uid = uuidv4();
        const fileSyncAt = formatTo(new Date(fileSyncTime), 'hh:mm A');
        return [
          {
            id: `${slicedFileName}-${uid}`,
            path: [id, `${slicedFileName}-${uid}`],
            name: `${fileName}  - (${fileSyncAt})`,
            fileSyncAt,
            fileName
          },
          ...solitaryFlows?.flatMap(
            ({ workflowId, workflowName, mappingId, mappingName, source, destinations, actionAlias, sourcePath }) => {
              const flowUid = uuidv4();
              return [
                {
                  id: flowUid,
                  path: [id, `${slicedFileName}-${uid}`, flowUid],
                  name: `${workflowName} / ${mappingName}`,
                  actionAlias,
                  workflowId,
                  mappingId,
                  source: CONNECTION_NAME[source],
                  destinations: destinations?.map(({ destination }) => CONNECTION_NAME[destination]).join(', ')
                },
                ...[
                  {
                    isSource: true,
                    id: `${sourcePath}-${flowUid}`,
                    path: [id, `${slicedFileName}-${uid}`, flowUid, `${sourcePath}-${flowUid}`],
                    source: CONNECTION_NAME[source],
                    name: sourcePath,
                    filePath: sourcePath
                  },
                  ...destinations?.map(
                    ({ fileSyncLogId, destination, destinationPath, syncStatus, isContactSupportTeam }, index) => ({
                      id: fileSyncLogId,
                      serial: index + 1,
                      path: [id, `${slicedFileName}-${uid}`, flowUid, fileSyncLogId],
                      name: destinationPath,
                      destination: CONNECTION_NAME[destination],
                      filePath: destinationPath,
                      syncStatus,
                      isContactSupportTeam
                    })
                  )
                ]
              ];
            }
          )
        ];
      })
    ];
  });
};

export default RealTimeLogsDataGridTableRows;
