import React from 'react';

import { List } from '@mui/material';

import { CONNECTION_TYPES } from 'modules/Sync/Constants/constants';
import TabListItem from 'modules/Sync/WorkflowEditor/Drawers/PropertiesDrawer/TabListItem';

const DestinationTab = ({ data: destination }) => {
  const {
    hub: { name: hubName } = {},
    connection: { configuration, type: connectionType } = {},
    project: { name: projectName, folder: { name: folderName } } = {}
  } = destination;

  const {
    userName,
    email,
    password,
    useCustomApplicationCredentials,
    forgeHQServer,
    isField,
    isGlue,
    isTeamDocs,
    isHQ,
    shouldUploadFileInfoLog
  } = JSON.parse(configuration || '{}');

  return (
    <List sx={{ paddingTop: 0 }}>
      {connectionType && <TabListItem title="Connection" content={connectionType} />}
      {hubName && (
        <TabListItem
          title={connectionType === CONNECTION_TYPES.PROCORE ? 'Company' : 'Hub'}
          content={hubName}
        />
      )}
      {projectName && <TabListItem title="Project" content={projectName} />}
      {folderName && <TabListItem title="Folder" content={folderName} />}
      {email && <TabListItem title="Login" content={email} />}
      {userName && <TabListItem title="Username" content={userName} />}
      {password && <TabListItem title="Password" content={password} />}
      {useCustomApplicationCredentials && (
        <TabListItem title="Use custom application credentials" content="Yes" />
      )}
      {forgeHQServer && <TabListItem title="Forge Hq Server" content={forgeHQServer} />}
      {isField && <TabListItem title="Is Field" content="Yes" />}
      {isGlue && <TabListItem title="Is Glue" content="Yes" />}
      {isTeamDocs && <TabListItem title="Is Team Docs" content="Yes" />}
      {isHQ && <TabListItem title="Is Hq" content="Yes" />}
      {shouldUploadFileInfoLog && <TabListItem title="Should upload file info log" content="Yes" />}
    </List>
  );
};

export default DestinationTab;
