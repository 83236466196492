import { useEffect, useMemo } from 'react';

import { useGridApiRef } from '@mui/x-data-grid-pro';

import ItemsDataGridPro from 'components/ItemsDatagridPro';
import { getTimeZone } from 'helpers/dateFunctions';
import { useSyncLogsContext } from 'modules/Sync/Logs/Contexts/LogsContext';
import useRealtimeLogsLazyPaginationHook from 'modules/Sync/Logs/Contexts/useRealtimeLogsLazyPaginationHook';
import NoRowsOverlay from 'modules/Sync/Logs/NoRowsOverlay';
import RealTimeLogsDataGridTableCell from 'modules/Sync/Logs/RealTimeLogsDataGridTable/RealTimeLogsDataGridTableCell';
import RealTimeLogsDataGridTableColumns from 'modules/Sync/Logs/RealTimeLogsDataGridTable/RealTimeLogsDataGridTableColumns';
import RealTimeLogsDataGridTableRows from 'modules/Sync/Logs/RealTimeLogsDataGridTable/RealTimeLogsDataGridTableRows';

import RealTimeLogDetailsDataGridGroupingCell from './RealTimeLogDetailsDataGridGroupingCell';
import realTimeLogsDataGridStyles from './realTimeLogsDataGridStyles';
import RealTimeLogsDataHeader from './RealTimeLogsDataHeader';

const NoRowsOverlayText = () => <span>Sync a mapping or workflow to view log reports.</span>;

const RealTimeLogsDataGridTable = () => {
  const {
    realTimeLogsPaginationHandler,
    loadingRealtimeLogs,
    realTimelogsData,
    realTimeLogsSortHandler,
    realTimeLogsSearchHandler,
    lazyLoadRealTimeLogs
  } = useRealtimeLogsLazyPaginationHook();
  const { setRealTimeLogsSearchPhrase, realTimeLogSearchPhrase } = useSyncLogsContext();

  const apiRef = useGridApiRef();
  const timeZone = useMemo(() => getTimeZone(), []);

  const handleCellComponent = ({ field, row }) => <RealTimeLogsDataGridTableCell field={field} row={row} />;
  const onScroll = () =>
    realTimeLogsPaginationHandler(realTimelogsData?.syncRealTimeLogs?.length, {
      searchPhrase: realTimeLogSearchPhrase,
      timeZone
    });

  const columns = RealTimeLogsDataGridTableColumns(handleCellComponent);
  const rows = useMemo(
    () => RealTimeLogsDataGridTableRows(realTimelogsData?.syncRealTimeLogs),
    [realTimelogsData?.syncRealTimeLogs]
  );
  const lastParentRowId = rows?.filter(({ isParent }) => isParent)?.at(-1)?.id;
  const groupingColDef = {
    headerName: '',
    sortable: false,
    maxWidth: 50,
    renderCell: (params) => (
      <RealTimeLogDetailsDataGridGroupingCell
        {...params}
        onWaypointEnter={onScroll}
        lastParentRowId={lastParentRowId}
      />
    )
  };

  const handleSort = (sortModel) =>
    sortModel.length ? realTimeLogsSortHandler({ columnName: sortModel?.[0]?.field }) : null;

  const onSearch = (phrase) => {
    setRealTimeLogsSearchPhrase(phrase);
    return realTimeLogsSearchHandler(phrase);
  };

  useEffect(() => {
    lazyLoadRealTimeLogs({ timeZone });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <RealTimeLogsDataHeader searchHandler={onSearch} />
      <ItemsDataGridPro
        apiRef={apiRef}
        treeData
        getTreeDataPath={(row) => row.path}
        groupingColDef={groupingColDef}
        isGroupExpandedByDefault={(node) => rows?.[0]?.id === node?.id || rows?.[1]?.id === node?.id}
        components={{
          NoRowsOverlay: () => <NoRowsOverlay text={<NoRowsOverlayText />} />
        }}
        density="standard"
        checkboxSelection={false}
        disableColumnMenu
        disableSelectionOnClick
        columns={columns}
        rows={rows}
        loading={loadingRealtimeLogs}
        experimentalFeatures={{ rowPinning: true, newEditingApi: true }}
        asyncApi={{ onScroll }}
        onSortModelChange={handleSort}
        sortingOrder={['asc', 'desc']}
        getRowClassName={(params) => params?.row?.rowClass}
        sx={realTimeLogsDataGridStyles}
      />
    </>
  );
};

export default RealTimeLogsDataGridTable;
