import { useCallback } from 'react';

import { gql } from '@apollo/client';
import { Stack } from '@mui/material';

import { FETCH_POLICIES } from 'constants/globalConstants';
import {
  getSharepointFolders as GET_SHAREPOINT_FOLDERS,
  getSharepointSites as GET_SHAREPOINT_SITES
} from 'graphql/queries';
import useLazyPaginatedQuery from 'hooks/useLazyPaginatedQuery';
import { DROPDOWN_DEFAULT_PG_SIZE, MAX_DROPDOWN_ITEMS_IN_VIEW } from 'modules/Sync/Constants/constants';
import { useCopyFilesFormContext } from 'modules/Sync/WorkflowEditor/Contexts/CopyFilesContext';
import SharepointFoldersDropdown from 'modules/Sync/WorkflowEditor/Forms/CopyFiles/Sharepoint/SharepointFoldersDropdown';
import FiltersAutocompleteDropdown from 'modules/Sync/WorkflowEditor/Forms/Shared/CopyFilesComponents/FiltersAutocompleteDropdown';
import DropdownFormControl from 'modules/Sync/WorkflowEditor/Utils/DropdownFormControl';

import { useSharepointMountHook } from './useSharepointMountHook';

const { fetchPolicy: FETCH_POLICY } = FETCH_POLICIES.CACHE_AND_NETWORK;

const Sharepoint = (props) => {
  const { connectionId = '', connectionType = '', nodeId = '' } = props;
  const { fileTypeFilters = [], showFilters = false, isSource = false, allowPrivateFiles = false } = props;
  const { getConnectorNodeValues, updateConnectorNodeValues } = useCopyFilesFormContext();
  const formValues = getConnectorNodeValues({ isSource, nodeId });
  const { projects: selectedSites = [], project = selectedSites?.[0] } = formValues;

  const [
    { paginatedLazyLoad: lazyLoadSites, paginationHandler: sitesPaginationHandler },
    { loading: isLoadingSites, data: { getSharepointSites: SharepointSites = [] } = {} }
  ] = useLazyPaginatedQuery(gql(GET_SHAREPOINT_SITES), FETCH_POLICY, MAX_DROPDOWN_ITEMS_IN_VIEW);

  const onScrollSites = () => sitesPaginationHandler(SharepointSites?.length, { connectionId });

  const [
    { lazyLoad: loadFolders, paginationHandler: fetchMoreRootFolders },
    { loading: isLoadingFolders, data: { getSharepointFolders: SharepointFolders = [] } = {} }
  ] = useLazyPaginatedQuery(gql(GET_SHAREPOINT_FOLDERS), FETCH_POLICY, DROPDOWN_DEFAULT_PG_SIZE);

  const siteId = selectedSites?.[0]?.id || project?.id;
  const fetchOnMountSharepointFolders = siteId && !isLoadingSites && SharepointSites?.length;

  const updateFormValues = useCallback(
    (props) => updateConnectorNodeValues({ nodeId, isSource, props }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formValues, isSource, nodeId]
  );

  const selectSitesHandler = (event) => {
    const {
      target: { value }
    } = event;
    const lastValue = value[value.length - 1];
    const isValueString = typeof value === 'string';
    updateFormValues({
      hub: null,
      projects: isValueString
        ? [{ id: value, name: SharepointSites?.find(({ id }) => id === value)?.text }]
        : value?.map((projectId) => ({
            id: projectId,
            name: SharepointSites?.find(({ id }) => id === projectId)?.text
          })),
      project: isValueString
        ? { id: value, name: SharepointSites?.find(({ id }) => id === value)?.text }
        : { id: lastValue, name: SharepointSites?.find(({ id }) => id === lastValue)?.text },
      folder: { id: '', name: '' },
      folders: [],
      isSubmitted: isValueString ? false : value.length >= 1
    });

    if (value.length || isValueString) {
      loadFolders({
        connectionId,
        siteId: isValueString ? value : value?.[0]
      });
    }
  };

  useSharepointMountHook({
    project,
    connectionId,
    connectionType,
    nodeId,
    SharepointSites,
    fetchOnMountSharepointFolders,
    siteId,
    lazyLoadSites,
    updateFormValues,
    loadFolders
  });

  /* Folders dropdown is disabled when no site is selected, or more than one site is selected or Sites couldn't be loaded */
  const foldersDropdownDisabled = !siteId || selectedSites?.length > 1 || !SharepointSites?.length;

  return (
    <Stack spacing={2}>
      <DropdownFormControl
        title="Sites"
        id="sites"
        labelId="sites"
        label="sites"
        items={SharepointSites}
        value={isSource ? selectedSites.map(({ id }) => id) : project?.id}
        onChange={selectSitesHandler}
        loading={isLoadingSites}
        multiple={isSource} /* Multi Sites selection is only allowed for source connectors */
        fetchPaginatedRecords={onScrollSites}
        required
      />

      <SharepointFoldersDropdown
        connectionId={connectionId}
        folders={SharepointFolders}
        disabled={isLoadingSites || foldersDropdownDisabled}
        isLoadingFolders={isLoadingFolders}
        isSource={isSource}
        nodeId={nodeId}
        multiple={isSource} /* Multi Folder selection is only allowed for source connectors */
        fetchMoreRootFolders={fetchMoreRootFolders}
        required={!isSource}
      />

      <FiltersAutocompleteDropdown
        allowPrivateFiles={allowPrivateFiles}
        showFilters={showFilters}
        fileTypeFilters={fileTypeFilters}
        isSource={isSource}
      />
    </Stack>
  );
};

export default Sharepoint;
