import React, { useMemo } from 'react';

import { Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import InputSelectField from 'components/FormComponents/InputSelectField';
import FileInput from 'modules/Sync/Connections/components/FileInput';
import Accordion from 'modules/Sync/Connections/components/ImportAccordion';
import { useImportWorkflowsContext } from 'modules/Sync/Connections/components/ImportWorkflowsContext';
import { useSyncConnectionsContext } from 'modules/Sync/Connections/ConnectionsContext';
import ImportMappingProperties from 'modules/Sync/Connections/Modals/ImportConnectionModal/ImportMappingProperties';
import ImportTips from 'modules/Sync/Connections/Modals/ImportConnectionModal/ImportTips';
import useImportWorkflowsHook from 'modules/Sync/Connections/Modals/ImportConnectionModal/useImportWorkflowsHook';
import WarningLoader from 'modules/Sync/Connections/Modals/ImportConnectionModal/WarningLoader';
import {
  CONNECTION_NAME,
  CONNECTION_TYPES,
  IMPORT_GLOBAL_WINDOWS_SELECT,
  MIGRATION_TOOL
} from 'modules/Sync/Constants/constants';
import { importErrors } from 'modules/Sync/helpers/extractWorkflowConfig';
import {
  getErrors,
  getUpdatedConnectionIdMappings,
  updateAllWindowsConnection
} from 'modules/Sync/helpers/importHelpers';

const { DEFAULT_WORKFLOWS_PATH } = MIGRATION_TOOL;

const WorkflowTitle = () => (
  <Typography variant="caption" fontWeight="bold" mt={0}>
    Select the Configurations folder to convert into workflows & mappings
  </Typography>
);

const getWindowsAvailability = (workflowData) =>
  workflowData.some((item) =>
    item?.mappings?.some(
      (mapItem) =>
        mapItem?.solitaryFlowConfigurations[0]?.source.connectionName === CONNECTION_NAME[CONNECTION_TYPES.WINDOWS] ||
        mapItem?.solitaryFlowConfigurations[0]?.destinations?.[0].connectionName ===
          CONNECTION_NAME[CONNECTION_TYPES.WINDOWS]
    )
  );

const getWindowsOptions = (connectionsData) =>
  connectionsData?.syncConnections?.reduce((acc, connection) => {
    if (connection.type === CONNECTION_TYPES.WINDOWS) {
      acc.push({ value: connection.id, text: connection?.name, label: connection?.name });
    }
    return acc;
  }, []);

const isMasterWindowVisible = ({ windowsOptions, workflowData, isWindowsAvailable }) => {
  const isVisible = !!(windowsOptions.length && workflowData?.length && isWindowsAvailable);
  return isVisible;
};

const getConnectionName = (connectionsData, connectionId) =>
  connectionsData?.syncConnections?.find((item) => item.id === connectionId)?.name;

const getFileName = (workflowLen) =>
  workflowLen ? `${workflowLen} ${workflowLen > 1 ? 'files' : 'file'} selected` : 'Select configuration files';

const WorkflowsForm = ({ error, handleConfigChange, data }) => {
  const { isWorkflowLoading, workflowData: workflowDataOriginal } = useImportWorkflowsContext();

  const { connectionsData } = useSyncConnectionsContext();
  const { control, setValue } = useFormContext();

  const { fields: workflowData = [], update, replace, remove } = data || {};

  const fileName = getFileName(workflowData?.length);
  const windowsOptions = getWindowsOptions(connectionsData);
  const isWindowsAvailable = getWindowsAvailability(workflowDataOriginal);

  const windowVisibilityInput = { windowsOptions, workflowData, isWindowsAvailable };
  const showMasterWindowsSelect = isMasterWindowVisible({ ...windowVisibilityInput });

  const updateAllWindowsMappings = (connectionId) => {
    const connectionName = getConnectionName(connectionsData, connectionId);
    const updatedWorkflows = updateAllWindowsConnection({ workflowData, connectionId, connectionName });
    replace(updatedWorkflows);
  };

  const updateWindowsConnection = ({ connectionId, ...rest }) => {
    const connectionName = getConnectionName(connectionsData, connectionId);
    const updatedWorkflows = getUpdatedConnectionIdMappings({ workflowData, connectionName, connectionId, ...rest });
    replace(updatedWorkflows);
  };

  const refreshWorkflow = (event, workflowId) => {
    event.stopPropagation();
    const workflowToUpdate = workflowDataOriginal.find((item) => item.workflowId === workflowId);
    const indexToUpdate = workflowData.findIndex((item) => item.workflowId === workflowId);
    if (indexToUpdate > -1 && workflowToUpdate) update(indexToUpdate, workflowToUpdate);
  };

  const removeWorkflow = (index) => remove(index);

  const { workflowId: errorWorkflowId } = getErrors(workflowData);
  const expandId = useMemo(() => {
    const expandableId = workflowData.findIndex((item) => item.workflowId === errorWorkflowId);
    return { id: expandableId };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, errorWorkflowId);

  const workflowItems = workflowData.map((item, index) => {
    const { isAdded, error } = importErrors(MIGRATION_TOOL.WORKFLOW_ERROR, item);
    const isDuplicateWorkflow = item?.mappings?.find((item) => item.isDuplicate || item.shouldOverwrite)
      ? 'This workflow has duplicate mappings'
      : null;
    return {
      id: index,
      title: `${item?.workflowName}`,
      content: (
        <ImportMappingProperties
          key={item.workflowId}
          index={index}
          data={item}
          control={control}
          workflowName={item.workflowName}
          update={update}
          updateWindowsConnection={updateWindowsConnection}
        />
      ),
      isAdded,
      error,
      onDelete: !isAdded ? () => removeWorkflow(index) : null,
      onRefresh: !isAdded ? (e) => refreshWorkflow(e, item.workflowId) : null,
      warning: isDuplicateWorkflow && !isAdded
    };
  });

  const onMountCallback = () => {
    replace(workflowData);
    setValue(IMPORT_GLOBAL_WINDOWS_SELECT, '');
  };

  useImportWorkflowsHook({ onMountCallback });

  return (
    <>
      <WorkflowTitle />

      <FileInput
        onChange={handleConfigChange}
        error={error}
        fileName={fileName}
        multiple
        disabled={isWorkflowLoading}
      />

      {!workflowData?.length && <ImportTips fileName="Configurations" textToCopy={DEFAULT_WORKFLOWS_PATH} isFolder />}

      {showMasterWindowsSelect && (
        <InputSelectField
          name={IMPORT_GLOBAL_WINDOWS_SELECT}
          label="Choose your Windows Connector"
          control={control}
          options={windowsOptions}
          onSelectChange={(event) => updateAllWindowsMappings(event.target.value)}
          sx={{ marginTop: 0 }}
        />
      )}

      {workflowData?.length > 0 && (
        <Accordion
          titleSx={{ fontWeight: 800 }}
          hideFirstBorder
          hideLastBorder
          expand={expandId}
          items={workflowItems}
          sx={{ marginTop: '0 !important', '& .MuiAccordionSummary-root, .MuiAccordionDetails-root': { padding: 0 } }}
        />
      )}

      {isWorkflowLoading && <WarningLoader />}
    </>
  );
};

export default WorkflowsForm;
