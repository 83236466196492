import React, { useState, useEffect } from 'react';

import { gql } from '@apollo/client';

import { FETCH_POLICIES } from 'constants/globalConstants';
import { syncWebhooks as SYNC_WEBHOOKS } from 'graphql/queries';
import useLazyPaginatedQuery from 'hooks/useLazyPaginatedQuery';
import { DATAGRID_DEFAULT_PG_SIZE } from 'modules/Sync/Constants/constants';

const { fetchPolicy } = FETCH_POLICIES.CACHE_AND_NETWORK;

const SyncWebhooksContext = React.createContext({});

const SyncWebhooksProvider = ({ children }) => {
  const [selectedWebhookIds, setSelectedWebhookIds] = useState([]);
  const [selectedWebhookDetails, setSelectedWebhookDetails] = useState([]);
  const [webhookIdWithStatus, setWebhookIdWithStatus] = useState([]);
  const [searchPhrase, setSearchPhrase] = useState('');
  const [activeModal, setActiveModal] = useState('');
  const [
    {
      lazyLoad: lazyLoadWebhooks,
      paginationHandler: webhooksPaginationHandler,
      searchHandler: webhooksSearchHandler,
      onOrderby: webhooksSortHandler
    },
    { loading: loadingWebhooks, data: webhooks }
  ] = useLazyPaginatedQuery(gql(SYNC_WEBHOOKS), fetchPolicy, DATAGRID_DEFAULT_PG_SIZE);

  useEffect(() => {
    lazyLoadWebhooks(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectedStateObj = React.useMemo(
    () => ({
      selectedWebhookIds,
      setSelectedWebhookIds,
      selectedWebhookDetails,
      setSelectedWebhookDetails,
      webhookIdWithStatus,
      setWebhookIdWithStatus,
      webhooksPaginationHandler,
      webhooksSearchHandler,
      loadingWebhooks,
      webhooks,
      webhooksSortHandler,
      searchPhrase,
      setSearchPhrase,
      activeModal,
      setActiveModal
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      selectedWebhookIds,
      selectedWebhookDetails,
      setSelectedWebhookDetails,
      setWebhookIdWithStatus,
      webhookIdWithStatus,
      setSelectedWebhookIds,
      webhooks,
      loadingWebhooks,
      searchPhrase,
      activeModal,
      setActiveModal
    ]
  );

  return <SyncWebhooksContext.Provider value={selectedStateObj}>{children}</SyncWebhooksContext.Provider>;
};

const useSyncWebhooksContext = () => {
  const context = React.useContext(SyncWebhooksContext);
  if (context === undefined) {
    throw new Error('useSyncWebhooksContext must be used within a SyncWebhooksContext');
  }
  return context;
};

export { SyncWebhooksContext, SyncWebhooksProvider, useSyncWebhooksContext };

export const WEBHOOKS_APIS = ['syncWebhooks'];
