import { useEffect } from 'react';

import { gql } from '@apollo/client';

import { FETCH_POLICIES } from 'constants/globalConstants';
import { syncWorkflows as SYNC_WORKFLOWS } from 'graphql/queries';
import useLazyPaginatedQuery from 'hooks/useLazyPaginatedQuery';
import { DATAGRID_DEFAULT_PG_SIZE } from 'modules/Sync/Constants/constants';

const { fetchPolicy: FETCH_POLICY } = FETCH_POLICIES.CACHE_AND_NETWORK;

const useWorkflowsLazyPaginationHook = () => {
  const [
    {
      lazyLoad: lazyLoadWorkflows,
      paginationHandler: workflowsPaginationHandler,
      searchHandler: workflowsSearchHandler,
      onOrderby: workflowsSortHandler
    },
    { loading: loadingWorkflows, data: workflows }
  ] = useLazyPaginatedQuery(gql(SYNC_WORKFLOWS), FETCH_POLICY, DATAGRID_DEFAULT_PG_SIZE);

  useEffect(() => {
    lazyLoadWorkflows(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    lazyLoadWorkflows,
    workflowsPaginationHandler,
    workflowsSearchHandler,
    workflowsSortHandler,
    loadingWorkflows,
    workflows
  };
};

export default useWorkflowsLazyPaginationHook;
