import React, { useState } from 'react';

import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';

import { Custom } from 'components/Icons';
import CustomMenu from 'components/Menu';
import CustomTooltip from 'components/Tooltip';

const DeleteMenuButton = ({
  hideMenu = false,
  sxMenu,
  onDelete = () => {},
  handleOpen = () => {},
  handleExtClose = () => {},
  ...props
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    handleOpen();
  };
  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
    handleExtClose();
  };

  const buildElement = () => (
    <MenuItem
      data-cy="more-menu-option-delete"
      key="delete"
      onClick={(event) => {
        onDelete();
        handleClose(event);
      }}
    >
      Delete workflow
    </MenuItem>
  );

  return (
    <>
      <CustomTooltip title="Delete workflow" arrow placement="left">
        <IconButton mr={1} sx={{ marginLeft: 'auto', marginRight: '5px' }} onClick={handleClick}>
          <Custom.DeleteOutlineActive style={{ width: '16px', height: '16px' }} />
        </IconButton>
      </CustomTooltip>
      {!hideMenu && (
        <CustomMenu
          id="more-menu"
          data-testid="more-menu"
          data-cy="more-menu"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button'
          }}
          sx={sxMenu}
          {...props}
        >
          {buildElement()}
        </CustomMenu>
      )}
    </>
  );
};

export default DeleteMenuButton;
