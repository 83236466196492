/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "./types";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const navigationModules = /* GraphQL */ `query NavigationModules {
  navigationModules {
    navigationModuleId
    navigationModuleName
    navigationModuleCode
    sortOrder
    navigationModuleSets {
      navigationModuleSetId
      navigationModuleId
      navigationSetId
      navigationSet {
        navigationSetId
        navigationSetName
        isMenu
        icon
        sortOrder
        navigationSetItems {
          navigationSetItemId
          navigationSetId
          navigationItemId
          navigationItem {
            navigationItemId
            navigationItemName
            icon
            routePath
            iconPath
            __typename
          }
          sortOrder
          __typename
        }
        __typename
      }
      sortOrder
      isLastInGroup
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.NavigationModulesQueryVariables,
  APITypes.NavigationModulesQuery
>;
export const syncConnectorsList = /* GraphQL */ `query SyncConnectorsList {
  syncConnectorsList {
    name
    url
    logoutUrl
    realTimeSync
    isLicensed
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncConnectorsListQueryVariables,
  APITypes.SyncConnectorsListQuery
>;
export const getSupportedExtensions = /* GraphQL */ `query GetSupportedExtensions {
  getSupportedExtensions {
    type
    extensions
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSupportedExtensionsQueryVariables,
  APITypes.GetSupportedExtensionsQuery
>;
export const syncGetAllWorkflowMappingTemplates = /* GraphQL */ `query SyncGetAllWorkflowMappingTemplates {
  syncGetAllWorkflowMappingTemplates {
    templateId
    templateName
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncGetAllWorkflowMappingTemplatesQueryVariables,
  APITypes.SyncGetAllWorkflowMappingTemplatesQuery
>;
export const syncLogsDataUsageReport = /* GraphQL */ `query SyncLogsDataUsageReport {
  syncLogsDataUsageReport {
    monthlyDataUsage
    yearlyDataUsage
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncLogsDataUsageReportQueryVariables,
  APITypes.SyncLogsDataUsageReportQuery
>;
export const userStatus = /* GraphQL */ `query UserStatus($params: QueryUserStatusParamsInput!) {
  userStatus(params: $params) {
    userEmail
    userName
    userStatus
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserStatusQueryVariables,
  APITypes.UserStatusQuery
>;
export const userStatusById = /* GraphQL */ `query UserStatusById($params: QueryUserStatusByIdParamsInput!) {
  userStatusById(params: $params) {
    userEmail
    userName
    userStatus
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserStatusByIdQueryVariables,
  APITypes.UserStatusByIdQuery
>;
export const userByName = /* GraphQL */ `query UserByName($params: QueryUserByNameParamsInput!) {
  userByName(params: $params) {
    userId
    userName
    userFirstName
    userLastName
    userIdentifier
    userEmail
    workPhoneNumber
    personalPhoneNumber
    userStatus
    userStatusId
    inviteStatus
    userTypes {
      userTypeId
      typeName
      typeId
      __typename
    }
    acceptedLatestEula
    companyId
    isAdmin
    isDeleted
    userPhotoId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserByNameQueryVariables,
  APITypes.UserByNameQuery
>;
export const userById = /* GraphQL */ `query UserById($params: QueryUserByIdParamsInput!) {
  userById(params: $params) {
    userId
    userName
    userFirstName
    userLastName
    userIdentifier
    userEmail
    workPhoneNumber
    personalPhoneNumber
    userStatus
    userStatusId
    inviteStatus
    userTypes {
      userTypeId
      typeName
      typeId
      __typename
    }
    acceptedLatestEula
    companyId
    isAdmin
    isDeleted
    userPhotoId
    __typename
  }
}
` as GeneratedQuery<APITypes.UserByIdQueryVariables, APITypes.UserByIdQuery>;
export const users = /* GraphQL */ `query Users($query: QueryUsersQueryInput) {
  users(query: $query) {
    userId
    userName
    userFirstName
    userLastName
    userIdentifier
    userEmail
    workPhoneNumber
    personalPhoneNumber
    userStatus
    userStatusId
    inviteStatus
    userTypes {
      userTypeId
      typeName
      typeId
      __typename
    }
    acceptedLatestEula
    companyId
    isAdmin
    isDeleted
    userPhotoId
    __typename
  }
}
` as GeneratedQuery<APITypes.UsersQueryVariables, APITypes.UsersQuery>;
export const navigationModuleById = /* GraphQL */ `query NavigationModuleById($params: QueryNavigationModuleByIdParamsInput!) {
  navigationModuleById(params: $params) {
    navigationModuleId
    navigationModuleName
    navigationModuleCode
    sortOrder
    navigationModuleSets {
      navigationModuleSetId
      navigationModuleId
      navigationSetId
      navigationSet {
        navigationSetId
        navigationSetName
        isMenu
        icon
        sortOrder
        navigationSetItems {
          navigationSetItemId
          navigationSetId
          navigationItemId
          navigationItem {
            navigationItemId
            navigationItemName
            icon
            routePath
            iconPath
            __typename
          }
          sortOrder
          __typename
        }
        __typename
      }
      sortOrder
      isLastInGroup
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.NavigationModuleByIdQueryVariables,
  APITypes.NavigationModuleByIdQuery
>;
export const syncConnections = /* GraphQL */ `query SyncConnections($query: QuerySyncConnectionsQueryInput) {
  syncConnections(query: $query) {
    id
    name
    type
    configuration
    isConnectionUsed
    lastModified
    desktopConnectionId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncConnectionsQueryVariables,
  APITypes.SyncConnectionsQuery
>;
export const syncWorkflows = /* GraphQL */ `query SyncWorkflows($query: QuerySyncWorkflowsQueryInput) {
  syncWorkflows(query: $query) {
    workflowId
    workflowName
    workflowMapping {
      workflowMappingId
      workflowMappingName
      isSyncRunning
      lastSyncAt
      lastSavedAt
      isArchived
      isDeactivated
      solitaryFlows {
        solitaryFlowId
        action
        actionNodeId
        actionAlias
        isArchived
        isDeactivated
        source {
          syncDetailsId
          nodeId
          connection {
            id
            name
            type
            configuration
            isConnectionUsed
            lastModified
            desktopConnectionId
            __typename
          }
          hub {
            id
            name
            __typename
          }
          projects {
            id
            name
            folders {
              id
              name
              items {
                id
                name
                __typename
              }
              __typename
            }
            __typename
          }
          additionalData
          settings {
            isSyncEnabled
            includeExtensions
            secondaryFilesSetting
            isSyncAllRevisions
            syncType
            scheduleSyncConfig
            __typename
          }
          __typename
        }
        destinations {
          syncDetailsId
          nodeId
          connection {
            id
            name
            type
            configuration
            isConnectionUsed
            lastModified
            desktopConnectionId
            __typename
          }
          hub {
            id
            name
            __typename
          }
          project {
            id
            name
            folder {
              id
              name
              __typename
            }
            __typename
          }
          additionalData
          __typename
        }
        syncStatus
        lastSyncAt
        actionType
        __typename
      }
      publishModelSolitaryFlows {
        solitaryFlowId
        action
        actionNodeId
        actionAlias
        isArchived
        isDeactivated
        source {
          syncDetailsId
          nodeId
          connection {
            id
            name
            type
            configuration
            isConnectionUsed
            lastModified
            desktopConnectionId
            __typename
          }
          hub {
            id
            name
            __typename
          }
          projects {
            id
            name
            folders {
              id
              name
              items {
                id
                name
                __typename
              }
              __typename
            }
            __typename
          }
          additionalData
          settings {
            isSyncEnabled
            includeExtensions
            secondaryFilesSetting
            isSyncAllRevisions
            syncType
            scheduleSyncConfig
            __typename
          }
          __typename
        }
        destinations {
          syncDetailsId
          nodeId
          connection {
            id
            name
            type
            configuration
            isConnectionUsed
            lastModified
            desktopConnectionId
            __typename
          }
          hub {
            id
            name
            __typename
          }
          project {
            id
            name
            folder {
              id
              name
              __typename
            }
            __typename
          }
          additionalData
          __typename
        }
        syncStatus
        lastSyncAt
        actionType
        __typename
      }
      __typename
    }
    isArchived
    isDeactivated
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncWorkflowsQueryVariables,
  APITypes.SyncWorkflowsQuery
>;
export const syncArchivedWorkflows = /* GraphQL */ `query SyncArchivedWorkflows($query: QuerySyncArchivedWorkflowsQueryInput) {
  syncArchivedWorkflows(query: $query) {
    workflowId
    workflowName
    workflowMapping {
      workflowMappingId
      workflowMappingName
      isSyncRunning
      lastSyncAt
      lastSavedAt
      isArchived
      isDeactivated
      solitaryFlows {
        solitaryFlowId
        action
        actionNodeId
        actionAlias
        isArchived
        isDeactivated
        source {
          syncDetailsId
          nodeId
          connection {
            id
            name
            type
            configuration
            isConnectionUsed
            lastModified
            desktopConnectionId
            __typename
          }
          hub {
            id
            name
            __typename
          }
          projects {
            id
            name
            folders {
              id
              name
              items {
                id
                name
                __typename
              }
              __typename
            }
            __typename
          }
          additionalData
          settings {
            isSyncEnabled
            includeExtensions
            secondaryFilesSetting
            isSyncAllRevisions
            syncType
            scheduleSyncConfig
            __typename
          }
          __typename
        }
        destinations {
          syncDetailsId
          nodeId
          connection {
            id
            name
            type
            configuration
            isConnectionUsed
            lastModified
            desktopConnectionId
            __typename
          }
          hub {
            id
            name
            __typename
          }
          project {
            id
            name
            folder {
              id
              name
              __typename
            }
            __typename
          }
          additionalData
          __typename
        }
        syncStatus
        lastSyncAt
        actionType
        __typename
      }
      publishModelSolitaryFlows {
        solitaryFlowId
        action
        actionNodeId
        actionAlias
        isArchived
        isDeactivated
        source {
          syncDetailsId
          nodeId
          connection {
            id
            name
            type
            configuration
            isConnectionUsed
            lastModified
            desktopConnectionId
            __typename
          }
          hub {
            id
            name
            __typename
          }
          projects {
            id
            name
            folders {
              id
              name
              items {
                id
                name
                __typename
              }
              __typename
            }
            __typename
          }
          additionalData
          settings {
            isSyncEnabled
            includeExtensions
            secondaryFilesSetting
            isSyncAllRevisions
            syncType
            scheduleSyncConfig
            __typename
          }
          __typename
        }
        destinations {
          syncDetailsId
          nodeId
          connection {
            id
            name
            type
            configuration
            isConnectionUsed
            lastModified
            desktopConnectionId
            __typename
          }
          hub {
            id
            name
            __typename
          }
          project {
            id
            name
            folder {
              id
              name
              __typename
            }
            __typename
          }
          additionalData
          __typename
        }
        syncStatus
        lastSyncAt
        actionType
        __typename
      }
      __typename
    }
    isArchived
    isDeactivated
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncArchivedWorkflowsQueryVariables,
  APITypes.SyncArchivedWorkflowsQuery
>;
export const syncWebhooks = /* GraphQL */ `query SyncWebhooks($query: QuerySyncWebhooksQueryInput) {
  syncWebhooks(query: $query) {
    webhookId
    webhookDetails
    connectionType
    isActive
    lastSavedAt
    workflows {
      workflowId
      workflowName
      mappings {
        mappingId
        mappingName
        isSyncRunning
        syncStatus
        lastSyncTime
        solitaryFlows {
          solitaryFlowId
          actionAlias
          syncStatus
          syncType
          lastSyncTime
          webhookRegistryId
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncWebhooksQueryVariables,
  APITypes.SyncWebhooksQuery
>;
export const getWorkflowById = /* GraphQL */ `query GetWorkflowById($params: QueryGetWorkflowByIdParamsInput!) {
  getWorkflowById(params: $params) {
    workflowId
    workflowName
    workflowMapping {
      workflowMappingId
      workflowMappingName
      isSyncRunning
      lastSyncAt
      lastSavedAt
      isArchived
      isDeactivated
      solitaryFlows {
        solitaryFlowId
        action
        actionNodeId
        actionAlias
        isArchived
        isDeactivated
        source {
          syncDetailsId
          nodeId
          connection {
            id
            name
            type
            configuration
            isConnectionUsed
            lastModified
            desktopConnectionId
            __typename
          }
          hub {
            id
            name
            __typename
          }
          projects {
            id
            name
            folders {
              id
              name
              items {
                id
                name
                __typename
              }
              __typename
            }
            __typename
          }
          additionalData
          settings {
            isSyncEnabled
            includeExtensions
            secondaryFilesSetting
            isSyncAllRevisions
            syncType
            scheduleSyncConfig
            __typename
          }
          __typename
        }
        destinations {
          syncDetailsId
          nodeId
          connection {
            id
            name
            type
            configuration
            isConnectionUsed
            lastModified
            desktopConnectionId
            __typename
          }
          hub {
            id
            name
            __typename
          }
          project {
            id
            name
            folder {
              id
              name
              __typename
            }
            __typename
          }
          additionalData
          __typename
        }
        syncStatus
        lastSyncAt
        actionType
        __typename
      }
      publishModelSolitaryFlows {
        solitaryFlowId
        action
        actionNodeId
        actionAlias
        isArchived
        isDeactivated
        source {
          syncDetailsId
          nodeId
          connection {
            id
            name
            type
            configuration
            isConnectionUsed
            lastModified
            desktopConnectionId
            __typename
          }
          hub {
            id
            name
            __typename
          }
          projects {
            id
            name
            folders {
              id
              name
              items {
                id
                name
                __typename
              }
              __typename
            }
            __typename
          }
          additionalData
          settings {
            isSyncEnabled
            includeExtensions
            secondaryFilesSetting
            isSyncAllRevisions
            syncType
            scheduleSyncConfig
            __typename
          }
          __typename
        }
        destinations {
          syncDetailsId
          nodeId
          connection {
            id
            name
            type
            configuration
            isConnectionUsed
            lastModified
            desktopConnectionId
            __typename
          }
          hub {
            id
            name
            __typename
          }
          project {
            id
            name
            folder {
              id
              name
              __typename
            }
            __typename
          }
          additionalData
          __typename
        }
        syncStatus
        lastSyncAt
        actionType
        __typename
      }
      __typename
    }
    isArchived
    isDeactivated
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetWorkflowByIdQueryVariables,
  APITypes.GetWorkflowByIdQuery
>;
export const getProcoreCompanies = /* GraphQL */ `query GetProcoreCompanies($query: QueryGetProcoreCompaniesQueryInput) {
  getProcoreCompanies(query: $query) {
    id
    text
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProcoreCompaniesQueryVariables,
  APITypes.GetProcoreCompaniesQuery
>;
export const getProcoreProjects = /* GraphQL */ `query GetProcoreProjects($query: QueryGetProcoreProjectsQueryInput) {
  getProcoreProjects(query: $query) {
    id
    text
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProcoreProjectsQueryVariables,
  APITypes.GetProcoreProjectsQuery
>;
export const getProcoreFolders = /* GraphQL */ `query GetProcoreFolders(
  $params: QueryGetProcoreFoldersParamsInput!
  $query: QueryGetProcoreFoldersQueryInput
) {
  getProcoreFolders(params: $params, query: $query) {
    id
    text
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProcoreFoldersQueryVariables,
  APITypes.GetProcoreFoldersQuery
>;
export const getProcoreSelectedFolderIdStack = /* GraphQL */ `query GetProcoreSelectedFolderIdStack(
  $params: QueryGetProcoreSelectedFolderIdStackParamsInput!
  $query: QueryGetProcoreSelectedFolderIdStackQueryInput
) {
  getProcoreSelectedFolderIdStack(params: $params, query: $query)
}
` as GeneratedQuery<
  APITypes.GetProcoreSelectedFolderIdStackQueryVariables,
  APITypes.GetProcoreSelectedFolderIdStackQuery
>;
export const getProcoreFolderName = /* GraphQL */ `query GetProcoreFolderName($query: QueryGetProcoreFolderNameQueryInput) {
  getProcoreFolderName(query: $query) {
    success
    name
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProcoreFolderNameQueryVariables,
  APITypes.GetProcoreFolderNameQuery
>;
export const getAutodeskFieldProjects = /* GraphQL */ `query GetAutodeskFieldProjects(
  $query: QueryGetAutodeskFieldProjectsQueryInput
) {
  getAutodeskFieldProjects(query: $query) {
    id
    text
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAutodeskFieldProjectsQueryVariables,
  APITypes.GetAutodeskFieldProjectsQuery
>;
export const getAutodeskFieldFolders = /* GraphQL */ `query GetAutodeskFieldFolders($query: QueryGetAutodeskFieldFoldersQueryInput) {
  getAutodeskFieldFolders(query: $query) {
    id
    text
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAutodeskFieldFoldersQueryVariables,
  APITypes.GetAutodeskFieldFoldersQuery
>;
export const getAutodeskFieldFolderName = /* GraphQL */ `query GetAutodeskFieldFolderName(
  $query: QueryGetAutodeskFieldFolderNameQueryInput
) {
  getAutodeskFieldFolderName(query: $query) {
    success
    name
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAutodeskFieldFolderNameQueryVariables,
  APITypes.GetAutodeskFieldFolderNameQuery
>;
export const getAutodeskForgeHubs = /* GraphQL */ `query GetAutodeskForgeHubs($query: QueryGetAutodeskForgeHubsQueryInput) {
  getAutodeskForgeHubs(query: $query) {
    id
    text
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAutodeskForgeHubsQueryVariables,
  APITypes.GetAutodeskForgeHubsQuery
>;
export const getAutodeskForgeProjects = /* GraphQL */ `query GetAutodeskForgeProjects(
  $query: QueryGetAutodeskForgeProjectsQueryInput
) {
  getAutodeskForgeProjects(query: $query) {
    id
    text
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAutodeskForgeProjectsQueryVariables,
  APITypes.GetAutodeskForgeProjectsQuery
>;
export const getAutodeskForgeSubItems = /* GraphQL */ `query GetAutodeskForgeSubItems(
  $query: QueryGetAutodeskForgeSubItemsQueryInput
) {
  getAutodeskForgeSubItems(query: $query) {
    id
    text
    isFolder
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAutodeskForgeSubItemsQueryVariables,
  APITypes.GetAutodeskForgeSubItemsQuery
>;
export const getAutodeskForgeItemName = /* GraphQL */ `query GetAutodeskForgeItemName(
  $query: QueryGetAutodeskForgeItemNameQueryInput
) {
  getAutodeskForgeItemName(query: $query) {
    success
    name
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAutodeskForgeItemNameQueryVariables,
  APITypes.GetAutodeskForgeItemNameQuery
>;
export const getAutodeskForgeSelectedSubItemIdStack = /* GraphQL */ `query GetAutodeskForgeSelectedSubItemIdStack(
  $query: QueryGetAutodeskForgeSelectedSubItemIdStackQueryInput
) {
  getAutodeskForgeSelectedSubItemIdStack(query: $query)
}
` as GeneratedQuery<
  APITypes.GetAutodeskForgeSelectedSubItemIdStackQueryVariables,
  APITypes.GetAutodeskForgeSelectedSubItemIdStackQuery
>;
export const syncGetWorkflowMappingConfiguration = /* GraphQL */ `query SyncGetWorkflowMappingConfiguration(
  $params: QuerySyncGetWorkflowMappingConfigurationParamsInput!
) {
  syncGetWorkflowMappingConfiguration(params: $params) {
    workflowId
    workflowName
    mapping {
      mappingId
      mappingName
      canvasNode
      isSyncRunning
      lastSyncAt
      lastSavedAt
      isArchived
      isDeactivated
      solitaryFlowConfiguration {
        solitaryFlowId
        action
        actionNodeId
        actionAlias
        isDeactivated
        isArchived
        source {
          syncDetailsId
          nodeId
          connection {
            id
            name
            type
            configuration
            isConnectionUsed
            lastModified
            desktopConnectionId
            __typename
          }
          hub {
            id
            name
            __typename
          }
          projects {
            id
            name
            folders {
              id
              name
              items {
                id
                name
                __typename
              }
              __typename
            }
            __typename
          }
          additionalData
          settings {
            isSyncEnabled
            includeExtensions
            secondaryFilesSetting
            isSyncAllRevisions
            syncType
            scheduleSyncConfig
            __typename
          }
          __typename
        }
        destinations {
          syncDetailsId
          nodeId
          connection {
            id
            name
            type
            configuration
            isConnectionUsed
            lastModified
            desktopConnectionId
            __typename
          }
          hub {
            id
            name
            __typename
          }
          project {
            id
            name
            folder {
              id
              name
              __typename
            }
            __typename
          }
          additionalData
          __typename
        }
        __typename
      }
      solitaryFlowPublishModelConfiguration {
        solitaryFlowId
        action
        actionNodeId
        actionAlias
        syncStatus
        lastSyncAt
        isDeactivated
        isArchived
        source {
          syncDetailsId
          nodeId
          connection {
            id
            name
            type
            configuration
            isConnectionUsed
            lastModified
            desktopConnectionId
            __typename
          }
          hub {
            id
            name
            __typename
          }
          projects {
            id
            name
            folders {
              id
              name
              items {
                id
                name
                __typename
              }
              __typename
            }
            __typename
          }
          additionalData
          settings {
            isSyncEnabled
            includeExtensions
            secondaryFilesSetting
            isSyncAllRevisions
            syncType
            scheduleSyncConfig
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncGetWorkflowMappingConfigurationQueryVariables,
  APITypes.SyncGetWorkflowMappingConfigurationQuery
>;
export const syncGetWorkflowMappingTemplatesList = /* GraphQL */ `query SyncGetWorkflowMappingTemplatesList(
  $query: QuerySyncGetWorkflowMappingTemplatesListQueryInput
) {
  syncGetWorkflowMappingTemplatesList(query: $query) {
    templateId
    templateName
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncGetWorkflowMappingTemplatesListQueryVariables,
  APITypes.SyncGetWorkflowMappingTemplatesListQuery
>;
export const syncGetConnectionById = /* GraphQL */ `query SyncGetConnectionById($params: QuerySyncGetConnectionByIdParamsInput!) {
  syncGetConnectionById(params: $params) {
    id
    name
    type
    configuration
    isConnectionUsed
    lastModified
    desktopConnectionId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncGetConnectionByIdQueryVariables,
  APITypes.SyncGetConnectionByIdQuery
>;
export const syncLogs = /* GraphQL */ `query SyncLogs($query: QuerySyncLogsQueryInput) {
  syncLogs(query: $query) {
    workflowName
    workflowId
    totalFiles
    successfulFilesCount
    mappings {
      mappingName
      mappingId
      totalFiles
      successfulFilesCount
      solitaryFlows {
        fileSyncLogId
        source {
          name
          type
          __typename
        }
        syncStatus
        syncType
        totalFiles
        totalSize
        successfulFilesCount
        actionAlias
        destinations {
          name
          type
          __typename
        }
        lastSyncTime
        isContactSupportTeam
        __typename
      }
      publishModelSolitaryFlows {
        fileSyncLogId
        source {
          name
          type
          __typename
        }
        syncStatus
        syncType
        totalFiles
        totalSize
        successfulFilesCount
        actionAlias
        destinations {
          name
          type
          __typename
        }
        lastSyncTime
        isContactSupportTeam
        __typename
      }
      issueManagementSolitaryFlows {
        fileSyncLogId
        source {
          name
          type
          __typename
        }
        syncStatus
        syncType
        totalFiles
        totalSize
        successfulFilesCount
        actionAlias
        destinations {
          name
          type
          __typename
        }
        lastSyncTime
        isContactSupportTeam
        __typename
      }
      lastSyncTime
      __typename
    }
    lastSyncTime
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncLogsQueryVariables, APITypes.SyncLogsQuery>;
export const syncRealTimeLogs = /* GraphQL */ `query SyncRealTimeLogs($query: QuerySyncRealTimeLogsQueryInput) {
  syncRealTimeLogs(query: $query) {
    date
    files {
      fileName
      fileSyncTime
      solitaryFlows {
        workflowId
        workflowName
        mappingId
        mappingName
        actionAlias
        source
        sourcePath
        destinations {
          fileSyncLogId
          destination
          destinationPath
          syncStatus
          isContactSupportTeam
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncRealTimeLogsQueryVariables,
  APITypes.SyncRealTimeLogsQuery
>;
export const syncLogFileDetails = /* GraphQL */ `query SyncLogFileDetails($query: QuerySyncLogFileDetailsQueryInput) {
  syncLogFileDetails(query: $query)
}
` as GeneratedQuery<
  APITypes.SyncLogFileDetailsQueryVariables,
  APITypes.SyncLogFileDetailsQuery
>;
export const syncLogFileDetailsWithCount = /* GraphQL */ `query SyncLogFileDetailsWithCount(
  $query: QuerySyncLogFileDetailsWithCountQueryInput
) {
  syncLogFileDetailsWithCount(query: $query) {
    data
    entireCount
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncLogFileDetailsWithCountQueryVariables,
  APITypes.SyncLogFileDetailsWithCountQuery
>;
export const syncLogActionDetails = /* GraphQL */ `query SyncLogActionDetails($query: QuerySyncLogActionDetailsQueryInput) {
  syncLogActionDetails(query: $query) {
    workflowId
    workflowName
    mappingId
    mappingName
    actionAlias
    syncStatus
    data {
      destinations {
        syncFlowMetricId
        name
        terminalStatus
        publishModelSuccess
        publishModelMessage
        issueManagementCount
        numFiles
        totalSize
        __typename
      }
      isComplete
      isPublishModel
      isIssueManagement
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncLogActionDetailsQueryVariables,
  APITypes.SyncLogActionDetailsQuery
>;
export const syncLogActionFolderDetails = /* GraphQL */ `query SyncLogActionFolderDetails(
  $query: QuerySyncLogActionFolderDetailsQueryInput
) {
  syncLogActionFolderDetails(query: $query) {
    syncFolderMetricId
    name
    numFiles
    numSubFolders
    terminalStatus
    removeFilesNotInSourceFailed
    removeSubFoldersNotInSourceFailed
    skippedFilesDueToLimit
    skippedSubFoldersDueToDepth
    totalSize
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncLogActionFolderDetailsQueryVariables,
  APITypes.SyncLogActionFolderDetailsQuery
>;
export const syncLogActionFileDetails = /* GraphQL */ `query SyncLogActionFileDetails(
  $query: QuerySyncLogActionFileDetailsQueryInput
) {
  syncLogActionFileDetails(query: $query) {
    name
    fileSyncDate
    terminalStatus
    totalSize
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncLogActionFileDetailsQueryVariables,
  APITypes.SyncLogActionFileDetailsQuery
>;
export const getAutodeskGlueProjects = /* GraphQL */ `query GetAutodeskGlueProjects($query: QueryGetAutodeskGlueProjectsQueryInput) {
  getAutodeskGlueProjects(query: $query) {
    id
    text
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAutodeskGlueProjectsQueryVariables,
  APITypes.GetAutodeskGlueProjectsQuery
>;
export const getAutodeskGlueFolders = /* GraphQL */ `query GetAutodeskGlueFolders($query: QueryGetAutodeskGlueFoldersQueryInput) {
  getAutodeskGlueFolders(query: $query) {
    id
    text
    isFolder
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAutodeskGlueFoldersQueryVariables,
  APITypes.GetAutodeskGlueFoldersQuery
>;
export const getAutodeskGlueSelectedFolderIdStack = /* GraphQL */ `query GetAutodeskGlueSelectedFolderIdStack(
  $query: QueryGetAutodeskGlueSelectedFolderIdStackQueryInput
) {
  getAutodeskGlueSelectedFolderIdStack(query: $query)
}
` as GeneratedQuery<
  APITypes.GetAutodeskGlueSelectedFolderIdStackQueryVariables,
  APITypes.GetAutodeskGlueSelectedFolderIdStackQuery
>;
export const getAutodeskGlueFolderName = /* GraphQL */ `query GetAutodeskGlueFolderName(
  $query: QueryGetAutodeskGlueFolderNameQueryInput
) {
  getAutodeskGlueFolderName(query: $query) {
    name
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAutodeskGlueFolderNameQueryVariables,
  APITypes.GetAutodeskGlueFolderNameQuery
>;
export const getSharepointSites = /* GraphQL */ `query GetSharepointSites($query: QueryGetSharepointSitesQueryInput) {
  getSharepointSites(query: $query) {
    id
    text
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSharepointSitesQueryVariables,
  APITypes.GetSharepointSitesQuery
>;
export const getSharepointFolders = /* GraphQL */ `query GetSharepointFolders($query: QueryGetSharepointFoldersQueryInput) {
  getSharepointFolders(query: $query) {
    id
    text
    isFolder
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSharepointFoldersQueryVariables,
  APITypes.GetSharepointFoldersQuery
>;
export const getSharepointSelectedFolderIdStack = /* GraphQL */ `query GetSharepointSelectedFolderIdStack(
  $query: QueryGetSharepointSelectedFolderIdStackQueryInput
) {
  getSharepointSelectedFolderIdStack(query: $query)
}
` as GeneratedQuery<
  APITypes.GetSharepointSelectedFolderIdStackQueryVariables,
  APITypes.GetSharepointSelectedFolderIdStackQuery
>;
export const getSharepointFolderName = /* GraphQL */ `query GetSharepointFolderName($query: QueryGetSharepointFolderNameQueryInput) {
  getSharepointFolderName(query: $query) {
    success
    name
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSharepointFolderNameQueryVariables,
  APITypes.GetSharepointFolderNameQuery
>;
export const getNewformaProjects = /* GraphQL */ `query GetNewformaProjects($query: QueryGetNewformaProjectsQueryInput) {
  getNewformaProjects(query: $query) {
    id
    text
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetNewformaProjectsQueryVariables,
  APITypes.GetNewformaProjectsQuery
>;
export const getNewformaFolders = /* GraphQL */ `query GetNewformaFolders($query: QueryGetNewformaFoldersQueryInput) {
  getNewformaFolders(query: $query) {
    id
    text
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetNewformaFoldersQueryVariables,
  APITypes.GetNewformaFoldersQuery
>;
export const getNewformaSelectedFolderIdStack = /* GraphQL */ `query GetNewformaSelectedFolderIdStack(
  $query: QueryGetNewformaSelectedFolderIdStackQueryInput
) {
  getNewformaSelectedFolderIdStack(query: $query)
}
` as GeneratedQuery<
  APITypes.GetNewformaSelectedFolderIdStackQueryVariables,
  APITypes.GetNewformaSelectedFolderIdStackQuery
>;
export const getNewformaFolderName = /* GraphQL */ `query GetNewformaFolderName($query: QueryGetNewformaFolderNameQueryInput) {
  getNewformaFolderName(query: $query) {
    success
    name
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetNewformaFolderNameQueryVariables,
  APITypes.GetNewformaFolderNameQuery
>;
export const getPlangridProjects = /* GraphQL */ `query GetPlangridProjects($query: QueryGetPlangridProjectsQueryInput) {
  getPlangridProjects(query: $query) {
    id
    text
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPlangridProjectsQueryVariables,
  APITypes.GetPlangridProjectsQuery
>;
export const getPlangridFolders = /* GraphQL */ `query GetPlangridFolders($query: QueryGetPlangridFoldersQueryInput) {
  getPlangridFolders(query: $query) {
    id
    text
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPlangridFoldersQueryVariables,
  APITypes.GetPlangridFoldersQuery
>;
export const getPlangridSelectedFolderIdStack = /* GraphQL */ `query GetPlangridSelectedFolderIdStack(
  $query: QueryGetPlangridSelectedFolderIdStackQueryInput
) {
  getPlangridSelectedFolderIdStack(query: $query)
}
` as GeneratedQuery<
  APITypes.GetPlangridSelectedFolderIdStackQueryVariables,
  APITypes.GetPlangridSelectedFolderIdStackQuery
>;
export const getPlangridFolderName = /* GraphQL */ `query GetPlangridFolderName($query: QueryGetPlangridFolderNameQueryInput) {
  getPlangridFolderName(query: $query) {
    success
    name
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPlangridFolderNameQueryVariables,
  APITypes.GetPlangridFolderNameQuery
>;
export const syncGetWorkflowMappingSyncStatus = /* GraphQL */ `query SyncGetWorkflowMappingSyncStatus(
  $query: QuerySyncGetWorkflowMappingSyncStatusQueryInput
) {
  syncGetWorkflowMappingSyncStatus(query: $query) {
    isSyncRunning
    lastSyncAt
    solitaryFlows {
      id
      actionNodeId
      syncStatus
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncGetWorkflowMappingSyncStatusQueryVariables,
  APITypes.SyncGetWorkflowMappingSyncStatusQuery
>;
export const syncFetchForesiteCompanies = /* GraphQL */ `query SyncFetchForesiteCompanies(
  $query: QuerySyncFetchForesiteCompaniesQueryInput
) {
  syncFetchForesiteCompanies(query: $query) {
    id
    text
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncFetchForesiteCompaniesQueryVariables,
  APITypes.SyncFetchForesiteCompaniesQuery
>;
export const syncFetchForesiteProjects = /* GraphQL */ `query SyncFetchForesiteProjects(
  $query: QuerySyncFetchForesiteProjectsQueryInput
) {
  syncFetchForesiteProjects(query: $query) {
    id
    text
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncFetchForesiteProjectsQueryVariables,
  APITypes.SyncFetchForesiteProjectsQuery
>;
export const syncFetchForesiteFolders = /* GraphQL */ `query SyncFetchForesiteFolders(
  $query: QuerySyncFetchForesiteFoldersQueryInput
) {
  syncFetchForesiteFolders(query: $query) {
    id
    text
    isFolder
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncFetchForesiteFoldersQueryVariables,
  APITypes.SyncFetchForesiteFoldersQuery
>;
export const syncFetchForesiteSelectedFolderIdStack = /* GraphQL */ `query SyncFetchForesiteSelectedFolderIdStack(
  $query: QuerySyncFetchForesiteSelectedFolderIdStackQueryInput
) {
  syncFetchForesiteSelectedFolderIdStack(query: $query)
}
` as GeneratedQuery<
  APITypes.SyncFetchForesiteSelectedFolderIdStackQueryVariables,
  APITypes.SyncFetchForesiteSelectedFolderIdStackQuery
>;
export const syncGetWindowsFolders = /* GraphQL */ `query SyncGetWindowsFolders($query: QuerySyncGetWindowsFoldersQueryInput) {
  syncGetWindowsFolders(query: $query) {
    id
    text
    isFolder
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncGetWindowsFoldersQueryVariables,
  APITypes.SyncGetWindowsFoldersQuery
>;
export const syncValidateName = /* GraphQL */ `query SyncValidateName(
  $params: QuerySyncValidateNameParamsInput!
  $query: QuerySyncValidateNameQueryInput
) {
  syncValidateName(params: $params, query: $query)
}
` as GeneratedQuery<
  APITypes.SyncValidateNameQueryVariables,
  APITypes.SyncValidateNameQuery
>;
export const syncRecommendedScheduleTimes = /* GraphQL */ `query SyncRecommendedScheduleTimes(
  $query: QuerySyncRecommendedScheduleTimesQueryInput
) {
  syncRecommendedScheduleTimes(query: $query)
}
` as GeneratedQuery<
  APITypes.SyncRecommendedScheduleTimesQueryVariables,
  APITypes.SyncRecommendedScheduleTimesQuery
>;
