import React from 'react';

import { Autocomplete, TextField } from '@mui/material';

import { Custom } from 'components/Icons';
import { getMicroFeContainer } from 'helpers/domHelpers';

import AutoCompleteGroup from './AutoCompleteGroup';
import AutocompleteOption from './AutocompleteOption';
import AutocompleteTag from './AutocompleteTag';

const styles = {
  listbox: { sx: { height: '230px' } },
  componentProps: {
    popper: {
      placement: 'right',
      modifiers: [
        {
          name: 'offset',
          options: { offset: [50, 10] }
        }
      ],
      container: getMicroFeContainer
    }
  }
};

const AutoComplete = ({
  expandedFilterGroups,
  filters,
  defaultFilters,
  selectFilterHandler,
  selectAllFiltersHandler,
  onFilterGroupTransition,
  filterGroups,
  selectFilterGroupHandler,
  options,
  onClear
}) => {
  const renderAutocompleteGroup = ({ group, children, key }) => (
    <AutoCompleteGroup
      id={key}
      group={group}
      expandedFilterGroups={expandedFilterGroups}
      filters={filters}
      defaultFilters={defaultFilters}
      selectAllFiltersHandler={selectAllFiltersHandler}
      onFilterGroupTransition={onFilterGroupTransition}
      filterGroups={filterGroups}
      selectFilterGroupHandler={selectFilterGroupHandler}
    >
      {children}
    </AutoCompleteGroup>
  );

  const renderAutocompleteOption = (props, { name }) => (
    <AutocompleteOption
      optionProps={props}
      labelName={name}
      filters={filters}
      onClick={(e) => selectFilterHandler(e, name)}
    />
  );

  const renderAutocompleteTags = (options) => <AutocompleteTag options={options} />;

  const renderAutocompleteInput = (params) => (
    <TextField {...params} label="Documents" placeholder="Filetypes" color="secondary" />
  );

  return (
    <Autocomplete
      multiple
      fullWidth
      freeSolo
      disableCloseOnSelect
      size="small"
      id="multiple-limit-tags"
      value={[...filters]}
      options={options}
      filterOptions={(options, { inputValue }) =>
        options.filter(({ name }) => name.toLowerCase().includes(inputValue.toLowerCase()))
      }
      ListboxProps={styles.listbox}
      componentsProps={{ popper: styles.componentProps.popper }}
      getOptionLabel={({ name }) => name}
      groupBy={({ type }) => type}
      clearIcon={<Custom.Clear onClick={onClear} />}
      renderGroup={renderAutocompleteGroup}
      renderOption={renderAutocompleteOption}
      renderTags={renderAutocompleteTags}
      renderInput={renderAutocompleteInput}
      sx={{ marginTop: '4px' }}
    />
  );
};

export default AutoComplete;
