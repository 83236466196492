import React, { useEffect } from 'react';

import { Box, ListItemButton, ListItemText, Stack } from '@mui/material';
import { useStoreState } from 'react-flow-renderer';

import DraggableBox from 'components/DraggableComponents/DraggableBox';
import { Custom, IconStyled } from 'components/Icons';
import ListFlyoutPaper from 'components/ListFlyoutPaper';
import NavItemTooltip from 'components/Tooltip/NavItemTooltip';
import { ACTIONS_DRAWER, CANVAS_NODE_TYPES, CANVAS_VALIDATIONS } from 'modules/Sync/Constants/constants';
import { useSyncWorkflowEditorContext } from 'modules/Sync/WorkflowEditor/Contexts/WorkflowEditorContext';
import { onDrag, onDragStart, onDragEnd } from 'modules/Sync/WorkflowEditor/Drawers/dragDropHelpers';
import {
  actionDrawerStyles,
  StyledActionDnDNode,
  StyledActionNodeThumbnailContainer
} from 'modules/Sync/WorkflowEditor/Drawers/drawer-styles';
import { actionsMapper } from 'modules/Sync/WorkflowEditor/Drawers/node-mappers';
import StyledPopup from 'modules/Sync/WorkflowEditor/Drawers/StyledPopup';
import { getSyncIcon } from 'modules/Sync/WorkflowEditor/helpers/SyncIcons';
import { syncColors } from 'styles/theme/colors';

const { MIN_CONNECTOR_NODES_REQUIRED_FOR_ACTION, MIN_CONNECTOR_NODES_REQUIRED_FOR_PUBLISH_ACTION } = CANVAS_VALIDATIONS;

const actionsData = actionsMapper(ACTIONS_DRAWER);

const ActionsDrawer = ({ onClose, draggable }) => {
  const onCloseHandler = () => onClose('', false);
  return (
    <DraggableBox draggable={draggable} handle=".handle" sx={{ zIndex: 'auto' }}>
      <StyledPopup onClose={onCloseHandler} sx={{ width: 280, height: '88%', bottom: 4 }}>
        <Box className="handle" sx={{ cursor: 'move' }}>
          <ListFlyoutPaper.Title textHelp="Drag items to add to workflow">Actions</ListFlyoutPaper.Title>
        </Box>
        <ListFlyoutPaper.Items sx={{ overflowY: 'auto', mb: 2, height: '100%', mt: 2 }}>
          {!!actionsData?.length && (
            <Stack
              sx={{
                width: '98%',
                height: '100%',
                overflowY: 'overlay',
                scrollbarColor: 'red yellow',
                marginBottom: 0,
                'span:last-child .dndnode': { mb: 0 }
              }}
            >
              {actionsData?.map((data) => (
                <ActionType key={data?.actionName} data={data} onDragStop={onCloseHandler} />
              ))}
            </Stack>
          )}
        </ListFlyoutPaper.Items>
      </StyledPopup>
    </DraggableBox>
  );
};

const ActionType = ({ data, onDragStop }) => <DndActionTaskType data={data} onDragStop={onDragStop} />;

const getDisabledStatus = (nodes, validations, minConnectorNode) => {
  const connectors = nodes.filter(({ type }) => type === 'connectorType');
  const availableConnectionTypes = [...new Set(connectors.map(({ data }) => data?.connectionType))];

  const requiredConnections = validations?.requiredConnections
    ? !validations?.requiredConnections?.every((connection) => availableConnectionTypes.includes(connection))
    : false;

  const minRequiredConnections = validations?.minRequiredConnections
    ? !availableConnectionTypes.some((type) => validations?.minRequiredConnections.includes(type))
    : false;

  const disabledStatus = connectors.length < minConnectorNode || requiredConnections || minRequiredConnections;

  return disabledStatus;
};

const DndActionTaskType = ({ data }) => {
  const queryParameters = new URLSearchParams(window.location.search);
  const pathname = queryParameters.get('isArchived');
  const isArchived = pathname !== null;
  const { isCanvasUnlocked, setIsCanvasUnlocked, isInQueue } = useSyncWorkflowEditorContext();
  const nodes = useStoreState((store) => store.nodes);
  const prevNodes = React.useRef(nodes);
  const [isHovered, setIsHovered] = React.useState(false);
  const minConnectorNode =
    data.name !== 'Publish'
      ? `${MIN_CONNECTOR_NODES_REQUIRED_FOR_ACTION}`
      : `${MIN_CONNECTOR_NODES_REQUIRED_FOR_PUBLISH_ACTION}`;
  const disabled = getDisabledStatus(nodes, data?.validations, minConnectorNode) || isInQueue;
  let disableText =
    data.name !== 'Publish'
      ? `Min ${minConnectorNode} Connector Nodes are required for this action`
      : `Min ${minConnectorNode} one Autodesk Teams/Docs or HQ Connector Node is required for this action`;
  if (isInQueue) disableText = 'Actions are disabled while in queue';
  const dragDisabled = disabled || !isCanvasUnlocked;

  const onDragStartHandler = (event) => onDragStart(event, 'default', data, CANVAS_NODE_TYPES.ACTION);

  const onDragEndHandler = () => {
    if (nodes.length === prevNodes.current.length + 1) {
      prevNodes.current = nodes;
    }
    onDragEnd();
  };

  useEffect(() => {
    setIsCanvasUnlocked(true);
  }, [setIsCanvasUnlocked]);

  return (
    <NavItemTooltip
      title={
        (isArchived && 'Workflow/mapping/action(s) are archived') ||
        (disabled ? disableText : `${isCanvasUnlocked ? '' : 'Dragging nodes is disabled while canvas is locked'}`)
      }
    >
      <span>
        <ListItemButton
          disableGutters
          disabled={dragDisabled}
          sx={{ margin: 0, padding: 0, pl: '2%', width: '100%', justifyContent: 'space-between' }}
        >
          <IconStyled
            icon={<Custom.DragIndicator />}
            sx={{ position: 'absolute', left: 6, top: 12, opacity: isHovered ? 1 : 0 }}
          />
          <StyledActionDnDNode
            className="dndnode"
            draggable={!dragDisabled}
            onDragStart={onDragStartHandler}
            onDrag={onDrag}
            onDragEnd={onDragEndHandler}
            sx={{ cursor: dragDisabled ? '' : 'pointer', marginX: 2, width: '100%' }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <div style={{ ...actionDrawerStyles.flexrow }}>
              <StyledActionNodeThumbnailContainer>
                <div
                  style={{
                    ...actionDrawerStyles.actionThumbIcon,
                    backgroundColor: syncColors.canvasNodeColors.background
                  }}
                >
                  <IconStyled
                    icon={getSyncIcon(data?.icon)}
                    color={syncColors.canvasNodeColors.default}
                    sx={{ height: 20, width: 20, svg: { height: 20, width: 20 } }}
                  />
                </div>
              </StyledActionNodeThumbnailContainer>

              <ListItemText
                sx={actionDrawerStyles.actionTitleContainer}
                primary={
                  <div
                    style={{
                      ...actionDrawerStyles.actionTitle,
                      color: syncColors.canvasNodeColors.default
                    }}
                  >
                    {CANVAS_NODE_TYPES.ACTION}
                  </div>
                }
                secondary={
                  <div style={actionDrawerStyles.actionDetail}>
                    {data?.actionAlias || data?.name || data?.taskDetail}
                  </div>
                }
              />
            </div>
          </StyledActionDnDNode>
        </ListItemButton>
      </span>
    </NavItemTooltip>
  );
};

export default ActionsDrawer;
