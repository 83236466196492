import React, { useState } from 'react';

import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { syncConnections as SYNC_CONNECTIONS, syncConnectorsList as SYNC_CONNECTORS_LIST } from 'graphql/queries';
import { sortAlphabetically } from 'helpers/stringFunctions';

const SyncWorkflowEditorContext = React.createContext();

// eslint-disable-next-line max-lines-per-function
const SyncWorkflowEditorProvider = ({ children }) => {
  const [workflowElements, setWorkflowElements] = useState([]); /* Canvas Element */
  const [workflowMappings, setWorkflowMappings] = useState([]); /* Actions */
  const [currentMapping, setCurrentMapping] = useState({}); /* Active Workflow Mapping on canvas */
  const [canvasData, setCanvasData] = useState({}); /* WARNING: Directly used by FlowCanvas */
  const [actionId, setActionId] = useState();
  const [connectorAlias, setConnectorAlias] = useState('');
  const [actionAlias, setActionAlias] = useState('');
  const [isOpenActionsModal, setActionsModalVisibility] = useState(false);
  const [isOpenPublishModal, setPublishModalVisibility] = useState(false);
  const [isOpenAliasModal, setAliasModalVisibility] = useState(false);
  const [isOpenActionAliasModal, setActionAliasModalVisibility] = useState(false);
  const [isOpenTemplateModal, setTemplateModalVisibility] = useState(false);
  const [isOpenDeleteCanvasModal, setDeleteCanvasModalVisibility] = useState(false);
  const [isOpenCreateWorkflowModal, setCreateWorkflowModalVisibility] = useState(false);
  const [isOpenSyncConfigModal, setSyncConfigModalVisibility] = useState(false);
  const [isOpenGlobalSyncModal, setGlobalSyncModalVisibility] = useState(false);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [isSyncRunning, setIsSyncRunning] = useState(false);
  const [isInQueue, setIsInQueue] = useState(false);
  const [lastSavedAt, setLastSavedAt] = useState('');
  const [lastSyncAt, setLastSyncAt] = useState('');
  const [currentNodeStatus, setCurrentNodeStatus] = useState(false);
  const [isMappingDataSaved, setIsMappingDataSaved] = useState(true);
  const [isCanvasUnlocked, setIsCanvasUnlocked] = useState(true);
  const [isSetOpacity, setIsSetOpacity] = useState(false);
  const [filterType, setFilterType] = useState('');
  const [actionNodesInSync, setActionNodesInSync] = useState([]);
  const { id: mappingId } = useParams();
  const { data: connectionsList } = useQuery(gql(SYNC_CONNECTIONS), {
    fetchPolicy: 'no-cache',
    onCompleted: (connectionsList) => connectionsList?.syncConnections?.sort(sortAlphabetically)
  });
  const { data: allConnections } = useQuery(gql(SYNC_CONNECTORS_LIST));

  const showActionsModal = () => setActionsModalVisibility(true);
  const hideActionsModal = () => setActionsModalVisibility(false);
  const showPublishModal = () => setPublishModalVisibility(true);
  const hidePublishModal = () => setPublishModalVisibility(false);
  const showAliasModal = () => setAliasModalVisibility(true);
  const hideAliasModal = () => setAliasModalVisibility(false);
  const showActionAliasModal = () => setActionAliasModalVisibility(true);
  const hideActionAliasModal = () => setActionAliasModalVisibility(false);
  const showTemplateModal = () => setTemplateModalVisibility(true);
  const hideTemplateModal = () => setTemplateModalVisibility(false);
  const showDeleteCanvasModal = () => setDeleteCanvasModalVisibility(true);
  const hideDeleteCanvasModal = () => setDeleteCanvasModalVisibility(false);
  const showCreateWorkflowModal = () => setCreateWorkflowModalVisibility(true);
  const hideCreateWorkflowModal = () => setCreateWorkflowModalVisibility(false);
  const showSyncConfigModal = () => setSyncConfigModalVisibility(true);
  const hideSyncConfigModal = () => setSyncConfigModalVisibility(false);
  const showGlobalSyncModal = () => setGlobalSyncModalVisibility(true);
  const hideGlobalSyncModal = () => setGlobalSyncModalVisibility(false);

  const selectedStateObj = {
    workflowElements,
    setWorkflowElements,
    workflowMappings,
    setWorkflowMappings,
    connectionsList,
    allConnections,
    currentMapping,
    setCurrentMapping,
    canvasData,
    setCanvasData,
    actionId,
    setActionId,
    connectorAlias,
    setConnectorAlias,
    isOpenActionsModal,
    isOpenPublishModal,
    isOpenAliasModal,
    isOpenTemplateModal,
    showActionsModal,
    hideActionsModal,
    showPublishModal,
    hidePublishModal,
    showAliasModal,
    hideAliasModal,
    showTemplateModal,
    hideTemplateModal,
    reactFlowInstance,
    setReactFlowInstance,
    mappingId,
    isOpenDeleteCanvasModal,
    showDeleteCanvasModal,
    hideDeleteCanvasModal,
    isOpenCreateWorkflowModal,
    showCreateWorkflowModal,
    hideCreateWorkflowModal,
    isSyncRunning,
    setIsSyncRunning,
    isOpenSyncConfigModal,
    showSyncConfigModal,
    hideSyncConfigModal,
    isOpenGlobalSyncModal,
    showGlobalSyncModal,
    hideGlobalSyncModal,
    lastSavedAt,
    setLastSavedAt,
    lastSyncAt,
    setLastSyncAt,
    actionAlias,
    setActionAlias,
    isOpenActionAliasModal,
    showActionAliasModal,
    hideActionAliasModal,
    currentNodeStatus,
    setCurrentNodeStatus,
    isMappingDataSaved,
    setIsMappingDataSaved,
    isCanvasUnlocked,
    setIsCanvasUnlocked,
    isSetOpacity,
    setIsSetOpacity,
    filterType,
    setFilterType,
    actionNodesInSync,
    setActionNodesInSync,
    isInQueue,
    setIsInQueue
  };

  return <SyncWorkflowEditorContext.Provider value={selectedStateObj}>{children}</SyncWorkflowEditorContext.Provider>;
};

const useSyncWorkflowEditorContext = () => {
  const context = React.useContext(SyncWorkflowEditorContext);
  if (context === undefined) {
    throw new Error('useSyncWorkflowEditorContext must be used within a SyncWorkflowEditorProvider');
  }
  return context;
};

export { SyncWorkflowEditorContext, SyncWorkflowEditorProvider, useSyncWorkflowEditorContext };
