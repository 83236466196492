import { gql, useMutation } from '@apollo/client';
import { Typography } from '@mui/material';

import { syncActivateWorkflow as ACTIVATE_WORKFLOW } from 'graphql/mutations';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';
import CustomModal from 'modules/Sync/components/CustomModal';
import { WORKFLOW_MODAL_KEYS } from 'modules/Sync/Constants/constants';
import ModalFooter from 'modules/Sync/Workflows/components/ModalFooter';
import useWorkflowsLazyPaginationHook from 'modules/Sync/Workflows/Contexts/useWorkflowsLazyPaginationHook';
import { useSyncWorkflowsContext } from 'modules/Sync/Workflows/Contexts/WorkflowsContext';
import { actionMenusApiPayload } from 'modules/Sync/Workflows/WorkflowsDataGridTable/workflowsDataGridTableHelper';

const ActivateWorkflowModal = ({ onClose }) => {
  const { activeModal, selectedWorkflowIds, selectedMappingIds, selectedActionIds } = useSyncWorkflowsContext();
  const { workflows } = useWorkflowsLazyPaginationHook();
  const { handleResponse } = useGraphqlResponseHandler();

  const [activateWorkflow, { loading }] = useMutation(gql(ACTIVATE_WORKFLOW), {
    refetchQueries: ['SyncWorkflows']
  });

  const submit = async () => {
    const { selectedWorkflowIdsToSend, selectedMappingIdsToSend, selectedActionIdsToSend } = actionMenusApiPayload({
      selectedWorkflowIds,
      selectedMappingIds,
      selectedActionIds,
      workflows
    });
    await handleResponse(
      activateWorkflow,
      {
        variables: {
          body: {
            WorkflowIds: selectedWorkflowIdsToSend,
            MappingIds: selectedMappingIdsToSend,
            SolitaryFlowIds: selectedActionIdsToSend
          }
        }
      },
      { successMessage: 'Workflow/Mapping/Action(s) successfully activated' },
      onClose,
      onClose
    );
  };

  return (
    <CustomModal
      disableModalOutsideClick
      disableCloseButton={loading}
      open={activeModal === WORKFLOW_MODAL_KEYS.ACTIVATE_WORKFLOW}
      onClose={onClose}
      title="Confirm activate"
      reducePadding
      titleStyles={{ fontWeight: 'bold' }}
      maxWidth="false"
      paperSx={{ width: 600 }}
      footer={
        <ModalFooter
          cancelBtnText="NO"
          createBtnText="CONFIRM"
          loading={loading}
          onModalClose={onClose}
          onSubmit={submit}
        />
      }
    >
      <Typography variant="body1" mb="12px">
        Are you sure you want to activate selected workflow/mapping/action(s)?
      </Typography>
    </CustomModal>
  );
};

export default ActivateWorkflowModal;
