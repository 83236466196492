import React from 'react';

import { Box, Stack, Typography } from '@mui/material';

import { Custom, IconStyled } from 'components/Icons';
import NavItemTooltip from 'components/Tooltip/NavItemTooltip';
import CustomSyncTooltip from 'modules/Sync/components/CustomSyncTooltip';
import { SyncStatusBackground, SyncStatusLabel } from 'modules/Sync/components/SyncStatusHig';
import { actionColors } from 'styles/theme/colors';

const TOOLTIP_STYLES = { width: '100%', display: 'inline' };

const syncStatusIndicator = (status, sx) => {
  const title = SyncStatusLabel(status);
  const backgroundColor = SyncStatusBackground(status);
  return (
    <NavItemTooltip title={title}>
      <Stack gap={1} direction="horizontal" display="flex" alignItems="center" sx={sx}>
        <Box
          sx={{
            height: 8,
            width: 8,
            borderRadius: '50%',
            justifyContent: 'right',
            backgroundColor
          }}
        />
      </Stack>
    </NavItemTooltip>
  );
};

const CellTooltip = ({ title, children, ...rest }) => (
  <CustomSyncTooltip title={title} tooltipSx={TOOLTIP_STYLES} noWrapper {...rest}>
    {children}
  </CustomSyncTooltip>
);

const RealTimeLogsDataGridTableCell = ({ field, row }) => {
  if (field === 'isContactSupportTeam' && row?.fileName) {
    return row[field] ? '' : <Custom.Mail />;
  }

  if (field === 'name') {
    return (
      <Stack
        spacing={1}
        direction="row"
        sx={{ '& .MuiTypography-body2': { display: 'flex' }, '& .action-details': { ml: 7 } }}
        width="100%"
      >
        {row?.date && (
          <Typography variant="body2">
            <IconStyled sx={{ ml: 0, mr: 1.5, alignItems: 'center', display: 'flex' }} icon={<Custom.Calendar />} />
            {row.name}
          </Typography>
        )}
        {row?.fileName && (
          <Typography variant="body2" ml={0}>
            <IconStyled
              sx={{ ml: 1.5, mr: 1.5, alignItems: 'center', display: 'flex' }}
              color={actionColors.lightSurface.active}
              icon={<Custom.AttachFile />}
            />
            <CellTooltip title={row.mappingName}>{row.name}</CellTooltip>
          </Typography>
        )}
        {row?.actionAlias && (
          <Typography variant="body2" ml={3}>
            <IconStyled
              sx={{ ml: 3, mr: 1.5, alignItems: 'center', display: 'flex' }}
              color={actionColors.lightSurface.active}
              icon={<Custom.Mapping />}
            />
            <CellTooltip title={row.mappingName}>{row.name}</CellTooltip>
          </Typography>
        )}
        {row?.isSource && (
          <Typography variant="body2" justifyContent="start" width="80%" className="action-details">
            <Stack gap={6} direction="row" width="100%" sx={{ '& div': { display: 'flex', width: '50%' } }}>
              <Box>
                <strong>Source:</strong>&nbsp;&nbsp;
                <CellTooltip title={row.source}>{row.source}</CellTooltip>
              </Box>
              <Box>
                <strong>Folder:</strong>&nbsp;&nbsp;
                <CellTooltip title={row.name}>{row.name}</CellTooltip>
              </Box>
            </Stack>
          </Typography>
        )}
        {row?.syncStatus && (
          <Typography variant="body2" justifyContent="start" width="80%" className="action-details">
            {syncStatusIndicator(row.syncStatus, { ml: -3, mr: 2 })}
            <Stack gap={6} direction="row" width="100%" sx={{ '& div': { display: 'flex', width: '50%' } }}>
              <Box>
                <strong>Destination: {row.serial}</strong>&nbsp;&nbsp;
                <CellTooltip title={row.destination}>{row.destination}</CellTooltip>
              </Box>
              <Box>
                <strong>Folder:</strong>&nbsp;&nbsp;
                <CellTooltip title={row.name}>{row.name}</CellTooltip>
              </Box>
            </Stack>
          </Typography>
        )}
      </Stack>
    );
  }

  return row[field] ? <CellTooltip title={row[field]}>{row[field]}</CellTooltip> : null;
};

export default RealTimeLogsDataGridTableCell;
