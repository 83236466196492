import { useEffect } from 'react';

const useSyncFilesHooks = ({
  currentFlowId,
  currentFlow,
  setValue,
  sourceScheduleSyncConfig = {}
}) => {
  const currentFlowScheduleSyncConfig = currentFlow?.scheduleSyncConfig || {};
  useEffect(() => {
    if (Object.keys(currentFlow || {})?.length) {
      const { syncType, isSyncEnabled, scheduleSyncConfig = {} } = currentFlow || {};
      if (typeof syncType !== 'undefined' && typeof isSyncEnabled !== 'undefined') {
        setValue('syncType', syncType);
        setValue('isSyncEnabled', isSyncEnabled);
      }
      Object.keys(scheduleSyncConfig).map((key) => setValue(key, scheduleSyncConfig[key]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFlowId]);

  /* For global sync config case  */
  useEffect(() => {
    Object.keys(currentFlowScheduleSyncConfig)?.forEach((key) => {
      setValue(key, currentFlowScheduleSyncConfig[key]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFlow]);

  /* For single flow (copy files action) case  */
  useEffect(() => {
    Object.keys(sourceScheduleSyncConfig)?.forEach((key) =>
      setValue(key, sourceScheduleSyncConfig[key])
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceScheduleSyncConfig]);
};

export default useSyncFilesHooks;
