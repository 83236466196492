import { useEffect } from 'react';

import { Box, CircularProgress, FormControlLabel, Grid, Stack, Tab, Tabs, Typography } from '@mui/material';
import SwitchComponent from '@mui/material/Switch';
import { useParams } from 'react-router-dom';

import { Custom } from 'components/Icons';
import { Columns } from 'components/ItemsDatagridPro';
import Search from 'components/TableComponents/Search';
import { DATAGRID_DEFAULT_PG_SIZE, SHOW_LOGS_SEARCH_BAR } from 'modules/Sync/Constants/constants';
import DownloadLogFile, { getLogFileName } from 'modules/Sync/Logs/components/DownloadLogFile';
import LogsDetailsToolbar from 'modules/Sync/Logs/LogsDetails/LogsDetailsHeader/LogsDetailsToolbar';
import RefreshLogsDetailsBtn from 'modules/Sync/Logs/LogsDetails/LogsDetailsHeader/RefreshLogsDetailsBtn';
import { surfaceColors, syncColors } from 'styles/theme/colors';

const columnSxProps = {
  typoProps: { color: syncColors.text },
  baseButtonSx: {
    '&:hover': { backgroundColor: syncColors.tableHover },
    '&:disabled': { color: 'action.lightSurface.disabled' },
    '.MuiTypography-root.MuiTypography-button': { color: syncColors.text },
    '.MuiSvgIcon-root': { mt: 0, color: syncColors.text },
    '.MuiStack-root': { alignItems: 'center' }
  },
  popperStackSx: {
    boxShadow:
      '0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12)'
  },
  buttonSx: {
    '& .MuiButton-root': { '&:hover': { backgroundColor: syncColors.tableHover } },
    '& .MuiTypography-button': { color: syncColors.text }
  }
};

const tabsSx = {
  width: '30%',
  '.Mui-selected': { color: `${syncColors.text} !important` },
  '.MuiTabs-indicator': { backgroundColor: syncColors.text }
};

const LogsSearchBar = ({ activeTab, searchHandler }) =>
  SHOW_LOGS_SEARCH_BAR && (
    <Search
      placeholder={`Search ${activeTab ? 'Log File...' : 'Folders…'}`}
      handleSearch={searchHandler}
      sx={{
        ml: 18,
        '& .MuiInputBase-root': { paddingLeft: 1 },
        visibility: activeTab ? 'inherit' : 'hidden'
      }}
      startAdornment={<Custom.Search style={{ fill: surfaceColors.lightSurface.secondary, marginRight: 10 }} />}
    />
  );

const LoadingIndicator = ({ loadingLogs, syncInProgress }) => (
  <Grid item alignItems="center" wrap="nowrap" justifyContent="center" display="flex" width="80%">
    {(loadingLogs || syncInProgress) && (
      <>
        <CircularProgress color="secondary" size={25} />
        <Typography variant="body2" sx={{ ml: 1, fontWeight: 400, letterSpacing: '0.15px' }}>
          {loadingLogs && 'Loading...'}
          {!loadingLogs && syncInProgress && 'Sync in progress...'}
        </Typography>
      </>
    )}
  </Grid>
);

const DebugSwitch = ({ onDebugToggle, debugging }) => (
  <Grid item>
    <FormControlLabel
      onChange={onDebugToggle}
      control={<SwitchComponent sx={{ color: syncColors.text }} color="warning" />}
      checked={debugging}
      label={
        <Typography variant="body1" sx={{ fontWeight: 400, letterSpacing: '0.15px' }}>
          Debug
        </Typography>
      }
    />
  </Grid>
);

const AutoRefreshSwitch = ({ onAutoRefreshFolderDetailsToggle, autoRefreshFolderDetails }) => (
  <Grid item>
    <FormControlLabel
      onChange={onAutoRefreshFolderDetailsToggle}
      control={<SwitchComponent sx={{ color: syncColors.text }} color="warning" />}
      checked={autoRefreshFolderDetails}
      label={
        <Typography variant="body1" sx={{ fontWeight: 400, letterSpacing: '0.15px', whiteSpace: 'nowrap' }}>
          Auto Refresh
        </Typography>
      }
      sx={{ mr: 0.5 }}
    />
  </Grid>
);

const LogDetailsHeader = ({
  activeTab,
  setActiveTab,
  searchHandler,
  debugHandler,
  autoRefreshFolderDetailsHandler,
  loadingLogs = false,
  syncInProgress = false,
  debugging,
  refetchExistingLogsDetailsPages,
  logDetails,
  loadLogActionDetails,
  logActionDetailsData,
  autoRefreshFolderDetails,
  expandedRows,
  apiRef
}) => {
  const { id: fileSyncLogId = '' } = useParams();
  const { workflowName, mappingName } = logActionDetailsData?.syncLogActionDetails || {};
  const fileName = getLogFileName({ workflowName, mappingName, fileSyncLogId });
  const downloadDisabled = !(fileSyncLogId && workflowName && mappingName);

  const onChangeHandler = (event, index) => {
    event.preventDefault();
    setActiveTab(index);
  };

  const onDebugToggle = (_, state) =>
    debugHandler(state, logDetails?.syncLogFileDetails?.length || DATAGRID_DEFAULT_PG_SIZE);

  const onAutoRefreshFolderDetailsToggle = (_, state) => autoRefreshFolderDetailsHandler(state);

  useEffect(() => {
    if (fileSyncLogId) {
      const fetchFoldersData = async () => {
        await loadLogActionDetails({ variables: { query: { fileSyncLogId } } });
      };
      fetchFoldersData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileSyncLogId]);

  return (
    <Box sx={{ marginBottom: 1 }}>
      <Stack direction="row" justifyContent="space-between">
        <LogsDetailsToolbar />
        <Tabs value={activeTab} onChange={onChangeHandler} variant="fullWidth" sx={tabsSx}>
          <StyledTab label="Folders" />
          <StyledTab label="Log File" />
        </Tabs>
        <LogsSearchBar activeTab={activeTab} searchHandler={searchHandler} />
      </Stack>
      <Grid container justifyContent="space-between" wrap="nowrap" alignItems="center" mt={2}>
        <LoadingIndicator loadingLogs={loadingLogs} syncInProgress={syncInProgress} />
        <Grid
          container
          item
          spacing={2}
          alignItems="center"
          marginRight={2}
          wrap="nowrap"
          justifyContent="flex-end"
          width="auto"
          sx={{ '& .MuiButtonBase-root ': { marginRight: 0 } }}
        >
          {!activeTab && (
            <>
              <Grid item>
                <Columns disableColumnSearch {...columnSxProps} />
              </Grid>
              <AutoRefreshSwitch
                onAutoRefreshFolderDetailsToggle={onAutoRefreshFolderDetailsToggle}
                autoRefreshFolderDetails={autoRefreshFolderDetails}
              />
            </>
          )}
          {!!activeTab && <DebugSwitch onDebugToggle={onDebugToggle} debugging={debugging} />}
          <Grid item>
            <RefreshLogsDetailsBtn
              logDetails={logDetails}
              activeTab={activeTab}
              debugging={debugging}
              refetchExistingLogsDetailsPages={refetchExistingLogsDetailsPages}
              loadLogActionDetails={loadLogActionDetails}
              logActionDetailsData={logActionDetailsData}
              apiRef={apiRef}
              expandedRows={expandedRows}
            />
          </Grid>
          <Grid item>
            <DownloadLogFile
              fileSyncLogId={fileSyncLogId}
              fileName={fileName}
              disabled={downloadDisabled}
              syncInProgress={syncInProgress}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LogDetailsHeader;

const StyledTab = ({ sx, ...rest }) => (
  <Tab
    disableRipple
    {...rest}
    sx={{
      color: 'secondary',
      marginRight: 1,
      fontWeight: 500,
      fontSize: 15,
      ...sx
    }}
  />
);
