import { useState } from 'react';

import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useForm, FormProvider, useFieldArray } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { FETCH_POLICIES } from 'constants/globalConstants';
import { syncWorkflowsImport as WORKFLOWS_IMPORT } from 'graphql/mutations';
import { getWorkflowById as GET_WORKFLOW_BY_ID } from 'graphql/queries';
import CustomModal from 'modules/Sync/components/CustomModal';
import CustomSyncTooltip from 'modules/Sync/components/CustomSyncTooltip';
import { useFetchWorkflowDataContext } from 'modules/Sync/Connections/components/FetchWorkflowDataContext';
import { useImportWorkflowsContext } from 'modules/Sync/Connections/components/ImportWorkflowsContext';
import { useSyncConnectionsContext } from 'modules/Sync/Connections/ConnectionsContext';
import ImportConnectionsForm from 'modules/Sync/Connections/Modals/ImportConnectionModal/ImportConnectionsForm';
import ModalFooter from 'modules/Sync/Connections/Modals/ImportConnectionModal/ModalFooter';
import { IMPORT_GLOBAL_WINDOWS_SELECT, ROUTES } from 'modules/Sync/Constants/constants';
import {
  getConnecorSubmitStatus,
  getSyncAllSubmitStatus,
  getSyncSubmitStatus
} from 'modules/Sync/helpers/importHelpers';

import useImportMethodsHooks from './useImportMethods';

const modalProps = {
  sx: { '& .MuiPaper-root': { overflowY: 'visible' } },
  contentStyles: { overflow: 'auto', width: 505, height: 709, padding: '17px' }
};

const { SYNC, WORKFLOWS } = ROUTES;

const ModalTitle = ({ actionAlias }) => (
  <Typography fontWeight={600} variant="body4">
    Import Connectors
    {actionAlias && (
      <Typography variant="body2">
        <CustomSyncTooltip title={`Action Alias: ${actionAlias}`} sx={{ maxWidth: 410 }}>
          {actionAlias}
        </CustomSyncTooltip>
      </Typography>
    )}
  </Typography>
);

const { fetchPolicy } = FETCH_POLICIES.NO_CACHE;

const ImportConnectionAction = ({ open, onClose }) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar() || {};
  const [activeStep, setActiveStep] = useState(0);
  const [error, setError] = useState('');

  const [importWorkflow, { loading: loadingWorkflows }] = useMutation(gql(WORKFLOWS_IMPORT));
  const [getWorkflowById] = useLazyQuery(gql(GET_WORKFLOW_BY_ID), { fetchPolicy });

  const { getProjectName, getCompanyName, getFolderName } = useFetchWorkflowDataContext();
  const { importedConnections, setImportConnectionOpen, setConfirmImportOpen } = useSyncConnectionsContext();
  const { setWorkflowData, workflowData, workflowSyncData, isWorkflowLoading } = useImportWorkflowsContext();
  const { isSyncAll, syncAllData, setIsWorkflowLoading, setIsSyncAll, resetContext } = useImportWorkflowsContext();
  const { connectionsData } = useSyncConnectionsContext();

  const methods = useForm({ mode: 'all', defaultValues: { workflowData } });

  const { control, setValue, getValues } = methods;

  const data = useFieldArray({ control, name: 'workflowData' });
  const { fields, replace } = data;

  const isStepDisabled = (step = activeStep) => step < 1;

  const getWorkflowDataMethods = { getProjectName, getCompanyName, getFolderName, getWorkflowById };

  const onModalClose = () => {
    onClose?.();
    setActiveStep(0);
    resetContext();
    setValue(IMPORT_GLOBAL_WINDOWS_SELECT, '');
  };

  const confirmModalHandler = async () => {
    setImportConnectionOpen(false);
    setConfirmImportOpen(true);
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    const totalMappings = workflowSyncData.reduce((acc, currentItem) => currentItem?.mappings.length + acc, 0);
    if (!totalMappings) return navigate(`${SYNC}${WORKFLOWS}`);

    return confirmModalHandler();
  };

  const onClickHandler = (step) => setActiveStep(step);

  const { saveWorkflows, handleConfigChange } = useImportMethodsHooks({
    setActiveStep,
    fields,
    enqueueSnackbar,
    importWorkflow,
    setWorkflowData,
    replace,
    setError,
    setIsWorkflowLoading,
    connectionsData,
    getWorkflowDataMethods,
    workflowData
  });

  const onNext = async () => {
    if (activeStep !== 1) return setActiveStep((state) => state + 1);
    const workflowData = getValues('workflowData');
    return saveWorkflows(workflowData);
  };

  const onBack = () => {
    setActiveStep((state) => state - 1);
    setIsSyncAll(true);
  };
  const onSkip = () => setActiveStep((state) => state + 1);

  const connectorsSubmitDisabled = getConnecorSubmitStatus(importedConnections);
  const workflowsSubmitDisabled = loadingWorkflows || !workflowData?.length || isWorkflowLoading;
  const disableSubmit = isSyncAll ? getSyncAllSubmitStatus(syncAllData) : getSyncSubmitStatus(workflowSyncData);

  return (
    <FormProvider {...methods}>
      <CustomModal
        disableModalOutsideClick
        draggable
        open={open}
        title={<ModalTitle actionAlias="Choose configuration file" />}
        onClose={onModalClose}
        footer={
          <ModalFooter
            onClose={onModalClose}
            onSubmit={onSubmit}
            onBack={onBack}
            onNext={onNext}
            onSkip={onSkip}
            activeStep={activeStep}
            connectorsSubmitDisabled={connectorsSubmitDisabled}
            workflowsSubmitDisabled={workflowsSubmitDisabled}
            disableSubmit={disableSubmit}
          />
        }
        {...modalProps}
      >
        <ImportConnectionsForm
          activeStep={activeStep}
          isStepDisabled={isStepDisabled}
          onClickHandler={onClickHandler}
          error={error}
          handleConfigChange={handleConfigChange}
          data={data}
        />
      </CustomModal>
    </FormProvider>
  );
};

const ImportConnectionsModal = (props) => <ImportConnectionAction {...props} />;

export default ImportConnectionsModal;
