import { gql } from '@apollo/client';

import client from 'apollo/client';
import { FETCH_POLICIES } from 'constants/globalConstants';
import { syncFetchForesiteFolders as SYNC_FETCH_FORESITE_FOLDERS } from 'graphql/queries';
import { DROPDOWN_DEFAULT_PG_SIZE } from 'modules/Sync/Constants/constants';
import { multiSelectFolders } from 'modules/Sync/WorkflowEditor/Forms/Shared/CopyFilesComponents/FoldersSelectDropdown/folderTreeItemHelper';

const {
  NO_CACHE: { fetchPolicy: NO_CACHE_FETCH_POLICY }
} = FETCH_POLICIES;

export const getForesiteSelectEventHandlers = (props) => {
  const { connectionId, projects, loadFolders, loadProjects, updateFormValues } = props;
  const { hub, folders, uiFolders, parentFolderId, setParentFolderId, project } = props;
  const selectCompanyHandler = (event, childElement) => {
    event.preventDefault();
    const hubId = event.target.value;
    updateFormValues({
      hub: { id: hubId, name: childElement?.props?.dataText },
      projects: [],
      project: { id: '', name: '' },
      folderType: '',
      folders: [],
      folder: { id: '', name: '' },
      isSubmitted: false
    });
    loadProjects({
      variables: { query: { connectionId, companyId: hubId } }
    });
  };

  const selectProjectsHandler = (event) => {
    const {
      target: { value }
    } = event;
    const lastValue = value[value.length - 1];
    const isValueString = typeof value === 'string';
    updateFormValues({
      projects: isValueString
        ? [{ id: value, name: projects?.find(({ id }) => id === value)?.text }]
        : value?.map((projectId) => ({
            id: projectId,
            name: projects?.find(({ id }) => id === projectId)?.text
          })),
      project: isValueString
        ? { id: value, name: projects?.find(({ id }) => id === value)?.text }
        : { id: lastValue, name: projects?.find(({ id }) => id === lastValue)?.text },
      folder: { id: '', name: '' },
      folders: [],
      isSubmitted: isValueString ? false : value.length >= 1
    });

    if (value.length || isValueString) {
      loadFolders({
        connectionId,
        companyId: hub?.id,
        projectId: isValueString ? value : value?.[0],
        skip: 0,
        take: DROPDOWN_DEFAULT_PG_SIZE
      });
    }
  };

  const selectFolderHandler = (event, id, children) =>
    multiSelectFolders({
      event,
      id,
      folders,
      uiFolders,
      children,
      parentFolderId,
      setParentFolderId,
      updateFormValues
    });

  const fetchForsiteFolders = async ({
    parentFolderId,
    fetchPolicy = NO_CACHE_FETCH_POLICY,
    skip = 0,
    take = DROPDOWN_DEFAULT_PG_SIZE
  }) => {
    const { data } = await client.query({
      query: gql(SYNC_FETCH_FORESITE_FOLDERS),
      variables: {
        query: {
          companyId: hub?.id,
          projectId: project?.id,
          parentFolderId,
          connectionId,
          skip,
          take
        }
      },
      fetchPolicy
    });
    const response = data?.syncFetchForesiteFolders || [];
    return response;
  };

  return {
    selectCompanyHandler,
    selectProjectsHandler,
    selectFolderHandler,
    fetchForsiteFolders
  };
};
