import React from 'react';

import { useMutation, gql } from '@apollo/client';
import { Typography } from '@mui/material';

import { syncActivateWebhook as ACTIVATE_WEBHOOK } from 'graphql/mutations';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';
import CustomModal from 'modules/Sync/components/CustomModal';
import { WEBHOOK_MODAL_KEYS } from 'modules/Sync/Constants/constants';
import { useSyncWebhooksContext } from 'modules/Sync/Webhooks/Contexts/WebhooksContext';

import ModalFooter from './ModalFooter';

const ActivateWebhookModal = ({ onClose }) => {
  const { activeModal, selectedWebhookIds, setSelectedWebhookIds } = useSyncWebhooksContext();
  const { handleResponse } = useGraphqlResponseHandler();

  const [activateWebhook, { loading }] = useMutation(gql(ACTIVATE_WEBHOOK), {
    refetchQueries: ['SyncWebhooks']
  });
  const submit = async () => {
    await handleResponse(
      activateWebhook,
      {
        variables: { body: { WebhookIds: selectedWebhookIds } }
      },
      { successMessage: 'Webhook(s) successfully activated' }
    );
    onClose();
    setSelectedWebhookIds([]);
  };

  return (
    <CustomModal
      disableModalOutsideClick
      disableCloseButton={loading}
      open={activeModal === WEBHOOK_MODAL_KEYS.ACTIVATE_WEBHOOK}
      onClose={onClose}
      title="Confirm Activate"
      reducePadding
      titleStyles={{ fontWeight: 'bold' }}
      maxWidth="false"
      paperSx={{ width: 550 }}
      footer={
        <ModalFooter
          cancelBtnText="NO"
          createBtnText="CONFIRM"
          loading={loading}
          onModalClose={onClose}
          onSubmit={submit}
        />
      }
    >
      <Typography mb={1}>Are you sure you want to activate selected webhook(s)</Typography>
    </CustomModal>
  );
};
export default ActivateWebhookModal;
