import { useMemo } from 'react';

import { getTimeZone } from 'helpers/dateFunctions';
import OutlinedButton from 'modules/Sync/components/OutlinedButton';
import { useSyncLogsContext } from 'modules/Sync/Logs/Contexts/LogsContext';
import useLogsLazyPaginationHook from 'modules/Sync/Logs/Contexts/useLogsLazyPaginationHook';
import useRealtimeLogsLazyPaginationHook from 'modules/Sync/Logs/Contexts/useRealtimeLogsLazyPaginationHook';

const RefreshLogsBtn = ({ hidden = false }) => {
  const { activeLogTab } = useSyncLogsContext();
  const { lazyLoadLogs } = useLogsLazyPaginationHook();
  const { lazyLoadRealTimeLogs } = useRealtimeLogsLazyPaginationHook();
  const timeZone = useMemo(() => getTimeZone(), []);

  const handleClick = () => (activeLogTab ? lazyLoadRealTimeLogs({ timeZone }) : lazyLoadLogs());

  return hidden ? (
    ''
  ) : (
    <OutlinedButton
      onClick={handleClick}
      sx={{ border: 'solid 1px', whiteSpace: 'nowrap', width: 171 }}
      label="REFRESH LOGS"
    />
  );
};

export default RefreshLogsBtn;
