import { Custom } from 'components/Icons';
import { syncColors } from 'styles/theme/colors';

export const CONNECTION_TYPES = {
  PROCORE: 'PROCORE',
  AUTODESK: 'AUTODESK',
  AUTODESKFIELD: 'AUTODESKFIELD',
  AUTODESKGLUE: 'AUTODESKGLUE',
  AUTODESKHQ: 'AUTODESKHQ',
  AUTODESKTEAMDOCS: 'AUTODESKTEAMDOCS',
  SHAREPOINT: 'SHAREPOINT',
  PLANGRID: 'PLANGRID',
  NEWFORMA: 'NEWFORMA',
  FORESITE: 'EVOLVEFORESITE',
  WINDOWS: 'WINDOWS'
};
export const CONNECTION_NAME = {
  [CONNECTION_TYPES.PROCORE]: 'Procore',
  [CONNECTION_TYPES.AUTODESK]: 'Autodesk',
  [CONNECTION_TYPES.AUTODESKFIELD]: 'Autodesk Field',
  [CONNECTION_TYPES.AUTODESKGLUE]: 'Autodesk Glue',
  [CONNECTION_TYPES.AUTODESKHQ]: 'Autodesk HQ',
  [CONNECTION_TYPES.AUTODESKTEAMDOCS]: 'BIM 360 Team /Autodesk Docs',
  [CONNECTION_TYPES.SHAREPOINT]: 'Sharepoint',
  [CONNECTION_TYPES.PLANGRID]: 'Plangrid',
  [CONNECTION_TYPES.NEWFORMA]: 'Newforma',
  [CONNECTION_TYPES.FORESITE]: 'Foresite',
  [CONNECTION_TYPES.WINDOWS]: 'Windows'
};

export const COPY_FILES_ACTION_STEPS = {
  SOURCE: 'Source',
  DESTINATION: 'Destination',
  SYNC: 'Sync'
};

export const CONNECTOR_NODE_TYPES = {
  SOURCE: 'source',
  DESTINATION: 'destination'
};

export const CONNECTION_KEY_LABEL_MAPPING = {
  [CONNECTION_TYPES.PROCORE]: 'Procore',
  [CONNECTION_TYPES.AUTODESK]: 'Autodesk',
  [CONNECTION_TYPES.AUTODESKFIELD]: 'Autodesk Field',
  [CONNECTION_TYPES.AUTODESKGLUE]: 'Autodesk Glue',
  [CONNECTION_TYPES.AUTODESKHQ]: 'Autodesk HQ',
  [CONNECTION_TYPES.AUTODESKTEAMDOCS]: 'Autodesk Teams/Docs',
  [CONNECTION_TYPES.SHAREPOINT]: 'Sharepoint',
  [CONNECTION_TYPES.PLANGRID]: 'Plangrid',
  [CONNECTION_TYPES.NEWFORMA]: 'Newforma',
  [CONNECTION_TYPES.FORESITE]: 'Evolve Foresite',
  [CONNECTION_TYPES.WINDOWS]: 'Windows'
};

export function getConnectionTypeKey(type) {
  return Object.keys(CONNECTION_KEY_LABEL_MAPPING).find((key) => CONNECTION_KEY_LABEL_MAPPING[key] === type);
}

export const CONNECTION_KEY_API_PARAMS_MAPPING = {
  [CONNECTION_TYPES.PROCORE]: 'procore',
  [CONNECTION_TYPES.AUTODESKFIELD]: 'autodeskfield',
  [CONNECTION_TYPES.AUTODESKGLUE]: 'autodeskglue',
  [CONNECTION_TYPES.AUTODESKHQ]: 'autodeskhq',
  [CONNECTION_TYPES.AUTODESKTEAMDOCS]: 'autodeskteamdocs',
  [CONNECTION_TYPES.SHAREPOINT]: 'sharepoint',
  [CONNECTION_TYPES.PLANGRID]: 'plangrid',
  [CONNECTION_TYPES.NEWFORMA]: 'newforma',
  [CONNECTION_TYPES.FORESITE]: 'evolveforesite',
  [CONNECTION_TYPES.WINDOWS]: 'windows'
};

export const CONNECTION_KEY_DESKTOP_MAPPING = {
  [CONNECTION_TYPES.PROCORE]: 'procoreConnections',
  [CONNECTION_TYPES.AUTODESKFIELD]: 'bim360Connections',
  [CONNECTION_TYPES.SHAREPOINT]: 'sharePointConnections',
  [CONNECTION_TYPES.PLANGRID]: 'plangridConnections',
  [CONNECTION_TYPES.NEWFORMA]: 'newformaConnections'
};

export const WORKFLOW_MODAL_KEYS = {
  CREATE_WORKFLOW: 'CREATE_WORKFLOW',
  CREATE_MAPPING: 'CREATE_MAPPING',
  CREATE_TEMPLATE: 'CREATE_TEMPLATE',
  DELETE_WORKFLOW: 'DELETE_WORKFLOW',
  DELETE_TEMPLATE: 'DELETE_TEMPLATE',
  DUPLICATE_WORKFLOW: 'DUPLICATE_WORKFLOW',
  DUPLICATE_MAPPING: 'DUPLICATE_MAPPING',
  DUPLICATE_TEMPLATE: 'DUPLICATE_TEMPLATE',
  RENAME_WORKFLOW: 'RENAME_WORKFLOW',
  RENAME_MAPPING: 'RENAME_MAPPING',
  RENAME_TEMPLATE: 'RENAME_TEMPLATE',
  ACTIVATE_WORKFLOW: 'ACTIVATE_WORKFLOW',
  DEACTIVATE_WORKFLOW: 'DEACTIVATE_WORKFLOW',
  ARCHIVE_WORKFLOW: 'ARCHIVE_WORKFLOW',
  RESTORE_WORKFLOW: 'RESTORE_WORKFLOW'
};
export const WEBHOOK_MODAL_KEYS = {
  DELETE_WEBHOOK: 'DELETE_WEBHOOK',
  ACTIVATE_WEBHOOK: 'ACTIVATE_WEBHOOK',
  DEACTIVATE_WEBHOOK: 'DEACTIVATE_WEBHOOK'
};

export const MAPPING_ACTIONS = {
  copyFiles: 'Copy Files',
  publish: 'Publish'
};

export const MAPPING_ACTION_TYPES = {
  copyFiles: 'FileSync',
  publishAction: 'PublishModel'
};

export const ACTIONS_DRAWER = [
  {
    name: 'Copy Files',
    validations: { minConnectorNodesRequired: 2 },
    icon: 'COPY_FILES'
  },
  {
    name: 'Manage issues',
    validations: { minConnectorNodesRequired: 2 },
    icon: 'MANAGE_ISSUES',
    hidden: true
  },
  {
    name: 'Publish',
    validations: {
      minConnectorNodesRequired: 1,
      minRequiredConnections: [CONNECTION_TYPES.AUTODESKHQ, CONNECTION_TYPES.AUTODESKTEAMDOCS]
    },
    icon: 'PUBLISH'
  }
];

export const fieldServerOptions = ['US', 'EU'];
export const glueServerOptions = ['US', 'EU'];
export const teamsAndDocsServerOptions = ['US', 'EU'];
export const hQServerOptions = ['US', 'EU'];

export const ROUTES = {
  SYNC: '/sync',
  FACILITIES: 'facilities',
  PROJECTS: 'projects',
  SYNC_MEMBERS: '360-sync-members',
  PROJECT_MEMBERS: 'members/project-members',
  PROJECT_PROPERTIES: 'properties/project-properties',
  CONNECTIONS: '/connectors',
  CONNECTIONS_CALLBACK: '/connections/callback',
  WORKFLOWS: '/workflows',
  MAPPING_EDITOR: '/workflows/editor/mapping/:id',
  TEMPLATE_EDITOR: '/workflows/editor/template/:id',
  ARCHIVED_EDITOR: '/workflows/editor/archived/:id',
  LOGS: '/logs',
  DASHBOARD: '/dashboard',
  LOGS_DETAILS: '/logs/details/:id'
};

export const CANVAS_VALIDATIONS = {
  ENFORCE_CANVAS_VALIDATIONS: true,
  MAX_ACTION_NODES_ALLOWED: 5,
  MAX_DESTINATION_ALLOWED_PER_SOURCE: 5,
  MIN_CONNECTOR_NODES_REQUIRED_FOR_ACTION: 2,
  MIN_CONNECTOR_NODES_REQUIRED_FOR_PUBLISH_ACTION: 1
};

export const DRAWER_NAV_MENU_ITEMS = {
  CONNECTOR: 'connector-tasks',
  ACTION: 'action-tasks',
  PROPERTIES: 'properties'
};

export const CONNECTOR_CANVAS_NODE = 'connectorType';
export const ACTION_CANVAS_NODE = 'actionType';
export const FLOATING_CANVAS_EDGE = 'floating'; /* A.K.A. connecting lines on the canvas */

export const CANVAS_NODE_TYPES = {
  CONNECTOR: 'connector',
  ACTION: 'action'
};

export const CANVAS_TEMPLATE_CORDINATES = {
  SOURCE: {
    x: 0.0003528223806341833,
    y: 0
  },
  DESTINATION: {
    x: 0.0005738884365248955,
    y: 200
  },
  ACTION_NODE: {
    x: 0.00033302085886590895,
    y: 100
  },
  CANVAS_POSITION: [485.34301336703135, 84.42929292929293],
  CANVAS_ZOOM: 1.1565656565656566
};

export const DOCUMENT_TYPE_FOLDER_ID = 'document';
export const DRAWING_TYPE_FOLDER_ID = 'drawing';
export const RFI_TYPE_FOLDER_ID = 'rfi';
export const SUBMITTAL_TYPE_FOLDER_ID = 'submittal';

export const FOLDER_TYPES = [
  {
    id: DOCUMENT_TYPE_FOLDER_ID,
    text: 'Documents',
    disabled: false,
    allowCreateFolder: true,
    isFolder: false,
    expandableFolders: true,
    downloadOnly: false
  },
  {
    id: DRAWING_TYPE_FOLDER_ID,
    text: 'Drawings',
    disabled: false,
    allowCreateFolder: true /* Use 'permitFolderCreationForIds' to restrict folder creation for root folder only */,
    isFolder: false,
    expandableFolders: false /* All the folders under this type are in-expandable */,
    downloadOnly: false
  },
  {
    id: RFI_TYPE_FOLDER_ID,
    text: 'RFIs',
    disabled: false,
    allowCreateFolder: false,
    isFolder: true /* No folder selection for this folder type */,
    expandableFolders: true,
    downloadOnly: true /* Only allowed on source */
  },
  {
    id: SUBMITTAL_TYPE_FOLDER_ID,
    text: 'Submittals',
    disabled: false,
    allowCreateFolder: false,
    isFolder: true /* No folder selection for this folder type */,
    expandableFolders: true,
    downloadOnly: true /* Only allowed on source */
  }
];

export const DESKTOP_FOLDER_TYPE_MAPPING = {
  'ConnectionType=0': DOCUMENT_TYPE_FOLDER_ID,
  'ConnectionType=1': DRAWING_TYPE_FOLDER_ID,
  'ConnectionType=2': RFI_TYPE_FOLDER_ID,
  'ConnectionType=3': SUBMITTAL_TYPE_FOLDER_ID
};

export const DESKTOP_FOLDER_TYPE_MAPPING_API = {
  'ConnectionType=0': 'Documents',
  'ConnectionType=1': 'Drawings',
  'ConnectionType=2': 'RFIs',
  'ConnectionType=3': 'Submittals'
};

export const SYNC_STATUS = {
  START: 'Start',
  SYNC_IN_PROGRESS: 'SyncInProgress',
  REAL_TIME_SYNC_IN_PROGRESS: 'RealTimeSyncInProgress'
};

export const ACTION_STATUS = {
  PENDING: 0,
  IN_PROGRESS: 1,
  COMPLETED: 2,
  FAILED: 3,
  CANCELLED: 4,
  UP_TO_DATE: 5,
  DISABLED: 6,
  IN_THE_QUEUE: 7,
  NEED_REVIEW: 8 /* Front end exclusive */,
  DEACTIVATED: 9 /* Custom status */
};

export const SYNC_LOGS = {
  LOGS_SYNC_TYPE: {
    SYNCED: 0,
    REAL_TIME_SYNC: 1,
    SCHEDULED: 2
  },
  LOGS_SYNC_STATUS: {
    PENDING: 0,
    IN_PROGRESS: 1,
    COMPLETED: 2,
    FAILED: 3,
    CANCELLED: 4,
    UP_TO_DATE: 5,
    DISABLED: 6,
    IN_THE_QUEUE: 7,
    NEED_REVIEW: 8 /* Front end exclusive */,
    DEACTIVATED: 9 /* Custom status */
  },

  LOG_FILE_TERMINAL_STATUS: {
    IN_PROGRESS: 0,
    SUCCESS: 1,
    FAILED: 2,
    SKIPPED: 3
  },
  LOG_FOLDER_TERMINAL_STATUS: {
    IN_PROGRESS: 0,
    SUCCESS: 1,
    NEEDS_REVIEW: 2,
    FAILED: 3,
    SKIPPED: 4
  },
  LOG_ACTION_TERMINAL_STATUS: {
    IN_PROGRESS: 0,
    SUCCESS: 1,
    NEEDS_REVIEW: 2,
    FAILED: 3,
    SKIPPED: 4
  }
};

export const REALTIME_SYNC = 'RealTimeSync';
export const SCHEDULE_SYNC = 'ScheduleSync';
export const SYNC_NOW = 'SyncNow';

export const SYNC_OPTION_LABELS = {
  [MAPPING_ACTIONS.publish]: {
    optionLabel: 'Publish',
    disabledDescription: 'Not ready to publish or need to pause an existing publish.',
    scheduleDescription: 'Publish automatically on a defined schedule.',
    chooseDaysDescription: 'Choose the days to schedule publish*'
  },
  [MAPPING_ACTIONS.copyFiles]: {
    optionLabel: 'Sync',
    disabledDescription: 'Not ready to sync or need to pause an existing sync.',
    scheduleDescription: 'Sync automatically on a defined schedule.',
    chooseDaysDescription: 'Choose the days to schedule sync*'
  }
};

export const SYNC_TYPES = [
  {
    label: 'Sync now',
    alternateLabel: 'Sync now',
    value: SYNC_NOW,
    syncType: SYNC_NOW,
    icon: <Custom.Complete sx={{ width: 20, height: 20 }} style={{ fill: syncColors.canvasNodeColors.syncNow }} />,
    description: 'Copy files right now. This will only occur once.',
    isDetailed: false
  },
  {
    label: 'Schedule sync',
    alternateLabel: 'Scheduled sync',
    value: SCHEDULE_SYNC,
    syncType: SCHEDULE_SYNC,
    icon: (
      <Custom.UpdateIcon sx={{ width: 20, height: 20 }} style={{ fill: syncColors.canvasNodeColors.scheduleSync }} />
    ),
    description: 'Sync automatically on a defined schedule.',
    isDetailed: true
  },
  {
    label: 'Real-time sync',
    alternateLabel: 'Real-time sync',
    value: REALTIME_SYNC,
    syncType: REALTIME_SYNC,
    icon: <Custom.SyncIcon sx={{ width: 20, height: 20 }} style={{ fill: syncColors.canvasNodeColors.realTimeSync }} />,
    description: 'Sync once immediately, then sync updated files in real-time.',
    isDetailed: false
  }
];

export const PUBLISH_SYNC_TYPES = [
  {
    label: 'Publish file now',
    alternateLabel: 'Publish file now',
    value: SYNC_NOW,
    syncType: SYNC_NOW,
    icon: <Custom.Complete sx={{ width: 20, height: 20 }} style={{ fill: syncColors.canvasNodeColors.syncNow }} />,
    description: 'Publish file right now. This will only occur once.',
    isDetailed: false
  },
  {
    label: 'Schedule publish file',
    alternateLabel: 'Schedule publish file',
    value: SCHEDULE_SYNC,
    syncType: SCHEDULE_SYNC,
    icon: (
      <Custom.UpdateIcon sx={{ width: 20, height: 20 }} style={{ fill: syncColors.canvasNodeColors.scheduleSync }} />
    ),
    description: 'Publish automatically on a defined schedule.',
    isDetailed: true
  }
];

export const SYNC_ENABLED_CONNECTIONS = [
  CONNECTION_TYPES.PROCORE,
  CONNECTION_TYPES.AUTODESKHQ,
  CONNECTION_TYPES.AUTODESKTEAMDOCS,
  CONNECTION_TYPES.AUTODESKFIELD,
  CONNECTION_TYPES.AUTODESKGLUE,
  CONNECTION_TYPES.SHAREPOINT,
  CONNECTION_TYPES.PLANGRID,
  CONNECTION_TYPES.NEWFORMA,
  CONNECTION_TYPES.FORESITE,
  CONNECTION_TYPES.WINDOWS
];

export const SHOW_LOGS_SEARCH_BAR = true;

export const SYNC_DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
export const FORMATS = ['AM', 'PM'];

export const DRAGGED_NODE_ELEMENT_ID = '__dragged_node_element__';

export const VALIDATION_ENTITY = {
  CONNECTION: 'connection',
  MAPPING: 'mapping',
  TEMPLATE: 'template',
  WORKFLOW: 'workflow'
};

export const DESKTOP_CONNECTION_TYPE_MAPPING = {
  'BIM 360 Field': CONNECTION_TYPES.AUTODESKFIELD,
  'BIM 360 Glue': CONNECTION_TYPES.AUTODESKGLUE,
  'BIM 360 HQ': CONNECTION_TYPES.AUTODESKHQ,
  'BIM 360 Team/Docs': CONNECTION_TYPES.AUTODESKTEAMDOCS,
  Newforma: CONNECTION_TYPES.NEWFORMA,
  Procore: CONNECTION_TYPES.PROCORE,
  SharePoint: CONNECTION_TYPES.SHAREPOINT,
  Plangrid: CONNECTION_TYPES.PLANGRID,
  Windows: CONNECTION_TYPES.WINDOWS
};

export const DESKTOP_CONNECTION_NAME = { AUTODESKFIELD: 'BIM 360 Field' };

export const MIGRATION_TOOL = {
  WORKFLOW_ERROR: 'workflowError',
  MAPPING_ERROR: 'mappingError',
  FOLDER_TYPE: 'FolderType',
  DEFAULT_CONNECTION_PATH: 'C:\\360 Sync for Windows',
  DEFAULT_WORKFLOWS_PATH: 'C:\\360 Sync for Windows\\Configurations'
};

export const DEFAULT_ROOT_FOLDER = { ID: '<ProjectRoot>', TEXT: '<ProjectRoot>' };

export const MAX_DATAGRID_ROWS_IN_VIEW = 10;

export const DATAGRID_DEFAULT_PG_SIZE = 20;

export const MAX_DROPDOWN_ITEMS_IN_VIEW = 10;

export const DROPDOWN_DEFAULT_PG_SIZE = 20;

export const WORKFLOW_TABLE_ENTITIES = {
  WORKFLOW: 'workflow',
  MAPPING: 'mapping',
  SOLITARY_FLOW: 'solitaryFlow'
};

export const defaultSyncSchedule = {
  Sunday: false,
  Monday: false,
  Tuesday: false,
  Wednesday: false,
  Thursday: false,
  Friday: false,
  Saturday: false,
  days: [],
  hours: '',
  minutes: '',
  format: 'AM'
};

export const LOG_POLLING_INTERVAL = 30000;

export const WORKFLOW_CANVAS_SYNC_STATUS_POLLING_INTERVAL = 3000;

export const LOG_FILE_DOWNLOAD_BATCH_SIZE = 20000;
export const WAIT_TIME_FOR_DOWNLOAD_COMPLETED = 1000;

export const filterTypes = {
  ALL: 'ALL',
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED'
};

export const ENABLE_DATA_USAGE_REPORTS = false;
export const IMPORT_GLOBAL_WINDOWS_SELECT = 'windowsConnectorsAll';
export const IMPORT_CONFIG_POSTFIX = 'FileSync.json';

export const SYNC_ENTITY_NAME_MAX_LENGTH = 32;
