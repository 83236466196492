import React from 'react';

import { Box, List } from '@mui/material';

import { CONNECTION_TYPES, MAPPING_ACTIONS } from 'modules/Sync/Constants/constants';
import TabListItem from 'modules/Sync/WorkflowEditor/Drawers/PropertiesDrawer/TabListItem';
import { getSyncType } from 'modules/Sync/WorkflowEditor/helpers/mappingConfigHelper';

const SourceTab = ({ data: source, action }) => {
  const {
    hub: { name: hubName } = {},
    connection: { configuration, type: connectionType } = {},
    projects,
    settings: { isSyncEnabled, syncType, scheduleSyncConfig } = {}
  } = source;
  const folders = projects?.[0]?.folders;
  const { days, hours, minutes, format } = scheduleSyncConfig || {};
  const {
    userName,
    email,
    password,
    useCustomApplicationCredentials,
    forgeHQServer,
    isField,
    isGlue,
    isTeamDocs,
    isHQ,
    shouldUploadFileInfoLog
  } = JSON.parse(configuration || '{}');

  const { label: syncLabel } = getSyncType(syncType);
  const syncedItem = action === MAPPING_ACTIONS.publish ? 'File' : 'Folder';

  return (
    <List sx={{ paddingTop: 0 }}>
      {connectionType && <TabListItem title="Connection" content={connectionType} />}
      <TabListItem title="Sync Enabled" content={isSyncEnabled ? 'Yes' : 'No'} />
      {syncType && <TabListItem title="syncType" content={<Box>{syncLabel}</Box>} />}
      {!!days?.length && <TabListItem title="Days" content={days?.join('|')} />}
      {hours && minutes && format && <TabListItem title="Start Time" content={`${hours}:${minutes} ${format}`} />}
      {hubName && (
        <TabListItem title={connectionType === CONNECTION_TYPES.PROCORE ? 'Company' : 'Hub'} content={hubName} />
      )}{' '}
      {!!projects?.length && (
        <TabListItem
          title={projects?.length === 1 ? 'Project' : 'Projects'}
          content={projects.reduce((acc, { name }) => (name ? [...acc, name] : acc), []).join(',')}
        />
      )}
      {!!folders?.length && (
        <TabListItem
          title={folders?.length === 1 ? syncedItem : `${syncedItem}s`}
          content={folders.reduce((acc, { name }) => (name ? [...acc, name] : acc), []).join(',')}
        />
      )}
      {email && <TabListItem title="Login" content={email} />}
      {userName && <TabListItem title="Username" content={userName} />}
      {password && <TabListItem title="Password" content={password} />}
      {useCustomApplicationCredentials && <TabListItem title="Use custom application credentials" content="Yes" />}
      {forgeHQServer && <TabListItem title="Forge Hq Server" content={forgeHQServer} />}
      {isField && <TabListItem title="Is Field" content="Yes" />}
      {isGlue && <TabListItem title="Is Glue" content="Yes" />}
      {isTeamDocs && <TabListItem title="Is Team Docs" content="Yes" />}
      {isHQ && <TabListItem title="Is Hq" content="Yes" />}
      {shouldUploadFileInfoLog && <TabListItem title="Should upload file info log" content="Yes" />}
    </List>
  );
};

export default SourceTab;
