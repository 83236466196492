export const connectionsMapper = (connections = []) => {
  if (!connections) return [];

  return connections.map(({ id, name, type }) => ({ nodeId: id, name, connectionType: type }));
};

export const actionsMapper = (actions = []) => {
  if (!actions) return [];

  return actions.reduce((actions, { actionAlias, name, isAvailable, icon, validations, hidden = false }) => {
    if (!hidden) actions.push({ actionAlias, name, isAvailable, icon, validations });
    return actions;
  }, []);
};
