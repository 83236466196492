import { useMemo, useState } from 'react';

import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid-pro';
import { Waypoint } from 'react-waypoint';

import { MoreMenu } from 'components';
import ItemsDataGridPro from 'components/ItemsDatagridPro';
import { getFormattedDateTime } from 'helpers/dateFunctions';
import CustomSyncTooltip from 'modules/Sync/components/CustomSyncTooltip';
import EmptyDataGridCell from 'modules/Sync/components/EmptyDataGridCell';
import ConnectionsDeleteModal from 'modules/Sync/Connections/components/ConnectionsDeleteModal';
import NewConnectionButton from 'modules/Sync/Connections/components/NewConnectionButton';
import { useSyncConnectionsContext } from 'modules/Sync/Connections/ConnectionsContext';
import { CONNECTION_KEY_LABEL_MAPPING } from 'modules/Sync/Constants/constants';
import { actionColors } from 'styles/theme/colors';

import ConnectionsDataGridTableColumns from './ConnectionsDataGridTableColumns';
import NoRowsOverlay from './NoRowsOverlay';

const connectionsDataGridSx = {
  mb: 1.6,
  '& .MuiDataGrid-checkboxInput.Mui-disabled': { opacity: 0.38 },
  '& .MuiDataGrid-row:hover': {
    '& .MuiSvgIcon-root': { opacity: 1 },
    cursor: 'pointer'
  },
  '& .MuiDataGrid-pinnedRows .MuiDataGrid-row': {
    background: actionColors.lightSurface.selected
  },
  '.MuiDataGrid-pinnedColumnHeaders, .MuiDataGrid-pinnedColumns': {
    background: 'transparent',
    boxShadow: 'none'
  },
  '.selected-connector': {
    background: actionColors.lightSurface.selected
  },
  '.MuiDataGrid-pinnedRows': {
    zIndex: 2,
    '.MuiDataGrid-pinnedRowsRenderZone>div>:nth-child(3)': {
      display: 'none'
    }
  }
};

const connectionsMapper = (connections = []) => {
  if (!connections) return [];

  return connections?.map(({ id, name, type, lastModified, isConnectionUsed, configuration }, index) => ({
    id,
    connectionId: id,
    connectionName: name || <EmptyDataGridCell />,
    connectionType: type || <EmptyDataGridCell />,
    typeLabel: CONNECTION_KEY_LABEL_MAPPING[type] || <EmptyDataGridCell />,
    lastModified: getFormattedDateTime(lastModified, true) || <EmptyDataGridCell />,
    isConnectionUsed,
    configuration: typeof configuration === 'string' ? JSON.parse(configuration) : configuration || {},
    isLast: index === connections?.length - 1
  }));
};
const initialRows = [{ connectionId: 0, connectionName: 'New Connector' }];

const ConnectionsDataGridTable = () => {
  const {
    currentConnection,
    selectedConnectionIds,
    setSelectedConnectionIds,
    paginationHandler,
    connectionsData,
    setCurrentConnection,
    loading,
    sortHandler,
    searchPhrase,
    setHeaderActionsDisabled
  } = useSyncConnectionsContext();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleOnDelete = (id) => {
    setSelectedConnectionIds([id]);
    setShowDeleteModal(true);
  };

  const onScroll = () => paginationHandler(connectionsData?.syncConnections?.length, { searchPhrase });

  const handleCellComponent = ({ id, field, row }) => {
    if (id === 0 && field === 'connectionName') {
      return <NewConnectionButton type="row" />;
    }
    if (id !== 0 && field === '*options') {
      return (
        !row?.isConnectionUsed &&
        currentConnection?.connectionType !== '' && <MoreOptions onDelete={() => handleOnDelete(id)} />
      );
    }
    if (id !== 0 && field === 'connectionType') {
      return <CellValue text={CONNECTION_KEY_LABEL_MAPPING[row[field]]} />;
    }
    return (
      <>
        <CellValue text={row[field]} />
        {row?.isLast && <Waypoint key={id} bottomOffset="-20%" onEnter={onScroll} />}
      </>
    );
  };

  const columns = [
    { ...GRID_CHECKBOX_SELECTION_COL_DEF, connectionType: '', hideable: false },
    ...ConnectionsDataGridTableColumns(handleCellComponent),
    {
      field: '*options',
      headerName: '',
      flex: 0.1,
      maxWidth: 40,
      hideable: false,
      sortable: false,
      renderCell: handleCellComponent
    }
  ];
  const rows = useMemo(() => connectionsMapper(connectionsData?.syncConnections), [connectionsData]);

  const handleDeleteModalClose = () => {
    setShowDeleteModal(false);
    setSelectedConnectionIds([]);
    setCurrentConnection();
  };

  const handleSort = (sortModel) => (sortModel.length ? sortHandler({ columnName: sortModel?.[0]?.field }) : null);

  const isCreatingNewConnection = currentConnection?.connectionType === '';
  return (
    <div style={{ width: `calc(${currentConnection?.connectionId} ? 50% : 100%)`, height: '100%' }}>
      <ItemsDataGridPro
        density="standard"
        disableColumnMenu
        disableSelectionOnClick
        columns={columns}
        rows={rows}
        loading={loading}
        initialState={{ pinnedColumns: { left: [], right: ['*options'] } }}
        pinnedRows={isCreatingNewConnection ? { top: initialRows } : {}}
        getRowId={(row) => row?.connectionId}
        getRowClassName={({ row: { id } }) => id === currentConnection?.id && 'selected-connector'}
        experimentalFeatures={{ rowPinning: true, newEditingApi: true }}
        onRowsScrollEnd={onScroll}
        components={{
          NoRowsOverlay: () => <NoRowsOverlay text="Start by creating a new connector." />
        }}
        onRowClick={(data) => {
          if (data?.row && data?.row?.connectionId && !isCreatingNewConnection) {
            setCurrentConnection(data?.row);
            setHeaderActionsDisabled(false);
          }
        }}
        isRowSelectable={(data) => !data.row.isConnectionUsed}
        onSelectionModelChange={(ids) => setSelectedConnectionIds(ids)}
        sortingOrder={['asc', 'desc']}
        onSortModelChange={handleSort}
        sx={connectionsDataGridSx}
      />
      <ConnectionsDeleteModal
        connectionIds={selectedConnectionIds}
        open={showDeleteModal}
        onClose={handleDeleteModalClose}
      />
    </div>
  );
};

export default ConnectionsDataGridTable;

const MoreOptions = ({ onDelete }) => (
  <MoreMenu
    options={[
      {
        label: 'Delete',
        clickEvent: onDelete,
        color: '#F44336'
      }
    ]}
    sx={{ fontSize: 21, opacity: 0, ':hover': { opacity: 1 } }}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
  />
);

const CellValue = ({ text }) => (
  <CustomSyncTooltip title={text} sx={{ maxWidth: '95%' }}>
    {text}
  </CustomSyncTooltip>
);
