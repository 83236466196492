import { gql } from '@apollo/client';

import { FETCH_POLICIES } from 'constants/globalConstants';
import { syncLogFileDetails as SYNC_LOG_FILE_DETAILS } from 'graphql/queries';
import useLazyPaginatedQuery from 'hooks/useLazyPaginatedQuery';
import { DATAGRID_DEFAULT_PG_SIZE } from 'modules/Sync/Constants/constants';

const { fetchPolicy: FETCH_POLICY } = FETCH_POLICIES.CACHE_AND_NETWORK;

const useLogsDetailsLazyPaginationHook = (fetchPolicy = FETCH_POLICY) => {
  const [
    {
      lazyLoad: lazyLoadLogsDetails,
      paginationHandler: logsDetailsPaginationHandler,
      searchHandler: logsDetailsSearchHandler,
      refetch: refetchLogsDetailsPages,
      refetchExistingPages: refetchExistingLogsDetailsPages,
      startPolling: startPollingLogDetails,
      stopPolling: stopPollingLogDetails
    },
    { loading: loadingLogDetails, data: logDetails }
  ] = useLazyPaginatedQuery(gql(SYNC_LOG_FILE_DETAILS), fetchPolicy, DATAGRID_DEFAULT_PG_SIZE);

  return {
    lazyLoadLogsDetails,
    logsDetailsPaginationHandler,
    logsDetailsSearchHandler,
    refetchLogsDetailsPages,
    refetchExistingLogsDetailsPages,
    startPollingLogDetails,
    stopPollingLogDetails,
    loadingLogDetails,
    logDetails
  };
};

export default useLogsDetailsLazyPaginationHook;
