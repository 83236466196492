import React, { useCallback, useEffect } from 'react';

import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { FormControl } from '@mui/material';
import { useForm } from 'react-hook-form';

import { syncRenameWorkflow as SYNC_RENAME_WORKFLOW } from 'graphql/mutations';
import { syncValidateName as SYNC_VALIDATE_NAME } from 'graphql/queries';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';
import CustomModal from 'modules/Sync/components/CustomModal';
import { WORKFLOW_MODAL_KEYS, VALIDATION_ENTITY } from 'modules/Sync/Constants/constants';
import { useSyncWorkflowsContext } from 'modules/Sync/Workflows/Contexts/WorkflowsContext';

import ModalFooter from './ModalFooter';
import ModalTitle from './ModalTitle';
import WorkflowTextField from './WorkflowTextField';

const { RENAME_WORKFLOW } = WORKFLOW_MODAL_KEYS;

const RenameWorkflowModal = ({ onClose }) => {
  const { currentWorkflow, activeModal } = useSyncWorkflowsContext();
  const { workflowName, workflowId } = currentWorkflow || {};
  const open = activeModal === RENAME_WORKFLOW;
  const { handleResponse } = useGraphqlResponseHandler();

  const methods = useForm({
    mode: 'all',
    defaultValues: { workflowName }
  });
  const {
    formState: { errors, isValid },
    control,
    handleSubmit,
    setValue,
    setError,
    clearErrors
  } = methods;

  const [renameWorkflow, { loading }] = useMutation(gql(SYNC_RENAME_WORKFLOW), {
    refetchQueries: ['SyncWorkflows']
  });

  useEffect(() => {
    if (workflowName) {
      setValue('workflowName', workflowName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflowName]);

  const onModalClose = () => {
    onClose?.();
  };

  const [validateName, { errors: validationErrors }] = useLazyQuery(gql(SYNC_VALIDATE_NAME), {
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
    variables: { params: { entity: VALIDATION_ENTITY.WORKFLOW } }
  });

  const handleChange = useCallback(
    (searchName) => {
      validateName({
        variables: {
          query: {
            name: encodeURIComponent(searchName.trim())
          }
        }
      });
    },
    [validateName]
  );

  const onSubmit = handleSubmit((formValues) => {
    const { workflowName } = formValues;
    return handleResponse(
      renameWorkflow,
      {
        variables: { body: { WorkflowName: workflowName, WorkflowId: workflowId } }
      },
      {
        successMessage: 'Workflow successfully saved'
      },
      onModalClose,
      onModalClose
    );
  });

  return (
    <CustomModal
      disableModalOutsideClick
      open={open}
      title={<ModalTitle title="Rename workflow" />}
      onClose={onModalClose}
      sx={{ '& .MuiPaper-root': { width: '475px' } }}
      footer={
        <ModalFooter
          loading={loading}
          onModalClose={onModalClose}
          onSubmit={onSubmit}
          onSubmitDisabled={!isValid}
        />
      }
    >
      <FormControl size="small" fullWidth>
        <WorkflowTextField
          disabled={!workflowId || loading}
          control={control}
          errors={errors}
          setError={setError}
          clearErrors={clearErrors}
          validateChange={handleChange}
          validationErrors={validationErrors}
          defaultValue={workflowName}
        />
      </FormControl>
    </CustomModal>
  );
};

export default RenameWorkflowModal;
