import { useEffect } from 'react';

import { gql, useLazyQuery } from '@apollo/client';

import { FETCH_POLICIES } from 'constants/globalConstants';
import {
  syncGetAllWorkflowMappingTemplates as SYNC_GET_ALL_WORKFLOW_MAPPING_TEMPLATES,
  syncGetWorkflowMappingTemplatesList as SYNC_GET_WORKFLOW_MAPPING_TEMPLATES_LIST
} from 'graphql/queries';
import useLazyPaginatedQuery from 'hooks/useLazyPaginatedQuery';
import { DATAGRID_DEFAULT_PG_SIZE } from 'modules/Sync/Constants/constants';

const { fetchPolicy: FETCH_POLICY } = FETCH_POLICIES.CACHE_AND_NETWORK;

const useTemplatesLazyPaginationHook = () => {
  const [
    {
      lazyLoad: lazyLoadTemplates,
      paginationHandler: templatesPaginationHandler,
      searchHandler: templatesSearchHandler,
      onOrderby: templatesSortHandler
    },
    { loading: loadingTemplates, data: templates }
  ] = useLazyPaginatedQuery(gql(SYNC_GET_WORKFLOW_MAPPING_TEMPLATES_LIST), FETCH_POLICY, DATAGRID_DEFAULT_PG_SIZE);

  const [
    loadAllTemplates,
    { data: { syncGetAllWorkflowMappingTemplates: allTemplatesList = [] } = {}, loading: loadingAllTemplates = false }
  ] = useLazyQuery(gql(SYNC_GET_ALL_WORKFLOW_MAPPING_TEMPLATES), {
    FETCH_POLICY
  });

  useEffect(() => {
    lazyLoadTemplates(false);
    loadAllTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    lazyLoadTemplates,
    templatesPaginationHandler,
    templatesSearchHandler,
    templatesSortHandler,
    loadingTemplates,
    templates,
    loadAllTemplates,
    allTemplatesList,
    loadingAllTemplates
  };
};

export default useTemplatesLazyPaginationHook;
