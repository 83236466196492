import React, { useState } from 'react';

import { createFilterOptions, Autocomplete } from '@mui/material';
import { ForesiteTextField } from 'evolvemep-foresite-ui-components';
import { useController } from 'react-hook-form';

import { getMicroFeContainer } from 'helpers/domHelpers';

const SimpleControlledAutoComplete = ({
  limitOptions,
  options,
  control,
  name,
  label,
  rules,
  defaultValue = '',
  placeholder,
  helperText,
  freeSolo = true,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [highlightedOption, setHighligtedOption] = useState({});
  const filterOptions = (options, state) => createFilterOptions()(options, state).slice(0, limitOptions);

  const {
    field: { onChange, value },
    fieldState: { error }
  } = useController({
    name,
    control,
    rules,
    defaultValue,
    shouldUnregister: true
  });

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && open) {
      onChange(highlightedOption);
      setOpen(false);
      e.stopPropagation();
    }
  };

  return (
    <Autocomplete
      freeSolo={freeSolo}
      placeholder={placeholder}
      filterOptions={filterOptions}
      value={value}
      onChange={(_event, item) => onChange(item)}
      onInputChange={(_event, inputValue) => onChange(inputValue)}
      options={options}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onHighlightChange={(_e, option) => setHighligtedOption(option)}
      componentsProps={{
        popper: {
          container: getMicroFeContainer
        }
      }}
      renderOption={(renderOptionProps, option) => {
        const label = props.getOptionLabel?.(option) ?? option?.label;
        const itemOption = { id: option?.id, label };
        return <AutocompleteOption key={option?.id} itemOption={itemOption} renderOptionProps={renderOptionProps} />;
      }}
      renderInput={(params) => (
        <ForesiteTextField
          {...params}
          margin="normal"
          variant="outlined"
          label={label}
          error={!!error}
          helperText={error?.message?.replace("'", '').replace("'", '') || helperText}
          fullWidth
          placeholder={placeholder}
          {...props}
          id="ClickAwayListener-ItemIgnored-TextField"
          data-cy="assign-admin"
          onKeyDown={handleKeyDown}
        />
      )}
      {...props}
    />
  );
};

export default SimpleControlledAutoComplete;

const AutocompleteOption = ({ itemOption, renderOptionProps }) => (
  <li
    key={itemOption.id}
    tabIndex="-1"
    role="option"
    data-option-index="0"
    aria-disabled="false"
    aria-selected="false"
    className="MuiAutocomplete-option Mui-focused"
    style={{
      padding: '0 16px'
    }}
    {...renderOptionProps}
    id={`ClickAwayListener-ItemIgnored-${itemOption.id}`}
  >
    {itemOption.label}
  </li>
);
