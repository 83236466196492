import { useEffect } from 'react';

import { gql, useMutation } from '@apollo/client';
import { FormControl } from '@mui/material';
import { useForm } from 'react-hook-form';

import { syncDuplicateWorkflow as SYNC_DUPLICATE_WORKFLOW } from 'graphql/mutations';
import useGraphqlResponseHandler from 'hooks/useGraphqlResponseHandler';
import CustomModal from 'modules/Sync/components/CustomModal';
import { SYNC_ENTITY_NAME_MAX_LENGTH, WORKFLOW_MODAL_KEYS } from 'modules/Sync/Constants/constants';
import { useSyncWorkflowsContext } from 'modules/Sync/Workflows/Contexts/WorkflowsContext';

import ModalFooter from './ModalFooter';
import ModalTitle from './ModalTitle';
import WorkflowTextField from './WorkflowTextField';

const DuplicateWorkflowModal = ({ onClose }) => {
  const { currentWorkflow, activeModal } = useSyncWorkflowsContext();
  const { workflowName, workflowId } = currentWorkflow || {};
  const open = activeModal === WORKFLOW_MODAL_KEYS.DUPLICATE_WORKFLOW;
  const { handleResponse } = useGraphqlResponseHandler();

  const methods = useForm({
    mode: 'all',
    defaultValues: { workflowName }
  });
  const {
    formState: { errors, isValid },
    control,
    handleSubmit,
    setValue
  } = methods;

  const [duplicateWorkflow, { loading }] = useMutation(gql(SYNC_DUPLICATE_WORKFLOW), {
    refetchQueries: ['SyncWorkflows']
  });

  useEffect(() => {
    if (workflowName) {
      const name = `${workflowName} - clone`;
      setValue(
        'workflowName',
        name.length > SYNC_ENTITY_NAME_MAX_LENGTH ? name.slice(0, SYNC_ENTITY_NAME_MAX_LENGTH) : name
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflowName]);

  const onModalClose = () => {
    onClose?.();
  };

  const onSubmit = handleSubmit((formValues) => {
    const { workflowName } = formValues;

    return handleResponse(
      duplicateWorkflow,
      {
        variables: { body: { WorkflowName: workflowName, WorkflowId: workflowId } }
      },
      {
        successMessage: 'Workflow successfully duplicated'
      },
      onModalClose,
      onModalClose
    );
  });

  return (
    <CustomModal
      disableModalOutsideClick
      open={open}
      title={<ModalTitle title="Duplicate workflow" />}
      onClose={onModalClose}
      sx={{ '& .MuiPaper-root': { width: '475px' } }}
      footer={
        <ModalFooter loading={loading} onModalClose={onModalClose} onSubmit={onSubmit} onSubmitDisabled={!isValid} />
      }
    >
      <FormControl size="small" fullWidth>
        <WorkflowTextField
          disabled={loading}
          control={control}
          errors={errors}
          maxLength={SYNC_ENTITY_NAME_MAX_LENGTH}
        />
      </FormControl>
    </CustomModal>
  );
};

export default DuplicateWorkflowModal;
