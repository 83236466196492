import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GridHeaderCheckbox
} from '@mui/x-data-grid-pro';
import { v4 as uuidv4 } from 'uuid';

import { getFormattedDateTime } from 'helpers/dateFunctions';
import EmptyDataGridCell from 'modules/Sync/components/EmptyDataGridCell';
import WebhooksDataGridGroupingCell from 'modules/Sync/Webhooks/WebhooksDataGridTable/WebhooksDataGridGroupingCell';
import WebhooksDataGridTableColumns from 'modules/Sync/Webhooks/WebhooksDataGridTable/WebhooksDataGridTableColumns';

export const webhooksMapper = (WebhooksData = []) => {
  if (!WebhooksData?.length) return [];

  const getWebhook = WebhooksData.filter(({ webhookId }) => webhookId).flatMap(
    ({ webhookId, webhookDetails, workflows, isActive }) => {
      const uid = uuidv4();
      return [
        {
          path: [`${webhookId}-${uid}`],
          id: `${webhookId}-${uid}`,
          rowWebhookId: webhookId,
          webhookId,
          webhookDetails,
          name: webhookDetails || <EmptyDataGridCell />,
          workflows,
          isActive,
          isWebhook: true
        },
        ...workflows.flatMap(({ workflowName, workflowId, mappings }) => {
          const wuid = uuidv4();
          return [
            {
              path: [`${webhookId}-${uid}`, `${workflowId}-${wuid}`],
              id: `${workflowId}-${wuid}`,
              workflowId,
              name: workflowName || <EmptyDataGridCell />,
              mappings,
              isworkflow: true
            },
            ...mappings?.flatMap(({ mappingId, mappingName, solitaryFlows, lastSyncTime }) => {
              const mappinguid = uuidv4();
              return [
                {
                  path: [`${webhookId}-${uid}`, `${workflowId}-${wuid}`, `${mappingId}-${mappinguid}`],
                  id: `${mappingId}-${mappinguid}`,
                  mappingId,
                  name: mappingName || <EmptyDataGridCell />,
                  lastSyncAt: lastSyncTime ? getFormattedDateTime(lastSyncTime, true) : <EmptyDataGridCell />,
                  lastSavedAt: lastSyncTime ? getFormattedDateTime(lastSyncTime, true) : <EmptyDataGridCell />,
                  solitaryFlows,
                  ismapping: true
                },
                ...solitaryFlows?.map(({ solitaryFlowId, actionAlias }) => {
                  const actionuid = uuidv4();
                  return {
                    path: [
                      `${webhookId}-${uid}`,
                      `${workflowId}-${wuid}`,
                      `${mappingId}-${mappinguid}`,
                      `${solitaryFlowId}-${actionuid}`
                    ],
                    id: `${solitaryFlowId}-${actionuid}`,
                    mappingId,
                    solitaryFlowId,
                    name: actionAlias || <EmptyDataGridCell />
                  };
                })
              ];
            })
          ];
        })
      ];
    }
  );

  return getWebhook;
};

export const groupingColDef = {
  headerName: '',
  hideable: false,
  sortable: false,
  maxWidth: 40,
  resizable: false,
  renderCell: (params) => <WebhooksDataGridGroupingCell {...params} />
};

export const getColumns = ({ renderCell, apiRef }) => [
  {
    ...GRID_CHECKBOX_SELECTION_COL_DEF,
    type: '',
    maxWidth: 40,
    hideable: false,
    resizable: false,
    renderHeader: (params) => {
      const totalSelectableRowsCount = apiRef.current?.getRowModels()?.filter(({ isWebhook }) => isWebhook)?.size ?? 0;
      const selectedRows = apiRef.current?.getSelectedRows();
      const selectedRowsCount = selectedRows?.size;
      const indeterminate = selectedRowsCount > 0 && selectedRowsCount < totalSelectableRowsCount;
      return <GridHeaderCheckbox {...params} indeterminate={indeterminate} checked={selectedRowsCount > 0} />;
    }
  },
  { ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF, maxWidth: 40, hideable: false, resizable: false },
  ...WebhooksDataGridTableColumns(renderCell),
  {
    field: '*options',
    headerName: '',
    flex: 1,
    maxWidth: 40,
    hideable: false,
    sortable: false,
    resizable: false,
    renderCell
  }
];

export const defaultDataGridConfig = {
  treeData: true,
  disableChildrenSorting: true,
  disableChildrenFiltering: true,
  density: 'standard',
  disableColumnMenu: true,
  disableSelectionOnClick: true,
  experimentalFeatures: { rowPinning: true, newEditingApi: true },
  initialState: { pinnedColumns: { left: [], right: ['*options'] } }
};

export const dataGridStyles = {
  marginBottom: 1.6,
  '& .MuiDataGrid-checkboxInput.Mui-disabled': { opacity: 0.38 },
  '.MuiDataGrid-row [data-id="create-mapping-icon"]': {
    opacity: 0
  },
  '& .MuiDataGrid-row:hover': {
    '[data-id="create-mapping-icon"]': { opacity: 1 },
    '& .MuiSvgIcon-root': { opacity: 1 },
    cursor: 'pointer'
  },

  '& .MuiDataGrid-columnHeader:nth-child(2) .MuiDataGrid-columnSeparator': {
    visibility: 'hidden'
  },

  '.mapping-row > .MuiDataGrid-cellCheckbox': {
    paddingLeft: 5
  },
  '.workflow-row > .MuiDataGrid-cellCheckbox > .MuiButtonBase-root': {
    opacity: 0
  },
  '.mapping-row > .MuiDataGrid-cellCheckbox > .MuiButtonBase-root': {
    opacity: 0
  },
  '.action-row > .MuiDataGrid-cellCheckbox > .MuiButtonBase-root': {
    opacity: 0
  },
  '.mapping-row:hover .mapping-name, .action-row:hover .action-name': {
    textDecoration: 'underline'
  },
  '.webhook-row .webhook-name': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  '.mapping-row > .MuiDataGrid-cell[data-field="__tree_data_group__"]': {
    margin: 0
  },
  '.MuiDataGrid-columnHeaderTitle': {
    fontWeight: 500
  },
  '.MuiDataGrid-pinnedColumnHeaders, .MuiDataGrid-pinnedColumns': {
    backgroundColor: 'transparent',
    boxShadow: 'none'
  }
};
